import {
  ButtonBase,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Align, Justify } from '@vestaboard/vbml'
import React, { useState } from 'react'

interface IQaTitle {
  title: string | null
  enabled: boolean
  color: string | null
  justify?: Justify | null
  align?: Align | null
  setTitle: (title: string | null) => void
  setEnabled: (enabled: boolean) => void
  setColor: (color: string | null) => void
  setJustify?: (justify: Justify | null) => void
  setAlign?: (align: Align | null) => void
}

const swatchStyle = {
  width: 32,
  height: 32,
  borderRadius: 5,
  border: '1px solid #ccc',
  marginRight: 5
}

const useStyles = makeStyles({
  swatch: swatchStyle,
  activeSwatch: {
    ...swatchStyle,
    borderColor: '#000'
  }
})

const swatches = [
  {
    code: '63',
    title: 'Red',
    color: '#da291c'
  },
  {
    code: '64',
    title: 'Orange',
    color: '#ff7401'
  },
  {
    code: '65',
    title: 'Yellow',
    color: '#feb81d'
  },
  {
    code: '66',
    title: 'Green',
    color: '#009b45'
  },
  {
    code: '67',
    title: 'Blue',
    color: '#0184d4'
  },
  {
    code: '68',
    title: 'Purple',
    color: '#712f8b'
  },
  {
    code: '69',
    title: 'White',
    color: '#ffffff'
  },
  {
    code: '70',
    title: 'Black',
    color: '#000000'
  },
  {
    code: '0',
    title: 'Blank',
    color: '#333'
  },
  {
    code: '71',
    title: 'Inverse',
    color: '#DDD'
  }
]

export const QaTitle = (props: IQaTitle) => {
  const [title, setTitle] = useState<string>(props.title || '')

  const classes = useStyles()

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h5'>Style</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 200 }}>
                <Typography>Enabled Title</Typography>
              </TableCell>
              <TableCell>
                <Switch checked={props.enabled} onChange={e => props.setEnabled(e.target.checked)} />
              </TableCell>
            </TableRow>
            {props.enabled && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography>Title</Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      fullWidth
                      value={title}
                      onChange={e => {
                        if (e.target.value.length > 18) {
                          return
                        }

                        setTitle(e.target.value)
                      }}
                      onBlur={() => {
                        props.setTitle(title)
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Title Color</Typography>
                  </TableCell>
                  <TableCell>
                    {swatches.map(swatch => (
                      <ButtonBase
                        key={swatch.code}
                        title={`${swatch.title} (${swatch.code})`}
                        onClick={() => {
                          props.setColor(swatch.code)
                        }}>
                        <div
                          className={swatch.code === props.color ? classes.activeSwatch : classes.swatch}
                          style={{ backgroundColor: swatch.color }}></div>
                      </ButtonBase>
                    ))}
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell>
                <Typography>Justify Messages</Typography>
              </TableCell>
              <TableCell>
                <Select
                  variant='outlined'
                  value={props.justify || Justify.justified}
                  onChange={e => {
                    props.setJustify && props.setJustify(e.target.value as Justify)
                  }}>
                  <MenuItem value={Justify.left}>Left</MenuItem>
                  <MenuItem value={Justify.center}>Center</MenuItem>
                  <MenuItem value={Justify.right}>Right</MenuItem>
                  <MenuItem value={Justify.justified}>Justified</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Align Messages</Typography>
              </TableCell>
              <TableCell>
                <Select
                  variant='outlined'
                  value={props.align || Align.justified}
                  onChange={e => {
                    props.setAlign && props.setAlign(e.target.value as Align)
                  }}>
                  <MenuItem value={Align.top}>Top</MenuItem>
                  <MenuItem value={Align.center}>Center</MenuItem>
                  <MenuItem value={Align.bottom}>Bottom</MenuItem>
                  <MenuItem value={Align.justified}>Justified</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
