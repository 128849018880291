import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGetMessageSetSubmission } from '../hooks/useGetMessageSetSubmission'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { BoardPreview, Confirm, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { Link } from '../../link'
import InfiniteScroll from 'react-infinite-scroller'
import { useRemoveMessageSetSubmission } from '../hooks/useRemoveMessageSetSubmission'
import { useCreateMessageSet } from '../../message-set-v2/hooks/useCreateMessageSet'

export const MessageSetSubmission = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetMessageSetSubmission(id)
  const [messagesToShow, setMessagesToShow] = useState(25)
  const [isRejecting, setIsRejecting] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const [removeMessageSetSubmissionMutation] = useRemoveMessageSetSubmission()
  const [createMessageSet] = useCreateMessageSet()
  const history = useHistory()

  const messages =
    data?.submittedMessageSetV2?.__typename === 'SubmittedMessageSetV2' ? data?.submittedMessageSetV2.messages : []

  const removeMessageSetSubmission = useCallback(async () => {
    await removeMessageSetSubmissionMutation({
      variables: {
        input: {
          id
        }
      }
    })
  }, [id, removeMessageSetSubmissionMutation])

  return loading ? (
    <CircularProgress />
  ) : error || data?.submittedMessageSetV2.__typename === 'SubmittedMessageSetErrorV2' ? (
    <Alert severity='error'>There was an error loading the submitted message set</Alert>
  ) : (
    <>
      <Confirm
        title='Reject Submission?'
        message='Are you sure you want to reject this submission? This will remove it from the list of submissions.'
        open={isRejecting}
        handleClose={() => setIsRejecting(false)}
        handleAccept={async () => {
          await removeMessageSetSubmission()
          setIsRejecting(false)
          history.push('/submitted-message-sets')
        }}
      />
      <Confirm
        title='Approve Submission?'
        message='Are you sure you want to approve this submission? This will create a private message set with all of the message include. It will also remove it from the list of submissions.'
        open={isApproving}
        handleClose={() => setIsApproving(false)}
        handleAccept={async () => {
          if (data?.submittedMessageSetV2.__typename === 'SubmittedMessageSetV2') {
            await createMessageSet({
              variables: {
                input: {
                  title: data?.submittedMessageSetV2.title,
                  dynamic: false,
                  public: false,
                  messageIds: data?.submittedMessageSetV2.messages.map(message => message.id) || []
                }
              }
            })
            await removeMessageSetSubmission()
            setIsApproving(false)
            history.push('/submitted-message-sets')
          }
        }}
      />
      <Link to='/submitted-message-sets'>Message Set Submissions</Link>
      <Spacer size='small' />
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>{data?.submittedMessageSetV2.title}</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card component={Paper}>
        <Box padding={'24px'} paddingBottom={'0px'}>
          <TextField label='ID' value={data?.submittedMessageSetV2.id} fullWidth disabled variant='outlined' />
        </Box>
        <Box padding={'24px'} paddingBottom={'0px'}>
          <TextField label='Title' value={data?.submittedMessageSetV2.title} fullWidth disabled variant='outlined' />
        </Box>
        <Box padding={'24px'} paddingBottom={'0px'}>
          <TextField
            multiline
            minRows={3}
            label='Description'
            value={data?.submittedMessageSetV2.description}
            fullWidth
            disabled
            variant='outlined'
          />
        </Box>
        <Box padding={'24px'} paddingBottom={'0px'}>
          <Typography>
            Submitted by{' '}
            <Link to={`/person/${data?.submittedMessageSetV2?.personId}`}>
              {data?.submittedMessageSetV2?.personName || 'Unknown'}
            </Link>
          </Typography>
        </Box>
        <Box padding={'24px'}>
          <FlexHorizontal>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setIsApproving(true)
              }}>
              Approve
            </Button>
            <Box width={16} />
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                setIsRejecting(true)
              }}>
              Reject
            </Button>
          </FlexHorizontal>
        </Box>
      </Card>
      <Spacer size='extraLarge' />

      <Card>
        <InfiniteScroll
          pageStart={0}
          loadMore={async () => {
            setMessagesToShow(messagesToShow => messagesToShow + 25)
          }}
          hasMore={messages.length > messagesToShow}
          loader={
            <Box>
              <Spacer size='large' />
              <CircularProgress />
            </Box>
          }>
          <TableContainer>
            <Table>
              <TableBody>
                {messages
                  .filter((_, index) => index <= messagesToShow)
                  .map((message, index) => (
                    <TableRow key={message.id}>
                      <TableCell>
                        {message.text ? (
                          <>
                            <Card raised>
                              <CardContent>
                                <Typography variant='body2'>{message.text}</Typography>
                              </CardContent>
                            </Card>
                          </>
                        ) : null}
                      </TableCell>
                      <TableCell style={{ width: 250 }}>
                        <Box width={250}>
                          <Link to={`/message/${message.id}`}>
                            <BoardPreview characters={message.characters as IBoard} />
                          </Link>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Card>
    </>
  )
}
