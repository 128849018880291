import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreateNotificationSegment,
  CreateNotificationSegmentVariables
} from './__generated__/CreateNotificationSegment'

const MUTATION = gql`
  mutation CreateNotificationSegment($input: CreateNotificationSegmentInputV2!) {
    createNotificationSegment: createNotificationSegmentV2(input: $input) {
      ... on NotificationSegmentV2 {
        id
        title
        subscribers {
          id
          title
        }
      }
    }
  }
`

export const useCreateNotificationSegment = () =>
  useMutation<CreateNotificationSegment, CreateNotificationSegmentVariables>(MUTATION)
