import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateColorTemplate, CreateColorTemplateVariables } from './__generated__/CreateColorTemplate'

const CREATE_COLOR_TEMPLATE = gql`
  mutation CreateColorTemplate($input: CreateColorTemplateInput!) {
    createColorTemplate(input: $input) {
      created {
        id
        category {
          id
          title
        }
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useCreateColorTemplate = () => {
  return useMutation<CreateColorTemplate, CreateColorTemplateVariables>(CREATE_COLOR_TEMPLATE)
}
