import React, { useState } from 'react'
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import InfiniteScroll from 'react-infinite-scroller'
import { Spacer } from '@vestaboard/installables'
import { useListMessageFlags } from '../hooks/useListMessageFlags'

interface IListMessageAppearancesProps {
  messageId: string
}

const LIMIT = 50

export const ListMessageFlags = (props: IListMessageAppearancesProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { data, loading, error, fetchMore } = useListMessageFlags({
    id: props.messageId,
    input: {
      cursor: null,
      limit: LIMIT
    }
  })

  const appearances = data?.message.__typename === 'MessageV2' ? data.message.listFlaggedMessages.flaggedMessages : []

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>There was an error loading the message flags</Alert>
  ) : !appearances?.length ? (
    <Alert severity='info'>This message has not been flagged</Alert>
  ) : data?.message.__typename === 'MessageV2' ? (
    <Card>
      <InfiniteScroll
        pageStart={0}
        loadMore={async () => {
          if (isLoadingMore) {
            return
          }

          setIsLoadingMore(true)

          return await fetchMore({
            updateQuery(previousQueryResult: any, options: any) {
              if (options.fetchMoreResult.message.__typename !== 'MessageV2') {
                return options.fetchMoreResult
              }

              const newMessageSets = options.fetchMoreResult?.message?.listFlaggedMessages?.flaggedMessages || []
              const previousMessageSets = previousQueryResult?.message?.listFlaggedMessages?.flaggedMessages || []

              const result = {
                ...previousQueryResult,
                message: {
                  ...previousQueryResult.message,
                  listFlaggedMessages: {
                    ...previousQueryResult.message.listFlaggedMessages,
                    nextCursor: options.fetchMoreResult?.message.listFlaggedMessages.nextCursor || null,
                    flaggedMessages: [...previousMessageSets, ...newMessageSets]
                  }
                }
              }

              return result
            },
            variables: {
              id: props.messageId,
              input: {
                cursor:
                  data.message.__typename === 'MessageV2'
                    ? data?.message?.listFlaggedMessages.nextCursor || null
                    : null,
                limit: LIMIT
              }
            }
          }).then(res => {
            setIsLoadingMore(false)
            return res
          })
        }}
        hasMore={!!data?.message?.listFlaggedMessages.nextCursor}
        loader={
          <Box>
            <Spacer size='large' />
            <CircularProgress />
          </Box>
        }>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Person ID</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appearances.map(feedItem => (
                <TableRow key={feedItem.personId}>
                  <TableCell>
                    <TruncatedId value={feedItem.personId} full path={`/person/${feedItem.personId}`} />
                  </TableCell>
                  <TableCell>{feedItem.reason || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Card>
  ) : (
    <Alert severity='error'>{data?.message.error}</Alert>
  )
}
