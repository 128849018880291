import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UpdatePick, UpdatePickVariables } from './__generated__/UpdatePick'

const UPDATE_PICK = gql`
  mutation UpdatePick($input: UpdatePickInput!) {
    updatePick(input: $input) {
      updated {
        id
        categories {
          id
          title
        }
        recommendAfter
        recommendBefore
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useUpdatePick = () => {
  return useMutation<UpdatePick, UpdatePickVariables>(UPDATE_PICK)
}
