import { useCallback } from 'react'
import { useAuth0 } from '../react-auth0-spa'

export const useMigratedColorTemplates = () => {
  const { getTokenSilently } = useAuth0()

  const migrateColorTemplates = useCallback(async () => {
    return await fetch(`${process.env.REACT_APP_GRAPHQL_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-vestaboard-token': await getTokenSilently()
      },
      body: JSON.stringify({
        query: `mutation {
            migrateColorTemplatesV2 {
                success
                description
            }
        }`,
        variables: {}
      })
    })
      .then(res => res.json())
      .then(json => console.log(json))
      .catch(err => console.error('error:' + err))
  }, [getTokenSilently])

  return [migrateColorTemplates]
}
