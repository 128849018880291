import { CreateMessageV2, CreateMessageV2Variables } from './__generated__/CreateMessageV2'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation CreateMessageV2($input: CreateMessageInputV2!) {
    createMessage: createMessageV2(input: $input) {
      ... on MessageV2 {
        id
        characters
        text
        createdAt
        tags
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreateMessage = () => {
  return useMutation<CreateMessageV2, CreateMessageV2Variables>(MUTATION)
}
