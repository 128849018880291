import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateMessageMutation, CreateMessageMutationVariables } from './__generated__/CreateMessageMutation'

const MUTATION = gql`
  mutation CreateMessageMutation($input: CreateMessage2Input!) {
    createMessage2(input: $input) {
      created
      message {
        id
        formatted
        created
      }
    }
  }
`

export const useSendMessage = () => useMutation<CreateMessageMutation, CreateMessageMutationVariables>(MUTATION)
