import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { BoardStyles } from './__generated__/BoardStyles'

const BOARD_STYLES_QUERY = gql`
  query BoardStyles {
    boardStyles {
      id
      title
      value
    }
  }
`

export const useListBoardStyles = () => useQuery<BoardStyles>(BOARD_STYLES_QUERY)
