import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { OptIntoRenewal, OptIntoRenewalVariables } from './__generated__/OptIntoRenewal'

const OPT_INTO_RENEWAL = gql`
  mutation OptIntoRenewal($input: OptIntoRenewalInput!) {
    optIntoRenewal(input: $input) {
      id
      optOutOfAutoRenewal
      optOutReason
      optOutDate
    }
  }
`

export const useOptIntRenewal = () => {
  return useMutation<OptIntoRenewal, OptIntoRenewalVariables>(OPT_INTO_RENEWAL)
}
