import React from 'react'
import { Route, Switch } from 'react-router'
import { BoardPage } from './pages/GetBoard'
import { PairedBoards } from './pages/PairedBoards'
import { LiveBoard } from './pages/Live'

export const BoardRouter = () => (
  <Switch>
    <Route exact path='/boards/:id/live' component={LiveBoard} />
    <Route exact path='/boards/:id' component={BoardPage} />
    <Route exact path='/boards' component={PairedBoards} />
  </Switch>
)
