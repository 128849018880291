import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { OptOutOfRenewal, OptOutOfRenewalVariables } from './__generated__/OptOutOfRenewal'

const OPT_OUT_OF_RENEWAL = gql`
  mutation OptOutOfRenewal($input: OptOutOfRenewalInput!) {
    optOutOfRenewal(input: $input) {
      id
      optOutOfAutoRenewal
      optOutReason
      optOutDate
    }
  }
`

export const useOptOutOfRenewal = () => {
  return useMutation<OptOutOfRenewal, OptOutOfRenewalVariables>(OPT_OUT_OF_RENEWAL)
}
