export enum MarketplaceListingSortBy {
  categories = 'categories',
  developerName = 'developerName',
  enabledSubscriptionsCount = 'enabledSubscriptionsCount',
  id = 'id',
  installableId = 'installableId',
  installableTitle = 'installableTitle',
  installationsCount = 'installationsCount',
  messageSetId = 'messageSetId',
  messageSetTitle = 'messageSetTitle',
  releaseDate = 'releaseDate',
  subscriptionsCount = 'subscriptionsCount',
  title = 'title',
  developerId = 'developerId',
  featureTypes = 'featureTypes',
  subscriptionsInPast30DaysCount = 'subscriptionsInPast30DaysCount'
}

export enum MarketplaceListingSortOrder {
  asc = 'asc',
  desc = 'desc'
}
