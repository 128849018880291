import { gql } from 'apollo-boost'
import { GetPickCategoryQuery, GetPickCategoryQueryVariables } from './__generated__/GetPickCategoryQuery'
import { useQuery } from '@apollo/react-hooks'

const GET_PICK_CATEGORY = gql`
  query GetPickCategoryQuery($id: String!) {
    pickCategory(id: $id) {
      id
      title
    }
  }
`

export const useGetPickCategory = (id: string) => {
  return useQuery<GetPickCategoryQuery, GetPickCategoryQueryVariables>(GET_PICK_CATEGORY, {
    variables: {
      id
    }
  })
}
