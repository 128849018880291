import React from 'react'
import { Route, Switch } from 'react-router'
import { ListMessageSets } from './pages/ListMessageSets'
import { UpdateMessageSet } from './pages/UpdateMessageSet'
import { CreateMessageSet } from './pages/CreateMessageSet'

export const MessageSetRouterV2 = () => (
  <Switch>
    <Route exact path={`/message-sets`} component={ListMessageSets} />
    <Route exact path={`/message-sets/create`} component={CreateMessageSet} />
    <Route exact path={`/message-sets/:id`} component={UpdateMessageSet} />
  </Switch>
)
