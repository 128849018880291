import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreateNewMarketplaceListingMutation,
  CreateNewMarketplaceListingMutationVariables
} from './__generated__/CreateNewMarketplaceListingMutation'

const MUTATION = gql`
  mutation CreateNewMarketplaceListingMutation(
    $installable: String!
    $messageSet: String
    $title: String!
    $description: String!
    $releaseDate: Long!
  ) {
    createMarketplaceListing(
      input: {
        installable: $installable
        messageSet: $messageSet
        title: $title
        description: $description
        releaseDate: $releaseDate
        stagingOnly: true
      }
    ) {
      id
    }
  }
`

export const useCreateMarketplaceListing = () =>
  useMutation<CreateNewMarketplaceListingMutation, CreateNewMarketplaceListingMutationVariables>(MUTATION)
