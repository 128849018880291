import { dsvFormat } from 'd3-dsv'

const CSV_DELIMITER = ','

export const csvFormat = <T extends object>(rows: readonly T[], columns?: ReadonlyArray<keyof T>): string =>
  dsvFormat(CSV_DELIMITER).format(rows, columns)

export const csvDownload = (csv: string, title?: string) => {
  const a = document.createElement('a')
  const blob = new Blob([csv], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  a.setAttribute('href', url)
  a.setAttribute('download', `${title || 'export'}.csv`)
  a.click()
}

export const csvToArray = (strData: string): string[][] => {
  const strDelimiter = ','

  const objPattern = new RegExp(
    '(\\' + strDelimiter + '|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\\' + strDelimiter + '\\r\\n]*))',
    'gi'
  )

  const arrData = [[]]

  let arrMatches = null

  // @ts-ignore
  while ((arrMatches = objPattern.exec(strData))) {
    const strMatchedDelimiter = arrMatches[1]
    let strMatchedValue

    // @ts-ignore
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([])
    }

    if (arrMatches[2]) {
      // @ts-ignore
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
    } else {
      strMatchedValue = arrMatches[3]
    }

    // @ts-ignore
    arrData[arrData.length - 1].push(strMatchedValue)
  }

  return arrData
}
