import { GetMessageV2, GetMessageV2Variables } from './__generated__/GetMessageV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetMessageV2($id: String!) {
    message: messageV2(id: $id) {
      ... on MessageV2 {
        id
        characters
        text
        tags
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetMessage = (id: string) => {
  return useQuery<GetMessageV2, GetMessageV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id }
  })
}
