import {
  ButtonBase,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core'
import { Spacer } from '@vestaboard/installables'
import React, { useMemo, useState } from 'react'
import { useListNotificationSubscribers } from '../hooks/useListNotificationSubscribers'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { CheckBoxOutlineBlank } from '@material-ui/icons'
import CheckBox from '@material-ui/icons/CheckBox'

export interface ISubscriber {
  id: string
  title: string
}

interface ISubscriberSelector {
  disabled?: boolean
  subscribers: ISubscriber[]
  setSubscribers: (subscribers: ISubscriber[]) => void
}

export const SubscriberSelector = (props: ISubscriberSelector) => {
  const [terms, setTerms] = useState('')
  const { data, loading, error } = useListNotificationSubscribers(
    {
      input: {
        terms
      }
    },
    !terms
  )
  const subscriberIds = useMemo(() => props.subscribers.map(subscriber => subscriber.id), [props.subscribers])

  return (
    <>
      <TextField label='Search' fullWidth variant='outlined' value={terms} onChange={e => setTerms(e.target.value)} />
      <Spacer size='medium' />
      {loading && <CircularProgress />}
      {error && (
        <Alert severity='error'>
          There was an error loading the subscribers matching your term: "<strong>{terms}</strong>"
        </Alert>
      )}
      {(!!props.subscribers?.length || !!data?.listNotificationSubscribers?.notificationSubscribers?.length) && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {props.subscribers
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(subscriber => (
                  <TableRow key={subscriber.id}>
                    <TableCell>
                      <ButtonBase
                        disabled={props.disabled}
                        onClick={() => {
                          props.setSubscribers(props.subscribers.filter(record => record.id !== subscriber.id))
                        }}>
                        <CheckBox />
                      </ButtonBase>
                    </TableCell>
                    <TableCell>
                      <TruncatedId value={subscriber.id} path={`/notifications/subscriber/${subscriber.id}`} />
                    </TableCell>
                    <TableCell>{subscriber.title}</TableCell>
                  </TableRow>
                ))}
              {(data?.listNotificationSubscribers?.notificationSubscribers || [])
                .filter(subscriber => !subscriberIds.includes(subscriber.id))
                .map(subscriber => (
                  <TableRow key={subscriber.id}>
                    <TableCell>
                      <ButtonBase
                        disabled={props.disabled}
                        onClick={() => {
                          props.setSubscribers([
                            ...props.subscribers,
                            {
                              id: subscriber.id,
                              title: subscriber.title
                            }
                          ])
                        }}>
                        <CheckBoxOutlineBlank />
                      </ButtonBase>
                    </TableCell>
                    <TableCell>
                      <TruncatedId value={subscriber.id} path={`/notifications/subscriber/${subscriber.id}`} />
                    </TableCell>
                    <TableCell>{subscriber.title}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
