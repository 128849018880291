import { Box, Button, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import moment from 'moment'
import React, { useState } from 'react'
import { SearchInput } from './SearchInput'

interface IOption {
  key: string
  value: string
}

interface ISearchType {
  key: string
  title: string
  type: 'text' | 'select' | 'range' | 'boolean' | 'dateRange' | 'button'
  options?: IOption[]
}

export type SearchValueType =
  | string
  | null
  | boolean
  | {
      min: number
      max: number
    }

interface IAdvancedSearch {
  searchType: string
  setSearchType: (searchType: string) => void
  value: SearchValueType
  setValue: (value: SearchValueType) => void
  searchTypes: ISearchType[]
}

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  select: {
    height: 49
  },
  rangeInput: {
    width: 100
  },
  dateRangeInput: {
    width: 140
  },
  spacer: {
    width: 7
  }
})

export const AdvancedSearch = (props: IAdvancedSearch) => {
  const classes = useStyles()
  const [rangeMin, setRangeMin] = useState(0)
  const [rangeMax, setRangeMax] = useState(0)
  const [dateRangeMin, setDateRangeMin] = useState('')
  const [dateRangeMax, setDateRangeMax] = useState('')
  const [minMax, setMinMax] = useState('min')

  const handleSelectSearchType = (e: any) => {
    props.setValue(null)
    props.setSearchType(e?.target?.value as string)
  }

  const selectedSearchType = props.searchTypes.find(type => type.key === props.searchType)

  const dateRangeMinField = (
    <TextField
      variant='standard'
      type='date'
      className={classes.dateRangeInput}
      size='small'
      value={dateRangeMin}
      onChange={(e: any) => {
        setDateRangeMin(e.target?.value)
        if (minMax === 'min') {
          setDateRangeMax('2050-01-01')
        }
      }}
    />
  )

  const dateRangeMaxField = (
    <TextField
      variant='standard'
      type='date'
      className={classes.dateRangeInput}
      size='small'
      value={dateRangeMax}
      onChange={(e: any) => {
        setDateRangeMax(e.target?.value)
        if (minMax === 'max') {
          setDateRangeMin('2000-01-01')
        }
      }}
    />
  )

  const rangeMinField = (
    <TextField
      variant='standard'
      type='number'
      label='Min'
      className={classes.rangeInput}
      size='small'
      value={rangeMin}
      onChange={(e: any) => {
        setRangeMin(+e.target?.value)
        if (minMax === 'min') {
          setRangeMax(2147483647)
        }
      }}
    />
  )

  const rangeMaxField = (
    <TextField
      variant='standard'
      type='number'
      label='Max'
      className={classes.rangeInput}
      size='small'
      value={rangeMax}
      onChange={(e: any) => {
        setRangeMax(+e.target?.value)
        if (minMax === 'max') {
          setRangeMin(0)
        }
      }}
    />
  )

  return (
    <Box className={classes.container}>
      <Select className={classes.select} variant='outlined' value={props.searchType} onChange={handleSelectSearchType}>
        {props.searchTypes.map(type => (
          <MenuItem key={type.key} value={type.key}>
            {type.title}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.spacer} />
      {selectedSearchType?.type === 'select' ? (
        <Select
          className={classes.select}
          variant='outlined'
          value={props.value}
          onChange={(e: any) => {
            props.setValue(e?.target?.value as string)
          }}>
          {(selectedSearchType?.options || []).map(type => (
            <MenuItem key={type.key} value={type.key}>
              {type.value}
            </MenuItem>
          ))}
        </Select>
      ) : selectedSearchType?.type === 'boolean' ? (
        <Select
          className={classes.select}
          variant='outlined'
          value={props.value ? 'yes' : props.value === false ? 'no' : null}
          onChange={(e: any) => {
            props.setValue((e?.target?.value as string) === 'yes' ? true : false)
          }}>
          <MenuItem value='yes'>Yes</MenuItem>
          <MenuItem value='no'>No</MenuItem>
        </Select>
      ) : selectedSearchType?.type === 'range' ? (
        <>
          <Select
            className={classes.select}
            variant='outlined'
            value={minMax}
            onChange={(e: any) => {
              setRangeMax(0)
              setRangeMin(0)
              setMinMax(e?.target?.value as string)
            }}>
            <MenuItem value='min'>Greater Than</MenuItem>
            <MenuItem value='max'>Less Than</MenuItem>
            <MenuItem value='between'>Between</MenuItem>
          </Select>
          <Box className={classes.spacer} />
          {minMax === 'min' ? (
            rangeMinField
          ) : minMax === 'max' ? (
            rangeMaxField
          ) : (
            <>
              {rangeMinField}
              <Box className={classes.spacer} />
              {rangeMaxField}
            </>
          )}

          <Box className={classes.spacer} />
          <Button
            size='small'
            variant='outlined'
            onClick={() => {
              props.setValue({
                min: rangeMin,
                max: rangeMax
              })
            }}>
            <Search />
          </Button>
        </>
      ) : selectedSearchType?.type === 'dateRange' ? (
        <>
          <Select
            className={classes.select}
            variant='outlined'
            value={minMax}
            onChange={(e: any) => {
              setDateRangeMax('')
              setDateRangeMin('')
              setMinMax(e?.target?.value as string)
            }}>
            <MenuItem value='min'>Greater Than</MenuItem>
            <MenuItem value='max'>Less Than</MenuItem>
            <MenuItem value='between'>Between</MenuItem>
          </Select>
          <Box className={classes.spacer} />
          {minMax === 'min' ? (
            dateRangeMinField
          ) : minMax === 'max' ? (
            dateRangeMaxField
          ) : (
            <>
              {dateRangeMinField}
              <Box className={classes.spacer} />
              {dateRangeMaxField}
            </>
          )}

          <Box className={classes.spacer} />
          <Button
            size='small'
            variant='outlined'
            onClick={() => {
              props.setValue({
                min: moment(dateRangeMin).toDate().getTime(),
                max: moment(dateRangeMax).toDate().getTime()
              })
            }}>
            <Search />
          </Button>
        </>
      ) : (
        <SearchInput
          onSearch={value => {
            props.setValue(value)
          }}
        />
      )}
    </Box>
  )
}
