import * as React from 'react'
import { MessagesPerBoardType } from '../components/MessagesPerBoardType'
import { MessageSources } from '../components/MessageSources'
import { MaxMessagesPerUser } from '../components/MaxMessagesPerUser'
import { RangeType } from '../../../ui/RangeType'
import { YearPicker } from '../../../ui/YearPicker'
import { MonthPicker } from '../../../ui/MonthPicker'
import { WeekPicker } from '../../../ui/WeekPicker'
import { ReportLayout } from '../../../ui/ReportLayout'
import { ReportNextButton } from '../../../ui/ReportNextButton'
import { ReportPreviousButton } from '../../../ui/ReportPreviousButton'
import { ChartContainer } from '../../../ui/ChartContainer'
import { getReportStateFromHash } from '../../../utils/getReportStateFromHash'
import { useGetMessagesByBoardType } from '../hooks/useGetMessagesByBoardType'
import { useGetMessageSources } from '../hooks/useGetMessageSources'
import { useGetMessagesPerUser } from '../hooks/useGetMessagesPerUser'
import { CsvSimpleExport } from '../../../ui/CsvSimpleExport'

export const DashboardMessages = () => {
  const [{ rangeType, searchKey }, setSearchState] = React.useState<{
    rangeType: string
    searchKey: string | null
  }>(
    getReportStateFromHash() || {
      rangeType: 'month',
      searchKey: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    }
  )
  const { data: messagesByBoardTypeData } = useGetMessagesByBoardType({ rangeType, searchKey })
  const { data: messageSourcesData } = useGetMessageSources({ rangeType, searchKey })
  const { data: messagesPerUserData } = useGetMessagesPerUser({ rangeType, searchKey })

  const setSearch = React.useCallback(({ rangeType, searchKey }) => {
    setSearchState({ rangeType, searchKey })
    window.location.hash = `#${rangeType}|${searchKey}`
  }, [])

  return (
    <ReportLayout
      title='Messages Report'
      filters={
        <>
          {rangeType === 'week' ? (
            <WeekPicker
              year={searchKey?.split('-')[0] || '2021'}
              value={searchKey?.split('-')[1] || '1'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 1 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          {rangeType === 'month' ? (
            <MonthPicker
              value={searchKey?.split('-')[1] || '1'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 1 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          {rangeType === 'month' || rangeType === 'week' || rangeType === 'year_weekly' || rangeType === 'year' ? (
            <YearPicker
              value={searchKey?.split('-')[0] || '2021'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 0 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          <RangeType
            setSearch={setSearch}
            rangeType={rangeType}
            types={['week', 'month', 'year', 'year_weekly', 'all_time']}
          />
          <ReportPreviousButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'month') {
                const [year, month] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${month === '1' ? `${+year - 1}` : year}-${month === '1' ? '12' : +month - 1}`
                })
              }

              if (rangeType === 'week') {
                const [year, week] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${week === '1' ? `${+year - 1}` : year}-${week === '1' ? '52' : +week - 1}`
                })
              }

              if (rangeType === 'year_weekly' || rangeType === 'year') {
                const [year] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${+year - 1}`
                })
              }
            }}
          />
          <ReportNextButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'month') {
                const [year, month] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${month === '12' ? `${+year + 1}` : year}-${month === '12' ? '1' : +month + 1}`
                })
              }

              if (rangeType === 'week') {
                const [year, week] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${week === '52' ? `${+year + 1}` : year}-${week === '52' ? '1' : +week + 1}`
                })
              }

              if (rangeType === 'year_weekly' || rangeType === 'year') {
                const [year] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${+year + 1}`
                })
              }
            }}
          />
        </>
      }>
      <ChartContainer
        title='Messages Per Subscription Type'
        exportButton={
          <CsvSimpleExport
            title={`messages-per-subscription-type-${rangeType}-${searchKey}`}
            data={messagesByBoardTypeData}
          />
        }>
        <MessagesPerBoardType data={messagesByBoardTypeData} />
      </ChartContainer>
      <ChartContainer
        title='Message Sources'
        exportButton={
          <CsvSimpleExport title={`messages-sources-${rangeType}-${searchKey}`} data={messageSourcesData} />
        }>
        <MessageSources data={messageSourcesData} />
      </ChartContainer>
      <ChartContainer
        title='Most Messages Sent By Single User'
        exportButton={
          <CsvSimpleExport title={`max-messages-per-user-${rangeType}-${searchKey}`} data={messagesPerUserData} />
        }>
        <MaxMessagesPerUser data={messagesPerUserData} />
      </ChartContainer>
    </ReportLayout>
  )
}
