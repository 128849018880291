import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DevelopersListQuery } from './__generated__/DevelopersListQuery'

const QUERY = gql`
  query DevelopersListQuery {
    developers {
      id
      created
      title
    }
  }
`

export const useListDevelopers = () => useQuery<DevelopersListQuery>(QUERY)
