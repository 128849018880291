import { Box, Button, ButtonBase } from '@material-ui/core'
import * as React from 'react'
import { MediaDetailModal } from '../components/media-detail-modal'

interface IMediaUploadButtonProps {
  mediaId?: string | null
  previewUrl?: string | null
  onUpload?: (id: string) => void
  aspectRatio?: number
}

type MediaUploadButtonProps = IMediaUploadButtonProps

export const MediaUploadButton: React.FC<MediaUploadButtonProps> = props => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [previewUrl, setPreviewUrl] = React.useState('')

  React.useEffect(() => {
    if (!previewUrl) {
      setPreviewUrl(props.previewUrl ?? '')
    }
  }, [previewUrl, setPreviewUrl, props])

  return (
    <>
      <MediaDetailModal
        open={modalOpen}
        aspectRatio={props.aspectRatio}
        onClose={() => setModalOpen(false)}
        onSave={(id, cdnUrl) => {
          setModalOpen(false)
          setPreviewUrl(cdnUrl)
          props.onUpload?.(id)
        }}
      />
      <Box>
        <ButtonBase onClick={() => setModalOpen(true)}>
          {previewUrl ? (
            <img style={{ maxHeight: 200 }} src={previewUrl} alt='preview' />
          ) : (
            <Button variant='outlined'>Upload</Button>
          )}
        </ButtonBase>
      </Box>
    </>
  )
}
