import React from 'react'
import { Box, ButtonBase, Typography, makeStyles } from '@material-ui/core'
import { CheckBox as CheckBoxOutline, CheckBoxOutlineBlank } from '@material-ui/icons'

interface ICheckbox {
  label?: string
  checked: boolean
  handleCheck: (value: boolean) => void
}

const useStyles = makeStyles({
  root: {
    display: 'flex'
  }
})

export const Checkbox = (props: ICheckbox) => {
  const classes = useStyles()

  return (
    <ButtonBase
      onClick={() => {
        props.handleCheck(!props.checked)
      }}>
      <Box className={classes.root}>
        {props.checked ? <CheckBoxOutline /> : <CheckBoxOutlineBlank />}
        <Box width={7} />
        {props.label ? <Typography variant='body1'>{props.label}</Typography> : null}
      </Box>
    </ButtonBase>
  )
}
