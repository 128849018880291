import React, { useState } from 'react'

import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link as MuiLink,
  Box,
  ButtonBase,
  Typography
} from '@material-ui/core'
import { FullModal, Spacer } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { FormattedDateTime } from '../../formatted-date-time'
import { Link } from 'react-router-dom'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useMessageHistory } from '../hooks/useMessageHistory'

interface IMessages {
  boardId: string
  onClose: () => void
}

export const Messages = (props: IMessages) => {
  const [page, setPage] = useState(0)
  const { data, loading, error } = useMessageHistory(props.boardId, page)

  return (
    <FullModal onClose={props.onClose}>
      <Card>
        {error ? (
          <Alert severity='error'>Error Loading Messages</Alert>
        ) : loading || !data ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 320 }}>ID</TableCell>
                  <TableCell style={{ width: 180 }}>Created</TableCell>
                  <TableCell>Author</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.board.history.map(history => {
                  const { message } = history
                  return (
                    <TableRow key={message?.id}>
                      <TableCell>
                        <MuiLink component={Link} to={`/message/${message?.id}`}>
                          {message?.id}
                        </MuiLink>
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime
                          format='MM/DD/YYYY HH:mm:ss'
                          value={message?.created ? +message?.created : undefined}
                        />
                      </TableCell>
                      <TableCell>{message?.authorFormatted}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Spacer size='large' />
        <Box display='flex' justifyContent='center' alignItems='center'>
          <ButtonBase
            disabled={page === 0}
            onClick={() => {
              setPage(page - 1)
            }}>
            <ChevronLeft />
            <Typography>Previous</Typography>
          </ButtonBase>
          <Box width={14} />
          <Typography
            style={{
              fontWeight: 'bold'
            }}>
            Page {page + 1}
          </Typography>
          <Box width={14} />
          <ButtonBase
            onClick={() => {
              setPage(page + 1)
            }}>
            <Typography>Next</Typography>
            <ChevronRight />
          </ButtonBase>
        </Box>
        <Spacer size='large' />
      </Card>
    </FullModal>
  )
}
