import { gql } from 'apollo-boost'
import { GetTagSubscribers, GetTagSubscribersVariables } from './__generated__/GetTagSubscribers'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetTagSubscribers($id: String!) {
    notificationTag: notificationTagV2(id: $id) {
      ... on NotificationTagV2 {
        id
        title
        subscribers {
          id
          personId
          title
        }
      }
      ... on NotificationTagErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetTagSubscribers = (variables: GetTagSubscribersVariables) =>
  useQuery<GetTagSubscribers, GetTagSubscribersVariables>(QUERY, { variables })
