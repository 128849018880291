import { useCallback, useEffect, useState } from 'react'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'

interface QAResult {
  id: number
  title: string
  created_at: string
  updated_at: string
}

interface IUseGetQas {
  token: string
}

export const useGetQAs = (props: IUseGetQas) => {
  const [data, setData] = useState<QAResult[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const fetchQas = useCallback(async () => {
    try {
      const result = await fetch(`${INSTALLABLES_MONOREPO_API}/api/question-answer/set`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      setData(result?.data as QAResult[])
    } catch (err) {
      setError('Error fetching QAs')
    }

    setLoading(false)
  }, [props.token])

  useEffect(() => {
    fetchQas()
  }, [fetchQas])

  return { data, loading, error }
}
