import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, ComposedChart, Area } from 'recharts'

interface IActiveOverTime {
  data: {
    Active: number
    key: string
  }[]
}

export const ActiveOverTime = (props: IActiveOverTime) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key' />
        <YAxis domain={['dataMin', 'dataMax']} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Area type='monotone' dataKey='Active' fill='rgb(0, 154, 68)' stroke='rgb(0, 154, 68)' />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
