import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListMessageSetSubmissionsV2,
  ListMessageSetSubmissionsV2Variables
} from './__generated__/ListMessageSetSubmissionsV2'

const QUERY = gql`
  query ListMessageSetSubmissionsV2($input: ListSubmittedMessageSetInputV2!) {
    listSubmittedMessageSets: listSubmittedMessageSetsV2(input: $input) {
      submittedMessageSets {
        id
        createdAt
        personId
        personName
        title
        messageIds
        description
      }
      nextCursor
    }
  }
`

export const useListMessageSetSubmissions = (variables: ListMessageSetSubmissionsV2Variables) => {
  return useQuery<ListMessageSetSubmissionsV2, ListMessageSetSubmissionsV2Variables>(QUERY, {
    variables
  })
}
