import React from 'react'
import { Route, Switch } from 'react-router'
import { InstallablesDetail } from './pages/Update'
import { InstallablesList } from './pages/List'

export const InstallablesRouter = () => (
  <Switch>
    <Route exact path='/installables' component={InstallablesList} />
    <Route exact path='/installables/:id' component={InstallablesDetail} />
  </Switch>
)
