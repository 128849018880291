import { useQuery } from '@apollo/react-hooks'
import { NewMarketplaceListingQuery } from './__generated__/NewMarketplaceListingQuery'
import { gql } from 'apollo-boost'

const QUERY = gql`
  query NewMarketplaceListingQuery {
    messageSets {
      id
      title
    }
    publicInstallables {
      id
      public
      created
      acceptsMessageSets
      developer {
        id
        title
      }
      title
    }
  }
`

export const useNewMarketplaceListingQuery = () =>
  useQuery<NewMarketplaceListingQuery>(QUERY, { fetchPolicy: 'network-only' })
