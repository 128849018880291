import React from 'react'
import { useParams } from 'react-router'
import {
  Card,
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { FormattedDateTime } from '../../formatted-date-time'
import { TruncatedId } from '../../truncated-id'
import { useGetMessage } from '../hooks/useGetMessage'

const useStyles = makeStyles({
  columnTitle: {
    width: '200px',
    fontWeight: 'bold',
    background: '#eee'
  }
})

export const Message = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetMessage({ id })
  const classes = useStyles()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Message not found.</Alert>
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <BoardPreview characters={data?.message.formatted as IBoard} />
      <Spacer size='extraLarge' />
      <Card>
        <Table>
          <TableRow>
            <TableCell className={classes.columnTitle}>Author</TableCell>
            <TableCell>{data?.message.authorFormatted}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Created</TableCell>
            <TableCell>
              <FormattedDateTime value={data?.message.created ? +data?.message.created : undefined} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Message</TableCell>
            <TableCell>
              <TextField value={data?.message.text} disabled fullWidth variant='outlined' />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Subscription Title</TableCell>
            <TableCell>{data?.message.subscription?.title || 'No Subscription Title'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Subscription ID</TableCell>
            <TableCell>
              <TruncatedId
                value={data?.message.subscription?.id || ''}
                path={`/subscription/${data?.message.subscription?.id}`}
                full
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Source ID</TableCell>
            <TableCell>
              <TruncatedId value={data?.message.source?.id || ''} full />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
    </>
  )
}
