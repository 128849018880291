import { GetDigitalDevice, GetDigitalDeviceVariables } from './__generated__/GetDigitalDevice'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetDigitalDevice($id: String!) {
    board: getBoardV2(id: $id) {
      ... on BoardV2 {
        id
        title
        digitalDevice {
          id
          audioEnabled
          digitalDeviceStyle {
            id
            title
          }
          screenSaverEnabled
          type
        }
      }
      ... on BoardErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetDigitalDevice = (variables: { id?: string }) =>
  useQuery<GetDigitalDevice, GetDigitalDeviceVariables>(QUERY, {
    skip: !variables.id,
    variables: {
      id: variables.id || ''
    }
  })
