import * as React from 'react'
import { useParams } from 'react-router'
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { useGetDeveloper, useGetDeveloperMarketplaceListings, useUpdateDeveloper } from '../hooks'

type DeveloperDetailProps = IDeveloperDetailProps

interface IDeveloperDetailProps {}

const useStyles = makeStyles(_theme => ({
  developerTitleInput: {
    width: 250
  }
}))

export const DeveloperDetail: React.FC<DeveloperDetailProps> = props => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { error, data, loading, refetch } = useGetDeveloper({
    id
  })
  const developerInstallablesQuery = useGetDeveloperMarketplaceListings({
    input: {
      terms: id,
      page: 0,
      perPage: 500
    }
  })

  const [mutate, { error: saveError, loading: saving }] = useUpdateDeveloper()

  const [title, setTitle] = React.useState('')

  React.useEffect(() => {
    if (data?.developer && !title) {
      setTitle(data?.developer.title)
    }
  }, [data, title])

  const save = async () => {
    try {
      await mutate({
        variables: {
          id,
          title
        }
      })
      await refetch()
    } catch (err) {}
  }

  return (
    <>
      {error ? (
        <Alert severity='error'>Developer not found.</Alert>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>Edit Developer</Typography>
          </FlexHorizontal>
          <Spacer size='extraLarge' />
          <Card>
            <Table>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>
                  <TextField
                    variant='outlined'
                    value={data?.developer?.id}
                    disabled
                    className={classes.developerTitleInput}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Developer Title</TableCell>
                <TableCell>
                  <TextField
                    variant='outlined'
                    value={title}
                    className={classes.developerTitleInput}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </Card>
          {saveError ? (
            <>
              <Spacer size='large' />
              <Alert severity='error'>There was an error saving your developer</Alert>
            </>
          ) : null}
          <Spacer size='large' />
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={save}
            disabled={title === data?.developer.title || saving}>
            {saving ? <CircularProgress /> : 'Save'}
          </Button>
          <Spacer size='extraLarge' />
          <FlexHorizontal spaceBetween>
            <Typography variant='h6'>Developer Marketplace Listings</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          {developerInstallablesQuery.loading ? (
            <CircularProgress />
          ) : developerInstallablesQuery.data?.listMarketplaceListings.marketplaceListings.length ? (
            <>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: 50
                          }}
                        />
                        <TableCell>Listing ID</TableCell>
                        <TableCell>Listing Title</TableCell>
                        <TableCell>Installable ID</TableCell>
                        <TableCell>Installable Title</TableCell>
                      </TableRow>
                    </TableHead>
                    {developerInstallablesQuery.data?.listMarketplaceListings.marketplaceListings
                      .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
                      .map(installable => (
                        <TableRow key={installable.id}>
                          <TableCell>
                            {installable.listing.icon ? (
                              <Avatar src={installable.listing.icon} />
                            ) : (
                              <Avatar>{installable.title?.split('')[0]}</Avatar>
                            )}
                          </TableCell>
                          <TableCell>
                            <TruncatedId value={installable.id} path={`/marketplace-listings/${installable.id}`} />
                          </TableCell>
                          <TableCell>{installable.title}</TableCell>
                          <TableCell>
                            <TruncatedId
                              value={installable.installableId || ''}
                              path={`/installables/${installable.installableId}`}
                            />
                          </TableCell>
                          <TableCell>{installable.installableTitle}</TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </TableContainer>
              </Card>
            </>
          ) : (
            <Alert severity='info'>This developer does not have any marketplace listings attributed to them</Alert>
          )}
          <Spacer size='extraLarge' />
          <FlexHorizontal spaceBetween>
            <Typography variant='h6'>Developer Installables</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          {!data?.developer.installables.length ? (
            <Alert severity='info'>This developer does not have any installables</Alert>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 170 }}>Installable ID</TableCell>
                    <TableCell>Installable Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.developer.installables
                    .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
                    .map(installable => (
                      <TableRow key={installable.id}>
                        <TableCell>
                          <TruncatedId full value={installable.id} path={`/installables/${installable.id}`} />
                        </TableCell>
                        <TableCell>{installable.title}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}
