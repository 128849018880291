import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { InstallableDetail, InstallableDetailVariables } from './__generated__/InstallableDetail'

const QUERY = gql`
  query InstallableDetail($id: String!) {
    developers {
      id
      created
      title
    }
    installable(id: $id) {
      id
      title
      created
      description
      icon
      hideFromInstallablesList
      installationsAreSingleton
      configurationSchema
      subscriptionsCanBeRenamed
      cadenceConfigurationUrl
      cadenceDisplayTemplate
      cadenceToken
      cadenceOnCommand
      webhookDestination {
        id
        url
      }
      subscriptionConfigurationUrl
      developer {
        id
        title
      }
    }
  }
`

export const useGetInstallable = (id: string) => {
  return useQuery<InstallableDetail, InstallableDetailVariables>(QUERY, {
    variables: {
      id
    }
  })
}
