import { ListFeedItemsV2, ListFeedItemsV2Variables } from './__generated__/ListFeedItemsV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const QUERY = gql`
  query ListFeedItemsV2($input: SearchMessageInputV2!) {
    listSearchMessages: listSearchMessagesV2(input: $input) {
      searchMessages {
        __typename
        ... on FeedItemV2 {
          id
          attribution
          created
          personId
          subscriptionId
          likeCount
          moderated
          message {
            id
            text
            characters
            tags
          }
        }
      }
      nextCursor
    }
  }
`

export const useListFeedItems = (variables: ListFeedItemsV2Variables) => {
  return useQuery<ListFeedItemsV2, ListFeedItemsV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
