import React, { useCallback, useState } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { QuestionAnswerForm } from './Form'
import { useGetQA } from '../hooks/useGetQA'
import { Alert } from '@material-ui/lab'
import { useUpdateQA } from '../hooks/useUpdateQa'
import { Confirm, LoadMask, useToasts } from '@vestaboard/installables'
import { IQa } from '../types'
import { useHistory, useParams } from 'react-router'
import { useDeleteQA } from '../hooks/useDeleteQa'

interface IUpdateQuestionAnswer {
  token: string
}

export const UpdateQuestionAnswer = (props: IUpdateQuestionAnswer) => {
  const { id } = useParams<{ id: string }>()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { data, loading, error } = useGetQA({
    token: props.token,
    id
  })
  const [saving, setSaving] = useState(false)
  const update = useUpdateQA(props)
  const deleteQa = useDeleteQA(props)
  const history = useHistory()
  const { addToast } = useToasts()

  const handleUpdate = useCallback(
    async (data: IQa) => {
      setSaving(true)
      const result = await update(data)
      setSaving(false)

      if (!!result?.questions_answers_set_id) {
        addToast('Questions and Answer updated successfully', {
          autoDismiss: true,
          appearance: 'success'
        })
      } else {
        addToast('There was an error saving your Question and Answer', {
          autoDismiss: true,
          appearance: 'error'
        })
      }
    },
    [addToast, update]
  )

  const handleDelete = useCallback(async () => {
    setSaving(true)
    try {
      await deleteQa({ id })
      setSaving(false)
      addToast('Questions and Answer deleted successfully', {
        autoDismiss: true,
        appearance: 'success'
      })
      history.push('/questions-and-answers')
    } catch (e) {
      setSaving(false)
      addToast('There was an error deleting your Question and Answer', {
        autoDismiss: true,
        appearance: 'error'
      })
    }
  }, [id, deleteQa, addToast, history])

  return (
    <>
      <Typography variant='h4'>Update Questions and Answers</Typography>
      {loading ? (
        <CircularProgress />
      ) : error || !data ? (
        <Alert severity='error'>There was an error loading the question and answer.</Alert>
      ) : (
        <QuestionAnswerForm
          data={data}
          onSubmit={handleUpdate}
          onDelete={() => {
            setConfirmDelete(true)
          }}
        />
      )}
      <Confirm
        title='Delete QA'
        message='Are you sure you want to delete this QA? This cannot be undone.'
        open={confirmDelete}
        handleClose={() => {
          setConfirmDelete(false)
        }}
        handleAccept={() => {
          setConfirmDelete(false)
          handleDelete()
        }}
      />
      {saving ? <LoadMask /> : null}
    </>
  )
}
