import React from 'react'
import { Route, Switch } from 'react-router'
import { ColorTemplateCategoryCreate } from './pages/CreateColorTemplateCategory'
import { ColorTemplatesList } from './pages/ListColorTemplates'
import { ColorTemplateCreate } from './pages/CreateColorTemplate'
import { ColorTemplateUpdate } from './pages/UpdateColorTemplate'
import { ColorTemplateCategoryUpdate } from './pages/UpdateColorTemplateCategory'

export const ColorTemplateRouterV1 = () => (
  <Switch>
    <Route exact path='/color-templates' component={ColorTemplatesList} />
    <Route exact path={`/color-templates/create`} component={ColorTemplateCreate} />
    <Route exact path={`/color-templates/:id`} component={ColorTemplateUpdate} />
    <Route exact path={`/color-templates/categories/create`} component={ColorTemplateCategoryCreate} />
    <Route exact path={`/color-templates/categories/:id`} component={ColorTemplateCategoryUpdate} />
  </Switch>
)
