import React from 'react'
import { useGetMessageAppearance } from '../hooks/useGetMessageAppearance'
import { useParams } from 'react-router'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'

const useStyles = makeStyles({
  cardInner: {
    padding: 20
  }
})

export const GetMessageAppearance = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetMessageAppearance(id)

  return error ? (
    <Alert severity='error'>There was an error loading the message appearance</Alert>
  ) : loading ? (
    <CircularProgress />
  ) : data?.messageAppearance.__typename === 'MessageAppearanceErrorV2' ? (
    <Alert severity='error'>{data.messageAppearance.error}</Alert>
  ) : (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message Appearance</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <Box className={classes.cardInner}>
          <TextField value={data?.messageAppearance.id} disabled label='ID' variant='outlined' fullWidth />
          <Spacer size='large' />
          <TextField value={data?.messageAppearance.message.text} disabled label='Text' variant='outlined' fullWidth />
          <Spacer size='large' />
          <Box width={400}>
            <BoardPreview characters={data?.messageAppearance.message?.characters as IBoard} />
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Message ID</TableCell>
                <TableCell>Board ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Attribution</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TruncatedId
                    value={data?.messageAppearance.message.id || ''}
                    path={`/message/${data?.messageAppearance.message.id}`}
                    full
                  />
                </TableCell>
                <TableCell>
                  <TruncatedId
                    value={data?.messageAppearance.boardId || ''}
                    path={`/boards/${data?.messageAppearance.boardId}`}
                    full
                  />
                </TableCell>
                <TableCell>
                  <FormattedDateTime value={data?.messageAppearance.created} />
                </TableCell>
                <TableCell>
                  <TruncatedId
                    value={data?.messageAppearance.attribution || ''}
                    path={
                      data?.messageAppearance.personId
                        ? `/person/${data?.messageAppearance.personId}`
                        : `/subscription/${data?.messageAppearance.subscriptionId}`
                    }
                    full
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}
