import { useMutation } from '@apollo/react-hooks'
import {
  UpdateMarketplaceListingMutation,
  UpdateMarketplaceListingMutationVariables
} from './__generated__/UpdateMarketplaceListingMutation'
import { gql } from 'apollo-boost'

const MUTATION = gql`
  mutation UpdateMarketplaceListingMutation($input: UpdateMarketplaceListingInput!) {
    updateMarketplaceListing(input: $input) {
      id
    }
  }
`

export const useUpdateMarketplaceListing = () =>
  useMutation<UpdateMarketplaceListingMutation, UpdateMarketplaceListingMutationVariables>(MUTATION)
