import React from 'react'
import { Route, Switch } from 'react-router'
import { ListPicks } from './pages/ListPicks'
import { UpdatePick } from './pages/UpdatePick'
import { CreatePick } from './pages/CreatePick'

export const PicksRouterV2 = () => (
  <Switch>
    <Route exact path='/picks/create' component={CreatePick} />
    <Route exact path='/picks/:id' component={UpdatePick} />
    <Route exact path='/picks' component={ListPicks} />
  </Switch>
)
