import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { MigrateMessageSets, MigrateMessageSetsVariables } from './__generated__/MigrateMessageSets'

const MUTATION = gql`
  mutation MigrateMessageSets($messageSetId: String) {
    migrateMessageSetsV2(messageSetId: $messageSetId) {
      success
      description
    }
  }
`

export const useMigrateMessageSet = () => {
  return useMutation<MigrateMessageSets, MigrateMessageSetsVariables>(MUTATION)
}
