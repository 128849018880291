import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import moment from 'moment'
import React, { useState } from 'react'
import { CsvExport } from '../../ui/CsvExport'
import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'
import { PlusRenewalFailureSortBy, PlusRenewalFailureSortOrder } from './types'
import {
  ListPlusRenewalFailures as ListPlusRenewalFailuresQuery,
  ListPlusRenewalFailuresVariables,
  ListPlusRenewalFailures_listPlusRenewalErrors_plusRenewalFailures
} from './__generated__/ListPlusRenewalFailures'
import { SuperGrid } from '../../ui/SuperGrid'

const QUERY = gql`
  query ListPlusRenewalFailures(
    $terms: String
    $page: Int
    $perPage: Int
    $sortBy: PlusRenewalFailureSortBy
    $sortOrder: PlusRenewalFailureSortOrder
  ) {
    listPlusRenewalErrors(terms: $terms, perPage: $perPage, page: $page, sortBy: $sortBy, sortOrder: $sortOrder) {
      plusRenewalFailures {
        id
        errorCode
        errorMessage
        created
        productApplication {
          id
        }
        details
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`

interface FlatFailure {
  id: string
  created: number
  productApplicationId: string
  errorCode: string
  errorMessage: string
  details: string
}

const columns = [
  {
    title: 'Failure ID',
    key: PlusRenewalFailureSortBy.id,
    render: (item: FlatFailure) => <TruncatedId value={item.id} />
  },
  {
    title: 'Occurred At',
    key: PlusRenewalFailureSortBy.created,
    render: (item: FlatFailure) => <FormattedDateTime format='MM/DD/YYYY' value={item.created} />
  },
  {
    title: 'Previous Subscription ID',
    key: PlusRenewalFailureSortBy.productApplicationId,
    render: (item: FlatFailure) =>
      item.productApplicationId ? (
        <TruncatedId value={item.productApplicationId} path={`/plus/${item.productApplicationId}`} />
      ) : null
  },
  {
    title: 'Error Code',
    key: PlusRenewalFailureSortBy.errorCode,
    render: (item: FlatFailure) => item.errorCode
  },
  {
    title: 'Error Message',
    key: PlusRenewalFailureSortBy.errorMessage,
    render: (item: FlatFailure) => item.errorMessage
  }
]

export const ListPlusRenewalFailures = () => {
  const perPage = 50
  const [sort, setSort] = useState<{
    by: PlusRenewalFailureSortBy
    order: PlusRenewalFailureSortOrder
  }>({
    by: PlusRenewalFailureSortBy.created,
    order: PlusRenewalFailureSortOrder.desc
  })
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const variables = {
    terms: (searchValue || '').trim(),
    sortBy: sort.by,
    sortOrder: sort.order
  }
  const { data, loading, error } = useQuery<ListPlusRenewalFailuresQuery, ListPlusRenewalFailuresVariables>(QUERY, {
    variables: {
      ...variables,
      perPage,
      page
    }
  })

  const plusRenewalFailures = data?.listPlusRenewalErrors.plusRenewalFailures || []
  const pagination = data?.listPlusRenewalErrors?.pagination

  const createFlatFailure = (input: ListPlusRenewalFailures_listPlusRenewalErrors_plusRenewalFailures) =>
    ({
      id: input.id,
      created: input.created,
      productApplicationId: input.productApplication?.id,
      errorCode: input.errorCode,
      errorMessage: input.errorMessage
    } as FlatFailure)

  const items = (plusRenewalFailures || []).map(createFlatFailure)

  return (
    <SuperGrid
      title='Plus Renewal Failures'
      setSort={setSort}
      columns={columns}
      rows={items}
      sortBy={sort.by}
      sortOrder={sort.order}
      pagination={pagination}
      setPage={setPage}
      error={!!error}
      loading={loading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      centerButtons={
        <CsvExport
          query={QUERY}
          variables={variables}
          getPagination={({ data }) => ({
            ...data.listPlusRenewalErrors.pagination,
            page: data.listPlusRenewalErrors.pagination.page + 1
          })}
          formatData={({ data }) => {
            return data.listPlusRenewalErrors.plusRenewalFailures.map(createFlatFailure).map((item: FlatFailure) => ({
              ...item,
              created: item.created ? moment(item.created).format('YYYY-MM-DD') : null
            }))
          }}
        />
      }
    />
  )
}
