import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useListMessageSets } from '../hooks/useListMessageSets'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { Check } from '@material-ui/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { MessageSetSortBy, MessageSetSortDirection } from '../types'
import { SortDirection, SortableTableCell } from '../../../ui/SortableTableCell'
import { SearchInput } from '../../../ui/SearchInput'

export const ListMessageSets = () => {
  const [search, setSearch] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<MessageSetSortDirection>(MessageSetSortDirection.asc)
  const [sortBy, setSortBy] = useState<MessageSetSortBy>(MessageSetSortBy.title)
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { data, loading, error, fetchMore } = useListMessageSets({
    input: {
      cursor: null,
      limit: 50,
      sortDirection,
      sortBy,
      title: search || undefined
    }
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message Sets</Typography>
        <FlexHorizontal>
          <SearchInput onSearch={setSearch} />
          <Box width={20} />
          <Button onClick={() => history.push('/message-sets/create')} variant='contained' color='primary'>
            Create New Message Set
          </Button>
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading && !data?.listMessageSets ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the message sets</Alert>
      ) : !data?.listMessageSets.messageSets.length ? (
        <Alert severity='info'>There are no message sets</Alert>
      ) : (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={async () => {
              if (isLoadingMore) {
                return
              }

              setIsLoadingMore(true)
              return await fetchMore({
                updateQuery(previousQueryResult, options) {
                  const newMessageSets = options.fetchMoreResult?.listMessageSets.messageSets || []
                  const previousMessageSets = previousQueryResult.listMessageSets.messageSets || []

                  return {
                    listMessageSets: {
                      ...previousQueryResult.listMessageSets,
                      nextCursor: options.fetchMoreResult?.listMessageSets.nextCursor || null,
                      messageSets: [...previousMessageSets, ...newMessageSets]
                    }
                  }
                },
                variables: {
                  input: {
                    sortDirection,
                    sortBy,
                    title: search || undefined,
                    cursor: data.listMessageSets.nextCursor || null,
                    limit: 50
                  }
                }
              }).then(res => {
                setIsLoadingMore(false)
                return res
              })
            }}
            hasMore={!!data?.listMessageSets?.nextCursor}
            loader={
              <Box>
                <Spacer size='large' />
                <CircularProgress />
              </Box>
            }>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: 100
                      }}>
                      ID
                    </TableCell>
                    <SortableTableCell
                      active={sortBy === MessageSetSortBy.title}
                      direction={SortDirection[sortDirection]}
                      onClick={direction => {
                        setSortDirection(MessageSetSortDirection[direction])
                        setSortBy(MessageSetSortBy.title)
                      }}>
                      Title
                    </SortableTableCell>
                    <SortableTableCell
                      width={100}
                      active={sortBy === MessageSetSortBy.public}
                      direction={SortDirection[sortDirection]}
                      onClick={direction => {
                        setSortDirection(MessageSetSortDirection[direction])
                        setSortBy(MessageSetSortBy.public)
                      }}>
                      Public
                    </SortableTableCell>
                    <SortableTableCell
                      width={120}
                      active={sortBy === MessageSetSortBy.dynamic}
                      direction={SortDirection[sortDirection]}
                      onClick={direction => {
                        setSortDirection(MessageSetSortDirection[direction])
                        setSortBy(MessageSetSortBy.dynamic)
                      }}>
                      Dynamic
                    </SortableTableCell>
                    <SortableTableCell
                      width={120}
                      active={sortBy === MessageSetSortBy.messagesCount}
                      direction={SortDirection[sortDirection]}
                      onClick={direction => {
                        setSortDirection(MessageSetSortDirection[direction])
                        setSortBy(MessageSetSortBy.messagesCount)
                      }}>
                      Messages
                    </SortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.listMessageSets.messageSets.map(messageSet => (
                    <TableRow key={messageSet.id}>
                      <TableCell>
                        <Button
                          onClick={() => {
                            history.push(`message-sets/${messageSet.id}`)
                          }}
                          variant='outlined'
                          size='small'
                          color='primary'>
                          <TruncatedId value={messageSet.id} />
                        </Button>
                      </TableCell>
                      <TableCell>{messageSet.title}</TableCell>
                      <TableCell>{messageSet.public ? <Check /> : null}</TableCell>
                      <TableCell>{messageSet.dynamic ? <Check /> : null}</TableCell>
                      <TableCell>{messageSet.messagesCount.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </>
      )}
    </Box>
  )
}
