import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Check, Clear } from '@material-ui/icons'
import React, { Fragment, useState } from 'react'
import { SearchMessageSortBy, SearchMessageSortDirection } from '../types'
import { SortDirection, SortableTableCell } from '../../../ui/SortableTableCell'

import { Alert } from '@material-ui/lab'
import InfiniteScroll from 'react-infinite-scroller'
import { MessageModal } from '../../message-v2'
import { SearchInput } from '../../../ui/SearchInput'
import { SearchMessageType } from '../../message-v2/types'
import { TruncatedId } from '../../truncated-id'
import { useHistory } from 'react-router'
import { useListPicks } from '../hooks/useListPicks'

const PER_PAGE = 25

export const ListPicks = () => {
  const [messageId, setMessageId] = useState<string | null>(null)
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<SearchMessageSortDirection>(SearchMessageSortDirection.desc)
  const [sortBy, setSortBy] = useState<SearchMessageSortBy>(SearchMessageSortBy.date)
  const [categoryTitle, setCategoryTitle] = useState<string | null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const isSearchTermDate = searchTerm && !!/^\d{4}-\d{2}-\d{2}$/.test(searchTerm)

  const input = {
    cursor: null,
    limit: PER_PAGE,
    sortBy,
    sortDirection,
    term: !isSearchTermDate ? searchTerm || undefined : undefined,
    date: isSearchTermDate ? new Date(searchTerm).getTime() : undefined,
    types: [SearchMessageType.Pick],
    tags: categoryTitle ? [categoryTitle] : undefined,
    showHidden: true
  }

  const { loading, error, data, fetchMore } = useListPicks({
    input
  })

  return (
    <>
      <MessageModal
        messageId={messageId}
        onClose={() => {
          setMessageId(null)
        }}
      />
      <Box>
        <FlexHorizontal spaceBetween>
          <Typography variant='h4'>Picks</Typography>
          <FlexHorizontal>
            {categoryTitle ? (
              <>
                <Button
                  onClick={() => {
                    setCategoryTitle(null)
                  }}
                  variant='outlined'
                  color='default'
                  endIcon={<Clear />}>
                  {categoryTitle}
                </Button>
                <Box width={20} />
              </>
            ) : null}
            <SearchInput onSearch={setSearchTerm} />
            <Box width={20} />
            <Button onClick={() => history.push('/picks/create')} variant='contained' color='primary'>
              Create New Pick
            </Button>
          </FlexHorizontal>
        </FlexHorizontal>
        <Spacer size='large' />
        {loading && !data?.listSearchMessages.searchMessages ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity='error'>There was an error loading the picks</Alert>
        ) : !data?.listSearchMessages.searchMessages.length ? (
          <Alert severity='info'>There are no picks</Alert>
        ) : (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newSearch = options.fetchMoreResult?.listSearchMessages.searchMessages || []
                    const previousSearch = previousQueryResult.listSearchMessages.searchMessages || []

                    return {
                      listSearchMessages: {
                        ...previousQueryResult.listSearchMessages,
                        nextCursor: options.fetchMoreResult?.listSearchMessages.nextCursor || null,
                        searchMessages: [...previousSearch, ...newSearch]
                      }
                    }
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data.listSearchMessages.nextCursor || null
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={!!data?.listSearchMessages?.nextCursor}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 360 }}>ID</TableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.date}
                        width={120}
                        onClick={direction => {
                          setSortDirection(SearchMessageSortDirection[direction])
                          setSortBy(SearchMessageSortBy.date)
                        }}>
                        Publish Date
                      </SortableTableCell>
                      <TableCell style={{ width: 100 }}>Published</TableCell>
                      <TableCell style={{ width: 300 }}>Categories</TableCell>
                      <TableCell>Tags</TableCell>
                      <SortableTableCell
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.messageText}
                        onClick={direction => {
                          setSortDirection(SearchMessageSortDirection[direction])
                          setSortBy(SearchMessageSortBy.messageText)
                        }}>
                        Message
                      </SortableTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listSearchMessages.searchMessages.map(pick =>
                      pick.__typename === 'PickV2' ? (
                        <TableRow key={pick.id}>
                          <TableCell>
                            <TruncatedId value={pick.id} full path={`/picks/${pick.id}`} />
                          </TableCell>
                          <TableCell>{pick.date}</TableCell>
                          <TableCell>{pick.public ? <Check /> : null}</TableCell>
                          <TableCell>
                            <FlexHorizontal>
                              {(pick?.categories || []).map((category, index) => (
                                <Fragment key={`${pick.id}-${category.id}`}>
                                  {index ? <Box width={7} /> : null}
                                  <Button
                                    size='small'
                                    variant='outlined'
                                    onClick={() => {
                                      setCategoryTitle(category.title)
                                    }}>
                                    {category.title}
                                  </Button>
                                </Fragment>
                              ))}
                            </FlexHorizontal>
                          </TableCell>

                          <TableCell>
                            <FlexHorizontal>
                              {(pick?.message?.tags || []).map((tag, index) => (
                                <Fragment key={`${pick.id}-${tag}`}>
                                  {index ? <Box width={7} /> : null}
                                  <Chip label={tag} />
                                </Fragment>
                              ))}
                            </FlexHorizontal>
                          </TableCell>
                          <TableCell style={{ width: 250 }}>
                            <Box width={250} onClick={() => setMessageId(pick.message.id)}>
                              <BoardPreview characters={pick.message.characters as IBoard} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </>
        )}
      </Box>
    </>
  )
}
