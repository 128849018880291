import { Typography } from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import React from 'react'
import { useHistory } from 'react-router'
import { IColorTemplateData, ColorTemplateForm } from '../components/ColorTemplateForm'
import { useCreateColorTemplate } from '../hooks/useCreateColorTemplate'
import { useMigratedColorTemplates } from '../../../api/useMigrateColorTemplates'

export const ColorTemplateCreate = () => {
  const history = useHistory()
  const [createColorTemplateMutation, { loading: saving }] = useCreateColorTemplate()
  const { addToast } = useToasts()
  const [migrate] = useMigratedColorTemplates()

  const saveColorTemplate = async (colorTemplate: IColorTemplateData) => {
    const { errors, data } = await createColorTemplateMutation({
      variables: {
        input: colorTemplate
      }
    })

    if (errors?.length) {
      return addToast('There was an error creating your color template', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your color template has been created', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/color-templates/${data?.createColorTemplate.created.id}`)

    await migrate()
  }

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Color Template</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <ColorTemplateForm onSave={saveColorTemplate} />
    </>
  )
}
