import { gql } from 'apollo-boost'
import { ExpirePlusSubscription, ExpirePlusSubscriptionVariables } from './__generated__/ExpirePlusSubscription'
import { useMutation } from '@apollo/react-hooks'

const EXPIRE_PLUS_SUBSCRIPTION_MUTATION = gql`
  mutation ExpirePlusSubscription($boardId: String!) {
    expirePlusSubscriptionForBoard(input: { board: $boardId }) {
      id
    }
  }
`

export const useExpirePlusSubscription = () => {
  return useMutation<ExpirePlusSubscription, ExpirePlusSubscriptionVariables>(EXPIRE_PLUS_SUBSCRIPTION_MUTATION)
}
