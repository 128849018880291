import { ButtonBase, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { gql } from 'apollo-boost'
import React from 'react'
import { UpdateDeviceCountryCode, UpdateDeviceCountryCodeVariables } from './__generated__/UpdateDeviceCountryCode'
import { useMutation } from '@apollo/react-hooks'
import emojiFlags from 'emoji-flags'

const UPDATE_DEVICE_COUNTRY_CODE = gql`
  mutation UpdateDeviceCountryCode($id: String!, $countryCode: String) {
    adminUpdateDeviceCountryCode(input: { id: $id, countryCode: $countryCode }) {
      id
      latestLocationCountryCode
    }
  }
`

interface ICountryCode {
  deviceId: string
  countryCode?: string | null
}

export const CountryCode = ({ deviceId, countryCode }: ICountryCode) => {
  const [updateCountryCodeMutation, { loading: updatingCountryCode }] = useMutation<
    UpdateDeviceCountryCode,
    UpdateDeviceCountryCodeVariables
  >(UPDATE_DEVICE_COUNTRY_CODE)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const updateCountryCode = React.useCallback(
    async (id: string, countryCode: string | null) => {
      await updateCountryCodeMutation({
        variables: {
          id,
          countryCode
        }
      })
    },
    [updateCountryCodeMutation]
  )

  const handleOpenCountryCode = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCountryCode = () => {
    setAnchorEl(null)
  }

  return !!deviceId ? (
    <>
      <ButtonBase onClick={handleOpenCountryCode}>
        {countryCode ? emojiFlags.countryCode(countryCode)?.emoji ?? 'Unknown' : <>🌎</>}
      </ButtonBase>
      <Menu elevation={1} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseCountryCode}>
        {updatingCountryCode ? (
          <CircularProgress />
        ) : (
          [
            <MenuItem
              onClick={async () => {
                await updateCountryCode(deviceId, 'US')
                handleCloseCountryCode()
              }}>
              {emojiFlags.countryCode('US')?.emoji}&nbsp; US
            </MenuItem>,
            <MenuItem
              onClick={async () => {
                await updateCountryCode(deviceId, null)
                handleCloseCountryCode()
              }}>
              <span role='img' aria-label='World'>
                🌎
              </span>
              &nbsp; Default
            </MenuItem>
          ]
        )}
      </Menu>
    </>
  ) : (
    <span role='img' aria-label='Virtual'>
      💻
    </span>
  )
}
