import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreateScheduledNotification,
  CreateScheduledNotificationVariables
} from './__generated__/CreateScheduledNotification'

const MUTATION = gql`
  mutation CreateScheduledNotification($input: CreateScheduledNotificationInputV2!) {
    createScheduledNotificationV2(input: $input) {
      ... on ScheduledNotificationV2 {
        id
        createdAt
        sendAt
        message
        title
        callToAction
        deepLink
        tag {
          id
          title
        }
        subscribers {
          id
          title
        }
      }
      ... on ScheduledNotificationErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreateScheduledNotification = () =>
  useMutation<CreateScheduledNotification, CreateScheduledNotificationVariables>(MUTATION)
