import * as React from 'react'

import { Link as MaterialLink } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

interface ILinkProps {
  children: string | JSX.Element
  to: string
}

const LinkBuilder = MaterialLink as any

export const Link: React.FC<ILinkProps> = props => {
  return (
    <LinkBuilder component={RouterLink} to={props.to}>
      {props.children}
    </LinkBuilder>
  )
}
