import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { useGenerateActivationCodes } from '../hooks/useGenerateActivationCodes'
import { csvDownload, csvFormat } from '../../../utils/csv'
import { TruncatedId } from '../../truncated-id'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles({
  box: {
    padding: 24
  }
})

const ONE_MONTH_PRICING = '5953435d-9524-4625-9008-2d2a1c7085a3'

export const GenerateActivationCodes = () => {
  const [number, setNumber] = useState(0)
  const classes = useStyles()
  const [generateActivationCodesMutation, { loading, data }] = useGenerateActivationCodes()
  const { addToast } = useToasts()

  const generateActivationCodes = useCallback(
    async (quantity: number) => {
      try {
        const { data, errors } = await generateActivationCodesMutation({
          variables: {
            input: {
              quantity,
              productPricingId: ONE_MONTH_PRICING
            }
          }
        })

        if (!data || errors) {
          addToast(`Error generating activation codes`, {
            appearance: 'error'
          })
          return
        }

        addToast(`Activation codes generated`, {
          appearance: 'success'
        })

        const csv = csvFormat(
          data.generateActivationCodes.map(ac => ({
            id: ac.id,
            code: ac.code
          })),
          ['id', 'code'] as any
        )

        csvDownload(csv, 'activation-codes')
      } catch (e) {
        addToast(`Error generating activation codes`, {
          appearance: 'error'
        })
      }
    },
    [addToast, generateActivationCodesMutation]
  )

  return (
    <>
      {loading ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Generate Activation Codes</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Box className={classes.box}>
          <FlexHorizontal>
            <Typography variant='h5'>Number of One Month Activation Codes to Generate</Typography>
          </FlexHorizontal>
          <Spacer size='medium' />
          <FlexHorizontal>
            <TextField
              variant='outlined'
              type='number'
              value={number}
              onChange={e => {
                if (+e.target.value < 0) {
                  setNumber(0)
                  return
                }
                setNumber(+e.target.value)
              }}
            />
            <Box width={7} />
            <Button
              variant='contained'
              color='primary'
              onClick={async () => {
                await generateActivationCodes(number)
              }}>
              Generate
            </Button>
          </FlexHorizontal>
        </Box>
      </Card>
      <Spacer size='large' />
      <Alert severity='info'>
        After clicking generate, make sure you save these codes in a safe place. You will never see them again!
      </Alert>
      <Spacer size='extraLarge' />
      {data && data.generateActivationCodes.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Activation Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.generateActivationCodes.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    <TruncatedId value={item.id} full />
                  </TableCell>
                  <TableCell>{item.code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  )
}
