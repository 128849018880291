import { BoardPreview, FlexHorizontal, IBoard, LoadMask, Spacer } from '@vestaboard/installables'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router'

import { Alert } from '@material-ui/lab'
import { FormattedDateTime } from '../../formatted-date-time'
import { Link } from 'react-router-dom'
import { MessageTags } from '../../message-v2'
import React from 'react'
import { TruncatedId } from '../../truncated-id'
import { useGetFeedItem } from '../hooks/useGetFeedItem'
import { useModerate } from '../hooks/useModerate'
import { useUnModerate } from '../hooks/useUnModerate'

const useStyles = makeStyles({
  cardInner: {
    padding: 20
  }
})

export const GetFeedItem = () => {
  const params = useParams<{ id: string }>()
  const { data, loading, error } = useGetFeedItem(params.id)
  const classes = useStyles()
  const [moderate, { loading: savingModerate }] = useModerate()
  const [unModerate, { loading: savingUnModerate }] = useUnModerate()
  const history = useHistory()

  return error ? (
    <Alert severity='error'>There was an error loading the feed item</Alert>
  ) : loading ? (
    <CircularProgress />
  ) : data?.feedItem.__typename === 'FeedItemErrorV2' ? (
    <Alert severity='error'>{data.feedItem.error}</Alert>
  ) : (
    <>
      {savingUnModerate || savingModerate ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Feed Item</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <Box className={classes.cardInner}>
          <TextField value={data?.feedItem.id} disabled label='ID' variant='outlined' fullWidth />
          <Spacer size='large' />
          <TextField value={data?.feedItem.message.text} disabled label='Text' variant='outlined' fullWidth />
          <Spacer size='large' />
          <Box width={400}>
            <BoardPreview characters={data?.feedItem.message?.characters as IBoard} />
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Message ID</TableCell>
                  <TableCell>Share Date</TableCell>
                  <TableCell>Share Type</TableCell>
                  <TableCell>Attribution</TableCell>
                  <TableCell>Likes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TruncatedId
                      value={data?.feedItem.message.id || ''}
                      path={`/message/${data?.feedItem.message.id}`}
                      full
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={data?.feedItem.created} />
                  </TableCell>
                  <TableCell>{data?.feedItem.subscriptionId ? 'Subscription' : 'Person'}</TableCell>
                  <TableCell>
                    <MuiLink
                      component={Link}
                      to={
                        data?.feedItem.subscriptionId
                          ? `/subscription/${data.feedItem.subscriptionId}`
                          : `/person/${data?.feedItem.personId}`
                      }>
                      {data?.feedItem.attribution}
                    </MuiLink>
                  </TableCell>
                  <TableCell>{data?.feedItem.likeCount.toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Spacer size='extraLarge' />
          <FlexHorizontal>
            <Button
              variant='contained'
              onClick={() => {
                history.push(`/feed-items`)
              }}>
              Cancel
            </Button>
            <Box style={{ width: 20 }} />
            <Button
              variant='contained'
              onClick={
                data?.feedItem.moderated
                  ? async () => {
                      await unModerate({
                        variables: {
                          input: {
                            id: params.id
                          }
                        }
                      })
                    }
                  : async () => {
                      await moderate({
                        variables: {
                          input: {
                            id: params.id
                          }
                        }
                      })
                    }
              }
              size='large'
              color={data?.feedItem.moderated ? 'secondary' : 'primary'}>
              {data?.feedItem.moderated ? 'Un-Flag as Inappropriate' : 'Flag as Inappropriate'}
            </Button>
          </FlexHorizontal>
        </Box>
      </Card>
      {data?.feedItem?.message ? (
        <MessageTags tags={data?.feedItem.message?.tags || []} messageId={data?.feedItem.message.id} />
      ) : null}
      {data?.feedItem.listFlags?.flaggedFeedItems.length ? (
        <>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>User Flags</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Person</TableCell>
                    <TableCell>Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.feedItem.listFlags?.flaggedFeedItems.map(flaggedFeedItem => (
                    <TableRow key={flaggedFeedItem.personId}>
                      <TableCell
                        style={{
                          width: 150
                        }}>
                        <TruncatedId
                          value={flaggedFeedItem.personId}
                          path={`/person/${flaggedFeedItem.personId}`}
                          full
                        />
                      </TableCell>
                      <TableCell>{flaggedFeedItem?.reason || ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      ) : null}
      <Spacer size='extraLarge' />
    </>
  )
}
