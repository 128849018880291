import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UpdateRenewalDate, UpdateRenewalDateVariables } from './__generated__/UpdateRenewalDate'

const UPDATE_RENEWAL_DATE = gql`
  mutation UpdateRenewalDate($input: UpdateRenewalDateInput!) {
    adminUpdateRenewalDate(input: $input) {
      id
      nextBillingDate
    }
  }
`

export const useUpdateRenewalDate = () => {
  return useMutation<UpdateRenewalDate, UpdateRenewalDateVariables>(UPDATE_RENEWAL_DATE)
}
