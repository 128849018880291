import React from 'react'
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

interface IYearPicker {
  value: string
  setValue: (value: string) => void
}

const getYears = () => {
  const years = [2021]

  while (years[years.length - 1] < new Date().getFullYear()) {
    years.push(years[years.length - 1] + 1)
  }
  return years.map(year => `${year}`)
}

const years = getYears()

const useStyles = makeStyles({
  button: {
    color: '#FFF'
  },
  menu: {
    marginTop: 60
  }
})

export const YearPicker = (props: IYearPicker) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant='text'
        className={classes.button}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        endIcon={<KeyboardArrowDown />}>
        {props.value}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.menu}>
        {years.map(year => (
          <MenuItem
            key={year}
            onClick={() => {
              props.setValue(year)
              handleClose()
            }}>
            {year}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
