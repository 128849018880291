import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import { useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'
import { useCreateInvitation } from '../hooks/useCreateInvitation'
import { useBoardDetailQuery } from '../hooks/useBoardDetailQuery'

export const BoardInvite = (props: any) => {
  const [inviting, setInviting] = React.useState(false)

  const [email, setEmail] = useState('')
  const [role, setRole] = useState('User')
  const [createInvitation] = useCreateInvitation()
  const { addToast } = useToasts()

  return (
    <div
      style={{
        padding: 8,
        paddingLeft: 0,
        paddingBottom: 12
      }}>
      <Button
        title='Invite User'
        onClick={() => {
          setInviting(!inviting)
        }}
        variant='outlined'>
        Invite User
      </Button>
      {inviting && (
        <div style={{ padding: 8 }}>
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
            <TextField
              id='email'
              label='Email'
              variant='outlined'
              style={{ width: 400 }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Select
              labelId='role'
              id='role'
              value={role}
              style={{
                marginLeft: 8
              }}
              label='Role'
              onChange={(e: any) => setRole(e.target.value)}>
              <MenuItem value={'Owner'}>Owner</MenuItem>
              <MenuItem value={'Admin'}>Admin</MenuItem>
              <MenuItem value={'User'}>User</MenuItem>
            </Select>
          </div>
          <div style={{ paddingTop: 8 }}>
            <Button
              title='Send Invite'
              onClick={async () => {
                await createInvitation({
                  variables: {
                    input: {
                      emailAddress: email,
                      boards: props.boardId,
                      role
                    }
                  },
                  refetchQueries: [
                    {
                      query: useBoardDetailQuery.Query,
                      variables: {
                        boardId: props.boardId
                      }
                    }
                  ]
                })
                addToast(`Successfully invited ${email}`, {
                  appearance: 'success'
                })
                setEmail('')
              }}
              variant='contained'>
              Send Invite
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
