import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GenerateActivationCodes, GenerateActivationCodesVariables } from './__generated__/GenerateActivationCodes'

const MUTATION = gql`
  mutation GenerateActivationCodes($input: GenerateActivationCodesInput!) {
    generateActivationCodes(input: $input) {
      id
      code
    }
  }
`

export const useGenerateActivationCodes = () => {
  return useMutation<GenerateActivationCodes, GenerateActivationCodesVariables>(MUTATION)
}
