import React from 'react'
import { ReportLayout } from '../../../ui/ReportLayout'
import { RangeType } from '../../../ui/RangeType'
import { getReportStateFromHash } from '../../../utils/getReportStateFromHash'
import { ReportNextButton } from '../../../ui/ReportNextButton'
import { ReportPreviousButton } from '../../../ui/ReportPreviousButton'
import { DisabledDateButton } from '../../../ui/DisabledDateButton'
import moment from 'moment'
import { DailyInstallablesDashboard } from '../reports/DailyInstallablesDashboard'

export const DashboardInstallables = () => {
  const [date, setDate] = React.useState<string | null>(null)
  const [{ rangeType, searchKey }, setSearchState] = React.useState<{
    rangeType: string
    searchKey: string | null
  }>(
    getReportStateFromHash() || {
      rangeType: 'day',
      searchKey: null
    }
  )

  const setSearch = React.useCallback(({ rangeType, searchKey }) => {
    setSearchState({ rangeType, searchKey })
    window.location.hash = `#${rangeType}|${searchKey}`
  }, [])

  return (
    <ReportLayout
      title='Installables Report'
      filters={
        <>
          {rangeType === 'day' ? <DisabledDateButton date={date} /> : null}
          <RangeType setSearch={setSearch} rangeType={rangeType} types={['day']} />
          <ReportPreviousButton
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.subtract(1, 'day').format('YYYY-MM-DD')
                })
              }
            }}
          />
          <ReportNextButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.add(1, 'day').format('YYYY-MM-DD')
                })
              }
            }}
          />
        </>
      }>
      <DailyInstallablesDashboard searchKey={searchKey} setDate={setDate} />
    </ReportLayout>
  )
}
