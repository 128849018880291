import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateMarketplaceFeatureTypeV2,
  UpdateMarketplaceFeatureTypeV2Variables
} from './__generated__/UpdateMarketplaceFeatureTypeV2'

const MUTATION = gql`
  mutation UpdateMarketplaceFeatureTypeV2($input: UpdateMarketplaceFeatureTypeInputV2!) {
    updateMarketplaceFeatureType: updateMarketplaceFeatureTypeV2(input: $input) {
      ... on MarketplaceFeatureTypeV2 {
        id
      }
      ... on MarketplaceFeatureTypeErrorV2 {
        error
        type
      }
    }
  }
`

export const useUpdateMarketplaceFeatureTypeV2 = () =>
  useMutation<UpdateMarketplaceFeatureTypeV2, UpdateMarketplaceFeatureTypeV2Variables>(MUTATION)
