export enum BoardDeviceAssociationSortBy {
  accountEmailAddress = 'accountEmailAddress',
  accountId = 'accountId',
  boardCreated = 'boardCreated',
  boardDeleted = 'boardDeleted',
  boardDeviceAssociationCreated = 'boardDeviceAssociationCreated',
  boardDeviceAssociationId = 'boardDeviceAssociationId',
  boardId = 'boardId',
  boardTitle = 'boardTitle',
  deviceCreated = 'deviceCreated',
  deviceId = 'deviceId',
  deviceLastSeen = 'deviceLastSeen',
  lastMessage = 'lastMessage',
  messagesAllTime = 'messagesAllTime',
  messagesInPastDay = 'messagesInPastDay',
  messagesInPastSevenDays = 'messagesInPastSevenDays',
  messagesInPastThirtyDays = 'messagesInPastThirtyDays',
  online = 'online',
  pairingCode = 'pairingCode',
  personFirstName = 'personFirstName',
  personId = 'personId',
  personLastName = 'personLastName',
  productApplicationCreated = 'productApplicationCreated',
  productApplicationId = 'productApplicationId',
  productPricingId = 'productPricingId',
  productPricingRecurringFrequency = 'productPricingRecurringFrequency',
  ssid = 'ssid',
  firmwareVersion = 'firmwareVersion',
  yoctoStatus = 'yoctoStatus',
  deviceModelId = 'deviceModelId',
  deviceModelTitle = 'deviceModelTitle',
  enabledChannels = 'enabledChannels',
  hubspotDealId = 'hubspotDealId',
  internalBoard = 'internalBoard',
  lastIndexed = 'lastIndexed',
  channels = 'channels',
  membersCount = 'membersCount',
  osVersionName = 'osVersionName',
  osVersionId = 'osVersionId',
  osVersionBuildId = 'osVersionBuildId'
}

export enum BoardDeviceAssociationSortOrder {
  asc = 'asc',
  desc = 'desc'
}
