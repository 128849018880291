import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Line, ComposedChart } from 'recharts'

interface IBoardsOverTime {
  data: {
    Paired: number
    Online: number
    Plus: number
    key: string
  }[]
}

export const BoardsOverTimeChart = (props: IBoardsOverTime) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key' />
        <YAxis domain={['dataMin', 'dataMax']} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Line type='monotone' dataKey='Paired' stroke='rgb(112, 47, 138)' />
        <Line type='monotone' dataKey='Online' stroke='rgb(0, 132, 213)' />
        <Line type='monotone' dataKey='Plus' stroke='rgb(255, 184, 28)' />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
