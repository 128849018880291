import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateColorTemplateCategoryMutation,
  UpdateColorTemplateCategoryMutationVariables
} from './__generated__/UpdateColorTemplateCategoryMutation'

const UPDATE_COLOR_TEMPLATE_CATEGORY = gql`
  mutation UpdateColorTemplateCategoryMutation($input: UpdateColorTemplateCategoryInput!) {
    updateColorTemplateCategory(input: $input) {
      updated {
        id
        title
      }
    }
  }
`

export const useUpdateColorTemplateCategory = () => {
  return useMutation<UpdateColorTemplateCategoryMutation, UpdateColorTemplateCategoryMutationVariables>(
    UPDATE_COLOR_TEMPLATE_CATEGORY
  )
}
