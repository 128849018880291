import { useQuery } from '@apollo/react-hooks'
import { CircularProgress, MenuItem, Select } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { gql } from 'apollo-boost'
import React, { useCallback, useState } from 'react'
import { useUpdateDevice } from '../../api'
import { CharacterSets } from './__generated__/CharacterSets'

interface ICharacterSet {
  deviceId: string
  id: string
}

const CHARACTER_SETS_QUERY = gql`
  query CharacterSets {
    characterSets {
      id
      title
    }
  }
`

export const CharacterSet = (props: ICharacterSet) => {
  const [id, setId] = useState(props.id)
  const { loading, data, error } = useQuery<CharacterSets>(CHARACTER_SETS_QUERY)
  const [updateDeviceMutation] = useUpdateDevice()
  const { deviceId } = props

  const updateDevice = useCallback(
    async (characterSet: string) => {
      setId(characterSet)

      try {
        await updateDeviceMutation({
          variables: {
            input: {
              id: deviceId,
              characterSet
            }
          }
        })
      } catch (e) {}
    },
    [updateDeviceMutation, deviceId]
  )

  return error ? (
    <Alert severity='error'>Unable to load device models</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Select
      variant='outlined'
      value={id}
      onChange={e => {
        updateDevice(e.target.value as string)
      }}>
      <MenuItem></MenuItem>
      {data.characterSets.map(characterSet => (
        <MenuItem key={characterSet.id} value={characterSet.id}>
          {characterSet.title}
        </MenuItem>
      ))}
    </Select>
  )
}
