import React from 'react'
import { PieChart, Tooltip, Pie } from 'recharts'

interface IFirmwareVersions {
  firmwareVersion: {
    [key: string]: number
  }
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (input: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, payload } = input
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  if (percent * 100 < 2) {
    return null
  }

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${payload?.name} - ${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const fillColors = [
  'rgb(0, 132, 213)',
  'rgb(0, 154, 68)',
  'rgb(218, 41, 28)',
  'rgb(112, 47, 138)',
  'rgb(255, 117, 0)',
  'rgb(255, 184, 28)'
]

export const FirmwareVersions = (props: IFirmwareVersions) => {
  const firmwareVersions = Object.keys(props.firmwareVersion).map((key, index) => ({
    name: key,
    value: props.firmwareVersion[key],
    fill: fillColors[index]
  }))

  return (
    <>
      <PieChart width={600} height={500} title='Firmware Versions'>
        <Pie
          data={firmwareVersions}
          dataKey='value'
          nameKey='name'
          cx='220'
          cy='200'
          outerRadius={200}
          fill='#FFF'
          label={renderCustomizedLabel}
          labelLine={false}
        />
        <Tooltip />
      </PieChart>
    </>
  )
}
