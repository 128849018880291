import { useCallback, useEffect, useState } from 'react'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'
import { IQa } from '../types'

interface IUseGetQa {
  token: string
  id: string
}

export const useGetQA = (props: IUseGetQa) => {
  const [data, setData] = useState<IQa | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const fetchQa = useCallback(async () => {
    try {
      const result = await fetch(`${INSTALLABLES_MONOREPO_API}/api/question-answer/set/${props.id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      setData(result as IQa)
    } catch (err) {
      setError('Error fetching QA')
    }

    setLoading(false)
  }, [props.token, props.id])

  useEffect(() => {
    fetchQa()
  }, [fetchQa])

  return { data, loading, error }
}
