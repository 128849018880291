import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListPickCategoriesView } from './__generated__/ListPickCategoriesView'

const LIST_PICK_CATEGORIES_VIEW = gql`
  query ListPickCategoriesView {
    picksCategories {
      id
      title
      picksLength
    }
  }
`

export const useListPickCategories = () => {
  return useQuery<ListPickCategoriesView>(LIST_PICK_CATEGORIES_VIEW, {
    fetchPolicy: 'cache-and-network'
  })
}
