import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { AdminUpdateBoard, AdminUpdateBoardVariables } from './__generated__/AdminUpdateBoard'

const MUTATION = gql`
  mutation AdminUpdateBoard($input: AdminUpdateBoardInput!) {
    adminUpdateBoard(input: $input) {
      id
      hubspotDealId
      internalBoard
    }
  }
`

export const useAdminUpdateBoard = () => {
  return useMutation<AdminUpdateBoard, AdminUpdateBoardVariables>(MUTATION)
}
