import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListAcceptedReferrals, ListAcceptedReferralsVariables } from './__generated__/ListAcceptedReferrals'

const QUERY = gql`
  query ListAcceptedReferrals($input: ListAcceptedReferralInputV2!) {
    listAcceptedReferrals: listAcceptedReferralsV2(input: $input) {
      acceptedReferrals {
        id
        notified
        createdAt
        purchaseId
        invalidated
        shopifyOrderId
        shopifyOrderName
        shopifyCustomerName
        shopifyCustomerEmail
        referrerPersonId
        referralCode
        referrerName
        referrerEmail
        orderDeliveredAt
        creditRedeemed
        creditType
      }
      nextCursor
    }
  }
`

export const useListAcceptedReferrals = (variables: ListAcceptedReferralsVariables) =>
  useQuery<ListAcceptedReferrals, ListAcceptedReferralsVariables>(QUERY, {
    variables
  })

useListAcceptedReferrals.QUERY = QUERY
