import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeleteSubscription, DeleteSubscriptionVariables } from './__generated__/DeleteSubscription'

const MUTATION = gql`
  mutation DeleteSubscription($subscriptionId: String!) {
    deleteSubscription(input: { id: $subscriptionId }) {
      deleted
    }
  }
`

export const useDeleteSubscription = () => {
  return useMutation<DeleteSubscription, DeleteSubscriptionVariables>(MUTATION)
}
