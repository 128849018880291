import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { MessageHistory, MessageHistoryVariables } from './__generated__/MessageHistory'

const QUERY = gql`
  query MessageHistory($id: String!, $page: Int!) {
    board(id: $id) {
      id
      history(perPage: 11, page: $page) {
        message {
          id
          text
          authorFormatted
          created
        }
      }
    }
  }
`

export const useMessageHistory = (boardId: string, page: number) =>
  useQuery<MessageHistory, MessageHistoryVariables>(QUERY, {
    variables: { id: boardId, page }
  })
