import { CircularProgress, Grid, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import { Confirm, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { DeleteMessageSet, DeleteMessageSetVariables } from './__generated__/DeleteMessageSet'
import { IMessageSetFormData, MessageSetForm } from '../message-set-form'
import { MessageSetDetails, MessageSetDetailsVariables } from './__generated__/MessageSetDetails'
import React, { useCallback, useEffect, useState } from 'react'
import { UpdateMessageSetMutation, UpdateMessageSetMutationVariables } from './__generated__/UpdateMessageSetMutation'
import { useHistory, useLocation, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Alert } from '@material-ui/lab'
import { DynamicMessages } from './DynamicMessages'
import { MessageSetVersion } from './MessageSetVersion'
import { gql } from 'apollo-boost'
import { parseQueryString } from '../../../utils/parseQuerystring'
import { useDeleteMessageSet as useDeleteMessageSetV2 } from '../../message-set-v2/hooks/useDeleteMessageSet'

const GET_MESSAGE_SET = gql`
  query MessageSetDetails($id: String!) {
    messageSet(id: $id) {
      id
      title
      public
      dynamic
      dynamicConfiguration
      count
      currentVersion {
        id
        isCurrent
        created
        createdBy {
          id
          firstName
          lastName
        }
      }
      versions(first: 50) {
        id
        isCurrent
        created
        count
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const UPDATE_MESSAGE_SET = gql`
  mutation UpdateMessageSetMutation($input: UpdateMessageSetInput!) {
    updateMessageSet(input: $input) {
      id
      public
      dynamic
      dynamicConfiguration
      title
    }
  }
`

const DELETE_MESSAGE_SET = gql`
  mutation DeleteMessageSet($input: DeleteMessageSetInput!) {
    deleteMessageSet(input: $input) {
      deleted
    }
  }
`

const useStyles = makeStyles({
  verticalCenter: {
    alignItems: 'center',
    paddingTop: 24
  }
})

export const MessageSetEditContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const { addToast } = useToasts()
  const classes = useStyles()
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const { id, versionId } = useParams<{ id: string; versionId?: string }>()
  const [selectedVersion, setSelectedVersion] = useState<string | null>(versionId || null)
  const [deleteMessageSetMutation, { loading: deletingMessageSet }] = useMutation<
    DeleteMessageSet,
    DeleteMessageSetVariables
  >(DELETE_MESSAGE_SET)
  const [updateMessageSetMutation, { loading: updatingMessageSet }] = useMutation<
    UpdateMessageSetMutation,
    UpdateMessageSetMutationVariables
  >(UPDATE_MESSAGE_SET)
  const { data, loading, error, refetch } = useQuery<MessageSetDetails, MessageSetDetailsVariables>(GET_MESSAGE_SET, {
    variables: {
      id
    }
  })
  const [deleteMessageSetV2] = useDeleteMessageSetV2()

  useEffect(() => {
    if (!selectedVersion && !data?.messageSet.dynamic) {
      setSelectedVersion(data?.messageSet.currentVersion?.id || null)
    }
  }, [data, selectedVersion])

  const updateMessageSet = useCallback(
    async (data: IMessageSetFormData) => {
      const result = await updateMessageSetMutation({
        variables: {
          input: {
            id,
            title: data.title,
            public: data.public
          }
        }
      })

      if (result?.errors?.length) {
        addToast('There was an error saving your message set', {
          appearance: 'error',
          autoDismiss: true
        })
        return
      } else {
        addToast('Message set saved', {
          appearance: 'success',
          autoDismiss: true
        })
      }
    },
    [id, updateMessageSetMutation, addToast]
  )

  const deleteMessageSet = useCallback(async () => {
    const result = await deleteMessageSetMutation({
      variables: {
        input: {
          id
        }
      }
    })

    if (result?.errors?.length) {
      addToast('There was an error deleting your message set', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    } else {
      addToast('Message set deleted', {
        appearance: 'success',
        autoDismiss: true
      })

      try {
        await deleteMessageSetV2({
          variables: {
            input: {
              id
            }
          }
        })
      } catch (err) {}

      history.push('/message-sets')
    }
  }, [deleteMessageSetMutation, id, addToast, history, deleteMessageSetV2])

  return error ? (
    <Alert severity='error'>Message set not found.</Alert>
  ) : loading || !data?.messageSet ? (
    <CircularProgress />
  ) : (
    <>
      <Confirm
        title='Delete Message Set?'
        message='Are you sure you want to delete this message set? Chose wisely!'
        open={confirmingDelete}
        handleClose={() => setConfirmingDelete(false)}
        handleAccept={deleteMessageSet}
      />
      {updatingMessageSet || deletingMessageSet ? <LoadMask /> : null}
      <Typography variant='h4'>Edit Message Set</Typography>
      <Spacer size='extraLarge' />
      <MessageSetForm
        messageSet={{
          title: data.messageSet.title || parseQueryString<{ title?: string }>(location.search)?.title || '',
          public: data.messageSet.public,
          dynamic: data.messageSet.dynamic,
          dynamicConfiguration: data.messageSet.dynamicConfiguration
        }}
        onSave={updateMessageSet}
        onCancel={() =>
          data.messageSet.title ? history.push('/message-sets') : history.push('/message-sets/submissions')
        }
        onDelete={() => {
          setConfirmingDelete(true)
        }}
      />
      <Spacer size='extraLarge' />
      {!data.messageSet.dynamic ? (
        <>
          <Grid container spacing={3} className={classes.verticalCenter}>
            <Grid item>
              <Typography variant='h5'>Message Set Version</Typography>
            </Grid>
            <Grid item>
              <Select
                value={selectedVersion}
                onChange={e => setSelectedVersion(e.target.value as string)}
                variant='outlined'>
                {(data.messageSet.versions?.length ? data.messageSet.versions : [data.messageSet.currentVersion]).map(
                  (version, key) => (
                    <MenuItem key={version.id} value={version.id}>
                      {`v${(data.messageSet.versions.length || 1) - key}.0`}
                      {version.isCurrent ? ' (current)' : ''}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid>
          </Grid>
          <Spacer size='large' />
        </>
      ) : null}
      {data.messageSet.dynamic ? <DynamicMessages messageSetId={id} /> : null}
      {selectedVersion ? (
        <MessageSetVersion
          key={selectedVersion}
          id={selectedVersion}
          messageSetId={id}
          onVersionCreated={async id => {
            await refetch()
            setSelectedVersion(id)
          }}
        />
      ) : null}
    </>
  )
}
