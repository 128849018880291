import * as React from 'react'
import { Typography, makeStyles, Tooltip, withStyles, ButtonBase } from '@material-ui/core'
import { Link } from '../link'
import { useToasts } from '@vestaboard/installables'

type TruncatedIdProps = ITruncatedIdProps

interface ITruncatedIdProps {
  value: string
  path?: string
  copy?: boolean
  full?: boolean
}

const useStyled = makeStyles({
  type: {
    width: '150px'
  },
  full: {
    width: '400px'
  }
})

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip)

export const TruncatedId: React.FC<TruncatedIdProps> = props => {
  const classes = useStyled()
  const { addToast } = useToasts()

  const copyId = React.useCallback(async () => {
    await navigator.clipboard.writeText(props.value)
    addToast(`Copied the UUID to your clipboard.`, {
      appearance: 'success',
      autoDismiss: true
    })
  }, [props.value, addToast])

  if (props.full) {
    return (
      <Typography>
        {props.path ? (
          <Link to={props.path}>{props.value}</Link>
        ) : props.copy ? (
          <ButtonBase onClick={copyId}>{props.value}</ButtonBase>
        ) : (
          props.value
        )}
      </Typography>
    )
  }

  return (
    <LightTooltip title={props.value} placement='bottom-start'>
      <Typography className={classes.type} noWrap>
        {props.path ? (
          <Link to={props.path}>{props.value}</Link>
        ) : props.copy ? (
          <ButtonBase onClick={copyId}>{props.value}</ButtonBase>
        ) : (
          props.value
        )}
      </Typography>
    </LightTooltip>
  )
}
