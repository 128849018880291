import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import {
  Box,
  Chip,
  CircularProgress,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { SearchMessageSortBy, SearchMessageSortDirection } from '../types'
import { SortDirection, SortableTableCell } from '../../../ui/SortableTableCell'

import { Alert } from '@material-ui/lab'
import { Check } from '@material-ui/icons'
import { FormattedDateTime } from '../../formatted-date-time'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { MessageModal } from '../../message-v2'
import { SearchInput } from '../../../ui/SearchInput'
import { SearchMessageType } from '../../message-v2/types'
import { TruncatedId } from '../../truncated-id'
import { useListFeedItems } from '../hooks/useListFeedItems'

const PER_PAGE = 25

export const ListFeedItems = () => {
  const [messageId, setMessageId] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [sortDirection, setSortDirection] = useState<SearchMessageSortDirection>(SearchMessageSortDirection.desc)
  const [sortBy, setSortBy] = useState<SearchMessageSortBy>(SearchMessageSortBy.date)

  const { loading, error, data, fetchMore } = useListFeedItems({
    input: {
      cursor: null,
      limit: PER_PAGE,
      term: searchTerm,
      types: [SearchMessageType.FeedItem],
      allUsers: true,
      showHidden: true,
      sortDirection,
      sortBy
    }
  })

  return (
    <>
      <MessageModal messageId={messageId} onClose={() => setMessageId(null)} />
      <Box>
        <FlexHorizontal spaceBetween>
          <Typography variant='h4'>Feed Items</Typography>
          <FlexHorizontal>
            <SearchInput onSearch={setSearchTerm} />
          </FlexHorizontal>
        </FlexHorizontal>
        <Spacer size='large' />
        {loading && !data?.listSearchMessages ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity='error'>There was an error loading the feed items</Alert>
        ) : !data?.listSearchMessages.searchMessages.length ? (
          <Alert severity='info'>There are no feed items</Alert>
        ) : (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newPicks = options.fetchMoreResult?.listSearchMessages.searchMessages || []
                    const previousPicks = previousQueryResult.listSearchMessages.searchMessages || []

                    return {
                      listSearchMessages: {
                        ...previousQueryResult.listSearchMessages,
                        nextCursor: options.fetchMoreResult?.listSearchMessages.nextCursor || null,
                        searchMessages: [...previousPicks, ...newPicks]
                      }
                    }
                  },
                  variables: {
                    input: {
                      limit: PER_PAGE,
                      term: searchTerm,
                      cursor: data.listSearchMessages.nextCursor || null,
                      types: [SearchMessageType.FeedItem],
                      allUsers: true,
                      showHidden: true,
                      sortDirection,
                      sortBy
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={false}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 360 }}>ID</TableCell>
                      <SortableTableCell
                        width={120}
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.moderated}
                        onClick={() => {
                          setSortBy(SearchMessageSortBy.moderated)
                          setSortDirection(
                            sortDirection === SearchMessageSortDirection.asc
                              ? SearchMessageSortDirection.desc
                              : SearchMessageSortDirection.asc
                          )
                        }}>
                        Moderated
                      </SortableTableCell>
                      <SortableTableCell
                        width={180}
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.date}
                        onClick={() => {
                          setSortBy(SearchMessageSortBy.date)
                          setSortDirection(
                            sortDirection === SearchMessageSortDirection.asc
                              ? SearchMessageSortDirection.desc
                              : SearchMessageSortDirection.asc
                          )
                        }}>
                        Share Date
                      </SortableTableCell>
                      <TableCell style={{ width: 120 }}>Share Type</TableCell>
                      <SortableTableCell
                        width={300}
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.attribution}
                        onClick={() => {
                          setSortBy(SearchMessageSortBy.attribution)
                          setSortDirection(
                            sortDirection === SearchMessageSortDirection.asc
                              ? SearchMessageSortDirection.desc
                              : SearchMessageSortDirection.asc
                          )
                        }}>
                        Attribution
                      </SortableTableCell>
                      <TableCell>Tags</TableCell>
                      <SortableTableCell
                        width={80}
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.likes}
                        onClick={() => {
                          setSortBy(SearchMessageSortBy.likes)
                          setSortDirection(
                            sortDirection === SearchMessageSortDirection.asc
                              ? SearchMessageSortDirection.desc
                              : SearchMessageSortDirection.asc
                          )
                        }}>
                        Likes
                      </SortableTableCell>
                      <SortableTableCell
                        width={250}
                        direction={SortDirection[sortDirection]}
                        active={sortBy === SearchMessageSortBy.messageText}
                        onClick={() => {
                          setSortBy(SearchMessageSortBy.messageText)
                          setSortDirection(
                            sortDirection === SearchMessageSortDirection.asc
                              ? SearchMessageSortDirection.desc
                              : SearchMessageSortDirection.asc
                          )
                        }}>
                        Message
                      </SortableTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listSearchMessages.searchMessages.map(searchMessage =>
                      searchMessage.__typename === 'FeedItemV2' ? (
                        <TableRow key={searchMessage.id}>
                          <TableCell>
                            <TruncatedId value={searchMessage.id} full path={`/feed-items/${searchMessage.id}`} />
                          </TableCell>
                          <TableCell>{searchMessage.moderated ? <Check /> : null}</TableCell>
                          <TableCell>
                            <FormattedDateTime value={searchMessage.created} />
                          </TableCell>
                          <TableCell>{searchMessage?.subscriptionId ? 'Subscription' : 'Person'}</TableCell>
                          <TableCell>
                            <MuiLink
                              component={Link}
                              to={
                                searchMessage?.subscriptionId
                                  ? `/subscription/${searchMessage?.subscriptionId}`
                                  : `/person/${searchMessage?.personId}`
                              }>
                              {searchMessage?.attribution}
                            </MuiLink>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                              {(searchMessage?.message?.tags || []).map((tag, index) => (
                                <Fragment key={`${searchMessage.id}-${tag}`}>
                                  {index ? <Box width={7} /> : null}
                                  <Chip label={tag} />
                                </Fragment>
                              ))}
                            </div>
                          </TableCell>
                          <TableCell>{searchMessage?.likeCount.toLocaleString()}</TableCell>
                          <TableCell>
                            <Box width={250} onClick={() => setMessageId(searchMessage.message.id)}>
                              <BoardPreview characters={searchMessage.message.characters as IBoard} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </>
        )}
      </Box>
    </>
  )
}
