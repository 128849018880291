import { useEffect, useState } from 'react'
import { REPORTS_API } from '../../../constants'
import { useAuth0 } from '../../../react-auth0-spa'

export interface IData {
  pairedBoards: number
  messagesInPastDay: number
  onlineBoards: number
  enabledChannels: number
  messagesInPastSevenDays: number
  date: string
  messagesAllTime: number
  plusRenewalType: {
    [key: string]: number
  }
  firmwareVersion: {
    [key: string]: number
  }
  plusBoards: number
  messagesInPastThirtyDays: number
  channels: number
  activeBoards: number
  records?: {
    [key: string]: IData
  }
  messagesByMinute?: {
    count: number
    time: string
  }[]
}

export const useGetBoardsReport = (rangeType: string, searchKey?: string | null) => {
  const { getTokenSilently } = useAuth0()
  const [data, setData] = useState<IData | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      setError(null)
      setData(null)
      setLoading(true)
      try {
        const response = await fetch(
          searchKey ? `${REPORTS_API}/board/${rangeType}/${searchKey}` : `${REPORTS_API}/board/current`,
          {
            headers: { 'X-Vestaboard-Token': await getTokenSilently() }
          }
        )
        const json = await response.json()
        setData({
          activeBoards: 0,
          ...json
        })
      } catch (e) {
        setData(null)
        setError('No data found')
      }
      setLoading(false)
    }
    fetchData()
  }, [getTokenSilently, searchKey, rangeType])

  return { data, error, loading }
}
