import React, { useCallback } from 'react'
import { createBoard, FullModal, BoardEditor, useToasts, IBoard } from '@vestaboard/installables'
import { useState } from 'react'
import { useSendMessage } from '../../message-v1/hooks/useSendMessage'

interface ISavedMessage {
  id: string
  created: string
  formatted: IBoard
}

interface ICreateMessage {
  onClose: () => void
  onSaved: (message: ISavedMessage) => void
}

export const CreateMessage = (props: ICreateMessage) => {
  const { addToast } = useToasts()
  const [value, setValue] = useState(createBoard())
  const [createMessageMutation] = useSendMessage()
  const { onSaved } = props

  const createMessage = useCallback(async () => {
    const result = await createMessageMutation({
      variables: {
        input: {
          characters: value
        }
      }
    })

    if (result.errors?.length) {
      addToast('There was an error creating this message', {
        appearance: 'error',
        autoDismiss: true
      })

      return
    }

    addToast('Message created', {
      appearance: 'success',
      autoDismiss: true
    })

    onSaved({
      id: result.data?.createMessage2.message?.id || '',
      formatted: result.data?.createMessage2.message?.formatted as IBoard,
      created: result.data?.createMessage2.message?.created || ''
    })
  }, [value, createMessageMutation, addToast, onSaved])

  return (
    <FullModal onClose={props.onClose}>
      <BoardEditor boardValue={value} setBoardValue={setValue} sendButtonLabel='Create' onSend={createMessage} />
    </FullModal>
  )
}
