import { CircularProgress, MenuItem, Select } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useCallback, useState } from 'react'
import { useUpdateBoard } from '../../../api'
import { useListBoardStyles } from '../hooks/useListBoardStyles'

interface IBoardStyle {
  boardId: string
  id: string
  disabled: boolean
}

export const BoardStyle = (props: IBoardStyle) => {
  const [id, setId] = useState(props.id)
  const { loading, data, error } = useListBoardStyles()
  const [updateBoardMutation] = useUpdateBoard()
  const { boardId } = props

  const updateDevice = useCallback(
    async (boardStyle: string) => {
      setId(boardStyle)

      try {
        await updateBoardMutation({
          variables: {
            input: {
              boardId,
              boardStyle
            }
          }
        })
      } catch (e) {}
    },
    [updateBoardMutation, boardId]
  )

  return error ? (
    <Alert severity='error'>Unable to load device models</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Select
      disabled={props.disabled}
      variant='outlined'
      value={id}
      onChange={e => {
        updateDevice(e.target.value as string)
      }}>
      <MenuItem></MenuItem>
      {data.boardStyles.map(boardStyle => (
        <MenuItem key={boardStyle.id} value={boardStyle.value}>
          {boardStyle.title}
        </MenuItem>
      ))}
    </Select>
  )
}
