import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { SearchSSID, SearchSSIDVariables } from '../../board/hooks/__generated__/SearchSSID'

const QUERY = gql`
  query SearchSSID($input: AdminConfigurationNetworkQueryInput!) {
    adminConfigurationNetwork(input: $input) {
      id
      ssid
      device {
        id
        created
        pairingCode
        firmwareVersion
        latestHeartbeat {
          id
          created
        }
      }
    }
  }
`

export const useSearchSSID = (ssid: string) => {
  return useQuery<SearchSSID, SearchSSIDVariables>(QUERY, {
    variables: {
      input: {
        ssid
      }
    }
  })
}
