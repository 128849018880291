import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGetMessageSet } from '../hooks/useGetMesssageSet'
import { Confirm, FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { CircularProgress, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MessageSetForm } from '../components/MessageSetForm'
import { useUpdateMessageSet } from '../hooks/useUpdateMessageSet'
import { useDeleteMessageSet } from '../hooks/useDeleteMessageSet'

export const UpdateMessageSet = () => {
  const params = useParams<{ id: string }>()
  const { data, loading, error } = useGetMessageSet(params.id)
  const [updateMessageSet, { loading: saving }] = useUpdateMessageSet()
  const [deleteMessageSet, { loading: deleting }] = useDeleteMessageSet()
  const history = useHistory()
  const { addToast } = useToasts()
  const [confirmDelete, setConfirmDelete] = useState(false)

  return (
    <>
      {saving || deleting ? <LoadMask /> : null}
      <Confirm
        title='Delete Message Set? This cannot be undone!'
        handleClose={() => {
          setConfirmDelete(false)
        }}
        open={confirmDelete}
        handleAccept={async () => {
          const response = await deleteMessageSet({
            variables: {
              input: {
                id: params.id
              }
            }
          })

          if (response.data?.deleteMessageSet?.__typename === 'MessageSetV2') {
            history.push(`/message-sets`)
          } else {
            addToast(response?.data?.deleteMessageSet?.error || 'There was an error updating your message set', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}></Confirm>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Update Message Set</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading this message set</Alert>
      ) : data?.messageSet?.__typename !== 'MessageSetV2' ? (
        <Alert severity='error'>{data?.messageSet.error}</Alert>
      ) : (
        <MessageSetForm
          onDelete={() => {
            setConfirmDelete(true)
          }}
          messageSet={data.messageSet}
          onSave={async payload => {
            const result = await updateMessageSet({
              variables: {
                input: {
                  id: params.id,
                  ...payload
                }
              }
            })

            if (result.data?.updateMessageSet.__typename === 'MessageSetV2') {
              history.push(`/message-sets`)
            } else {
              addToast(result?.data?.updateMessageSet?.error || 'There was an error updating your message set', {
                appearance: 'error',
                autoDismiss: true
              })
            }
          }}
        />
      )}
    </>
  )
}
