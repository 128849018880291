import './index.css'

import * as serviceWorker from './serviceWorker'

import App, { Providers } from './App'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY || '',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment: process.env.REACT_APP_ENVIRONMENT || 'production'
})

const AppWithLaunchDarkly = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY || ''
})(App)

ReactDOM.render(
  <Providers>
    <AppWithLaunchDarkly />
  </Providers>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
