import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UpdateDeviceMutation, UpdateDeviceMutationVariables } from './__generated__/UpdateDeviceMutation'

const UPDATE_DEVICE_MUTATION = gql`
  mutation UpdateDeviceMutation($input: UpdateDeviceInput!) {
    adminUpdateDevice(input: $input) {
      id
      characterSet {
        id
      }
      model {
        id
      }
    }
  }
`

export const useUpdateDevice = () => {
  return useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UPDATE_DEVICE_MUTATION)
}
