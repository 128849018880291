import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeveloperDetailQuery, DeveloperDetailQueryVariables } from './__generated__/DeveloperDetailQuery'

const QUERY = gql`
  query DeveloperDetailQuery($id: String!) {
    developer(id: $id) {
      id
      created
      title
      installables {
        id
        title
      }
    }
  }
`

export const useGetDeveloper = (variables: DeveloperDetailQueryVariables) =>
  useQuery<DeveloperDetailQuery, DeveloperDetailQueryVariables>(QUERY, {
    variables
  })
