import { Tab, Tabs } from '@material-ui/core'

import React from 'react'
import { useHistory } from 'react-router'

interface IListTab {
  activeTab: string
}

export const ListTab = (props: IListTab) => {
  const history = useHistory()
  return (
    <Tabs value={props.activeTab}>
      <Tab
        value='scheduled'
        label='Scheduled Notifications'
        onClick={() => {
          history.push('/notifications')
        }}
      />
      <Tab
        value='tags'
        label='Tags'
        onClick={() => {
          history.push('/notifications/tags')
        }}
      />
      <Tab
        value='segments'
        label='Segments'
        onClick={() => {
          history.push('/notifications/segments')
        }}
      />
      <Tab
        value='subscribers'
        label='Subscribers'
        onClick={() => {
          history.push('/notifications/subscribers')
        }}
      />
      <Tab
        value='templates'
        label='System Message Templates'
        onClick={() => {
          history.push('/notifications/templates')
        }}
      />
    </Tabs>
  )
}
