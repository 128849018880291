import { useMutation } from '@apollo/react-hooks'
import { UpdateColorTemplate, UpdateColorTemplateVariables } from './__generated__/UpdateColorTemplate'
import { gql } from 'apollo-boost'

const UPDATE_COLOR_TEMPLATE = gql`
  mutation UpdateColorTemplate($input: UpdateColorTemplateInput!) {
    updateColorTemplate(input: $input) {
      updated {
        id
        category {
          id
          title
        }
        created
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useUpdateColorTemplate = () => {
  return useMutation<UpdateColorTemplate, UpdateColorTemplateVariables>(UPDATE_COLOR_TEMPLATE)
}
