import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UpdateAcceptedReferral, UpdateAcceptedReferralVariables } from './__generated__/UpdateAcceptedReferral'

const MUTATION = gql`
  mutation UpdateAcceptedReferral($input: UpdateAcceptedReferralInputV2!) {
    updateAcceptedReferral: updateAcceptedReferralV2(input: $input) {
      ... on AcceptedReferralV2 {
        id
        notified
        createdAt
        purchaseId
        invalidated
        shopifyOrderId
        shopifyOrderName
        shopifyCustomerName
        shopifyCustomerEmail
        referrerPersonId
        referralCode
        referrerName
        referrerEmail
        orderDeliveredAt
        creditRedeemed
        creditType
      }
      ... on AcceptedReferralErrorV2 {
        type
        error
      }
    }
  }
`

export const useUpdateAcceptedReferral = () =>
  useMutation<UpdateAcceptedReferral, UpdateAcceptedReferralVariables>(MUTATION)
