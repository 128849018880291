import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UnInvalidateActivationCode,
  UnInvalidateActivationCodeVariables
} from './__generated__/UnInvalidateActivationCode'

const MUTATION = gql`
  mutation UnInvalidateActivationCode($id: String!) {
    unInvalidateActivationCode(input: { activationCode: $id }) {
      id
      code
      isInvalidated
      isUsed
    }
  }
`

export const useUnInvalidateActivationCode = () => {
  return useMutation<UnInvalidateActivationCode, UnInvalidateActivationCodeVariables>(MUTATION)
}
