import { gql } from 'apollo-boost'
import { ListMessageSetsV2, ListMessageSetsV2Variables } from './__generated__/ListMessageSetsV2'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListMessageSetsV2($input: ListMessageSetInputV2!) {
    listMessageSets: listMessageSetsV2(input: $input) {
      messageSets {
        id
        title
        dynamic
        messagesCount
        public
      }
      nextCursor
    }
  }
`

export const useListMessageSets = (variables: ListMessageSetsV2Variables) => {
  return useQuery<ListMessageSetsV2, ListMessageSetsV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
