import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { AddNotificationTag, AddNotificationTagVariables } from './__generated__/AddNotificationTag'

const MUTATION = gql`
  mutation AddNotificationTag($input: AddNotificationTagInputV2!) {
    addNotificationTag: addNotificationTagV2(input: $input) {
      ... on NotificationSubscriberV2 {
        id
        tags {
          id
          title
        }
      }
      ... on NotificationSubscriberErrorV2 {
        type
        error
      }
    }
  }
`

export const useAddNotificationTag = () => useMutation<AddNotificationTag, AddNotificationTagVariables>(MUTATION)
