import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListColorTemplatesV2, ListColorTemplatesV2Variables } from './__generated__/ListColorTemplatesV2'

const QUERY = gql`
  query ListColorTemplatesV2($input: ListColorTemplateInputV2!) {
    listColorTemplates: listColorTemplatesV2(input: $input) {
      colorTemplates {
        id
        title
        messagesCount
      }
    }
  }
`

export const useListColorTemplates = (variables: ListColorTemplatesV2Variables) => {
  return useQuery<ListColorTemplatesV2, ListColorTemplatesV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
