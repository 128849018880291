import React from 'react'
import { Route, Switch } from 'react-router'
import { PlusList } from './pages/ListPlus'
import { PlusView } from './pages/GetPlus'

export const PlusRouterV1 = () => (
  <Switch>
    <Route exact path='/plus/:id' component={PlusView} />
    <Route exact path='/plus' component={PlusList} />
  </Switch>
)
