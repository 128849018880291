import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListColorTemplates, ListColorTemplatesVariables } from './__generated__/ListColorTemplates'

const LIST_COLOR_TEMPLATES = gql`
  query ListColorTemplates($page: Int, $perPage: Int) {
    colorTemplatesLength
    colorTemplates(page: $page, perPage: $perPage) {
      id
      created
      disableForBlack
      disableForWhite
      category {
        id
        title
      }
      message {
        id
        formatted
      }
    }
  }
`

export const useListColorTemplates = (variables: ListColorTemplatesVariables) => {
  return useQuery<ListColorTemplates, ListColorTemplatesVariables>(LIST_COLOR_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
