import { useCallback } from 'react'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'
import { IQa } from '../types'

interface IUpdateGetQa {
  token: string
}

export const useUpdateQA = (props: IUpdateGetQa) => {
  const updateQa = useCallback(
    async (input: IQa) => {
      const result = await fetch(`${INSTALLABLES_MONOREPO_API}/api/question-answer/set/${input.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.token}`
        },
        body: JSON.stringify(input)
      }).then(res => res.json())

      return result
    },
    [props]
  )

  return updateQa
}
