import * as React from 'react'
import { Box } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { DeviceDetail } from '../device-detail'

type DevicePageProps = IDevicePageProps

interface IDevicePageProps {}

export const DevicePage: React.FC<DevicePageProps> = props => {
  const { id } = useParams<{ id: string }>()

  return (
    <Box>
      <DeviceDetail deviceId={id} />
    </Box>
  )
}
