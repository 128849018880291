import * as React from 'react'
import { RangeType } from '../../../ui/RangeType'
import { ReportLayout } from '../../../ui/ReportLayout'
import { ReportNextButton } from '../../../ui/ReportNextButton'
import { ReportPreviousButton } from '../../../ui/ReportPreviousButton'
import { getReportStateFromHash } from '../../../utils/getReportStateFromHash'
import moment from 'moment'
import { useGetBlendedDay } from '../hooks/useGetBlendedDay'
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { ReportNumberBox } from '../../../ui/ReportNumberBox'
import { Spacer } from '@vestaboard/installables'
import { ChartContainer } from '../../../ui/ChartContainer'
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useGetMessageSets } from '../hooks/useGetMessageSets'
import { AllChannels } from '../components/AllChannels'
import { CsvSimpleExport } from '../../../ui/CsvSimpleExport'

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    padding: 24
  },
  title: {
    color: '#FFF',
    fontSize: 24,
    paddingLeft: 24,
    fontWeight: 500
  },
  text: {
    padding: 24,
    paddingTop: 7
  },
  chart: {
    height: 350,
    padding: 24,
    marginBottom: 80,
    width: 'calc(100% - 48px)'
  }
})

const useTooltipStyles = makeStyles({
  root: {
    backgroundColor: '#000',
    padding: 14,
    display: 'flex'
  },
  text: {
    color: '#FFF'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 14
  }
})

export const CustomTooltip = ({ active, payload }: any) => {
  const classes = useTooltipStyles()

  if (active && payload && payload.length) {
    const value = payload?.[0]?.value || 0
    const title = payload[0]?.payload?.key

    return (
      <div className={classes.root}>
        <img src={payload?.[0]?.payload?.icon} className={classes.icon} alt={title} />
        <Typography className={classes.text}>{`${title} - ${value?.toLocaleString()}`}</Typography>
      </div>
    )
  }

  return null
}

export const DashboardBlended = () => {
  const classes = useStyles()
  const [date] = React.useState<string | null>(null)
  const [{ rangeType, searchKey }, setSearchState] = React.useState<{
    rangeType: string
    searchKey: string | null
  }>(
    getReportStateFromHash() || {
      rangeType: 'day',
      searchKey: null
    }
  )

  const setSearch = React.useCallback(({ rangeType, searchKey }) => {
    setSearchState({ rangeType, searchKey })
    window.location.hash = `#${rangeType}|${searchKey}`
  }, [])

  const { data, loading } = useGetBlendedDay(searchKey)
  const { data: messageSetData, loading: loadingMessageSets } = useGetMessageSets()

  const csvData = Object.keys(data?.messageSetCounts || {}).map(messageSetId => {
    const messageSet = messageSetData.find(messageSet => messageSet.id === messageSetId)
    return {
      key: messageSet?.title || messageSetId,
      Installed: data?.messageSetCounts[messageSetId] || 0,
      'Is Default': data?.defaultMessageSetIds.includes(messageSetId) ? 'Yes' : 'No'
    }
  })

  return (
    <ReportLayout
      title='Blended Report'
      filters={
        <>
          <RangeType setSearch={setSearch} rangeType={rangeType} types={['day']} />
          <ReportPreviousButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.subtract(1, 'day').format('YYYY-MM-DD')
                })
              }
            }}
          />
          <ReportNextButton
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.add(1, 'day').format('YYYY-MM-DD')
                })
              }
            }}
          />
        </>
      }>
      <Spacer size='large' />
      {loading || loadingMessageSets ? (
        <CircularProgress />
      ) : !data?.subscriptionsCount ? (
        <Box className={classes.root}>
          <Typography className={classes.title}>No data found</Typography>
          <Typography className={classes.text}>
            There is no data for this time range. Blended report data has not been fully backfilled and we can't predict
            the future.
            <br />
            Consider consulting the Magic 8 Board installable for all such inquiries.
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='flex-start' flexWrap='wrap' maxWidth='100%' padding='24px'>
            <ReportNumberBox title='Active Blended Subscriptions' number={data.subscriptionsCount} />
            <ReportNumberBox title='Average # of Message Sets' number={data.averageBlendsPerSubscription} />
            <ReportNumberBox title='Median # of Message Sets' number={data.medianBlendsPerSubscription} />
            <ReportNumberBox title='Highest # of Message Sets' number={data.mostBlendsPerSubscription} />
            <ReportNumberBox title='Total Message Set Blended' number={data.messageSetBlendCount} />
          </Box>
          <ChartContainer
            title='Most Uninstalled Default Message Sets'
            exportButton={<CsvSimpleExport data={csvData} title={`blended-${rangeType}-${searchKey || 'current'}`} />}>
            <ResponsiveContainer>
              <ComposedChart
                data={data.defaultMessageSetIds
                  .map(messageSetId => {
                    const messageSet = messageSetData.find(messageSet => messageSet.id === messageSetId)
                    return {
                      key: messageSet?.title || messageSetId,
                      icon: messageSet?.icon,
                      Uninstalled: data.subscriptionsCount - data.messageSetCounts[messageSetId]
                    }
                  })
                  .sort((a, b) => (a.Uninstalled > b.Uninstalled ? -1 : a.Uninstalled < b.Uninstalled ? 1 : 0))}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='key' hide />
                <YAxis />
                <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} content={<CustomTooltip />} />
                <Bar dataKey='Uninstalled' fill='rgb(112, 47, 138)' />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>
          <ChartContainer
            title='Top 20 Most Installed Non-Default Message Sets'
            exportButton={<CsvSimpleExport data={csvData} title={`blended-${rangeType}-${searchKey || 'current'}`} />}>
            <ResponsiveContainer>
              <ComposedChart
                data={Object.keys(data.messageSetCounts)
                  .filter(messageSetId => !data.defaultMessageSetIds.includes(messageSetId))
                  .map(messageSetId => {
                    const messageSet = messageSetData.find(messageSet => messageSet.id === messageSetId)
                    return {
                      key: messageSet?.title || messageSetId,
                      Installed: data.messageSetCounts[messageSetId],
                      icon: messageSet?.icon
                    }
                  })
                  .sort((a, b) => (a.Installed > b.Installed ? -1 : a.Installed < b.Installed ? 1 : 0))
                  .filter((_, index) => index < 20)}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='key' hide />
                <YAxis dataKey='Installed' />
                <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} content={<CustomTooltip />} />
                <Bar dataKey='Installed' fill='rgb(0, 132, 213)' />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>
          <Spacer size='extraLarge' />
          <Box padding='24px'>
            <Typography className={classes.title}>
              Message Set Rankings{' '}
              <CsvSimpleExport data={csvData} title={`blended-${rangeType}-${searchKey || 'current'}`} />
            </Typography>
          </Box>
          <AllChannels
            data={Object.keys(data.messageSetCounts)
              .map(key => {
                const value = data.messageSetCounts[key]
                const messageSet = messageSetData.find(messageSet => messageSet.id === key)

                return {
                  title: messageSet?.title || '',
                  value,
                  fill: data.defaultMessageSetIds.includes(key) ? 'rgb(255, 117, 0)' : 'rgb(0, 154, 68)'
                }
              })
              .sort((a, b) => b.value - a.value)}
          />
        </>
      )}
    </ReportLayout>
  )
}
