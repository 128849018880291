import React from 'react'
import { Route, Switch } from 'react-router'
import { MessageSetListContainer } from './message-set-list-container'
import { CreateMessageSet } from './create-message-set'
import { MessageSetEditContainer } from './message-set-edit-container'
import { MessageSetVersionSubmissionList } from './message-set-version-submission-list'

export const MessageSetRouterV1 = () => (
  <Switch>
    <Route exact path={`/message-sets`} component={MessageSetListContainer} />
    <Route exact path={`/message-sets/create`} component={CreateMessageSet} />
    <Route exact path={`/message-sets/submissions`} component={MessageSetVersionSubmissionList} />
    <Route exact path={`/message-sets/:id/:versionId?`} component={MessageSetEditContainer} />
  </Switch>
)
