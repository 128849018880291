import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreateNewInstallableMutation,
  CreateNewInstallableMutationVariables
} from './__generated__/CreateNewInstallableMutation'

const MUTATION = gql`
  mutation CreateNewInstallableMutation($input: PublishInstallableInput!) {
    publishInstallable(input: $input) {
      id
    }
  }
`

export const useCreateInstallable = () => {
  return useMutation<CreateNewInstallableMutation, CreateNewInstallableMutationVariables>(MUTATION)
}
