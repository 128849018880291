import * as React from 'react'
import { ReportActivations } from '../report-activations'

type ReportActivationsOfflineBoardsContainerProps = IReportActivationsOfflineBoardsContainerProps

interface IReportActivationsOfflineBoardsContainerProps {}

export const ReportActivationsOfflineBoardsContainer: React.FC<ReportActivationsOfflineBoardsContainerProps> = props => {
  return <ReportActivations deleted={null} ignored={null} revoked={null} online={false} />
}
