import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreateColorTemplateCategoryMutation,
  CreateColorTemplateCategoryMutationVariables
} from './__generated__/CreateColorTemplateCategoryMutation'

const CREATE_COLOR_TEMPLATE_CATEGORY = gql`
  mutation CreateColorTemplateCategoryMutation($input: CreateColorTemplateCategoryInput!) {
    createColorTemplateCategory(input: $input) {
      created {
        id
        title
      }
    }
  }
`

export const useCreateColorTemplateCategory = () => {
  return useMutation<CreateColorTemplateCategoryMutation, CreateColorTemplateCategoryMutationVariables>(
    CREATE_COLOR_TEMPLATE_CATEGORY
  )
}
