import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { TruncatedId } from '../../truncated-id'
import { useHistory } from 'react-router'
import { useListColorTemplates } from '../hooks/useListColorTemplates'

export const ListColorTemplates = () => {
  const history = useHistory()
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc')
  const [sortField, setSortField] = React.useState<'id' | 'title' | 'messages'>('title')
  const { loading, error, data } = useListColorTemplates({
    input: {
      cursor: null,
      limit: 100
    }
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Color Templates</Typography>
        <Button onClick={() => history.push('/color-templates/create')} variant='contained' color='primary'>
          Create New Color Template
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the color templates</Alert>
      ) : !data?.listColorTemplates.colorTemplates.length ? (
        <Alert severity='info'>There are no color templates</Alert>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: 360, cursor: 'pointer' }}
                    onClick={() => {
                      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
                      setSortField('id')
                    }}>
                    ID
                    {sortField === 'id' ? (
                      sortDirection === 'asc' ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    ) : null}
                  </TableCell>
                  <TableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
                      setSortField('title')
                    }}>
                    Title
                    {sortField === 'title' ? (
                      sortDirection === 'asc' ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    ) : null}
                  </TableCell>
                  <TableCell
                    style={{ width: 170, cursor: 'pointer' }}
                    onClick={() => {
                      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
                      setSortField('messages')
                    }}>
                    # of Messages{' '}
                    {sortField === 'messages' ? (
                      sortDirection === 'asc' ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.listColorTemplates.colorTemplates
                  .sort((a, b) => {
                    if (sortField === 'title') {
                      return sortDirection === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)
                    }
                    if (sortField === 'messages') {
                      return sortDirection === 'asc'
                        ? (a.messagesCount || 0) - (b.messagesCount || 0)
                        : (b.messagesCount || 0) - (a.messagesCount || 0)
                    }
                    return sortDirection === 'asc' ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id)
                  })
                  .map(colorTemplate => (
                    <TableRow key={colorTemplate.id}>
                      <TableCell>
                        <TruncatedId value={colorTemplate.id} full path={`/color-templates/${colorTemplate.id}`} />
                      </TableCell>
                      <TableCell>{colorTemplate.title}</TableCell>
                      <TableCell>{(colorTemplate.messagesCount || 0).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}
