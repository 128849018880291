import { IconButton, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '@vestaboard/installables'

interface ISearchInput {
  value?: string
  onSearch: (term: string) => void
}

const useStyles = makeStyles({
  input: {
    width: 200,
    backgroundColor: colors.white
  }
})

export const SearchInput = (props: ISearchInput) => {
  const [value, setValue] = useState(typeof props.value === 'string' ? props.value : '')
  const classes = useStyles()

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        props.onSearch(value)
      }}>
      <TextField
        className={classes.input}
        size='small'
        variant='outlined'
        label='Search'
        value={value}
        onChange={e => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton type='submit'>
              <Search />
            </IconButton>
          )
        }}
      />
    </form>
  )
}
