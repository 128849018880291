import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { Bar, BarChart, XAxis, YAxis, Tooltip } from 'recharts'

interface IOnlineBoards {
  pairedBoards: number
  onlineBoards: number
  activeBoards: number
  plusBoards: number
}

const useTooltipStyles = makeStyles({
  root: {
    backgroundColor: '#000',
    padding: 14
  }
})

export const CustomTooltip = ({ active, payload }: any) => {
  const classes = useTooltipStyles()

  if (active && payload && payload.length) {
    return (
      <div className={classes.root}>
        <Typography>{`${payload[0].value.toLocaleString()} Boards`}</Typography>
      </div>
    )
  }

  return null
}

export const OnlineBoards = (props: IOnlineBoards) => {
  return (
    <BarChart
      width={1240}
      height={400}
      layout='vertical'
      data={[
        {
          name: 'Paired',
          value: props.pairedBoards,
          fill: '#323232'
        },
        {
          name: 'Online',
          value: props.onlineBoards,
          fill: 'rgb(112, 47, 138)'
        },
        {
          name: 'Plus',
          value: props.plusBoards,
          fill: 'rgb(0, 132, 213)'
        },
        {
          name: '✨Active',
          value: props.activeBoards,
          fill: 'rgb(0, 154, 68, 1)'
        }
      ]}>
      <YAxis dataKey='name' type='category' />
      <XAxis type='number' />
      <Bar dataKey='value' />
      <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} content={CustomTooltip} />
    </BarChart>
  )
}
