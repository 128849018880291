import React from 'react'
import { Route, Switch } from 'react-router'
import { ListSubmittedMessageSets } from './pages/ListMessageSetSubmissions'
import { MessageSetSubmission } from './pages/MessageSetSubmission'

export const SubmittedMessageSetRouterV2 = () => (
  <Switch>
    <Route exact path={`/submitted-message-sets/:id`} component={MessageSetSubmission} />
    <Route exact path={`/submitted-message-sets`} component={ListSubmittedMessageSets} />
  </Switch>
)
