import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useCreateDeveloper, useListDevelopers } from '../hooks'

interface ISelectDeveloperProps {
  value: string | null
  setValue: (value: string | null) => void
}

interface IDeveloper {
  id: string | null
  title: string
}

const filter = createFilterOptions<IDeveloper>()

export const SelectDeveloper = (props: ISelectDeveloperProps) => {
  const [text, setText] = useState('')
  const { data, loading, refetch } = useListDevelopers()
  const [create] = useCreateDeveloper()

  useEffect(() => {
    if (data?.developers.length) {
      const newText = data.developers.find(developer => developer.id === props.value)?.title || ''
      setText(newText)
    }
  }, [props.value, data])

  return loading ? (
    <CircularProgress />
  ) : (
    <Autocomplete
      options={
        data?.developers
          .map(
            developer =>
              ({
                id: developer.id,
                title: developer.title
              } as IDeveloper)
          )
          .sort((a, b) => a.title.localeCompare(b.title)) || []
      }
      getOptionLabel={option => option.title}
      fullWidth
      onChange={async (_e, value) => {
        if (typeof value === 'string') {
          return
        }

        if (value?.id === null) {
          const createResult = await create({ variables: { title: text } })
          await refetch()
          props.setValue(createResult.data?.createDeveloper.id || null)
          return
        }

        props.setValue(value?.id || null)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            id: null,
            title: `Add "${params.inputValue}"`
          })
        }

        return filtered
      }}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          fullWidth
          value={text}
          placeholder={text}
          onChange={e => setText(e.target.value as string)}
        />
      )}
    />
  )
}
