import { ListDigitalDevices, ListDigitalDevicesVariables } from './__generated__/ListDigitalDevices'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListDigitalDevices($cursor: String, $limit: Int, $search: String) {
    listDigitalDevices: listDigitalDevicesV2(input: { all: true, cursor: $cursor, limit: $limit, search: $search }) {
      nextCursor
      digitalDevices {
        id
        digitalDeviceStyle {
          id
          title
        }
        board {
          id
          createdAt
          title
        }
      }
    }
  }
`

export const useListDigitalDevices = (variables: ListDigitalDevicesVariables) =>
  useQuery<ListDigitalDevices, ListDigitalDevicesVariables>(QUERY, { variables })
