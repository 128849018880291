import React from 'react'
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import moment from 'moment'
import { KeyboardArrowDown } from '@material-ui/icons'

interface IWeekPicker {
  year: string
  value: string
  setValue: (value: string) => void
}

const useStyles = makeStyles({
  button: {
    color: '#FFF'
  },
  menu: {
    marginTop: 60
  }
})

export const WeekPicker = (props: IWeekPicker) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const weeks = Array(52)
    .fill('')
    .map((_, index) =>
      moment(`${props.year}-01-01`)
        .week(index + 1)
        .add(1, 'days')
        .format('MM/DD')
    )

  return (
    <>
      <Button
        variant='text'
        className={classes.button}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        endIcon={<KeyboardArrowDown />}>
        {weeks[+props.value - 1] || 'Week'}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.menu}>
        {weeks.map((week, weekIndex) => (
          <MenuItem
            key={week}
            onClick={() => {
              props.setValue(`${weekIndex + 1}`)
              handleClose()
            }}>
            {week}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
