import React from 'react'
import { gql } from 'apollo-boost'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GetPrincipalQuery, GetPrincipalQueryVariables } from './__generated__/GetPrincipalQuery'
import { Card, CircularProgress, Table, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../truncated-id'
import { FormattedDateTime } from '../formatted-date-time'

const QUERY = gql`
  query GetPrincipalQuery($id: String!) {
    principal(id: $id) {
      id
      created
    }
  }
`

const useStyles = makeStyles({
  columnTitle: {
    width: '200px',
    fontWeight: 'bold',
    background: '#eee'
  }
})

export const Principal = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<GetPrincipalQuery, GetPrincipalQueryVariables>(QUERY, { variables: { id } })
  const classes = useStyles()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Principal not found.</Alert>
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Principal</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Table>
          <TableRow>
            <TableCell className={classes.columnTitle}>ID</TableCell>
            <TableCell>
              <TruncatedId value={data?.principal.id || ''} full />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Created</TableCell>
            <TableCell>
              <FormattedDateTime value={data?.principal.created ? +data?.principal.created : undefined} />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
    </>
  )
}
