import React, { useCallback, useState } from 'react'
import { Box, Card, CircularProgress, Typography } from '@material-ui/core'
import {
  BoardEditor,
  createBoard,
  IBoard,
  Spacer,
  Theme,
  CheckBox,
  Button,
  FlexHorizontal,
  Input
} from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router'
import moment from 'moment'
import { useListPickCategories } from '../hooks/useListPickCategories'

export interface IPickData {
  categoryIds: string[]
  recommendAfter: number
  recommendBefore: number
  characters: IBoard
}

interface IPickForm {
  onSave: (pick: IPickData) => void
  onDelete?: () => void
  pick?: IPickData
}

const useStyles = makeStyles({
  cardInner: {
    backgroundColor: '#181919',
    padding: 50
  }
})

const getTomorrow = () => {
  const today = new Date()
  today.setDate(today.getDate() + 1)
  return today
}

const getToday = () => new Date()

const oneDay = 1000 * 60 * 60 * 24

export const PickForm = (props: IPickForm) => {
  const [characters, setCharacters] = useState(props.pick?.characters || createBoard())
  const [categories, setCategories] = useState<string[]>(props.pick?.categoryIds || [])
  const [recommendAfter, setRecommendAfter] = useState(props.pick?.recommendAfter || getToday().getTime())
  const [recommendBefore, setRecommendBefore] = useState(props.pick?.recommendBefore || getTomorrow().getTime())
  const { data, loading, error } = useListPickCategories()
  const classes = useStyles()
  const history = useHistory()

  const endIsAfterStart = recommendAfter < recommendBefore

  const { onSave } = props

  const savePick = useCallback(() => {
    if (recommendAfter < recommendBefore) {
      onSave({
        characters,
        categoryIds: categories,
        recommendAfter,
        recommendBefore
      })
    }
  }, [characters, categories, recommendAfter, recommendBefore, onSave])

  return error ? (
    <Alert severity='error'>Unable to load the pick categories</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Theme>
      <Card>
        <Box className={classes.cardInner}>
          <BoardEditor boardValue={characters} setBoardValue={setCharacters} />
          <Spacer size='extraLarge' />
          <Spacer size='large' />
          <FlexHorizontal>
            <Box>
              <Input
                label='Display On'
                type='datetime-local'
                value={moment(recommendAfter).format('YYYY-MM-DDThh:mm')}
                onValueChange={newDate => {
                  setRecommendAfter(moment(newDate).toDate().getTime())
                  setRecommendBefore(moment(newDate).toDate().getTime() + oneDay)
                }}
              />
            </Box>
            <Box width={24} />
            <Box>
              <Input
                error={!endIsAfterStart ? 'Must be after display on' : undefined}
                label='End Display On'
                type='datetime-local'
                value={moment(recommendBefore).format('YYYY-MM-DDThh:mm')}
                onValueChange={newDate => {
                  setRecommendBefore(moment(newDate).toDate().getTime())
                }}
              />
            </Box>
          </FlexHorizontal>
          <Spacer size='extraLarge' />
          <Typography variant='h5' color='secondary'>
            Categories
          </Typography>
          <Spacer size='medium' />
          {data.picksCategories.map(pickCategory => (
            <CheckBox
              key={pickCategory.id}
              label={pickCategory.title}
              checked={categories.includes(pickCategory.id)}
              onValueChange={() => {
                setCategories(categories =>
                  categories.includes(pickCategory.id)
                    ? categories.filter(category => category !== pickCategory.id)
                    : [...categories, pickCategory.id]
                )
              }}
            />
          ))}
          <Spacer size='extraLarge' />
          <FlexHorizontal>
            <Button
              width={120}
              buttonType='outline'
              onClick={() => {
                history.push('/picks')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button width={120} buttonType='white' onClick={savePick}>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button width={120} buttonType='danger' onClick={props.onDelete}>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
    </Theme>
  )
}
