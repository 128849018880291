import { gql } from 'apollo-boost'
import {
  GetColorTemplateCategoryQuery,
  GetColorTemplateCategoryQueryVariables
} from './__generated__/GetColorTemplateCategoryQuery'
import { useQuery } from '@apollo/react-hooks'

const GET_COLOR_TEMPLATE_CATEGORY = gql`
  query GetColorTemplateCategoryQuery($id: String!) {
    colorTemplateCategory(id: $id) {
      id
      title
    }
  }
`

export const useGetColorTemplateCategory = (id: string) => {
  return useQuery<GetColorTemplateCategoryQuery, GetColorTemplateCategoryQueryVariables>(GET_COLOR_TEMPLATE_CATEGORY, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  })
}
