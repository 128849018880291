import { MenuItem, Select } from '@material-ui/core'

import React from 'react'
import { useListPlusFeatureTypes } from '../hooks/useListPlusFeatureTypes'

interface IPlusTypeSelector {
  value: string
  onChange: (value: string) => void
}

export const PlusTypeSelector = (props: IPlusTypeSelector) => {
  const { data } = useListPlusFeatureTypes()

  return (
    <Select fullWidth variant='outlined' value={props.value} onChange={e => props.onChange(e.target.value as string)}>
      <MenuItem>Feature Type</MenuItem>
      {(data?.listMarketplaceFeatureTypes?.marketplaceFeatureTypes || []).map(template => (
        <MenuItem key={template.id} value={template.id}>
          {template.title}
        </MenuItem>
      ))}
    </Select>
  )
}
