import React from 'react'
import { CsvImportButton } from '../../../ui/CsvImportButton'

interface IResponse {
  question: string
  answer: string
}

interface ICsvImport {
  handleImport: (records: IResponse[]) => void
}

export const CsvImport = (props: ICsvImport) => {
  return (
    <CsvImportButton
      noHeader
      mappings={['question', 'answer']}
      handleImport={async records => {
        props.handleImport((records as any) as IResponse[])
      }}
    />
  )
}
