import { gql } from 'apollo-boost'
import { ListPicksCategoriesV2, ListPicksCategoriesV2Variables } from './__generated__/ListPicksCategoriesV2'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListPicksCategoriesV2($input: ListPickCategoriesInputV2!) {
    listPickCategories: listPickCategoriesV2(input: $input) {
      pickCategories {
        id
        title
      }
      nextCursor
    }
  }
`

export const useListPickCategories = (variables: ListPicksCategoriesV2Variables) => {
  return useQuery<ListPicksCategoriesV2, ListPicksCategoriesV2Variables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables
  })
}
