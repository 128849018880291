import { Align, Justify, vbml } from '@vestaboard/vbml'
import { IHeader } from '../types'

export const formatQAMessage = (message: string | number[][], header?: IHeader) => {
  if (!header?.isEnabled) {
    return vbml.parse({
      components: [
        {
          style: {
            height: 6,
            width: 22,
            align: header?.align || Align.center,
            justify: header?.justify || Justify.justified
          },
          template:
            typeof message === 'string'
              ? `${message}`
              : vbml.characterCodesToString([...message], {
                  allowLineBreaks: true
                })
        }
      ]
    })
  }

  return vbml.parse({
    style: {
      width: 22,
      height: 6
    },
    components: [
      {
        style: {
          width: 2,
          height: 1
        },
        template: `{${header?.color || '0'}}{${header?.color || '0'}}`
      },
      {
        style: {
          justify: Justify.center,
          align: Align.center,
          width: 18,
          height: 1
        },
        template: header?.text || ''
      },
      {
        style: {
          width: 2,
          height: 1
        },
        template: `{${header?.color || '0'}}{${header?.color || '0'}}`
      },
      {
        style: {
          height: 5,
          width: 22,
          align: header?.align || Align.justified,
          justify: header?.justify || Justify.justified
        },
        template:
          typeof message === 'string'
            ? `${message}`
            : vbml.characterCodesToString([...message], {
                allowLineBreaks: true
              })
      }
    ]
  })
}
