import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateInstallableMutation,
  UpdateInstallableMutationVariables
} from './__generated__/UpdateInstallableMutation'

const MUTATION = gql`
  mutation UpdateInstallableMutation($input: UpdateInstallableInput!) {
    updateInstallable(input: $input) {
      id
    }
  }
`

export const useUpdateInstallable = () => {
  return useMutation<UpdateInstallableMutation, UpdateInstallableMutationVariables>(MUTATION)
}
