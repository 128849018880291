import * as React from 'react'
import { TableHead, TableRow, TableCell, TableBody, Table, Card } from '@material-ui/core'
import { TruncatedId } from '../truncated-id'
import { FormattedDateTime } from '../formatted-date-time'

interface IHardwareListPageProps {}

const hardwares = [
  {
    id: '459e5cb7-29c7-4168-90f2-c6158730df2d',
    created: '1577040838840',
    device: {
      id: '459e5cb7-29c7-4168-90f2-c6158730df2d'
    }
  },
  {
    id: '459e5cb7-29c7-4168-90f2-c6158730df21',
    created: '1577040838839',
    device: {
      id: '459e5cb7-29c7-4168-90f2-c6158730df21'
    }
  }
]

export const HardwareListPage: React.FC<IHardwareListPageProps> = props => {
  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Hardware ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Device Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hardwares.map(hardware => (
            <TableRow key={hardware.id}>
              <TableCell>
                <TruncatedId value={hardware.id} path={`/hardware/${hardware.id}`}></TruncatedId>
              </TableCell>
              <TableCell>
                <FormattedDateTime value={parseInt(hardware.created)} />
              </TableCell>
              <TableCell>{hardware.device.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}
