import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { InstallableSubscriptions, InstallableSubscriptionsVariables } from './__generated__/InstallableSubscriptions'

const QUERY = gql`
  query InstallableSubscriptions($id: String!, $page: Int, $perPage: Int) {
    installable(id: $id) {
      id
      title
      installations(page: $page, perPage: $perPage) {
        id
        created
        subscriptions {
          id
          title
          muted
          configurationUrl
          subscriptionSet {
            id
            boards {
              id
              title
            }
          }
        }
      }
    }
  }
`

interface IUseInstallableSubscriptions {
  page?: number
  perPage?: number
}

export const useInstallableSubscriptions = (id: string, config?: IUseInstallableSubscriptions) => {
  return useQuery<InstallableSubscriptions, InstallableSubscriptionsVariables>(QUERY, {
    variables: {
      id,
      page: config?.page,
      perPage: config?.perPage
    }
  })
}
