import { CircularProgress, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, IBoard, Spacer, LoadMask, Confirm, useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'
import { IColorTemplateData, ColorTemplateForm } from '../components/ColorTemplateForm'
import { useHistory, useParams } from 'react-router-dom'
import { useUpdateColorTemplate } from '../hooks/useUpdateColorTemplate'
import { useDeleteColorTemplate } from '../hooks/useDeleteColorTempalte'
import { useGetColorTemplate } from '../hooks/useGetColorTemplate'
import { useMigratedColorTemplates } from '../../../api/useMigrateColorTemplates'

interface RouteParams {
  id: string
}

export const ColorTemplateUpdate = () => {
  const { addToast } = useToasts()
  const history = useHistory()
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const params = useParams<RouteParams>()
  const [deleteColorTemplateMutation, { loading: deleting }] = useDeleteColorTemplate()
  const [updateColorTemplateMutation, { loading: saving }] = useUpdateColorTemplate()
  const { data, loading, error } = useGetColorTemplate(params.id)
  const [migrate] = useMigratedColorTemplates()

  const colorTemplate = data?.colorTemplate

  const updateColorTemplate = async (colorTemplate: IColorTemplateData) => {
    const { errors } = await updateColorTemplateMutation({
      variables: {
        input: {
          id: params.id,
          ...colorTemplate
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error updating your color template', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your color template has been updated', {
      appearance: 'success',
      autoDismiss: true
    })

    await migrate()
  }

  const deleteColorTemplate = async () => {
    setConfirmingDelete(false)

    const { errors } = await deleteColorTemplateMutation({
      variables: {
        input: {
          id: params.id
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error deleting your color template', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your color template has been deleted', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push('/color-templates')

    await migrate()
  }

  return (
    <>
      <Confirm
        title='Delete ColorTemplate?'
        message='Are you sure you want to delete this color template? Chose wisely!'
        open={confirmingDelete}
        handleClose={() => setConfirmingDelete(false)}
        handleAccept={deleteColorTemplate}
      />
      {saving || deleting ? <LoadMask /> : null}
      {error ? (
        <Alert severity='error'>Color Template not found.</Alert>
      ) : loading || !data ? (
        <CircularProgress />
      ) : (
        <>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>Update ColorTemplate</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <ColorTemplateForm
            onSave={updateColorTemplate}
            onDelete={() => {
              setConfirmingDelete(true)
            }}
            colorTemplate={{
              characters: colorTemplate?.message.formatted as IBoard,
              categoryId: colorTemplate?.category?.id || ''
            }}
          />
        </>
      )}
    </>
  )
}
