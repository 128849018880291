import React, { useCallback, useState } from 'react'
import { Box, Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { useDrawerContext } from '../App'
import { useHistory } from 'react-router'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { KeyboardArrowDown } from '@material-ui/icons'

interface IDashboardSwitcherProps {
  title: string
}

const useStyles = makeStyles({
  container: {
    marginLeft: 0
  },
  containerOpen: {
    marginLeft: 240
  },
  button: {
    color: '#FFF'
  },
  menu: {
    marginTop: 60
  }
})

export const DashboardSwitcher = (props: IDashboardSwitcherProps) => {
  const { drawerOpen } = useDrawerContext()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const history = useHistory()

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const dashboards = [
    {
      title: 'Boards Report',
      path: '/dashboard/boards'
    },
    {
      title: 'Messages Report',
      path: '/dashboard/messages'
    },
    {
      title: 'Installables Report',
      path: '/dashboard/installables'
    },
    {
      title: 'Blended Report',
      path: '/dashboard/blended'
    }
  ]

  return (
    <Box className={classes[drawerOpen ? 'containerOpen' : 'container']}>
      <Button
        className={classes.button}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        startIcon={<AssessmentIcon />}
        endIcon={<KeyboardArrowDown />}>
        {props.title}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.menu}>
        {dashboards.map(dashboard => (
          <MenuItem
            key={dashboard.title}
            onClick={() => {
              history.push(dashboard.path)
              handleClose()
            }}>
            {dashboard.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
