import { TableRow } from '@material-ui/core'
import React from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

interface IDraggableTableRow {
  children: React.ReactNode
}

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
})

export const DraggableTableRow = (id: string, index: number) => (props: IDraggableTableRow) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...props}>
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}
