import { ListPlusCategories } from './__generated__/ListPlusCategories'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListPlusCategories {
    listMarketplaceCategories: listMarketplaceCategoriesV2 {
      marketplaceCategories {
        id
        title
      }
    }
  }
`

export const useListPlusCategories = () => useQuery<ListPlusCategories>(QUERY)
