import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreatePickV2, CreatePickV2Variables } from './__generated__/CreatePickV2'

const MUTATION = gql`
  mutation CreatePickV2($input: CreatePickInputV2!) {
    createPick: createPickV2(input: $input) {
      __typename
      ... on PickV2 {
        id
        date
        created
        categories {
          id
          title
        }
        sortOrder
        attribution
        message {
          id
          text
          characters
        }
      }
      ... on PickErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreatePick = () => {
  return useMutation<CreatePickV2, CreatePickV2Variables>(MUTATION)
}
