import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  GetNotificationSubscriber,
  GetNotificationSubscriberVariables
} from './__generated__/GetNotificationSubscriber'

const QUERY = gql`
  query GetNotificationSubscriber($id: String!, $cursor: String) {
    subscriber: personNotificationSettingsV2(id: $id) {
      ... on NotificationSubscriberV2 {
        id
        title
        tags {
          id
          title
        }
        unreadCount
        listNotifications(input: { cursor: $cursor }) {
          nextCursor
          notifications {
            id
            title
            isRead
            createdAt
            scheduledId
            tag {
              id
              title
            }
          }
        }
      }
      ... on NotificationSubscriberErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetNotificationSubscriber = (id: string, cursor?: string) =>
  useQuery<GetNotificationSubscriber, GetNotificationSubscriberVariables>(QUERY, {
    variables: {
      id,
      cursor
    },
    fetchPolicy: 'network-only'
  })
