export const formatReportRangeType = (rangeType: string) => {
  if (rangeType === 'year_weekly') {
    return 'weekly'
  }

  if (rangeType === 'all_time') {
    return 'yearly'
  }

  if (rangeType === 'year') {
    return 'monthly'
  }

  return rangeType
}
