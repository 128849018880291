import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Line, ComposedChart } from 'recharts'

interface IFirmwareOverTime {
  data: {
    'Yocto 1': number
    'Yocto 3': number
    PVT: number
    Unknown: number
    key: string
  }[]
}

export const FirmwareOverTime = (props: IFirmwareOverTime) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key' />
        <YAxis domain={['dataMin', 'dataMax']} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Line type='monotone' dataKey='Unknown' stroke='rgb(112, 47, 138)' />
        <Line type='monotone' dataKey='PVT' stroke='rgb(218, 41, 28)' />
        <Line type='monotone' dataKey='Yocto 1' stroke='rgb(255, 117, 0)' />
        <Line type='monotone' dataKey='Yocto 3' stroke='rgb(255, 184, 28)' />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
