import { BoardPreview, FlexHorizontal, IBoard, Spacer, useToasts } from '@vestaboard/installables'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { CreateMessageModal, MessageTags } from '../../message-v2'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import { CreatePickInputV2 } from '../../../../__generated__/globalTypes'
import { GetPickV2_pick_PickV2 } from '../hooks/__generated__/GetPickV2'
import { RemoveCircle } from '@material-ui/icons'
import { useHistory } from 'react-router'
import { useListPickCategories } from '../hooks/useListPickCategories'

const useStyles = makeStyles({
  cardInner: {
    padding: 24
  }
})

interface IPickForm {
  pick?: GetPickV2_pick_PickV2
  onSave: (input: CreatePickInputV2) => void
  onDelete?: () => void
}

interface IMessage {
  id: string
  text: string | null
  characters: Array<number[]> | null
  tags: string[] | null
}

export const PickForm = (props: IPickForm) => {
  const classes = useStyles()
  const [message, setMessage] = useState<IMessage | null>(props?.pick?.message || null)
  const [date, setDate] = useState<string | null>(props?.pick?.date || new Date().toISOString().split('T')[0])
  const [order, setOrder] = useState<number>(props?.pick?.sortOrder || 0)
  const [categoryIds, setCategoryIds] = useState<string[]>(props?.pick?.categories?.map(category => category.id) || [])
  const [isAddingMessage, setIsAddingMessage] = useState(false)
  const { data: categoriesData } = useListPickCategories({
    input: {}
  })
  const { addToast } = useToasts()
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)

  const handleSave = () => {
    if (!message) {
      setError('message')
      addToast('Message is required', {
        appearance: 'error'
      })
      return
    }

    if (!date) {
      setError('date')
      addToast('Date is required', {
        appearance: 'error'
      })
      return
    }

    if (!categoryIds.length) {
      setError('category')
      addToast('At least one category must be selected', {
        appearance: 'error'
      })
      return
    }

    props.onSave({
      messageId: message.id,
      date,
      order,
      categoryIds
    })
  }

  return (
    <>
      {isAddingMessage ? (
        <CreateMessageModal
          onClose={() => {
            setIsAddingMessage(false)
          }}
          onSaved={message => {
            setMessage(message)
          }}
        />
      ) : null}
      <Card>
        <Box className={classes.cardInner}>
          <TextField
            value={date}
            onChange={e => {
              setDate(e.target.value)
            }}
            variant='outlined'
            label='Date'
            fullWidth
            type='date'
            error={error === 'date' && !date}
          />
          <Spacer size='large' />
          <TextField
            value={order}
            onChange={e => {
              const number = +e.target.value
              setOrder(number > 0 ? number : 0)
            }}
            variant='outlined'
            label='Sort Order'
            fullWidth
            type='number'
          />
          <Spacer size='large' />
          <Typography>Categories</Typography>
          <Select
            multiple
            multiline
            fullWidth
            value={categoryIds}
            variant='outlined'
            error={error === 'category' && !categoryIds.length}
            onChange={e => setCategoryIds(e.target.value as string[])}>
            {categoriesData?.listPickCategories.pickCategories.map(d => (
              <MenuItem value={d.id}>{d.title}</MenuItem>
            ))}
          </Select>
          <Spacer size='large' />
          <FlexHorizontal>
            <Button
              onClick={() => {
                history.push('/picks')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button onClick={handleSave} variant='contained' color='primary' size='large'>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button onClick={props.onDelete} variant='contained' color='secondary' size='large'>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween={true}>
        <Typography variant='h5'>Message</Typography>
        <Button variant='contained' onClick={() => setIsAddingMessage(true)}>
          Set Message
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      {!message ? (
        <Alert severity={error === 'message' ? 'error' : 'info'}>A message is required</Alert>
      ) : (
        <Card>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow key={message.id}>
                  <TableCell>
                    {message.text ? (
                      <Card raised>
                        <CardContent>
                          <Typography variant='body2'>{message.text}</Typography>
                        </CardContent>
                      </Card>
                    ) : null}
                  </TableCell>
                  <TableCell style={{ width: 250 }}>
                    <Box width={250}>
                      <BoardPreview characters={message.characters as IBoard} />
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: 30 }}>
                    <ButtonBase
                      onClick={() => {
                        setMessage(null)
                      }}>
                      <RemoveCircle color='action' />
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
      <Spacer size='large' />
      {message ? <MessageTags tags={message.tags || []} messageId={message.id} /> : null}
      <Spacer size='extraLarge' />
    </>
  )
}
