import * as React from 'react'
import { gql } from 'apollo-boost'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core'
import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'
import { useMutation } from '@apollo/react-hooks'
import { useAuth0 } from '../../react-auth0-spa'
import { useHistory, useLocation } from 'react-router'
import { CloudDownloadOutlined, RefreshOutlined } from '@material-ui/icons'
import { CellParams, ColDef, LicenseInfo, XGrid, XGridProps } from '@material-ui/x-grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { UnleaveBoardMutation, UnleaveBoardMutationVariables } from './__generated__/UnleaveBoardMutation'
import { UndeleteBoardMutation, UndeleteBoardMutationVariables } from './__generated__/UndeleteBoardMutation'
import {
  UpdatePersonTenantAssociationRoleMutation,
  UpdatePersonTenantAssociationRoleMutationVariables
} from './__generated__/UpdatePersonTenantAssociationRoleMutation'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

LicenseInfo.setLicenseKey(
  '8fef834ac094057799e038823118d084T1JERVI6MjAwNzQsRVhQSVJZPTE2NDE5NjMwMjkwMDAsS0VZVkVSU0lPTj0x'
)

const fetch = window.fetch

type ReportActivationsProps = IReportActivationsProps

interface IReportActivationsProps {
  deleted: boolean | null
  ignored: boolean | null
  revoked: boolean | null
  online: boolean | null
}

function useQueryString() {
  return new URLSearchParams(useLocation().search)
}

const UNLEAVE_BOARD_MUTATION = gql`
  mutation UnleaveBoardMutation($ptaId: String!) {
    unleaveBoard(input: { personTenantAssociationId: $ptaId }) {
      id
    }
  }
`

const UNDELETE_BOARD_MUTATION = gql`
  mutation UndeleteBoardMutation($boardId: String!) {
    undeleteBoard(input: { board: $boardId }) {
      id
    }
  }
`

const UPDATE_PERSON_TENANT_ASSOCIATION_ROLE_MUTATION = gql`
  mutation UpdatePersonTenantAssociationRoleMutation($personTenantAssociationId: String!, $newRole: String!) {
    updatePersonTenantAssociationMutation(
      input: { newRole: { role: $newRole }, personTenantAssociationId: $personTenantAssociationId }
    ) {
      id
    }
  }
`

export const useUnleaveBoardMutation = () => {
  return useMutation<UnleaveBoardMutation, UnleaveBoardMutationVariables>(UNLEAVE_BOARD_MUTATION)
}

export const useUndeleteBoardMutation = () => {
  return useMutation<UndeleteBoardMutation, UndeleteBoardMutationVariables>(UNDELETE_BOARD_MUTATION)
}

export const useUpdatePersonTenantAssociationRoleMutation = () => {
  return useMutation<UpdatePersonTenantAssociationRoleMutation, UpdatePersonTenantAssociationRoleMutationVariables>(
    UPDATE_PERSON_TENANT_ASSOCIATION_ROLE_MUTATION
  )
}

const useBoardsReport = (
  deleted?: boolean | null,
  ignored?: boolean | null,
  revoked?: boolean | null,
  online?: boolean | null
) => {
  const parameters = btoa(JSON.stringify({ conditions: {} }))
  const [data, setData] = React.useState([])
  const { getTokenSilently } = useAuth0()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  const [token, setToken] = React.useState('')

  const getToken = React.useCallback(async () => {
    setToken(await getTokenSilently())
  }, [setToken, getTokenSilently])

  React.useEffect(() => {
    getToken()
  }, [getTokenSilently, getToken])

  const get = React.useCallback(async () => {
    setError(false)
    setLoading(true)
    const _data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/analytics/boards?deleted=${deleted}`, {
      headers: {
        'X-Vestaboard-Token': token
      }
    })

    if (_data?.status !== 200) {
      setLoading(false)
      setError(true)
      return
    }

    const json = await _data.json()

    setData(json.rows)
    setLoading(false)
  }, [deleted, token])

  React.useEffect(() => {
    if (token) {
      get()
    }
  }, [parameters, token, get])

  const reload = () => get()

  return {
    error,
    data: data as { [key: string]: any | null }[],
    loading,
    reload
  }
}

export const ReportActivations: React.FC<ReportActivationsProps> = props => {
  const { getTokenSilently } = useAuth0()
  const query = useQueryString()

  const history = useHistory()
  const [, _setPage] = React.useState(0)

  const boardsReport = useBoardsReport(props.deleted, props.ignored, props.revoked, props.online)

  React.useEffect(() => {
    _setPage(parseInt(query.get('page') || '0'))
  }, [query])

  const [token, setToken] = React.useState('')

  const getToken = React.useCallback(async () => {
    setToken(await getTokenSilently())
  }, [setToken, getTokenSilently])

  React.useEffect(() => {
    getToken()
  }, [getTokenSilently, getToken])

  const idColumnProps = (onClick: (value: string) => void): Partial<ColDef> => ({
    resizable: false,
    flex: 0.75,
    sortable: false,
    renderCell: (params: CellParams) =>
      params.value ? (
        <Button onClick={() => onClick(params.value as string)} variant='outlined' size='small' color='primary'>
          <TruncatedId value={params.value as string} />
        </Button>
      ) : (
        <Box />
      )
  })

  const stringColumnProps = {
    flex: 1.5
  }

  const timestampColumnProps = {
    flex: 1,
    renderCell: (params: CellParams) => (params.value ? <FormattedDateTime value={params.value as number} /> : <Box />)
  }

  const enumColumnProps = {
    resizable: false,
    sortable: false,
    flex: 0.75
  }

  const booleanColumnProps = {
    resizable: false,
    flex: 0.5,
    filterable: false,
    renderCell: (params: CellParams) => {
      if (params.value === true) {
        return <CheckCircleIcon color={'primary'} />
      } else if (params.value === false) {
        return <HighlightOffIcon color={'disabled'} />
      } else {
        return <Box />
      }
    }
  }

  const columns: ColDef[] = [
    { field: 'id', headerName: 'Board ID', ...idColumnProps(board => history.push(`/boards/${board}`)) },
    {
      field: 'pairedDeviceId',
      headerName: 'Paired Device',
      ...idColumnProps(board => history.push(`/devices/${board}`))
    },
    { field: 'title', headerName: 'Board Title', ...stringColumnProps },
    { field: 'boardCreated', headerName: 'Created', ...timestampColumnProps },
    { field: 'devicePaired', headerName: 'Paired', ...timestampColumnProps },
    { field: 'latestMessage', headerName: 'Latest Message', ...timestampColumnProps },
    { field: 'pairingCode', headerName: 'Pairing Code' },
    { field: 'personId', headerName: 'Person ID', ...idColumnProps(board => history.push(`/person/${board}`)) },
    { field: 'firstName', headerName: 'First Name', ...stringColumnProps },
    { field: 'lastName', headerName: 'Last Name', ...stringColumnProps },
    { field: 'emailAddress', headerName: 'Email Address', ...stringColumnProps },
    { field: 'role', headerName: 'Role', ...enumColumnProps },
    { field: 'isInvited', headerName: 'Invited', ...booleanColumnProps },
    { field: 'isOnline', headerName: 'Online', ...booleanColumnProps },
    { field: 'isIgnored', headerName: 'Ignored', ...booleanColumnProps },
    { field: 'isDeleted', headerName: 'Deleted', ...booleanColumnProps }
  ]

  const gridProps: XGridProps = {
    columns,
    rows: boardsReport.data.map(row => ({ ...row, id: row.boardId }))
  }

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>{`${
          props.deleted ? 'Deleted' : props.online ? 'Online' : 'Offline'
        } Boards`}</Typography>
        <FlexHorizontal>
          {token && (
            <>
              <Button
                color='primary'
                variant='outlined'
                onClick={() =>
                  (window.location.href = `https://platform.vestaboard.com/export/current-boards.csv?token=${token}`)
                }>
                <CloudDownloadOutlined />
              </Button>
              <Box style={{ width: 7 }} />
            </>
          )}
          <Button color='primary' variant='outlined' onClick={() => {}}>
            <RefreshOutlined />
          </Button>
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='large' />
      {boardsReport.loading ? (
        <CircularProgress />
      ) : boardsReport.error ? (
        <Alert severity='error'>There was an error loading this board report</Alert>
      ) : (
        <Box style={{ height: 1000 }}>
          <XGrid {...gridProps}></XGrid>
        </Box>
      )}
    </Box>
  )
}

interface IManageInvitationStatusProps {
  boardTitle: string
  boardId: string
  personId: string
  personName: string
  personEmailAddress: string
  personTenantAssociationId: string
  status: string
  onClose: () => any
}

type ManageInvitationStatusProps = IManageInvitationStatusProps

interface IManageBoardDeletionStatusProps {
  boardId: string
  onClose: () => any
}

type ManageBoardDeletionStatusProps = IManageBoardDeletionStatusProps

export const ManageBoardDeletionStatus: React.FC<ManageBoardDeletionStatusProps> = props => {
  const [mutate] = useUndeleteBoardMutation()
  const [modalOpen, setModalOpen] = React.useState(false)

  const perform = async () => {
    await mutate({
      variables: {
        boardId: props.boardId
      }
    })
    setModalOpen(false)
    props.onClose()
  }

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Undelete a Vestaboard</DialogTitle>
        <DialogContent dividers>Are you sure you would like to undelete the Vestaboard?</DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => perform()}>
            Yes
          </Button>
          <Button>No</Button>
        </DialogActions>
      </Dialog>
      <Button variant='outlined' onClick={() => setModalOpen(true)}>
        Undelete
      </Button>
    </>
  )
}

export const ManageInvitationStatus: React.FC<ManageInvitationStatusProps> = props => {
  const [mutate] = useUnleaveBoardMutation()

  const perform = async () => {
    await mutate({
      variables: {
        ptaId: props.personTenantAssociationId
      }
    })
    setModalOpen(false)
    props.onClose()
  }

  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Grant access to a Vestaboard</DialogTitle>
        <DialogContent dividers>
          Are you sure you would like to make {props.boardTitle} available to {props.personName} (
          {props.personEmailAddress})?
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => perform()}>
            Yes
          </Button>
          <Button>No</Button>
        </DialogActions>
      </Dialog>
      <div>{props.status}</div>
      <Button variant='outlined' onClick={() => setModalOpen(true)}>
        Change
      </Button>
    </>
  )
}
