import { useMutation } from '@apollo/react-hooks'
import { CreatePick, CreatePickVariables } from './__generated__/CreatePick'
import { gql } from 'apollo-boost'

const CREATE_PICK = gql`
  mutation CreatePick($input: CreatePickInput!) {
    createPick(input: $input) {
      created {
        id
        categories {
          id
          title
        }
        recommendAfter
        recommendBefore
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useCreatePick = () => {
  return useMutation<CreatePick, CreatePickVariables>(CREATE_PICK)
}
