export const parseQueryString = <T>(search: string) => {
  if (!search) {
    return {} as T
  }

  return search
    .split('?')[1]
    .split('&')
    .reduce((prev, current) => {
      const [key, value] = current.split('=')
      return {
        ...prev,
        [decodeURI(key)]: decodeURI(value)
      }
    }, {}) as T
}
