import { UpdateColorTemplateV2, UpdateColorTemplateV2Variables } from './__generated__/UpdateColorTemplateV2'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdateColorTemplateV2($input: UpdateColorTemplateInputV2!) {
    updateColorTemplate: updateColorTemplateV2(input: $input) {
      __typename
      ... on ColorTemplateV2 {
        id
        title
        messages {
          id
          text
          characters
        }
      }
      ... on ColorTemplateErrorV2 {
        type
        error
      }
    }
  }
`

export const useUpdateColorTemplate = () => {
  return useMutation<UpdateColorTemplateV2, UpdateColorTemplateV2Variables>(MUTATION)
}
