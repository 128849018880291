import React, { useState } from 'react'
import { ApolloConsumer } from '@apollo/react-common'
import { DocumentNode } from 'graphql'
import { Button } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { LoadMask } from '@vestaboard/installables'
import { csvDownload, csvFormat } from '../utils'

interface ICsvExport {
  query: DocumentNode
  variables: any
  paginationKey?: string
  getPagination: (
    data: any
  ) => {
    page: number
    perPage: number
    total: number
  }
  formatData: (data: any) => any[]
}

const PER_PAGE = 100

export const CsvExport = (props: ICsvExport) => {
  const [loading, setLoading] = useState(false)

  return (
    <ApolloConsumer>
      {apollo => {
        const buildPaginationPage = async (prev: any[], page: number, retries: number): Promise<any[]> => {
          const paginationKeys = {
            perPage: PER_PAGE,
            page
          }

          try {
            const result = await apollo.query({
              fetchPolicy: 'network-only',
              query: props.query,
              variables: {
                ...props.variables,
                ...paginationKeys,
                input: {
                  ...props.variables.input,
                  ...paginationKeys
                }
              }
            })

            const pagination = props.getPagination(result)
            const data = [...prev, ...props.formatData(result)]

            if (pagination.page * pagination.perPage > pagination.total) {
              return data
            }

            return await buildPaginationPage(data, page + 1, 0)
          } catch (e) {
            if (retries < 5) {
              return await buildPaginationPage(prev, page + 1, retries + 1)
            } else {
              throw new Error('Out of retries')
            }
          }
        }

        return (
          <>
            {loading ? <LoadMask /> : null}
            <Button
              endIcon={<GetApp />}
              variant='outlined'
              onClick={async () => {
                setLoading(true)
                const result = await buildPaginationPage([], 0, 0)
                const csv = csvFormat(result, Object.keys(result[0]))
                setLoading(false)
                csvDownload(csv)
              }}>
              Export CSV
            </Button>
          </>
        )
      }}
    </ApolloConsumer>
  )
}
