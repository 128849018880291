export enum AcceptedReferralErrorType {
  NotAuthorized = 'NotAuthorized',
  NotFound = 'NotFound'
}

export enum AcceptedReferralSortBy {
  createdAt = 'createdAt',
  creditRedeemed = 'creditRedeemed',
  invalidated = 'invalidated',
  notified = 'notified',
  orderDeliveredAt = 'orderDeliveredAt',
  purchaseId = 'purchaseId',
  referrerEmail = 'referrerEmail',
  referrerName = 'referrerName',
  referrerPersonId = 'referrerPersonId',
  shopifyCustomerEmail = 'shopifyCustomerEmail',
  shopifyCustomerName = 'shopifyCustomerName',
  shopifyOrderName = 'shopifyOrderName'
}

export enum AcceptedReferralSortDirection {
  asc = 'asc',
  desc = 'desc'
}
