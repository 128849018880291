import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  RemoveMessageSetSubmissionV2,
  RemoveMessageSetSubmissionV2Variables
} from './__generated__/RemoveMessageSetSubmissionV2'

const MUTATION = gql`
  mutation RemoveMessageSetSubmissionV2($input: UnSubmitMessageSetInputV2!) {
    unSubmitMessageSet: unSubmitMessageSetV2(input: $input) {
      ... on SubmittedMessageSetV2 {
        id
      }

      ... on SubmittedMessageSetErrorV2 {
        type
        error
      }
    }
  }
`

export const useRemoveMessageSetSubmission = () =>
  useMutation<RemoveMessageSetSubmissionV2, RemoveMessageSetSubmissionV2Variables>(MUTATION)
