import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateInvitation, CreateInvitationVariables } from './__generated__/CreateInvitation'

const CREATE_INVITATION = gql`
  mutation CreateInvitation($input: AddPersonToExistingBoardsInput!) {
    createPersonBoardInvitations(input: $input) {
      exists
      added
    }
  }
`

export const useCreateInvitation = () => useMutation<CreateInvitation, CreateInvitationVariables>(CREATE_INVITATION)
