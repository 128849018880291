import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateMessageSetV2, CreateMessageSetV2Variables } from './__generated__/CreateMessageSetV2'

const MUTATION = gql`
  mutation CreateMessageSetV2($input: CreateMessageSetInputV2!) {
    createMessageSet: createMessageSetV2(input: $input) {
      __typename
      ... on MessageSetV2 {
        id
        title
        public
        dynamic
        rssUrl
        messages {
          id
          text
          characters
        }
      }
      ... on MessageSetErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreateMessageSet = () => useMutation<CreateMessageSetV2, CreateMessageSetV2Variables>(MUTATION)
