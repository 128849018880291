import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  CreatePickCategoryMutation,
  CreatePickCategoryMutationVariables
} from './__generated__/CreatePickCategoryMutation'

const CREATE_PICK_CATEGORY = gql`
  mutation CreatePickCategoryMutation($input: CreatePickCategoryInput!) {
    createPickCategory(input: $input) {
      created {
        id
        title
      }
    }
  }
`

export const useCreatePickCategory = () => {
  return useMutation<CreatePickCategoryMutation, CreatePickCategoryMutationVariables>(CREATE_PICK_CATEGORY)
}
