import * as React from 'react'
import Mustache from 'mustache'
import { useHistory } from 'react-router'
import { Box, Button, Card, CircularProgress, Switch, Table, TableCell, TableRow, TextField } from '@material-ui/core'
import moment from 'moment'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { SelectDeveloper } from '../../developers'
import { useGetInstallable } from '../hooks/useGetInstallable'
import { useUpdateInstallable } from '../hooks/useUpdateInstallable'

interface IEditInstallable {
  id: string
}

const hasTemplateError = (tpl: string): string | null => {
  try {
    Mustache.parse(tpl)
  } catch (err) {
    return (err as any).message
  }

  return null
}

export const EditInstallable: React.FC<IEditInstallable> = ({ id }) => {
  const history = useHistory()
  const { data, loading, refetch } = useGetInstallable(id)

  const [mutate, { loading: saving }] = useUpdateInstallable()

  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [iconUrl, setIconUrl] = React.useState('')
  const [hidden, setHidden] = React.useState(false as boolean | null)
  const [year, setYear] = React.useState(0)
  const [month, setMonth] = React.useState(0)
  const [day, setDay] = React.useState(0)
  const [developer, setDeveloper] = React.useState<string | null>(null)
  const [isSingleton, setIsSingleton] = React.useState(false)
  const [canRename, setCanRename] = React.useState(false)
  const [subscriptionConfigurationUrl, setSubscriptionConfigurationUrl] = React.useState<string | null>(null)
  const [webhookDestinationId, setWebhookDestinationId] = React.useState<string | null>(null)
  const [configurationSchema, setConfigurationSchema] = React.useState<string | null>(null)
  const [cadenceConfigurationUrl, setCadenceConfigurationUrl] = React.useState<string | null>(null)
  const [cadenceDisplayTemplate, setCadenceDisplayTemplate] = React.useState<string | null>(null)
  const [cadenceOnCommand, setCadenceOnCommand] = React.useState(false)
  const [cadenceToken, setCadenceToken] = React.useState<string | null>(null)
  const [templateError, setTemplateError] = React.useState<string | null>(null)
  const { addToast } = useToasts()

  React.useEffect(() => {
    const error = hasTemplateError(cadenceDisplayTemplate || '')
    setTemplateError(error)
  }, [cadenceDisplayTemplate])

  React.useEffect(() => {
    if (data?.installable) {
      const date = moment(parseInt(data.installable.created)).format('YYYY-MM-DD')
      const dateParts = date.split('-')

      setTitle(data?.installable.title ?? '')
      setDescription(data?.installable.description ?? '')
      setIconUrl(data?.installable.icon ?? '')
      setDeveloper(data?.installable?.developer?.id ?? '')
      setHidden(data?.installable.hideFromInstallablesList)
      setYear(parseInt(dateParts[0]))
      setMonth(parseInt(dateParts[1]))
      setDay(parseInt(dateParts[2]))
      setCanRename(data?.installable.subscriptionsCanBeRenamed ?? false)
      setIsSingleton(data?.installable.installationsAreSingleton ?? false)
      setSubscriptionConfigurationUrl(data?.installable.subscriptionConfigurationUrl ?? null)
      setWebhookDestinationId(data?.installable.webhookDestination?.id ?? null)
      setConfigurationSchema(data?.installable.configurationSchema || '{}')
      setCadenceConfigurationUrl(data?.installable?.cadenceConfigurationUrl ?? null)
      setCadenceDisplayTemplate(data?.installable?.cadenceDisplayTemplate ?? null)
      setCadenceToken(data?.installable?.cadenceToken ?? null)
      setCadenceOnCommand(data?.installable?.cadenceOnCommand ?? false)
    }
  }, [data])

  const save = async () => {
    if (templateError) {
      return
    }

    await mutate({
      variables: {
        input: {
          id,
          title,
          description,
          hiddenFromList: hidden ?? false,
          releaseMonth: month,
          releaseYear: year,
          releaseDay: day,
          developer: developer ?? '',
          logo: iconUrl,
          isSingleton,
          canRename,
          webhookDestinationId,
          subscriptionConfigurationUrl,
          configurationSchema,
          cadenceConfigurationUrl,
          cadenceDisplayTemplate,
          cadenceOnCommand,
          cadenceToken
        }
      }
    })

    addToast('Installable saved', {
      appearance: 'success',
      autoDismiss: true
    })

    await refetch()
  }

  if (loading) return <CircularProgress />

  if (!data) return <Alert severity='error'>Installable not found.</Alert>

  const dateValue = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`

  return (
    <>
      <Card
        style={{
          maxWidth: 850
        }}>
        <Table>
          <TableRow>
            <TableCell>Installable ID</TableCell>
            <TableCell>
              <TextField variant='outlined' onChange={() => {}} value={data.installable.id} fullWidth disabled />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Released</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                onChange={() => {}}
                value={moment(parseInt(data.installable.created)).format('YYYY-MM-DD')}
                fullWidth
                disabled
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                onChange={e => setTitle(e.target.value as string)}
                value={title}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                onChange={e => setDescription(e.target.value as string)}
                value={description}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Icon URL</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                onChange={e => setIconUrl(e.target.value as string)}
                value={iconUrl}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hidden from installable list</TableCell>
            <TableCell>
              <Switch checked={hidden ?? false} onChange={(e, v) => setHidden(v)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Subscriptions can be renamed by user</TableCell>
            <TableCell>
              <Switch checked={canRename ?? false} onChange={(e, v) => setCanRename(v)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Multiple subscriptions can be created for this installable {`(Not a Single instance)`}</TableCell>
            <TableCell>
              <Switch checked={!isSingleton ?? false} onChange={(e, v) => setIsSingleton(!v)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Developer</TableCell>
            <TableCell>
              <SelectDeveloper value={developer} setValue={setDeveloper} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Release date</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                type='date'
                onChange={e => {
                  const date = e.target.value.split('-')

                  setYear(parseInt(date[0]))
                  setMonth(parseInt(date[1]))
                  setDay(parseInt(date[2]))
                }}
                value={dateValue}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Configuration Schema</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                value={configurationSchema}
                onChange={e => setConfigurationSchema(e.target.value)}
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Subscription configuration URL</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                value={subscriptionConfigurationUrl}
                onChange={e => setSubscriptionConfigurationUrl(e.target.value)}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cadence Configuration URL</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                value={cadenceConfigurationUrl}
                onChange={e => setCadenceConfigurationUrl(e.target.value)}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cadence Token</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                value={cadenceToken}
                onChange={e => setCadenceToken(e.target.value)}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cadence Display Template</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                value={cadenceDisplayTemplate}
                onChange={e => setCadenceDisplayTemplate(e.target.value)}
                fullWidth
              />
              {templateError ? (
                <>
                  <Spacer size='large' />
                  <Alert severity='error'>{templateError}</Alert>
                </>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Treat Cadence As "On Command"</TableCell>
            <TableCell>
              <Switch
                checked={cadenceOnCommand}
                onChange={() => {
                  setCadenceOnCommand(cadenceOnCommand => !cadenceOnCommand)
                }}
              />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            history.push(`/installables`)
          }}>
          Cancel
        </Button>
        <Box width={24} />
        <Button size='large' variant='contained' color='primary' onClick={() => save()}>
          {saving ? <CircularProgress /> : 'Save'}
        </Button>
      </FlexHorizontal>
    </>
  )
}
