import React, { useEffect } from 'react'
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { Spacer } from '@vestaboard/installables'
import { FirmwareVersions } from '../components/FirmwareVersions'
import { PlusRenewalTerms } from '../components/PlusRenewalTerms'
import { Channels } from '../components/Channels'
import { OnlineBoards } from '../components/OnlineBoards'
import { useGetBoardsReport } from '../hooks/useGetBoardsReport'
import { ReportNumberBox } from '../../../ui/ReportNumberBox'
import { MessagesByMinute } from '../components/MessagesByMinute'
import moment from 'moment-timezone'
import { MessagesByHour } from '../components/MessagesByHour'
import { useGetDayMessageSources } from '../hooks/useGetDayMessageSources'
import { ReportNumberBoxLarge } from '../../../ui/ReportNumberBoxLarge'
import { CsvSimpleExport } from '../../../ui/CsvSimpleExport'

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    padding: 24
  },
  title: {
    color: '#FFF',
    fontSize: 24,
    paddingLeft: 24,
    fontWeight: 500
  },
  text: {
    padding: 24,
    paddingTop: 7
  },
  chart: {
    height: 350,
    padding: 24,
    marginBottom: 80,
    width: 'calc(100% - 48px)'
  }
})

interface IBoardsDashboard {
  searchKey: string | null
  setDate: (date: string | null) => void
}

export const DailyBoardsDashboard = (props: IBoardsDashboard) => {
  const { data, loading, error } = useGetBoardsReport('day', props.searchKey)
  const messageSources = useGetDayMessageSources(data?.date || props.searchKey)
  const classes = useStyles()
  const { setDate } = props

  useEffect(() => {
    if (data?.date) {
      setDate(data.date)
    }
  }, [data, setDate])

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress />
      </Box>
    )
  }

  if (error || !data) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.title}>No data found</Typography>
        <Typography className={classes.text}>
          There is no data for this time range. Board report data has not been fully backfilled and we can't predict the
          future.
          <br />
          Consider consulting the Magic 8 Board installable for all such inquiries.
        </Typography>
      </Box>
    )
  }

  const messagesByHour = data.messagesByMinute
    ? data.messagesByMinute.reduce(
        (prev, current) => {
          return {
            ...prev,
            [current.time.split(':')[0]]: (prev[current.time.split(':')[0]] || 0) + current.count
          }
        },
        {} as {
          [key: string]: number
        }
      )
    : {}

  const messagesByHourArray = Object.keys(messagesByHour)
    .map(key => ({
      hour: key,
      count: messagesByHour[key]
    }))
    .sort((a, b) => {
      return a.hour < b.hour ? -1 : 1
    })
    .map(item => {
      const time = moment
        .tz(data.date, 'UTC')
        .hour(+item.hour.split(':')[0])
        .tz(moment.tz.guess())

      return {
        ...item,
        hour: time.format('h a')
      }
    })

  const messagesByMinuteArray = (data?.messagesByMinute || [])
    .sort((a, b) => {
      return a.time < b.time ? -1 : 1
    })
    .map(item => {
      const time = moment
        .tz(data.date, 'UTC')
        .hour(+item.time.split(':')[0])
        .minutes(+item.time.split(':')[1])
        .tz(moment.tz.guess())

      return {
        ...item,
        time: time.format('h:mm a'),
        hour: time.format('h a')
      }
    })

  const onlineBoards = {
    onlineBoards: data?.onlineBoards,
    pairedBoards: data?.pairedBoards,
    activeBoards: data?.activeBoards,
    plusBoards: data?.plusBoards
  }

  const channels = {
    channels: data?.channels,
    enabledChannels: data?.enabledChannels
  }

  return (
    <Box className={classes.root}>
      <Box display='flex' justifyContent='flex-start' flexWrap='wrap' maxWidth='100%'>
        <ReportNumberBoxLarge
          title='Northstar Metric ✨ '
          description='(Percent of paired boards that have sent 7 messages in the past 7 days)'
          number={(data.activeBoards / data.pairedBoards) * 100}
          postfix='%'
        />
        <ReportNumberBoxLarge
          title='Plus Enabled Boards'
          description='(Percent of paired boards that currently have Vestaboard+ enabled)'
          number={(data.plusBoards / data.pairedBoards) * 100}
          postfix='%'
        />
      </Box>
      <Spacer size='extraLarge' />
      <Box display='flex' justifyContent='flex-start' flexWrap='wrap' maxWidth='100%'>
        <ReportNumberBox title='Messages In Past Day' number={data.messagesInPastDay} />
        <ReportNumberBox title='Messages In Past Seven Days' number={data.messagesInPastSevenDays} />
        <ReportNumberBox title='Messages In Past Thirty Days' number={data.messagesInPastThirtyDays} />
        <ReportNumberBox title='Messages All Time' number={data.messagesAllTime} />
        <ReportNumberBox
          title='Messages Per Board (Daily)'
          number={data.messagesInPastDay / data.pairedBoards}
          postfix='AVG'
        />
        <ReportNumberBox
          title='Messages Per Board (Weekly)'
          number={data.messagesInPastSevenDays / data.pairedBoards}
          postfix='AVG'
        />
        <ReportNumberBox
          title='Messages Per Board (Monthly)'
          number={data.messagesInPastThirtyDays / data.pairedBoards}
          postfix='AVG'
        />
        <ReportNumberBox
          title='Messages Per Board (All Time)'
          number={data.messagesAllTime / data.pairedBoards}
          postfix='AVG'
        />
        {messageSources?.data?.boardPersons ? (
          <ReportNumberBox
            title='Users Per Board'
            number={+(messageSources.data.boardPersons / data.onlineBoards).toFixed(2)}
            postfix='AVG'
          />
        ) : null}
        {messageSources?.data?.boardPersons && messageSources?.data?.messageSendingPersons ? (
          <ReportNumberBox
            title='Users Who Sent A Message'
            number={+(messageSources?.data?.messageSendingPersons / messageSources.data.boardPersons).toFixed(2)}
            postfix='%'
          />
        ) : null}
      </Box>
      {data.messagesByMinute ? (
        <>
          <Spacer size='extraLarge' />
          <Box className={classes.chart}>
            <Typography className={classes.title}>
              Messages By Hour <CsvSimpleExport data={messagesByHourArray} title={`messages-by-hour-${data.date}`} />
            </Typography>
            <Spacer size='large' />
            <MessagesByHour data={messagesByHourArray} />
          </Box>
          <Spacer size='extraLarge' />
          <Box className={classes.chart}>
            <Typography className={classes.title}>
              Messages By Minute{' '}
              <CsvSimpleExport data={messagesByMinuteArray} title={`messages-by-minute-${data.date}`} />
            </Typography>
            <Spacer size='large' />
            <MessagesByMinute data={messagesByMinuteArray} />
          </Box>
        </>
      ) : null}
      <Spacer size='extraLarge' />
      <Box>
        <Typography className={classes.title}>
          Boards
          <CsvSimpleExport data={[onlineBoards]} title={`boards-${data.date}`} />
        </Typography>
        <Spacer size='large' />
        <OnlineBoards {...onlineBoards} />
      </Box>
      <Spacer size='extraLarge' />
      <Box>
        <Typography className={classes.title}>
          Channel Subscriptions
          <CsvSimpleExport data={[channels]} title={`channel-subscriptions-${data.date}`} />
        </Typography>
        <Spacer size='large' />
        <Channels {...channels} />
      </Box>
      <Spacer size='extraLarge' />
      <Spacer size='extraLarge' />
      <Box display='flex' justifyContent='flex-start'>
        <Box>
          <Typography className={classes.title}>
            Firmware Versions
            <CsvSimpleExport
              data={Object.keys(data.firmwareVersion).map(firmwareVersion => ({
                firmwareVersion,
                count: data.firmwareVersion[firmwareVersion]
              }))}
              title={`firmware-versions-${data.date}`}
            />
          </Typography>
          <Spacer size='large' />
          <FirmwareVersions firmwareVersion={data.firmwareVersion} />
        </Box>
        <Box>
          <Typography className={classes.title}>
            Plus Renewal Terms
            <CsvSimpleExport
              data={Object.keys(data.plusRenewalType).map(plusRenewalType => ({
                plusRenewalType,
                count: data.plusRenewalType[plusRenewalType]
              }))}
              title={`plus-renewal-terms-${data.date}`}
            />
          </Typography>
          <Spacer size='large' />
          <PlusRenewalTerms plusRenewalTypes={data.plusRenewalType} />
        </Box>
      </Box>
    </Box>
  )
}
