import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import { EditableCell } from '../components/EditableCell'
import { ListTab } from '../components/ListTab'
import React from 'react'
import { useListSystemMessageTemplates } from '../hooks/useListSystemMessageTemplates'
import { useSaveSystemMessageTemplate } from '../hooks/useSaveSystemMessageTemplate'

export const Templates = () => {
  const [saveTemplate] = useSaveSystemMessageTemplate()
  const { data, loading, error } = useListSystemMessageTemplates({
    limit: 100
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Notifications</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <ListTab activeTab='templates' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the templates</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 1600 }}>
            <TableHead>
              <TableCell style={{ width: 100 }}>Topic</TableCell>
              <TableCell style={{ width: 300 }}>Title</TableCell>
              <TableCell>Message</TableCell>
              <TableCell style={{ width: 400 }}>Deep Link</TableCell>
              <TableCell style={{ width: 200 }}>Call To Action</TableCell>
            </TableHead>
            <TableBody>
              {(data?.listSystemMessageTemplates?.systemMessageTemplates || []).map(({ __typename, ...template }) => (
                <TableRow key={template.id}>
                  <TableCell>
                    <Typography color='textSecondary'>{template.id}</Typography>
                  </TableCell>
                  <EditableCell
                    value={template.title}
                    onChange={async title => {
                      await saveTemplate({
                        variables: {
                          input: {
                            ...template,
                            title
                          }
                        }
                      })
                    }}
                  />
                  <EditableCell
                    value={template.template}
                    onChange={async templateMessage => {
                      await saveTemplate({
                        variables: {
                          input: {
                            ...template,
                            template: templateMessage
                          }
                        }
                      })
                    }}
                  />
                  <EditableCell
                    value={template.deepLink || ''}
                    onChange={async deepLink => {
                      await saveTemplate({
                        variables: {
                          input: {
                            ...template,
                            deepLink
                          }
                        }
                      })
                    }}
                  />

                  <EditableCell
                    value={template.callToAction || ''}
                    onChange={async callToAction => {
                      await saveTemplate({
                        variables: {
                          input: {
                            ...template,
                            callToAction
                          }
                        }
                      })
                    }}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}
