import { UpdatePickV2, UpdatePickV2Variables } from './__generated__/UpdatePickV2'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdatePickV2($input: UpdatePickInputV2!) {
    updatePick: updatePickV2(input: $input) {
      ... on PickV2 {
        id
        date
        created
        categories {
          id
          title
        }
        sortOrder
        attribution
        message {
          id
          text
          characters
          tags
        }
      }
      ... on PickErrorV2 {
        type
        error
      }
    }
  }
`

export const useUpdatePick = () => {
  return useMutation<UpdatePickV2, UpdatePickV2Variables>(MUTATION)
}
