import React from 'react'
import { Switch } from '@material-ui/core'
import { useAdminUpdateBoard } from '../../../api/useAdminUpdateBoard'

interface IInternalSwitch {
  boardId: string
  internalBoard: boolean
}

export const InternalSwitch = (props: IInternalSwitch) => {
  const [updateBoard] = useAdminUpdateBoard()

  return (
    <Switch
      onChange={async () => {
        await updateBoard({
          variables: {
            input: {
              boardId: props.boardId,
              internalBoard: !props.internalBoard
            }
          }
        })
      }}
      checked={props.internalBoard}
    />
  )
}
