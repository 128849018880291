import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useListNotificationSegments } from '../hooks/useListNotificationSegments'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { ListTab } from '../components/ListTab'
import { Alert } from '@material-ui/lab'
import InfiniteScroll from 'react-infinite-scroller'
import { TruncatedId } from '../../truncated-id'

export const ListSegments = () => {
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const input = {
    cursor: null,
    limit: 25
  }

  const { loading, error, data, fetchMore } = useListNotificationSegments({
    input
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Notification Segments</Typography>
        <FlexHorizontal>
          <Button onClick={() => history.push('/notifications/segments/create')} variant='contained' color='primary'>
            Create Segment
          </Button>
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <ListTab activeTab='segments' />
      {loading && !data?.listNotificationSegments.notificationSegments ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the notification segments</Alert>
      ) : !data?.listNotificationSegments.notificationSegments.length ? (
        <Alert severity='info'>There are no notification segments</Alert>
      ) : (
        <>
          {data ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newSearch = options.fetchMoreResult?.listNotificationSegments.notificationSegments || []
                    const previousSearch = previousQueryResult.listNotificationSegments.notificationSegments || []

                    return {
                      listNotificationSegments: {
                        ...previousQueryResult.listNotificationSegments,
                        nextCursor: options.fetchMoreResult?.listNotificationSegments.nextCursor || null,
                        notificationSegments: [...previousSearch, ...newSearch]
                      }
                    }
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data.listNotificationSegments.nextCursor || null
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={!!data?.listNotificationSegments?.nextCursor}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listNotificationSegments.notificationSegments.map(segment => (
                      <TableRow key={segment.id}>
                        <TableCell>
                          <Button
                            onClick={() => history.push(`/notifications/segments/${segment.id}`)}
                            variant='outlined'
                            color='primary'>
                            <TruncatedId value={segment.id} full />
                          </Button>
                        </TableCell>
                        <TableCell>{segment.title}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : null}
        </>
      )}
    </Box>
  )
}
