import { useEffect, useState } from 'react'
import { REPORTS_API } from '../../../constants'
import { useAuth0 } from '../../../react-auth0-spa'

interface IListing {
  subscriptions: number
  id: string
  enabledSubscriptions: number
  categories: string[]
  title: string
  subscriptionsInPast30Days: number
  releaseDate: number
  created: number
}

export interface IData {
  date: string
  listings: IListing[]
  records?: {
    [key: string]: IData
  }
}

export const useGetMarketplaceListingsReport = (rangeType: string, searchKey?: string | null) => {
  const { getTokenSilently } = useAuth0()
  const [data, setData] = useState<IData | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      setError(null)
      setData(null)
      setLoading(true)
      try {
        const response = await fetch(
          searchKey
            ? `${REPORTS_API}/marketplace-listing/${rangeType}/${searchKey}`
            : `${REPORTS_API}/marketplace-listing/current`,
          {
            headers: { 'X-Vestaboard-Token': await getTokenSilently() }
          }
        )
        const json = await response.json()
        setData({
          activeBoards: 0,
          ...json
        })
      } catch (e) {
        setData(null)
        setError('No data found')
      }
      setLoading(false)
    }
    fetchData()
  }, [getTokenSilently, searchKey, rangeType])

  return { data, error, loading }
}
