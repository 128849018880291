import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { PairingCodeQuery, PairingCodeQueryVariables } from './__generated__/PairingCodeQuery'
import { useHistory, useParams } from 'react-router'

type PairingCodeRedirectProps = IPairingCodeRedirectProps

interface IPairingCodeRedirectProps {}

const QUERY = gql`
  query PairingCodeQuery($code: String!) {
    deviceByPairingCode(code: $code) {
      id
    }
  }
`

export const PairingCodeRedirect: React.FC<PairingCodeRedirectProps> = props => {
  const { code } = useParams<{ code: string }>()
  const { data } = useQuery<PairingCodeQuery, PairingCodeQueryVariables>(QUERY, {
    variables: {
      code
    }
  })
  const history = useHistory()

  React.useEffect(() => {
    if (data?.deviceByPairingCode.id) {
      history.push(`/devices/${data.deviceByPairingCode.id}`)
    }
  }, [data, history])

  return <></>
}
