import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  DeleteColorTemplateCategoryMutation,
  DeleteColorTemplateCategoryMutationVariables
} from './__generated__/DeleteColorTemplateCategoryMutation'

const DELETE_COLOR_TEMPLATE_CATEGORY = gql`
  mutation DeleteColorTemplateCategoryMutation($input: DeleteColorTemplateCategoryInput!) {
    deleteColorTemplateCategory(input: $input) {
      deleted {
        id
        title
      }
    }
  }
`

export const useDeleteColorTemplateCategory = () => {
  return useMutation<DeleteColorTemplateCategoryMutation, DeleteColorTemplateCategoryMutationVariables>(
    DELETE_COLOR_TEMPLATE_CATEGORY
  )
}
