import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { SearchDeviceById, SearchDeviceByIdVariables } from '../../board/hooks/__generated__/SearchDeviceById'

const QUERY = gql`
  query SearchDeviceById($id: String!) {
    device(id: $id) {
      id
      created
      pairingCode
      firmwareVersion
      latestHeartbeat {
        id
        created
      }
      configurationNetwork {
        id
        ssid
      }
    }
  }
`

export const useSearchByDeviceId = (id: string) => {
  return useQuery<SearchDeviceById, SearchDeviceByIdVariables>(QUERY, {
    variables: {
      id
    }
  })
}
