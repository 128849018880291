import React, { useState } from 'react'
import { useListTags } from '../hooks/useListTags'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core'
import { TruncatedId } from '../../truncated-id'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { FlexHorizontal } from '@vestaboard/installables'
import { useCreateTag } from '../hooks/useCreateTag'

interface ITagSelector {
  disabled?: boolean
  tag: string
  setTag: (tag: string) => void
}

export const TagSelector = (props: ITagSelector) => {
  const [tagName, setTagName] = useState('' as string)
  const { data, refetch, loading } = useListTags({
    input: {
      limit: 100
    }
  })
  const [createTag] = useCreateTag()

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {(data?.listNotificationTags?.notificationTags || []).map(tag => (
              <TableRow key={tag.id}>
                <TableCell>
                  {tag.id === props.tag ? (
                    <ButtonBase
                      disabled={props.disabled}
                      onClick={() => {
                        if (props.disabled) {
                          return
                        }

                        props.setTag('')
                      }}>
                      <RadioButtonChecked />
                    </ButtonBase>
                  ) : (
                    <ButtonBase disabled={props.disabled} onClick={() => props.setTag(tag.id)}>
                      <RadioButtonUnchecked />
                    </ButtonBase>
                  )}
                </TableCell>
                <TableCell>
                  <TruncatedId value={tag.id} full path={`/notifications/tag/${tag.id}`} />
                </TableCell>
                <TableCell>{tag.title}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                <FlexHorizontal>
                  <TextField
                    disabled={props.disabled}
                    label='Tag Name'
                    variant='outlined'
                    value={tagName}
                    onChange={e => setTagName(e.target.value)}
                  />
                  <Box width={14} />
                  <Button
                    disabled={props.disabled}
                    onClick={async () => {
                      if (props.disabled) {
                        return
                      }

                      const result = await createTag({
                        variables: {
                          input: {
                            title: tagName
                          }
                        }
                      })

                      if (result.data?.createNotificationTag.__typename === 'NotificationTagV2') {
                        setTagName('')
                        props.setTag(result.data.createNotificationTag.id)
                        await refetch()
                      }
                    }}
                    variant='outlined'
                    color='default'>
                    Create New Tag
                  </Button>
                </FlexHorizontal>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
