import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListNotificationSegments, ListNotificationSegmentsVariables } from './__generated__/ListNotificationSegments'

const QUERY = gql`
  query ListNotificationSegments($input: ListNotificationSegmentInputV2!) {
    listNotificationSegments: listNotificationSegmentsV2(input: $input) {
      nextCursor
      notificationSegments {
        id
        title
      }
    }
  }
`

export const useListNotificationSegments = (variables: ListNotificationSegmentsVariables) => {
  return useQuery<ListNotificationSegments, ListNotificationSegmentsVariables>(QUERY, {
    variables
  })
}

useListNotificationSegments.QUERY = QUERY
