import { gql } from 'apollo-boost'
import {
  ListCategoryColorTemplates,
  ListCategoryColorTemplatesVariables
} from './__generated__/ListCategoryColorTemplates'
import { useQuery } from '@apollo/react-hooks'

const LIST_UPCOMING_CATEGORY_COLOR_TEMPLATES = gql`
  query ListCategoryColorTemplates($id: String!, $page: Int, $perPage: Int) {
    colorTemplateCategory(id: $id) {
      id
      colorTemplates(page: $page, perPage: $perPage) {
        id
        sortOrder
        created
        category {
          id
          title
        }
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useListCategoryColorTemplates = (variables: ListCategoryColorTemplatesVariables) => {
  return useQuery<ListCategoryColorTemplates, ListCategoryColorTemplatesVariables>(
    LIST_UPCOMING_CATEGORY_COLOR_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
      variables
    }
  )
}
