import moment from 'moment'
import { useEffect, useState } from 'react'

interface IAnalyticsByDate {
  date: string
  messageSetCounts: {
    [key: string]: number
  }
  subscriptionsCount: number
  messageSetBlendCount: number
  averageBlendsPerSubscription: number
  defaultMessageSetIds: string[]
  medianBlendsPerSubscription: number
  mostBlendsPerSubscription: number
}

export const useGetBlendedDay = (searchKey?: string | null) => {
  const [data, setData] = useState<IAnalyticsByDate | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    fetch(`https://blended.vestaboard.com/api/analytics/date/${searchKey || moment().format('YYYY-MM-DD')}`, {})
      .then(res => res.json())
      .then(result => {
        setLoading(false)
        setData(result)
      })
      .catch(() => {
        setLoading(false)
        setData(null)
      })
  }, [searchKey])

  return { data, loading }
}
