import * as moment from 'moment'

import { Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import { DeviceQuery_device_latestHeartbeat_networks } from './__generated__/DeviceQuery'
interface IStyleProps {
  active: boolean
}

interface IConnectionCard {
  icon: React.ElementType
  title: string
  showSsid: boolean
  network?: DeviceQuery_device_latestHeartbeat_networks
  timeago: number
  deviceId: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      opacity: (props: IStyleProps) => (props.active ? 1 : 0.25)
    },
    strong: {
      fontWeight: 'bold',
      marginTop: 14
    },
    largeIcon: {
      marginBottom: 14,
      fontSize: 50,
      color: '#707070'
    },
    forgetNetworkButton: {
      marginTop: 24
    }
  })
)

export const ConnectionCard = (props: IConnectionCard) => {
  const [forgotten] = useState(false)
  const active = !!props?.network && !forgotten && props.network?.online === 'Online'
  const ipAddresses = props?.network?.ipAddress ? [props?.network?.ipAddress] : []

  const classes = useStyles({
    active
  })

  const Icon = props.icon

  return (
    <div className={classes.root}>
      <Icon fontSize='large' className={classes.largeIcon} />
      <Typography variant='h5'>{props.title}</Typography>
      {active && (
        <>
          <Typography className={classes.strong}>IP Address</Typography>
          {ipAddresses.map(ipAddress => (
            <Typography key={ipAddress}>{ipAddress}</Typography>
          ))}
          <Typography className={classes.strong}>As of</Typography>
          <Typography>{moment.duration(props.timeago, 'milliseconds').humanize()}</Typography>
          {props.showSsid && (
            <>
              <Typography className={classes.strong}>SSID</Typography>
              <Typography>{props?.network?.ssid}</Typography>
            </>
          )}
        </>
      )}
    </div>
  )
}
