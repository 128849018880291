import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetNotification, GetNotificationVariables } from './__generated__/GetNotification'

const QUERY = gql`
  query GetNotification($id: String!) {
    notification: notificationV2(id: $id) {
      ... on NotificationV2 {
        id
        title
        message
        isRead
        readAt
        deepLink
        callToAction
        tag {
          id
          title
        }
        personId
        createdAt
        scheduledNotification {
          id
          createdAt
        }
      }
      ... on NotificationErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetNotification = (id: string) =>
  useQuery<GetNotification, GetNotificationVariables>(QUERY, { variables: { id } })
