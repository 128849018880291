import { gql } from 'apollo-boost'
import { InstallablesListQuery } from './__generated__/InstallablesListQuery'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query InstallablesListQuery {
    publicInstallables {
      id
      created
      title
      description
      icon
    }
  }
`

export const useListPublicInstallables = () => {
  return useQuery<InstallablesListQuery>(QUERY)
}
