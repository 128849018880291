export const getReportStateFromHash = () => {
  const hash = window.location.hash.replace('#', '')
  const [rangeType, searchKey] = hash.split('|')
  return rangeType && searchKey
    ? {
        rangeType,
        searchKey
      }
    : null
}
