import { useQuery } from '@apollo/react-hooks'
import { CircularProgress, MenuItem, Select } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { gql } from 'apollo-boost'
import React, { useCallback, useState } from 'react'
import { useUpdateDevice } from '../../api'
import { DeviceModels } from './__generated__/DeviceModels'

interface IDeviceModel {
  deviceId: string
  id: string
}

const DEVICE_MODELS_QUERY = gql`
  query DeviceModels {
    deviceModels {
      id
      title
    }
  }
`

export const DeviceModel = (props: IDeviceModel) => {
  const [id, setId] = useState(props.id)
  const { loading, data, error } = useQuery<DeviceModels>(DEVICE_MODELS_QUERY)
  const [updateDeviceMutation] = useUpdateDevice()
  const { deviceId } = props

  const updateDevice = useCallback(
    async (deviceModel: string) => {
      setId(deviceModel)

      try {
        await updateDeviceMutation({
          variables: {
            input: {
              id: deviceId,
              deviceModel
            }
          }
        })
      } catch (e) {}
    },
    [updateDeviceMutation, deviceId]
  )

  return error ? (
    <Alert severity='error'>Unable to load device models</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Select
      variant='outlined'
      value={id}
      onChange={e => {
        updateDevice(e.target.value as string)
      }}>
      <MenuItem></MenuItem>
      {data.deviceModels.map(deviceModel => (
        <MenuItem key={deviceModel.id} value={deviceModel.id}>
          {deviceModel.title}
        </MenuItem>
      ))}
    </Select>
  )
}
