export const eventTypes = [
  {
    event: 'User created account',
    type: 'userEvent',
    error: false
  },
  {
    event: 'User paired board to device',
    type: 'userEvent',
    error: false
  },
  {
    event: 'User set wifi credentials',
    type: 'userEvent',
    error: false
  },
  {
    event: 'User experienced error',
    type: 'userEvent',
    error: true
  },
  {
    event: 'User sent message',
    type: 'userEvent',
    error: false
  },
  {
    event: 'User unpaired board from device',
    type: 'userEvent',
    error: false
  },
  {
    event: 'User factory provisioned hardware',
    type: 'userEvent',
    error: false
  },
  {
    event: 'Device was provisioned',
    type: 'deviceEvent',
    error: false
  },
  {
    event: 'Device displayed message',
    type: 'deviceEvent',
    error: false
  },
  {
    event: 'Device had error',
    type: 'deviceEvent',
    error: true
  },
  {
    event: 'Device detected mask removal',
    type: 'deviceEvent',
    error: false
  },
  {
    event: 'Device detected mask re-placement',
    type: 'deviceEvent',
    error: false
  },
  {
    event: 'Device experienced connectivity issue',
    type: 'deviceEvent',
    error: true
  }
]
