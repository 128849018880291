import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UpdateBoardMutation, UpdateBoardMutationVariables } from './__generated__/UpdateBoardMutation'

const UPDATE_BOARD_MUTATION = gql`
  mutation UpdateBoardMutation($input: UpdateBoardInput!) {
    updateBoard(input: $input) {
      board {
        id
        boardStyle
      }
    }
  }
`

export const useUpdateBoard = () => {
  return useMutation<UpdateBoardMutation, UpdateBoardMutationVariables>(UPDATE_BOARD_MUTATION)
}
