import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListBoardDeviceAssociations,
  ListBoardDeviceAssociationsVariables
} from './__generated__/ListBoardDeviceAssociations'

export const QUERY = gql`
  query ListBoardDeviceAssociations($input: BoardDeviceAssociationInput!) {
    listBoardDeviceAssociations(input: $input) {
      boardDeviceAssociations {
        id
        boardDeviceAssociationCreated
        deviceId
        deviceCreated
        pairingCode
        firmwareVersion
        boardId
        boardTitle
        boardCreated
        boardDeleted
        personId
        personFirstName
        personLastName
        accountId
        accountEmailAddress
        productApplicationId
        productApplicationCreated
        productPricingId
        productPricingRecurringFrequency
        ssid
        online
        lastMessage
        messagesInPastDay
        messagesInPastSevenDays
        messagesInPastThirtyDays
        messagesAllTime
        deviceLastSeen
        yoctoStatus
        deviceModelId
        deviceModelTitle
        lastIndexed
        memberEmailAddresses
        enabledChannels
        channels
        internalBoard
        hubspotDealId
        memberIds
        osVersionName
        osVersionId
        osVersionBuildId
      }
      pagination {
        page
        total
        perPage
      }
    }
  }
`

export const useBoardDeviceAssociations = (variables: ListBoardDeviceAssociationsVariables) =>
  useQuery<ListBoardDeviceAssociations, ListBoardDeviceAssociationsVariables>(QUERY, {
    variables
  })
