import { useQuery } from '@apollo/react-hooks'
import { GetPick, GetPickVariables } from './__generated__/GetPick'
import { gql } from 'apollo-boost'

const GET_PICK = gql`
  query GetPick($id: String!) {
    pick(id: $id) {
      id
      categories {
        id
        title
      }
      recommendAfter
      recommendBefore
      message {
        id
        formatted
      }
    }
  }
`

export const useGetPick = (id: string) => {
  return useQuery<GetPick, GetPickVariables>(GET_PICK, {
    variables: {
      id
    }
  })
}
