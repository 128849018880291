import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'

interface IChannels {
  enabledChannels: number
  channels: number
}

const useTooltipStyles = makeStyles({
  root: {
    backgroundColor: '#000',
    padding: 14
  }
})

export const CustomTooltip = ({ active, payload }: any) => {
  const classes = useTooltipStyles()

  if (active && payload && payload.length) {
    return (
      <div className={classes.root}>
        <Typography>{`${payload[0].value.toLocaleString()} Channels`}</Typography>
      </div>
    )
  }

  return null
}

export const Channels = (props: IChannels) => {
  return (
    <BarChart
      width={1240}
      height={200}
      layout='vertical'
      data={[
        {
          name: 'Enabled',
          value: props.enabledChannels,
          fill: '#323232'
        },
        {
          name: 'Installed',
          value: props.channels,
          fill: '#444'
        }
      ]}>
      <YAxis dataKey='name' type='category' />
      <XAxis type='number' />
      <Bar dataKey='value' fill='#323232' />
      <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} content={CustomTooltip} />
    </BarChart>
  )
}
