import React from 'react'
import { Route, Switch } from 'react-router'
import { UpdateMarketplaceListings } from './pages/Update'
import { MarketplaceListingsList } from './pages/List'

export const MarketplaceListingRouter = () => (
  <Switch>
    <Route exact path='/marketplace-listings' component={MarketplaceListingsList} />
    <Route exact path='/marketplace-listings/:id' component={UpdateMarketplaceListings} />
  </Switch>
)
