import { Typography, Link as MuiLink } from '@material-ui/core'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import React from 'react'
import { ScheduledNotificationForm } from '../components/ScheduledNotificationForm'
import { useCreateScheduledNotification } from '../hooks/useCreateScheduledNotification'
import { useListScheduledNotifications } from '../hooks/useListScheduledNotifications'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

export const CreateScheduledNotification = () => {
  const [create] = useCreateScheduledNotification()
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Notification</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <ScheduledNotificationForm
        onSubmit={async input => {
          const result = await create({
            variables: {
              input
            },
            refetchQueries: [
              {
                query: useListScheduledNotifications.QUERY,
                variables: {
                  input: {
                    cursor: null,
                    limit: 25
                  }
                }
              }
            ]
          })

          if (result.errors) {
            addToast('There was an error creating the notification', {
              appearance: 'error'
            })
            return
          }

          if (result.data?.createScheduledNotificationV2.__typename === 'ScheduledNotificationErrorV2') {
            addToast(
              result.data.createScheduledNotificationV2.error || 'There was an error creating the notification',
              {
                appearance: 'error'
              }
            )
            return
          } else {
            history.push('/notifications')
          }
        }}
      />
    </>
  )
}
