import { gql } from 'apollo-boost'
import { ListPicks, ListPicksVariables } from './__generated__/ListPicks'
import { useQuery } from '@apollo/react-hooks'

const LIST_PICKS = gql`
  query ListPicks($page: Int, $perPage: Int) {
    picksLength
    picks(page: $page, perPage: $perPage) {
      id
      recommendBefore
      recommendAfter
      categories {
        id
        title
      }
      message {
        id
        formatted
      }
    }
  }
`

export const useListPicks = (variables: ListPicksVariables) => {
  return useQuery<ListPicks, ListPicksVariables>(LIST_PICKS, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
