import React from 'react'
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { ISubscriber, SubscriberSelector } from './SubscriberSelector'
import { TagSelector } from './TagSelector'
import * as Yup from 'yup'
import { Alert } from '@material-ui/lab'
import { DeepLink } from './DeepLink'
import { useHistory } from 'react-router'
import { SegmentSelector } from './SegmentSelector'

interface IForm {
  subscriberType: string
  schedule: string
  tagId: string
  subscribers: ISubscriber[]
  sendAt: string
  message: string
  title: string
  deepLink: string
  callToAction: string
  segmentId: string
}

interface ISubmitValues {
  tagId?: string
  personIds?: string[]
  sendAt?: number
  message: string
  title: string
  deepLink?: string
  callToAction?: string
  segmentId?: string
}

interface IScheduledNotificationForm {
  disabled?: boolean
  data?: IForm
  onSubmit: (values: ISubmitValues) => void
}

const FormSchema = Yup.object().shape({
  message: Yup.string().required('You must enter a message'),
  title: Yup.string().required('You must enter a title'),
  deepLink: Yup.string().when('callToAction', {
    is: (callToAction: string) => !!callToAction,
    then: Yup.string().required('You must enter and set a deep link')
  }),
  callToAction: Yup.string().optional(),
  schedule: Yup.string().required('You must select a schedule type'),
  subscriberType: Yup.string().required('You must select a target type'),
  sendAt: Yup.string().when('schedule', {
    is: 'later',
    then: schema => {
      return schema.required('You must select a date and time to send the notification')
    }
  }),
  tagId: Yup.string().when('subscriberType', {
    is: 'tag',
    then: schema => {
      return schema.required('You must select a tag')
    }
  }),
  segmentId: Yup.string().when('subscriberType', {
    is: 'segment',
    then: schema => {
      return schema.required('You must select a segment')
    }
  }),
  subscribers: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      title: Yup.string().required()
    })
  ).when('subscriberType', {
    is: 'persons',
    then: schema => {
      return schema.min(1, 'You must select at least one subscriber')
    }
  })
})

export const ScheduledNotificationForm = (props: IScheduledNotificationForm) => {
  const initialValues: IForm = props.data || {
    subscriberType: '',
    schedule: '',
    tagId: '',
    subscribers: [],
    sendAt: '',
    message: '',
    title: '',
    deepLink: '',
    callToAction: '',
    segmentId: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: values => {
      props.onSubmit({
        tagId: values.subscriberType === 'tag' ? values.tagId : undefined,
        personIds:
          values.subscriberType === 'persons' ? values.subscribers.map(subscriber => subscriber.id) : undefined,
        sendAt: values.schedule === 'later' ? new Date(values.sendAt).getTime() : undefined,
        message: values.message,
        title: values.title,
        deepLink: values.deepLink || undefined,
        callToAction: values.callToAction || undefined,
        segmentId: values.subscriberType === 'segment' ? values.segmentId : undefined
      })
    }
  })

  const history = useHistory()

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant='h5'>Target</Typography>
      <Spacer size='medium' />
      <TableContainer component={Paper} style={{ maxWidth: 850 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: 200
                }}>
                <Typography>Target Type</Typography>
              </TableCell>
              <TableCell>
                <Select
                  disabled={props.disabled}
                  fullWidth
                  error={formik.errors.subscriberType ? true : false}
                  variant='outlined'
                  id='subscriberType'
                  name='subscriberType'
                  onChange={formik.handleChange}
                  value={formik.values.subscriberType}>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='tag'>Notification Tag</MenuItem>
                  <MenuItem value='persons'>Individual Subscribers</MenuItem>
                  <MenuItem value='segment'>Segment</MenuItem>
                </Select>
                {formik.submitCount && formik.errors.subscriberType ? (
                  <>
                    <Spacer size='small' />
                    <Alert severity='error'>{formik.errors.subscriberType}</Alert>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
            {formik.values.subscriberType === 'persons' ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <SubscriberSelector
                    disabled={props.disabled}
                    subscribers={formik.values.subscribers}
                    setSubscribers={subscribers => formik.setFieldValue('subscribers', subscribers)}
                  />
                  {formik.submitCount && formik.errors.subscribers ? (
                    <>
                      <Spacer size='small' />
                      <Alert severity='error'>{formik.errors.subscribers}</Alert>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
            {formik.values.subscriberType === 'tag' ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TagSelector
                    tag={formik.values.tagId}
                    setTag={tag => formik.setFieldValue('tagId', tag)}
                    disabled={props.disabled}
                  />
                  {formik.submitCount && formik.errors.tagId ? (
                    <>
                      <Spacer size='small' />
                      <Alert severity='error'>{formik.errors.tagId}</Alert>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
            {formik.values.subscriberType === 'segment' ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <SegmentSelector
                    segment={formik.values.segmentId}
                    setSegment={segment => formik.setFieldValue('segmentId', segment)}
                    disabled={props.disabled}
                  />
                  {formik.submitCount && formik.errors.segmentId ? (
                    <>
                      <Spacer size='small' />
                      <Alert severity='error'>{formik.errors.segmentId}</Alert>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
      <Typography variant='h5'>Schedule</Typography>
      <Spacer size='medium' />
      <TableContainer component={Paper} style={{ maxWidth: 850 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: 200
                }}>
                <Typography>Type</Typography>
              </TableCell>
              <TableCell>
                <Select
                  disabled={props.disabled}
                  fullWidth
                  variant='outlined'
                  id='schedule'
                  name='schedule'
                  onChange={formik.handleChange}
                  value={formik.values.schedule}>
                  <MenuItem value=''>Select</MenuItem>
                  <MenuItem value='now'>Send Now</MenuItem>
                  <MenuItem value='later'>Send Later</MenuItem>
                </Select>
                {formik.submitCount && formik.errors.schedule ? (
                  <>
                    <Spacer size='small' />
                    <Alert severity='error'>{formik.errors.schedule}</Alert>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
            {formik.values.schedule === 'later' ? (
              <TableRow>
                <TableCell>Send At</TableCell>
                <TableCell>
                  <TextField
                    disabled={props.disabled}
                    fullWidth
                    variant='outlined'
                    type='datetime-local'
                    value={formik.values.sendAt || ''}
                    onChange={e => {
                      formik.setFieldValue('sendAt', e.target.value)
                    }}
                  />
                  {formik.submitCount && formik.errors.sendAt ? (
                    <>
                      <Spacer size='small' />
                      <Alert severity='error'>{formik.errors.sendAt}</Alert>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
      <Typography variant='h5'>Message</Typography>
      <Spacer size='medium' />
      <TableContainer component={Paper} style={{ maxWidth: 850 }}>
        <Table>
          <TableRow>
            <TableCell
              style={{
                width: 200
              }}>
              <Typography>Title</Typography>
            </TableCell>
            <TableCell>
              <TextField
                disabled={props.disabled}
                fullWidth
                variant='outlined'
                id='title'
                name='title'
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {formik.submitCount && formik.errors.title ? (
                <>
                  <Spacer size='small' />
                  <Alert severity='error'>{formik.errors.title}</Alert>
                </>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Message</Typography>
            </TableCell>
            <TableCell>
              <TextField
                disabled={props.disabled}
                fullWidth
                variant='outlined'
                id='message'
                name='message'
                onChange={formik.handleChange}
                value={formik.values.message}
                minRows={3}
                multiline
              />
              {formik.submitCount && formik.errors.message ? (
                <>
                  <Spacer size='small' />
                  <Alert severity='error'>{formik.errors.message}</Alert>
                </>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Call to Action</Typography>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                disabled={props.disabled}
                variant='outlined'
                id='callToAction'
                name='callToAction'
                onChange={formik.handleChange}
                value={formik.values.callToAction}
              />
              {formik.submitCount && formik.errors.callToAction ? (
                <>
                  <Spacer size='small' />
                  <Alert severity='error'>{formik.errors.callToAction}</Alert>
                </>
              ) : null}
            </TableCell>
          </TableRow>
          {formik.values.callToAction ? (
            <TableRow>
              <TableCell>
                <Typography>Deep Link</Typography>
              </TableCell>
              <TableCell>
                <DeepLink
                  disabled={props.disabled}
                  value={formik.values.deepLink}
                  onChange={deepLink => formik.setFieldValue('deepLink', deepLink)}
                />
                {formik.submitCount && formik.errors.deepLink ? (
                  <>
                    <Spacer size='small' />
                    <Alert severity='error'>{formik.errors.deepLink}</Alert>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
          ) : null}
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <Button
          variant='contained'
          size='large'
          color='default'
          onClick={() => {
            history.push('/notifications')
          }}>
          Cancel
        </Button>
        <Box width={14} />
        <Button type='submit' variant='contained' size='large' color='primary' disabled={props.disabled}>
          {formik.values.schedule === 'now' ? 'Send Now' : 'Schedule'}
        </Button>
      </FlexHorizontal>
    </form>
  )
}
