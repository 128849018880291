import { useMutation } from '@apollo/react-hooks'
import {
  AddMarketplaceListingFeature,
  AddMarketplaceListingFeatureVariables
} from './__generated__/AddMarketplaceListingFeature'
import { gql } from 'apollo-boost'

const MUTATION = gql`
  mutation AddMarketplaceListingFeature($input: AddMarketplaceListingFeatureInput!) {
    addMarketplaceListingFeature(input: $input) {
      id
      marketplaceListing {
        id
        featureTypes {
          id
          marketplaceListingFeatureType {
            id
          }
        }
      }
    }
  }
`

export const useAddMarketplaceListingFeature = () =>
  useMutation<AddMarketplaceListingFeature, AddMarketplaceListingFeatureVariables>(MUTATION)
