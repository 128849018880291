import React, { useCallback, useState } from 'react'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import {
  Box,
  Button,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import { DragIndicator, RemoveCircle } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { MessageCreator } from './MessageCreator'

interface IIntroOutro {
  title: string
  messages: number[][][]
  setMessages: (messages: number[][][]) => void
}

export const IntroOutro = (props: IIntroOutro) => {
  const [isAddingIntro, setIsAddingIntro] = useState(false)
  const [editingIntro, setEditingIntro] = useState<{
    index: number
    value: IBoard
  } | null>(null)

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newIntro = Array.from(props.messages)
      const [removed] = newIntro.splice(startIndex, 1)
      newIntro.splice(endIndex, 0, removed)
      props.setMessages(newIntro)
    },
    [props]
  )

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h5'>{props.title}</Typography>
        <Button variant='outlined' onClick={() => setIsAddingIntro(true)}>
          Add Intro Message
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      {props.messages.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableBody component={DroppableTableBody(onDragEnd)}>
              {props.messages.map((message, index) => (
                <TableRow key={index} component={DraggableTableRow(JSON.stringify(message), index)}>
                  <TableCell style={{ width: 80 }}>
                    <DragIndicator />
                  </TableCell>
                  <TableCell>
                    <ButtonBase
                      onClick={() => {
                        setEditingIntro({
                          index,
                          value: message as IBoard
                        })
                      }}>
                      <Box width={250}>
                        <BoardPreview characters={message as IBoard} />
                      </Box>
                    </ButtonBase>
                  </TableCell>
                  <TableCell style={{ width: 70 }}>
                    <FlexHorizontal>
                      <ButtonBase
                        onClick={() => {
                          const newIntro = [...props.messages]
                          newIntro.splice(index, 1)
                          props.setMessages(newIntro)
                        }}>
                        <RemoveCircle color='action' />
                      </ButtonBase>
                    </FlexHorizontal>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity='info'>There are no {props.title} messages added to this Q&A set yet.</Alert>
      )}
      <MessageCreator
        visible={isAddingIntro}
        setVisible={setIsAddingIntro}
        onSave={message => {
          props.setMessages([...props.messages, message])
        }}
      />
      {editingIntro ? (
        <MessageCreator
          visible={!!editingIntro}
          setVisible={() => setEditingIntro(null)}
          defaultValue={editingIntro?.value}
          onSave={message => {
            const newIntro = [...props.messages]
            newIntro[editingIntro!.index] = message
            props.setMessages(newIntro)
            setEditingIntro(null)
          }}
        />
      ) : null}
    </>
  )
}
