import { GetColorTemplateV2, GetColorTemplateV2Variables } from './__generated__/GetColorTemplateV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetColorTemplateV2($id: String!) {
    colorTemplate: colorTemplateV2(id: $id) {
      __typename
      ... on ColorTemplateV2 {
        id
        title
        messages {
          id
          text
          characters
        }
      }
      ... on ColorTemplateErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetColorTemplate = (id: string) => {
  return useQuery<GetColorTemplateV2, GetColorTemplateV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id
    }
  })
}
