import React from 'react'
import { useListNotificationSegments } from '../hooks/useListNotificationSegments'
import {
  ButtonBase,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import { TruncatedId } from '../../truncated-id'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'

interface ISegmentSelector {
  disabled?: boolean
  segment: string
  setSegment: (segment: string) => void
}

export const SegmentSelector = (props: ISegmentSelector) => {
  const { data, loading } = useListNotificationSegments({
    input: {
      limit: 100
    }
  })

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {(data?.listNotificationSegments?.notificationSegments || []).map(segment => (
              <TableRow key={segment.id}>
                <TableCell>
                  {segment.id === props.segment ? (
                    <ButtonBase
                      disabled={props.disabled}
                      onClick={() => {
                        if (props.disabled) {
                          return
                        }

                        props.setSegment('')
                      }}>
                      <RadioButtonChecked />
                    </ButtonBase>
                  ) : (
                    <ButtonBase disabled={props.disabled} onClick={() => props.setSegment(segment.id)}>
                      <RadioButtonUnchecked />
                    </ButtonBase>
                  )}
                </TableCell>
                <TableCell>
                  <TruncatedId value={segment.id} full path={`/notifications/segments/${segment.id}`} />
                </TableCell>
                <TableCell>{segment.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
