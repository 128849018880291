import React, { useState } from 'react'
import { useListMarketplaceListingsV2 } from '../../marketplace-listings/hooks/useListMarketplaceListingsV2'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

interface IMarketplaceSelector {
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}

export const MarketplaceSelector = (props: IMarketplaceSelector) => {
  const [terms, setTerms] = useState('')
  const { data } = useListMarketplaceListingsV2({
    input: {
      terms
    }
  })

  const options = (data?.listMarketplaceListingsV2.marketplaceListings || [])
    .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
    .map(marketplaceListing => ({
      id: marketplaceListing.id,
      title: marketplaceListing.title || ''
    }))

  return (
    <>
      <Autocomplete
        disablePortal
        options={options}
        fullWidth
        getOptionLabel={option => option.title}
        value={options.find(option => option.id === props.value) || null}
        onChange={(event, newValue) => {
          if (newValue) {
            props.onChange(newValue.id)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            value={terms}
            onChange={e => setTerms(e.target.value)}
            disabled={props.disabled}
            label='Search Listings'
          />
        )}
      />
    </>
  )
}
