import React from 'react'
import {
  ButtonBase,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { BoardPreview, IBoard } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { useHistory, useLocation } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { useListColorTemplates } from '../hooks/useListColorTemplate'

const useStyles = makeStyles({
  chips: {
    '& > *': {
      margin: 3
    }
  }
})

export const ColorTemplates = () => {
  const perPage = 25
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page') || '0')

  const { data, loading, error } = useListColorTemplates({
    page,
    perPage
  })

  const colorTemplatesLength = data?.colorTemplatesLength || 0

  return error ? (
    <Alert severity='error'>There was an error loading the color templates</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Color Template ID</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.colorTemplates.map(colorTemplate => (
            <TableRow key={colorTemplate.id}>
              <TableCell>
                <TruncatedId value={colorTemplate.id} path={`/color-templates/${colorTemplate.id}`} />
              </TableCell>
              <TableCell>
                <FormattedDateTime format='MM/DD/YYYY hh:mm a' value={colorTemplate.created} />
              </TableCell>
              <TableCell className={classes.chips}>
                {colorTemplate?.category?.id ? (
                  <ButtonBase
                    key={colorTemplate.category.id}
                    onClick={() => {
                      history.push(`/color-templates/categories/${colorTemplate?.category?.id}`)
                    }}>
                    {<Chip label={colorTemplate.category.title} variant='outlined' />}
                  </ButtonBase>
                ) : null}
              </TableCell>
              <TableCell>
                <Link to={`/color-templates/${colorTemplate.id}`}>
                  {colorTemplate.message.formatted ? (
                    <BoardPreview characters={colorTemplate.message.formatted as IBoard} />
                  ) : null}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {colorTemplatesLength ? (
        <TablePagination
          rowsPerPageOptions={[]}
          count={colorTemplatesLength}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => history.push(`/color-templates?tab=ColorTemplates&page=${page}`)}
          onChangeRowsPerPage={() => {}}
        />
      ) : null}
    </Card>
  )
}
