import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { UnModerateFeedItemV2, UnModerateFeedItemV2Variables } from './__generated__/UnModerateFeedItemV2'

const MUTATION = gql`
  mutation UnModerateFeedItemV2($input: UnModerateFeedItemInputV2!) {
    unModerateFeedItem: unModerateFeedItemV2(input: $input) {
      __typename
      ... on FeedItemV2 {
        id
        moderated
      }
      ... on FeedItemErrorV2 {
        type
        error
      }
    }
  }
`

export const useUnModerate = () => {
  return useMutation<UnModerateFeedItemV2, UnModerateFeedItemV2Variables>(MUTATION)
}
