import * as React from 'react'
import { useParams } from 'react-router'
import { Box } from '@material-ui/core'
import { BoardDetail } from '../components/BoardDetail'

type BoardPageProps = IBoardPageProps

interface IBoardPageProps {}

export const BoardPage: React.FC<BoardPageProps> = props => {
  const { id } = useParams<{ id: string }>()

  if (!id) throw Error()

  return (
    <Box>
      <BoardDetail boardId={id} />
    </Box>
  )
}
