import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  chart: {
    height: 350,
    padding: 24,
    marginBottom: 80,
    width: 'calc(100% - 48px)'
  },
  title: {
    color: '#FFF',
    fontSize: 24,
    padding: 24,
    fontWeight: 500
  }
})

interface IChartContainer {
  title: string
  children: React.ReactNode
  exportButton?: React.ReactNode
}

export const ChartContainer = (props: IChartContainer) => {
  const classes = useStyles()

  return (
    <Box className={classes.chart}>
      <Typography className={classes.title}>
        {props.title}
        {props.exportButton || null}
      </Typography>
      {props.children}
    </Box>
  )
}
