import {
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import { Alert } from '@material-ui/lab'
import React from 'react'
import { Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../../truncated-id'
import { useGetDigitalDevice } from '../../digital-devices/hooks'

interface IDigitalDevice {
  id?: string
}

export const DigitalDevice = (props: IDigitalDevice) => {
  const { data, loading, error } = useGetDigitalDevice({ id: props.id })

  return (
    <>
      <Spacer size='extraLarge' />
      <Grid container>
        <Grid item md={12}>
          <Typography variant='h5' style={{ paddingBottom: 14 }}>
            Digital Devices
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : error || data?.board?.__typename === 'BoardErrorV2' || !data?.board?.digitalDevice?.id ? (
            <Alert severity='info'>There are no Digital Devices associated with this board.</Alert>
          ) : (
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Device Type</TableCell>
                    <TableCell>Device Style</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TruncatedId
                        value={data.board.digitalDevice.id}
                        path={`/digital-devices/${data.board.digitalDevice.id}`}
                        full
                      />
                    </TableCell>
                    <TableCell>{data.board.digitalDevice.type}</TableCell>
                    <TableCell>{data.board.digitalDevice.digitalDeviceStyle.title}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  )
}
