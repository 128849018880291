import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  GetMessageSetSubmissionV2,
  GetMessageSetSubmissionV2Variables
} from './__generated__/GetMessageSetSubmissionV2'

const QUERY = gql`
  query GetMessageSetSubmissionV2($id: String!) {
    submittedMessageSetV2(id: $id) {
      ... on SubmittedMessageSetV2 {
        id
        personId
        title
        createdAt
        personName
        description
        messages {
          id
          characters
          text
        }
      }
    }
  }
`

export const useGetMessageSetSubmission = (id: string) =>
  useQuery<GetMessageSetSubmissionV2, GetMessageSetSubmissionV2Variables>(QUERY, { variables: { id } })
