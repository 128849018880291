import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateDeveloperDetailMutation,
  UpdateDeveloperDetailMutationVariables
} from './__generated__/UpdateDeveloperDetailMutation'

const MUTATION = gql`
  mutation UpdateDeveloperDetailMutation($id: String!, $title: String!) {
    updateDeveloper(input: { id: $id, title: $title }) {
      id
      title
    }
  }
`

export const useUpdateDeveloper = () =>
  useMutation<UpdateDeveloperDetailMutation, UpdateDeveloperDetailMutationVariables>(MUTATION)
