import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetNotificationSegment, GetNotificationSegmentVariables } from './__generated__/GetNotificationSegment'

const QUERY = gql`
  query GetNotificationSegment($id: String!) {
    notificationSegment: notificationSegmentV2(id: $id) {
      ... on NotificationSegmentV2 {
        id
        title
        subscribers {
          id
          title
        }
      }
    }
  }
`

export const useGetNotificationSegment = (id: string) => {
  return useQuery<GetNotificationSegment, GetNotificationSegmentVariables>(QUERY, {
    variables: {
      id
    }
  })
}
