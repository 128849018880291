import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeleteInstallation, DeleteInstallationVariables } from './__generated__/DeleteInstallation'

const MUTATION = gql`
  mutation DeleteInstallation($installationId: String!) {
    deleteInstallation(input: { id: $installationId }) {
      deleted
    }
  }
`

export const useDeleteInstallation = () => {
  return useMutation<DeleteInstallation, DeleteInstallationVariables>(MUTATION)
}
