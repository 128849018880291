import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { Box, Card, CircularProgress, Tab, Tabs, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import { ListFeedAppearances } from '../components/ListFeedAppearances'
import { ListMessageAppearances } from '../components/ListMessageAppearances'
import { ListMessageFavorites } from '../components/ListMessageFavorites'
import { ListMessageFlags } from '../components/ListMessageFlags'
import { MessageTags } from '../components/MessageTags'
import { useGetMessage } from '../hooks/useGetMessage'
import { useParams } from 'react-router'

const useStyles = makeStyles({
  cardInner: {
    padding: 20
  }
})

interface IGetMessage {
  messageId?: string
}

export const GetMessage = (props: IGetMessage) => {
  const classes = useStyles()
  const params = useParams<{ id: string }>()
  const id = props?.messageId || params.id
  const { data, loading, error } = useGetMessage(id)
  const [tab, setTab] = useState(0)

  return error ? (
    <Alert severity='error'>There was an error loading the message</Alert>
  ) : loading ? (
    <CircularProgress />
  ) : data?.message.__typename === 'MessageErrorV2' ? (
    <Alert severity='error'>{data.message.error}</Alert>
  ) : (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <Box className={classes.cardInner}>
          <TextField value={data?.message.id} disabled label='ID' variant='outlined' fullWidth />
          <Spacer size='large' />
          <TextField value={data?.message.text} disabled label='Text' variant='outlined' fullWidth />
          <Spacer size='large' />
          <Box width={400}>
            <BoardPreview characters={data?.message?.characters as IBoard} />
          </Box>
          <MessageTags tags={data?.message?.tags || []} messageId={id} />
        </Box>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween>
        <Tabs
          value={tab}
          onChange={(_event, newValue) => {
            setTab(newValue)
          }}>
          <Tab label='Feed Appearances' id='feed' key='feed' />
          <Tab label='Board Appearances' id='board' key='board' />
          <Tab label='Favorites' id='favorite' key='favorite' />
          <Tab label='Flags' id='flag' key='flag' />
        </Tabs>
      </FlexHorizontal>
      <Spacer size='large' />
      {tab === 0 ? (
        <ListFeedAppearances messageId={id} />
      ) : tab === 1 ? (
        <ListMessageAppearances messageId={id} />
      ) : tab === 2 ? (
        <ListMessageFavorites messageId={id} />
      ) : (
        <ListMessageFlags messageId={id} />
      )}
    </>
  )
}
