import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useHistory, useParams } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  GetMessageAppearanceQuery,
  GetMessageAppearanceQueryVariables
} from './__generated__/GetMessageAppearanceQuery'

const QUERY = gql`
  query GetMessageAppearanceQuery($id: String!) {
    messageAppearance(id: $id) {
      id
      message {
        id
      }
    }
  }
`

export const MessageAppearance = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<GetMessageAppearanceQuery, GetMessageAppearanceQueryVariables>(QUERY, {
    variables: { id }
  })

  useEffect(() => {
    if (data?.messageAppearance?.message?.id) {
      history.push(`/message/${data?.messageAppearance?.message?.id}`)
    }
  }, [data, history])

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Message appearance not found.</Alert>
  }

  return null
}
