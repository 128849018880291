import React from 'react'
import { Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { useListPickCategories } from '../hooks/useListPickCategories'

export const Categories = () => {
  const { data, error, loading } = useListPickCategories()

  return error ? (
    <Alert severity='error'>There was an error loading the pick categories</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell># Of Picks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.picksCategories
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(category => (
              <TableRow key={category.id}>
                <TableCell>
                  <TruncatedId value={category.id} path={`/picks/categories/${category.id}`} />
                </TableCell>
                <TableCell>{category.title}</TableCell>
                <TableCell>{category.picksLength}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  )
}
