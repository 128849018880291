import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ListTags, ListTagsVariables } from './__generated__/ListTags'

const QUERY = gql`
  query ListTags($input: ListNotificationTagInputV2!) {
    listNotificationTags: listNotificationTagsV2(input: $input) {
      nextCursor
      notificationTags {
        id
        title
      }
    }
  }
`

export const useListTags = (variables: ListTagsVariables) =>
  useQuery<ListTags, ListTagsVariables>(QUERY, {
    variables
  })
