import { useQuery } from '@apollo/react-hooks'
import { GetColorTemplate, GetColorTemplateVariables } from './__generated__/GetColorTemplate'
import { gql } from 'apollo-boost'

const GET_COLOR_TEMPLATE = gql`
  query GetColorTemplate($id: String!) {
    colorTemplate(id: $id) {
      id
      category {
        id
        title
      }
      created
      message {
        id
        formatted
      }
    }
  }
`

export const useGetColorTemplate = (id: string) => {
  return useQuery<GetColorTemplate, GetColorTemplateVariables>(GET_COLOR_TEMPLATE, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  })
}
