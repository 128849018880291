import * as React from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { Confirm, FlexHorizontal, Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { useListPublicInstallables } from '../hooks/useListPublicInstallable'
import { useDeleteInstallable } from '../hooks/useDeleteInstallable'

type InstallablesListProps = IInstallablesListProps

interface IInstallablesListProps {}

const InstallablesTable: React.FC<InstallablesListProps> = props => {
  const perPage = 20
  const { data, loading, refetch } = useListPublicInstallables()
  const [isDeleting, setIsDeleting] = React.useState<string | null>(null)
  const [deleteInstallableMutation] = useDeleteInstallable()
  const [page, setPage] = React.useState(0)

  const visibleInstallables = React.useMemo(() => {
    const startIndex = perPage * page
    const endIndex = page * perPage + perPage
    return (data?.publicInstallables || []).slice(startIndex, endIndex)
  }, [data, page])

  const handleDelete = async (id: string) => {
    await deleteInstallableMutation({ variables: { id } })
    await refetch()
  }

  if (!data || loading) return <CircularProgress />

  return (
    <Box>
      <Confirm
        title='Delete Installable?'
        message='Are you sure you want to delete this installable? Chose wisely!'
        open={!!isDeleting}
        handleClose={() => setIsDeleting(null)}
        handleAccept={() => {
          if (isDeleting) {
            handleDelete(isDeleting)
          }
          setIsDeleting(null)
        }}
      />
      <Table>
        <TableRow>
          <TableCell style={{ width: 70 }}></TableCell>
          <TableCell>ID</TableCell>
          <TableCell style={{ width: 120 }}>Created</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Description</TableCell>
          <TableCell style={{ width: 120 }} />
        </TableRow>
        {visibleInstallables.map(installable => (
          <TableRow>
            <TableCell>
              {installable.icon ? (
                <Avatar src={installable.icon} />
              ) : (
                <Avatar>{installable.title?.split('')[0]}</Avatar>
              )}
            </TableCell>
            <TableCell>
              <TruncatedId value={installable.id} path={`/installables/${installable.id}`} />
            </TableCell>
            <TableCell>
              {installable.created ? <FormattedDateTime value={+installable.created} format='MMM DD, YYYY' /> : null}
            </TableCell>
            <TableCell>{installable.title}</TableCell>
            <TableCell>{installable.description}</TableCell>
            <TableCell>
              <Button variant='outlined' onClick={() => setIsDeleting(installable.id)}>
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {data.publicInstallables?.length ? (
        <TablePagination
          rowsPerPageOptions={[]}
          count={data.publicInstallables?.length}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => {
            setPage(page)
          }}
          onChangeRowsPerPage={() => {}}
        />
      ) : null}
    </Box>
  )
}

export const InstallablesList: React.FC<InstallablesListProps> = props => {
  const history = useHistory()
  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Installables</Typography>
        <Button variant='contained' color='primary' onClick={() => history.push('/new-installable')}>
          Create new
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <InstallablesTable />
      </Card>
    </Box>
  )
}
