import React, { useMemo, useState } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { MessageSetListQuery } from './__generated__/MessageSetListQuery'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { TruncatedId } from '../../truncated-id'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import CheckIcon from '@material-ui/icons/Check'
import { SearchInput } from '../../../ui/SearchInput'

type MessageSetListContainerProps = IMessageSetListContainerProps

interface IMessageSetListContainerProps {}

const QUERY = gql`
  query MessageSetListQuery {
    messageSets(all: true) {
      id
      title
      public
      migrated
      count
      dynamic
    }
  }
`

const useMessageSetsQuery = () => useQuery<MessageSetListQuery>(QUERY, { fetchPolicy: 'network-only' })

export const MessageSetListContainer: React.FC<MessageSetListContainerProps> = props => {
  const perPage = 30
  const messageSets = useMessageSetsQuery()
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  const filteredMessageSets = useMemo(() => {
    return (messageSets.data?.messageSets || []).filter(
      messageSet =>
        messageSet.id.includes(search) ||
        (messageSet.title || '').toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [messageSets, search])

  const visibleMessageSets = useMemo(() => {
    const startIndex = perPage * page
    const endIndex = page * perPage + perPage
    return filteredMessageSets.slice(startIndex, endIndex)
  }, [filteredMessageSets, page])

  if (messageSets.loading || !messageSets.data) return <CircularProgress />

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message Sets</Typography>
        <Button variant='contained' color='primary' onClick={() => history.push('/message-sets/create')}>
          Create new
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      <FlexHorizontal spaceBetween>
        <Tabs
          value={0}
          onChange={(_event, newValue) => {
            if (newValue === 1) {
              history.push(`/message-sets/submissions`)
            }
          }}>
          <Tab label='Message Sets' id='message-sets' />
          <Tab label='Message Set Submissions' id='message-sets/submissions' />
        </Tabs>
        <SearchInput onSearch={setSearch} />
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: 50
                }}>
                ID
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell style={{ width: 50 }}>Public</TableCell>
              <TableCell style={{ width: 50 }}>Dynamic</TableCell>
              <TableCell style={{ width: 50 }}>Messages</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(visibleMessageSets || []).map(messageSet => (
              <TableRow key={messageSet.id}>
                <TableCell>
                  <Button
                    onClick={() => {
                      history.push(`message-sets/${messageSet.id}`)
                    }}
                    variant='outlined'
                    size='small'
                    color='primary'>
                    <TruncatedId value={messageSet.id} />
                  </Button>
                </TableCell>
                <TableCell>{messageSet.title}</TableCell>
                <TableCell>{messageSet.public ? <CheckIcon color={'action'} /> : null}</TableCell>
                <TableCell>{messageSet.dynamic ? <CheckIcon color={'action'} /> : null}</TableCell>
                <TableCell>{messageSet.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {filteredMessageSets?.length ? (
          <TablePagination
            rowsPerPageOptions={[]}
            count={filteredMessageSets?.length}
            rowsPerPage={perPage}
            page={page}
            onPageChange={(_, page) => {
              setPage(page)
            }}
            onChangeRowsPerPage={() => {}}
          />
        ) : null}
      </Card>
    </Box>
  )
}
