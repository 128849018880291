import { Button, CircularProgress, Input, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useUpdateRenewalDate } from '../hooks/useUpdateRenewalDate'

interface IRenewalDateEditor {
  productApplicationId: string
  defaultDate: string | null
}

const useStyles = makeStyles({
  spacer: {
    width: 14
  },
  container: {
    display: 'flex'
  }
})

export const RenewalDateEditor = (props: IRenewalDateEditor) => {
  const [date, setDate] = useState<string | null>(props.defaultDate)
  const [updateRenewalDate, { loading }] = useUpdateRenewalDate()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Input value={date} onChange={e => setDate(e.target.value)} type='date' />
      <div className={classes.spacer} />
      <Button
        disabled={loading}
        variant='outlined'
        onClick={async () => {
          if (date) {
            await updateRenewalDate({
              variables: {
                input: {
                  productApplicationId: props.productApplicationId,
                  expiresOnOrAfter: date
                }
              }
            })
          }
        }}>
        {loading ? <CircularProgress /> : 'Update'}
      </Button>
    </div>
  )
}
