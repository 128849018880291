import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const SET_DEPLOYED_OS_VERSION_MUTATION = gql`
  mutation DeployOSVersion($id: String!) {
    deployOSVersion(input: { id: $id }) {
        success
    }
  }
`

const DISABLE_DEPLOYED_OS_VERSION_MUTATION = gql`
  mutation DisableDeployedOSVersion {
    disableDeployOsVersion {
        success
    }
  }
`

const SOFT_DELETE_OS_VERSION_MUTATION = gql`
  mutation SoftDeleteOSVersion($id: String!) {
    softDeleteOsVersion(input: { id: $id }) {
        success
    }
  }
`

export const useDeployOsVersion = () => {
  return useMutation(SET_DEPLOYED_OS_VERSION_MUTATION)
}

export const useDisableDeployedOsVersion = () => {
  return useMutation(DISABLE_DEPLOYED_OS_VERSION_MUTATION)
}

export const useSoftDeleteOsVersion = () => {
  return useMutation(SOFT_DELETE_OS_VERSION_MUTATION)
}