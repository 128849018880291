import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetScheduledNotification, GetScheduledNotificationVariables } from './__generated__/GetScheduledNotification'

const QUERY = gql`
  query GetScheduledNotification($id: String!) {
    scheduledNotification: scheduledNotificationV2(id: $id) {
      ... on ScheduledNotificationV2 {
        id
        createdAt
        sendAt
        message
        title
        callToAction
        deepLink
        segment {
          id
          title
        }
        tag {
          id
          title
        }
        subscribers {
          id
          title
        }
      }
      ... on ScheduledNotificationErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetScheduledNotification = (id: string) =>
  useQuery<GetScheduledNotification, GetScheduledNotificationVariables>(QUERY, {
    variables: {
      id
    }
  })
