import { IconButton, Menu, MenuItem, TextField, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import { HISTORY } from '../components/router'
import { Search } from '@material-ui/icons'
import { useFlags } from '../providers/FlagsProvider'

const useStyles = makeStyles({
  textField: {
    background: '#ccc',
    borderRadius: 7
  },
  menu: {
    marginTop: 40
  }
})

const typeMap: {
  [key: string]: string
} = {
  Account: `/account/{{id}}`,
  Board: `/boards/{{id}}`,
  ColorTemplate: `/color-templates/{{id}}`,
  ColorTemplateCategory: `/color-templates/categories/{{id}}`,
  Developer: `/developers/{{id}}`,
  Device: `/devices/{{id}}`,
  DigitalDevice: '/digital-devices/{{id}}',
  Installable: `/installables/{{id}}`,
  Installation: `/installation/{{id}}`,
  MarketplaceListing: `/marketplace-listings/{{id}}`,
  Message: `/message/{{id}}`,
  MessageAppearance: `/message-appearance/{{id}}`,
  MessageSet: `/message-sets/{{id}}`,
  Person: `/person/{{id}}`,
  Pick: `/picks/{{id}}`,
  PickCategory: `/picks/categories/{{id}}`,
  PlusSubscription: `/plus/{{id}}`,
  PlusOrder: `/orders/{{id}}`,
  Principal: `/principal/{{id}}`,
  Subscription: `/subscription/{{id}}`,
  Tenant: `/tenant/{{id}}`
}

export const OmniSearch = () => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const flags = useFlags()

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        if (!!searchText) {
          setAnchorEl(e.currentTarget)
        }
      }}>
      <TextField
        color='primary'
        className={classes.textField}
        variant='outlined'
        placeholder='Find'
        value={searchText}
        size='small'
        InputProps={{
          endAdornment: (
            <IconButton type='submit'>
              <Search />
            </IconButton>
          )
        }}
        onChange={e => {
          setSearchText(e.target.value)
        }}></TextField>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.menu}>
        {Object.keys(typeMap)
          .filter(key => {
            if (flags.messagesV2) {
              return key !== 'ColorTemplateCategory' && key !== 'PickCategory'
            }
            return true
          })
          .map(key => {
            const path = (typeMap[key] || '').replace('{{id}}', searchText)

            return (
              <MenuItem
                key={key}
                onClick={() => {
                  HISTORY.push(path)
                  setAnchorEl(null)
                }}>
                {key}
              </MenuItem>
            )
          })}
      </Menu>
    </form>
  )
}
