import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import { FullModal, useToasts } from '@vestaboard/installables'
import { Checkbox } from './Checkbox'
import { csvToArray } from '../utils'

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: `calc(100vh - 280px)`,
    overflowY: 'auto',
    marginBottom: 42
  },
  fileUpload: {
    position: 'absolute',
    background: 'yellow',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    cursor: 'pointer'
  }
})

interface IImportCsvButton {
  mappings: string[]
  handleImport: (data: string[][]) => void
  noHeader?: boolean
}

export const CsvImportButton = (props: IImportCsvButton) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const [data, setData] = useState<null | {
    keys: string[]
    values: string[][]
    excluded: string[]
  }>(null)

  return (
    <>
      <Button variant='outlined' color='default' endIcon={<CloudUpload />}>
        IMPORT CSV
        <input
          type='file'
          className={classes.fileUpload}
          onChange={e => {
            if (e.target.files?.length) {
              const file = e.target.files[0]
              const reader = new FileReader()
              reader.onload = () => {
                if (file.name.split('.').pop() !== 'csv') {
                  addToast('File must be CSV', { appearance: 'error' })
                  return
                }

                try {
                  const [keys, ...values] = csvToArray(reader.result as string)

                  setData({
                    keys: props.mappings,
                    values: props.noHeader ? [keys, ...values] : values,
                    excluded: []
                  })
                } catch (e) {
                  addToast('Unable to parse CSV file', { appearance: 'error' })
                }
              }
              reader.readAsText(file)
            }
          }}
        />
      </Button>
      {!!data ? (
        <FullModal
          title='Import CSV'
          onClose={() => {
            setData(null)
          }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Box className={classes.tableContainer}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Include</TableCell>
                        {data.keys.map((key, keyIndex) => (
                          <TableCell key={`${key}-${keyIndex}`}>
                            <select
                              value={key}
                              onChange={e => {
                                setData({
                                  ...data,
                                  keys: data.keys.map((k, i) => (i === keyIndex ? e.target.value : k))
                                })
                              }}>
                              <option>NOT MAPPED</option>
                              {props.mappings.map(mapping => (
                                <option key={mapping} value={mapping}>
                                  {mapping}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.values.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              checked={!data.excluded.includes(`${index}`)}
                              handleCheck={() => {
                                if (data.excluded.includes(`${index}`)) {
                                  setData({
                                    ...data,
                                    excluded: data.excluded.filter(excluded => excluded !== `${index}`)
                                  })
                                } else {
                                  setData({
                                    ...data,
                                    excluded: [...data.excluded, `${index}`]
                                  })
                                }
                              }}
                            />
                          </TableCell>
                          {row.map((field, fieldIndex) => (
                            <TableCell key={`field-${index}-${fieldIndex}`}>{field}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  const records = data.values
                    .filter((_row: string[], index: number) => !data.excluded.find(excluded => excluded === `${index}`))
                    .map(row => {
                      return row.reduce((prev: any, current: string, index: number) => {
                        const mapping = props.mappings.find(mapping => mapping === data.keys[index])

                        if (mapping) {
                          return {
                            ...prev,
                            [mapping]: current
                          }
                        }

                        return prev
                      }, {})
                    })

                  setLoading(true)

                  try {
                    await props.handleImport(records)
                    setLoading(false)
                    setData(null)
                    addToast('CSV Imported', { appearance: 'success' })
                  } catch (e) {
                    setLoading(false)
                    addToast('Unable to import CSV', { appearance: 'error' })
                  }
                }}>
                Import
              </Button>
            </>
          )}
        </FullModal>
      ) : null}
    </>
  )
}
