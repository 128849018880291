import { Route, Switch } from 'react-router'

import { GetDigitalDevice } from './pages/Get'
import { ListDigitalDevices } from './pages/List'
import React from 'react'

export const DigitalDevicesRouter = () => (
  <Switch>
    <Route exact path='/digital-devices' component={ListDigitalDevices} />
    <Route exact path='/digital-devices/:id' component={GetDigitalDevice} />
  </Switch>
)
