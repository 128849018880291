import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { NewInstallableQuery } from './__generated__/NewInstallableQuery'

const QUERY = gql`
  query NewInstallableQuery {
    installableCategories {
      id
      created
      title
    }
  }
`

export const useInstallableCategories = () => {
  return useQuery<NewInstallableQuery>(QUERY)
}
