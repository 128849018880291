import { GetPickV2, GetPickV2Variables } from './__generated__/GetPickV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetPickV2($id: String!) {
    pick: pickV2(id: $id) {
      __typename
      ... on PickV2 {
        id
        date
        created
        categories {
          id
          title
        }
        sortOrder
        attribution
        message {
          id
          text
          characters
          tags
        }
      }
      ... on PickErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetPick = (id: string) => {
  return useQuery<GetPickV2, GetPickV2Variables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: { id }
  })
}
