import * as React from 'react'
import { ReportActivations } from '../report-activations'

type ReportActivationsDeletedBoardsContainerProps = IReportActivationsDeletedBoardsContainerProps

interface IReportActivationsDeletedBoardsContainerProps {}

export const ReportActivationsDeletedBoardsContainer: React.FC<ReportActivationsDeletedBoardsContainerProps> = props => {
  return <ReportActivations deleted={true} ignored={null} revoked={null} online={null} />
}
