import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { BoardPreview, IBoard, LoadMask, useToasts } from '@vestaboard/installables'
import { gql } from 'apollo-boost'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedDateTime } from '../../formatted-date-time'
import { TruncatedId } from '../../truncated-id'
import { CreateMessage } from './CreateMessage'
import {
  CreateMessageSetVersionMutation,
  CreateMessageSetVersionMutationVariables
} from './__generated__/CreateMessageSetVersionMutation'
import { MessageSetVersionQuery, MessageSetVersionQueryVariables } from './__generated__/MessageSetVersionQuery'
import {
  PublishMessageSetVersionMutation,
  PublishMessageSetVersionMutationVariables
} from './__generated__/PublishMessageSetVersionMutation'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { RemoveCircle } from '@material-ui/icons'
import { UpdateMessage } from './UpdateMessage'
import { InvalidateMessageSet, InvalidateMessageSetVariables } from './__generated__/InvalidateMessageSet'
import { csvDownload } from '../../../utils'
import { useMigrateMessageSet } from '../../message-set-v2/hooks/useMigrateMessageSet'

const MESSAGE_SET_VERSION_QUERY = gql`
  query MessageSetVersionQuery($id: String!) {
    messageSetVersion(id: $id) {
      id
      created
      count
      messages(first: 1000) {
        id
        created
        formatted
        text
      }
    }
  }
`

const CREATE_MESSAGE_SET_VERSION_MUTATION = gql`
  mutation CreateMessageSetVersionMutation($input: CreateMessageSetVersionInput!) {
    createMessageSetVersion(input: $input) {
      id
    }
  }
`

const PUBLISH_VERSION_MUTATION = gql`
  mutation PublishMessageSetVersionMutation($input: PublishMessageSetVersionInput!) {
    publishMessageSetVersion(input: $input) {
      id
    }
  }
`

const INVALIDATE_MESSAGE_SET_MUTATIONS = gql`
  mutation InvalidateMessageSet($input: AdminInvalidateMessageSetInput!) {
    adminInvalidateMessageSet(input: $input) {
      id
      title
    }
  }
`

interface IMessageSetVersion {
  id: string
  messageSetId: string
  onVersionCreated: (id: string) => void
}

interface IMessageListing {
  id: string
  created: string
  formatted: IBoard
  text?: string
}

const useStyles = makeStyles({
  gridPadding: {
    padding: 20
  }
})

export const MessageSetVersion = (props: IMessageSetVersion) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [editingMessage, setEditingMessage] = useState<IMessageListing | null>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [isAddingMessage, setIsAddingMessage] = useState(false)
  const [messages, setMessages] = useState<IMessageListing[]>([])
  const { data, loading, error } = useQuery<MessageSetVersionQuery, MessageSetVersionQueryVariables>(
    MESSAGE_SET_VERSION_QUERY,
    {
      variables: {
        id: props.id
      }
    }
  )
  const [invalidateMessageSetMutation, { loading: invalidatingMessageSet }] = useMutation<
    InvalidateMessageSet,
    InvalidateMessageSetVariables
  >(INVALIDATE_MESSAGE_SET_MUTATIONS)
  const [createMessageSetVersion, { loading: savingMessageSetVersion }] = useMutation<
    CreateMessageSetVersionMutation,
    CreateMessageSetVersionMutationVariables
  >(CREATE_MESSAGE_SET_VERSION_MUTATION)
  const [publishMessageSetVersionMutation, { loading: publishingMessageSetVersion }] = useMutation<
    PublishMessageSetVersionMutation,
    PublishMessageSetVersionMutationVariables
  >(PUBLISH_VERSION_MUTATION)
  const [migrateMessageSet] = useMigrateMessageSet()
  const { messageSetId, onVersionCreated, id } = props

  useEffect(() => {
    setMessages(
      (data?.messageSetVersion?.messages || []).map(message => ({
        id: message.id,
        created: message.created,
        formatted: message.formatted as IBoard,
        text: message?.text || ''
      }))
    )
  }, [data])

  const createMessageSet = useCallback(async () => {
    const result = await createMessageSetVersion({
      variables: {
        input: {
          messageSet: messageSetId,
          messages: messages.map(message => message.id)
        }
      }
    })

    if (result.errors?.length) {
      addToast('There was an error saving the message set version', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }

    try {
      await migrateMessageSet({
        variables: {
          messageSetId
        }
      })
    } catch (e) {}

    addToast('Message set version saved', {
      appearance: 'success',
      autoDismiss: true
    })

    setIsDirty(false)
    onVersionCreated(result.data?.createMessageSetVersion.id || '')
  }, [createMessageSetVersion, messageSetId, messages, addToast, onVersionCreated, migrateMessageSet])

  const publishMessageSetVersion = useCallback(async () => {
    try {
      const result = await publishMessageSetVersionMutation({
        variables: {
          input: {
            messageSetVersion: id
          }
        }
      })

      try {
        await invalidateMessageSetMutation({
          variables: {
            input: {
              messageSet: messageSetId
            }
          }
        })
      } catch (e) {}

      if (result.errors?.length) {
        addToast('There was an error publishing the message set version', {
          appearance: 'error',
          autoDismiss: true
        })
        return
      }

      try {
        await migrateMessageSet({
          variables: {
            messageSetId
          }
        })
      } catch (e) {}

      addToast('Message set version published', {
        appearance: 'success',
        autoDismiss: true
      })

      onVersionCreated(id)
    } catch (e) {
      addToast('There was an error publishing the message set version', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }
  }, [
    publishMessageSetVersionMutation,
    id,
    addToast,
    onVersionCreated,
    invalidateMessageSetMutation,
    messageSetId,
    migrateMessageSet
  ])

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      setMessages(messages => {
        const newMessages = Array.from(messages)
        const [removed] = newMessages.splice(startIndex, 1)
        newMessages.splice(endIndex, 0, removed)
        return newMessages
      })
      setIsDirty(true)
    },
    [setMessages, setIsDirty]
  )

  const updateMessage = useCallback(
    async (data: { id: string; characters: IBoard }) => {
      setMessages(messages =>
        messages.map(message =>
          message.id === data.id
            ? {
                ...message,
                formatted: data.characters
              }
            : message
        )
      )

      try {
        await invalidateMessageSetMutation({
          variables: {
            input: {
              messageSet: messageSetId
            }
          }
        })
      } catch (e) {}
    },
    [messageSetId, invalidateMessageSetMutation]
  )

  return error ? (
    <Alert severity='error'>There was an error loading your messages</Alert>
  ) : loading ? (
    <CircularProgress />
  ) : (
    <>
      {editingMessage ? (
        <UpdateMessage
          onClose={() => setEditingMessage(null)}
          characters={editingMessage.formatted}
          id={editingMessage.id}
          onSave={updateMessage}
        />
      ) : null}
      {savingMessageSetVersion || publishingMessageSetVersion || invalidatingMessageSet ? <LoadMask /> : null}
      {isAddingMessage ? (
        <CreateMessage
          onClose={() => {
            setIsAddingMessage(false)
          }}
          onSaved={message => {
            setMessages(messages => [message, ...messages])
            setIsAddingMessage(false)
            setIsDirty(true)
          }}
        />
      ) : null}
      <Card>
        <Box className={classes.gridPadding}>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                size='small'
                variant='outlined'
                onClick={() => {
                  setIsAddingMessage(true)
                }}>
                Add Message
              </Button>
            </Grid>
            <Grid item>
              <Button size='small' variant='contained' color='primary' disabled={!isDirty} onClick={createMessageSet}>
                Save Version
              </Button>
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='contained'
                color='primary'
                disabled={isDirty}
                onClick={publishMessageSetVersion}>
                Publish Version
              </Button>
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='outlined'
                onClick={() => {
                  const csvData = messages.map(message => `"${JSON.stringify(message.formatted)}"`).join('\n')
                  csvDownload(csvData)
                }}>
                Export Version
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!messages.length ? (
          <Alert severity='warning'>There are no messages in this version</Alert>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }} />
                <TableCell
                  style={{
                    width: 100
                  }}>
                  Message ID
                </TableCell>
                <TableCell
                  style={{
                    width: 100
                  }}>
                  Created At
                </TableCell>
                <TableCell>Text</TableCell>
                <TableCell
                  style={{
                    width: 200
                  }}>
                  Message
                </TableCell>
                <TableCell style={{ width: 50 }} />
              </TableRow>
            </TableHead>
            <TableBody component={DroppableTableBody(onDragEnd)}>
              {messages.map((message, index) => (
                <TableRow component={DraggableTableRow(message.id, index)} key={message.id}>
                  <TableCell>
                    <DragIndicatorIcon />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setEditingMessage(message)
                      }}
                      variant='outlined'
                      size='small'
                      color='primary'>
                      <TruncatedId value={message.id} />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={+message.created} />
                  </TableCell>
                  <TableCell>
                    <Card raised>
                      <CardContent>
                        <Typography variant='body2'>{message.text}</Typography>
                      </CardContent>
                    </Card>
                  </TableCell>
                  <TableCell onClick={() => setEditingMessage(message)}>
                    <BoardPreview characters={message.formatted} />
                  </TableCell>
                  <TableCell>
                    <ButtonBase
                      onClick={() => {
                        setMessages(messages => messages.filter(currentMessage => currentMessage.id !== message.id))
                        setIsDirty(true)
                      }}>
                      <RemoveCircle color='action' />
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </>
  )
}
