import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { formatReportRangeType } from '../utils/formatReportRangeType'

interface ISearchType {
  rangeType: string
  searchKey: string | null
}

interface IRangeType {
  setSearch: (val: ISearchType) => void
  rangeType: string
  types: string[]
}

const useStyles = makeStyles({
  button: {
    color: '#FFF',
    '&:disabled': {
      color: '#FFF'
    }
  },
  menu: {
    marginTop: 60
  }
})

export const RangeType = ({ setSearch, rangeType, types }: IRangeType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant='text'
        className={classes.button}
        endIcon={<KeyboardArrowDown />}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}>
        Grouped By {formatReportRangeType(rangeType)}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.menu}>
        {types.includes('day') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'day',
                searchKey: `${new Date().toISOString().split('T')[0]}`
              })
            }}>
            Day
          </MenuItem>
        ) : null}
        {types.includes('week') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'week',
                searchKey: `${new Date().getFullYear()}-${moment().week()}`
              })
            }}>
            Week
          </MenuItem>
        ) : null}
        {types.includes('month') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'month',
                searchKey: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
              })
            }}>
            Month
          </MenuItem>
        ) : null}
        {types.includes('year_weekly') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'year_weekly',
                searchKey: `${new Date().getFullYear()}`
              })
            }}>
            Weekly
          </MenuItem>
        ) : null}
        {types.includes('year') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'year',
                searchKey: `${new Date().getFullYear()}`
              })
            }}>
            Monthly
          </MenuItem>
        ) : null}
        {types.includes('all_time') ? (
          <MenuItem
            onClick={() => {
              setSearch({
                rangeType: 'all_time',
                searchKey: null
              })
            }}>
            Yearly
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}
