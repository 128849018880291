import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { MigratePicks, MigratePicksVariables } from './__generated__/MigratePicks'

const MUTATION = gql`
  mutation MigratePicks($pickId: String) {
    migratePicksV2(pickId: $pickId) {
      success
      description
    }
  }
`

export const useMigratePick = () => {
  return useMutation<MigratePicks, MigratePicksVariables>(MUTATION)
}
