import { gql } from 'apollo-boost'
import { ListColorTemplateCategories } from './__generated__/ListColorTemplateCategories'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListColorTemplateCategories {
    colorTemplateCategories {
      id
      title
      colorTemplatesLength
    }
  }
`

export const useListColorTemplateCategories = () => {
  return useQuery<ListColorTemplateCategories>(QUERY, {
    fetchPolicy: 'cache-and-network'
  })
}
