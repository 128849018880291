import * as React from 'react'
import { gql } from 'apollo-boost'
import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress,
  TableBody,
  Box,
  Typography
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { PaymentListQuery, PaymentListQueryVariables } from './__generated__/PaymentListQuery'
import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'
import { formatCurrency } from '../../utils/formatCurrency'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

type PaymentListPageProps = IPaymentListPageProps

interface IPaymentListPageProps {}

const QUERY = gql`
  query PaymentListQuery($page: Int!) {
    payments(page: $page) {
      totalCount
      page
      payments {
        id
        created
        amount
        stripeChargeId
        orders {
          order {
            id
            created
          }
          amount
        }
      }
    }
  }
`

export const PaymentListPage: React.FC<PaymentListPageProps> = props => {
  const [page] = React.useState(0)

  const { data, loading, error } = useQuery<PaymentListQuery, PaymentListQueryVariables>(QUERY, {
    variables: {
      page
    }
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Payments</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {error ? (
        <Alert severity='error'>There was an error loading the payments</Alert>
      ) : !data || loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment ID</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Orders</TableCell>
                <TableCell>Stripe Charge</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.payments.payments.map(payment => (
                <TableRow key={payment.id}>
                  <TableCell>
                    <TruncatedId value={payment.id} />
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={payment.created} />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>{formatCurrency('USD', payment.amount)}</TableCell>
                  <TableCell>
                    {payment.orders
                      .filter(order => order && order.order)
                      .map((order, index): JSX.Element | null => {
                        if (order.order && index === 0) {
                          return <TruncatedId value={order.order.id} key={order.order.id} />
                        }

                        return null
                      })}
                  </TableCell>
                  <TableCell>
                    {payment.orders.filter(order => order && order.order).length > 1
                      ? `(+${payment.orders.length - 1})`
                      : null}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      )}
    </Box>
  )
}
