import { MenuItem, Select } from '@material-ui/core'

import React from 'react'
import { useListPlusCategories } from '../hooks/useListPlusCategories'

interface IPlusCategorySelector {
  value: string
  onChange: (value: string) => void
}

export const PlusCategorySelector = (props: IPlusCategorySelector) => {
  const { data } = useListPlusCategories()

  return (
    <Select fullWidth variant='outlined' value={props.value} onChange={e => props.onChange(e.target.value as string)}>
      <MenuItem>Category</MenuItem>
      {(data?.listMarketplaceCategories?.marketplaceCategories || []).map(template => (
        <MenuItem key={template.id} value={template.id}>
          {template.title}
        </MenuItem>
      ))}
    </Select>
  )
}
