import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { EditSubscriptionMuted, EditSubscriptionMutedVariables } from './__generated__/EditSubscriptionMuted'

const EDIT_SUBSCRIPTION_MUTED_MUTATION = gql`
  mutation EditSubscriptionMuted($subscriptionId: String!, $muted: Boolean!) {
    updateSubscription(input: { subscriptionId: $subscriptionId, muted: $muted }) {
      subscription {
        id
        muted
      }
    }
  }
`

export const useEditSubscriptionMuted = () =>
  useMutation<EditSubscriptionMuted, EditSubscriptionMutedVariables>(EDIT_SUBSCRIPTION_MUTED_MUTATION)
