import React from 'react'
import { ButtonBase, Tooltip, makeStyles } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

interface IReportNextButton {
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles({
  arrow: {
    color: '#FFF'
  }
})

export const ReportNextButton = (props: IReportNextButton) => {
  const classes = useStyles()

  return (
    <Tooltip title='Next'>
      <ButtonBase disabled={props.disabled} onClick={props.onClick}>
        <ChevronRight className={classes.arrow} />
      </ButtonBase>
    </Tooltip>
  )
}
