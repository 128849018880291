import React from 'react'
import { Route, Switch } from 'react-router'
import { ListReferrals } from './pages/ListReferrals'
import { GetReferral } from './pages/GetReferral'
import { ReferralReport } from '../referral-report'

export const ReferralsRouter = () => (
  <Switch>
    <Route exact path='/referrals' component={ListReferrals} />
    {/* Temporarily keeping this around for sanity check */}
    <Route exact path='/referrals/v1' component={ReferralReport} />
    <Route exact path='/referrals/:id' component={GetReferral} />
  </Switch>
)
