import { GetFeedItemV2, GetFeedItemV2Variables } from './__generated__/GetFeedItemV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetFeedItemV2($id: String!) {
    feedItem: feedItemV2(id: $id) {
      ... on FeedItemV2 {
        id
        personId
        subscriptionId
        attribution
        likeCount
        moderated
        created
        listFlags {
          flaggedFeedItems {
            personId
            reason
          }
        }
        message {
          id
          text
          characters
          tags
        }
      }
      ... on FeedItemErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetFeedItem = (id: string) => {
  return useQuery<GetFeedItemV2, GetFeedItemV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id }
  })
}
