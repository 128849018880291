import { gql } from 'apollo-boost'
import { ListUpcomingPicks, ListUpcomingPicksVariables } from './__generated__/ListUpcomingPicks'
import { useQuery } from '@apollo/react-hooks'

const LIST_UPCOMING_PICKS = gql`
  query ListUpcomingPicks($page: Int, $perPage: Int) {
    upcomingPicksLength
    upcomingPicks(page: $page, perPage: $perPage) {
      id
      recommendBefore
      recommendAfter
      categories {
        id
        title
      }
      message {
        id
        formatted
      }
    }
  }
`

export const useListUpcomingPicks = (variables: ListUpcomingPicksVariables) => {
  return useQuery<ListUpcomingPicks, ListUpcomingPicksVariables>(LIST_UPCOMING_PICKS, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
