import { gql } from 'apollo-boost'
import { UpdateMessageSetV2, UpdateMessageSetV2Variables } from './__generated__/UpdateMessageSetV2'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdateMessageSetV2($input: UpdateMessageSetInputV2!) {
    updateMessageSet: updateMessageSetV2(input: $input) {
      __typename
      ... on MessageSetV2 {
        id
        title
        public
        dynamic
        rssUrl
        messages {
          id
          text
          characters
        }
      }
      ... on MessageSetErrorV2 {
        type
        error
      }
    }
  }
`

export const useUpdateMessageSet = () => useMutation<UpdateMessageSetV2, UpdateMessageSetV2Variables>(MUTATION)
