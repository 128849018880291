import React from 'react'
import { useHistory } from 'react-router'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { Typography } from '@material-ui/core'
import { MessageSetForm } from '../components/MessageSetForm'
import { useCreateMessageSet } from '../hooks/useCreateMessageSet'

export const CreateMessageSet = () => {
  const [createMessageSet, { loading: saving }] = useCreateMessageSet()
  const history = useHistory()
  const { addToast } = useToasts()

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Message Set</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <MessageSetForm
        onSave={async payload => {
          const result = await createMessageSet({
            variables: {
              input: {
                ...payload
              }
            }
          })

          if (result.data?.createMessageSet.__typename === 'MessageSetV2') {
            history.push(`/message-sets/${result.data.createMessageSet.id}`)
          } else {
            addToast(result?.data?.createMessageSet?.error || 'There was an error updating your message set', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}
      />
    </>
  )
}
