import React, { useCallback, useState } from 'react'
import { Box, ButtonBase, Card, LinearProgress, Typography, makeStyles } from '@material-ui/core'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { Alert } from '@material-ui/lab'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import {
  SyncReservedPairingCodeMutation,
  SyncReservedPairingCodeMutationVariables
} from './__generated__/SyncReservedPairingCodeMutation'

const MUATION = gql`
  mutation SyncReservedPairingCodeMutation($input: SyncReservedPairingCodeInput!) {
    syncReservedPairingCode(input: $input) {
      pairingCode {
        id
        pairingCode
      }
    }
  }
`

const useStyles = makeStyles({
  box: {
    padding: 24
  },
  fileInput: {
    '&:before': {
      borderBottom: 'none !important'
    }
  }
})

export const SyncProvisionedDevices = () => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const [error, setError] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentRecord, setCurrentRecord] = useState(0)
  const [syncReservedPairingCodeMutation] = useMutation<
    SyncReservedPairingCodeMutation,
    SyncReservedPairingCodeMutationVariables
  >(MUATION)

  const syncReservedPairingCodeRecursive = useCallback(
    async (records: any[], current: number) => {
      const record = records[current]

      // DONE
      if (!record) {
        setIsSyncing(false)
        addToast('Synced all devices', { appearance: 'success' })
        return
      }

      setCurrentRecord(current)

      await syncReservedPairingCodeMutation({
        variables: {
          input: {
            pairingCode: record?.pairingCode,
            model: record?.model,
            type: record?.type,
            tags: record?.tags?.join(','),
            boiSerialNumber: record?.boiSerialNumber
          }
        }
      })

      await syncReservedPairingCodeRecursive(records, current + 1)
    },
    [syncReservedPairingCodeMutation, addToast]
  )

  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false)
      setIsSyncing(true)

      if (!e.target.files?.length) {
        return
      }

      try {
        const fileText = await new Response(e.target.files[0]).text()
        const json = JSON.parse(fileText)
        setTotalRecords(json.length)
        await syncReservedPairingCodeRecursive(json, 0)
      } catch (e) {
        setError(true)
        setIsSyncing(false)
      }
    },
    [syncReservedPairingCodeRecursive]
  )

  return (
    <>
      <Typography variant='h4'>Sync Provisioned Devices</Typography>
      <Spacer size='extraLarge' />
      <Card>
        <Box className={classes.box}>
          {isSyncing ? (
            <>
              <LinearProgress variant='determinate' value={(currentRecord / totalRecords) * 100} />
              <Spacer size='large' />
              <Typography>
                Synced {currentRecord} of {totalRecords} devices...
              </Typography>
            </>
          ) : (
            <>
              <FlexHorizontal>
                <Typography variant='h5'>Upload Provisioned Devices &nbsp;</Typography>
                <ButtonBase onClick={() => setShowHelp(showHelp => !showHelp)}>
                  <HelpIcon />
                </ButtonBase>
              </FlexHorizontal>
              <Spacer size='medium' />
              <FlexHorizontal>
                <input type='file' className={classes.fileInput} onChange={handleFileUpload} />
              </FlexHorizontal>
              {error ? (
                <>
                  <Spacer size='extraLarge' />
                  <Alert severity='error'>
                    There was an error parsing the file you uploaded. Are you sure it was a valid file with an array of
                    provisioned devices in JSON?
                  </Alert>
                </>
              ) : null}
              <Spacer size='extraLarge' />
              {showHelp ? (
                <Alert severity='info'>
                  You can download the current list of provisioned devices from the <strong>/export</strong> endpoint on
                  the factory server. If you don't know what this means, you probably shouldn't be messing around in
                  here.
                </Alert>
              ) : null}
            </>
          )}
        </Box>
      </Card>
    </>
  )
}
