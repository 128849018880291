import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, TextField, Button, makeStyles, CircularProgress } from '@material-ui/core'
import { Link } from '../link'
import moment from 'moment'
import { Timeline } from '../timeline'
import { eventTypes } from './eventTypes'

interface IHardwarePageProps {}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 42,
    position: 'sticky',
    top: 40,
    zIndex: 2,
    paddingBottom: 14,
    backgroundColor: '#FAFAFA',
    borderBottom: 'solid 1px #DDD'
  },
  dateInput: {
    marginRight: theme.spacing(2),
    width: 226
  },
  timeline: {
    background: '#FFF',
    position: 'relative',
    flex: 1,
    border: 'solid 1px #DDD',
    borderTop: 'none'
  },
  loading: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const DATE_FORMAT = 'YYYY-DD-MMThh:mm'

const anHourAgo = () => moment().subtract(2, 'hour').format(DATE_FORMAT)
const anHourFromNow = () => moment().add(0, 'hour').format(DATE_FORMAT)

export const HardwarePage: React.FC<IHardwarePageProps> = props => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const [startsAt, setStartsAt] = useState(anHourAgo())
  const [endsAt, setEndsAt] = useState(anHourFromNow())
  const [data, setData] = useState<
    Array<{
      date: number
      event: string
      type: string
      error: boolean
    }>
  >([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    // Simulate fetching some data...
    setTimeout(() => {
      setLoading(false)

      const randomEvent = () => eventTypes[Math.floor(Math.random() * eventTypes.length)]

      const currentDate = moment(startsAt)
      const dates = []

      while (currentDate < moment(endsAt)) {
        dates.push(+currentDate.format('X'))
        currentDate.add(10, 'minutes')
      }

      setData(
        dates.map(date => ({
          ...randomEvent(),
          date
        }))
      )
    }, 1000)
  }, [startsAt, endsAt])

  return (
    <Box>
      <Typography variant='button' display='block' gutterBottom>
        <Link to='/hardwares'>Hardware</Link>
      </Typography>
      <Typography variant='h4'>{id}</Typography>

      {/* Header */}
      <Box className={classes.header}>
        <TextField
          className={classes.dateInput}
          type='datetime-local'
          value={startsAt}
          onChange={e => {
            setStartsAt(e.target.value)
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          className={classes.dateInput}
          type='datetime-local'
          value={endsAt}
          onChange={e => {
            setEndsAt(e.target.value)
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant='contained'
          type='button'
          onClick={() => {
            setStartsAt(anHourAgo())
            setEndsAt(anHourFromNow())
          }}>
          Now
        </Button>
      </Box>

      {/* Timeline */}
      {loading ? (
        <Box className={classes.loading}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.timeline}>
          <Timeline
            data={data}
            onEventSelect={event => {
              // Depending on the event, we will route to different views here
              console.log('clicked event', event)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
