import React, { useState } from 'react'
import {
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link as MuiLink,
  Dialog,
  ButtonBase,
  Typography,
  Box
} from '@material-ui/core'
import { useInstallableSubscriptions } from '../hooks/useInstallableSubscriptions'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { Confirm, Spacer, useToasts } from '@vestaboard/installables'
import { useDeleteInstallation, useDeleteSubscription, useEditSubscriptionMuted } from '../../../api'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

interface IListInstallableSubscriptions {
  installableId: string
}

export const ListInstallableSubscriptions = (props: IListInstallableSubscriptions) => {
  const [page, setPage] = useState(0)
  const { loading, data, error, refetch } = useInstallableSubscriptions(props.installableId, {
    perPage: 50,
    page
  })
  const [simulateSubscriptionConfig, setSimulateSubscriptionConfig] = useState('')
  const [deleteInstallation] = useDeleteInstallation()
  const [deleteSubscription] = useDeleteSubscription()
  const [editSubscriptionMuted] = useEditSubscriptionMuted()
  const [isDeletingSubscription, setIsDeletingSubscription] = useState<string | null>(null)
  const [isDeletingInstallation, setIsDeletingInstallation] = useState<string | null>(null)
  const { addToast } = useToasts()

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>Unable to load subscriptions for this installable.</Alert>
  ) : (
    <>
      <Confirm
        title='Delete Installation?'
        open={!!isDeletingInstallation}
        handleClose={() => {
          setIsDeletingInstallation(null)
        }}
        handleAccept={async () => {
          if (isDeletingInstallation) {
            try {
              await deleteInstallation({
                variables: {
                  installationId: isDeletingInstallation
                }
              })
            } catch (e) {}
            setIsDeletingInstallation(null)
            await refetch()
            addToast('Installation deleted', {
              appearance: 'success'
            })
          }
        }}
      />
      <Confirm
        title='Delete Subscription?'
        open={!!isDeletingSubscription}
        handleClose={() => {
          setIsDeletingSubscription(null)
        }}
        handleAccept={async () => {
          if (isDeletingSubscription) {
            try {
              await deleteSubscription({
                variables: {
                  subscriptionId: isDeletingSubscription
                }
              })
            } catch (e) {}
            setIsDeletingSubscription(null)
            await refetch()
            addToast('Subscription deleted', {
              appearance: 'success'
            })
          }
        }}
      />
      <Dialog
        maxWidth='lg'
        style={{ width: 1000, height: 800 }}
        open={!!simulateSubscriptionConfig}
        onClose={() => setSimulateSubscriptionConfig('')}>
        <iframe style={{ width: 1000, height: 800 }} src={simulateSubscriptionConfig} title='simulator config' />
      </Dialog>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Installation ID</TableCell>
                <TableCell>Subscription ID</TableCell>
                <TableCell>Board ID</TableCell>
                <TableCell>Board Title</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Simulate</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.installable?.installations?.map(installation => {
                const subscription = installation.subscriptions?.[0]
                const subscriptionId = subscription?.id || ''
                const boardId = installation.subscriptions?.[0]?.subscriptionSet.boards?.[0].id || ''
                const boardTitle = installation.subscriptions?.[0]?.subscriptionSet.boards?.[0].title || ''

                return (
                  <TableRow key={installation.id}>
                    <TableCell>
                      <TruncatedId value={installation.id} path={`/installation/${installation.id}`} />
                    </TableCell>
                    <TableCell>
                      <TruncatedId value={subscriptionId} path={`/subscription/${subscriptionId}`} />
                    </TableCell>
                    <TableCell>
                      <TruncatedId value={boardId} path={`/boards/${boardId}`} />
                    </TableCell>
                    <TableCell>{boardTitle}</TableCell>
                    <TableCell>
                      <FormattedDateTime value={+installation.created} />
                    </TableCell>
                    <TableCell>
                      {subscription?.configurationUrl ? (
                        <div>
                          <Button
                            variant='outlined'
                            onClick={() =>
                              setSimulateSubscriptionConfig(`${subscription?.configurationUrl!}&platform=web`)
                            }>
                            Simulate
                          </Button>
                          <Spacer size='small' />
                          <MuiLink
                            rel='noopener noreferrer'
                            target='_blank'
                            href={`https://f31e349e.installables.vbrd.net/admin/errors/subscription/${subscription.id}`}>
                            Server Logs
                          </MuiLink>
                        </div>
                      ) : (
                        <Typography variant='body2'>No Config Page</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => {
                          if (subscription) {
                            setIsDeletingSubscription(subscription.id)
                          } else {
                            setIsDeletingInstallation(installation.id)
                          }
                        }}>
                        Delete
                      </Button>
                      <Button
                        onClick={() => {
                          editSubscriptionMuted({
                            variables: {
                              subscriptionId: subscriptionId,
                              muted: !subscription?.muted
                            }
                          })
                        }}>
                        {subscription?.muted ? 'Unmute' : 'Mute'}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!data?.installable?.installations?.length ? (
          <Alert severity='warning'>There are no subscriptions to see here...</Alert>
        ) : null}
      </Card>
      <Spacer size='large' />
      <Box display='flex'>
        <ButtonBase
          disabled={!page}
          onClick={() => {
            setPage(page => page - 1)
          }}>
          <ChevronLeft />
        </ButtonBase>
        <Typography>Page {page + 1}</Typography>
        <ButtonBase
          disabled={!data?.installable?.installations?.length}
          onClick={() => {
            setPage(page => page + 1)
          }}>
          <ChevronRight />
        </ButtonBase>
      </Box>
    </>
  )
}
