import * as React from 'react'

import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link as MuiLink
} from '@material-ui/core'
import { DeviceListQuery, DeviceListQueryVariables } from './__generated__/DeviceListQuery'

import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { DeviceListFilter } from './DeviceListFilter'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

type DeviceListPageProps = IDeviceListPageProps

interface IDeviceListPageProps {}

const QUERY = gql`
  query DeviceListQuery($page: Int!, $type: String, $perPage: Int, $yoctoStatus: String) {
    devices(page: $page, type: $type, perPage: $perPage, yoctoStatus: $yoctoStatus) {
      page
      count
      pageSize
      devices {
        id
        created
        type
        latestHeartbeat {
          created
          network {
            ssid
          }
        }
        model {
          id
          title
        }
        board {
          id
        }
      }
    }
  }
`

export const DeviceListPage: React.FC<DeviceListPageProps> = props => {
  const perPage = 50
  const [page, setPage] = React.useState(0)

  const params = useParams<{ status?: string }>()
  const yoctoStatus = params.status

  const { data, loading } = useQuery<DeviceListQuery, DeviceListQueryVariables>(QUERY, {
    variables: {
      page,
      type: 'flagship',
      perPage,
      yoctoStatus: yoctoStatus ? yoctoStatus.replace(/ /g, '') : null
    }
  })

  const totalCount = data?.devices.count || 0

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        {yoctoStatus ? (
          <Typography variant='h4'>
            <MuiLink component={Link} to='/devices'>
              Devices
            </MuiLink>{' '}
            / Yocto Status / {yoctoStatus}
          </Typography>
        ) : (
          <Typography variant='h4'>Devices</Typography>
        )}

        <DeviceListFilter />
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Last Contact</TableCell>
                <TableCell>SSID</TableCell>
                <TableCell>Paired Board(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.devices.devices.map(device => (
                <TableRow key={device.id}>
                  <TableCell>
                    <TruncatedId value={device.id} path={`/devices/${device.id}`}></TruncatedId>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <FormattedDateTime value={parseInt(device.created)} format='MMM DD, YYYY h:mm a' />
                  </TableCell>
                  <TableCell>{device.model && device.model.title}</TableCell>
                  <TableCell>
                    {device.latestHeartbeat ? moment(device.latestHeartbeat?.created).fromNow() : <span>Never</span>}
                  </TableCell>
                  <TableCell>{device.latestHeartbeat?.network?.ssid}</TableCell>
                  <TableCell>
                    {device.board[0] ? (
                      <TruncatedId value={device.board[0].id} path={`/boards/${device.board[0].id}`} />
                    ) : (
                      '(none)'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onChangeRowsPerPage={() => {}}
        />
      </Card>
    </>
  )
}
