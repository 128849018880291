import { useMutation } from '@apollo/react-hooks'
import { BoardEditor, FullModal, IBoard, LoadMask, useToasts } from '@vestaboard/installables'
import { gql } from 'apollo-boost'
import React, { useCallback, useState } from 'react'
import {
  AdminUpdateMessageMutation,
  AdminUpdateMessageMutationVariables
} from './__generated__/AdminUpdateMessageMutation'

interface IUpdateMessageData {
  id: string
  characters: IBoard
}

interface IUpdateMessage extends IUpdateMessageData {
  onClose: () => void
  onSave: (data: IUpdateMessageData) => void
}

const UPDATE_MESSAGE = gql`
  mutation AdminUpdateMessageMutation($input: AdminUpdateMessageInput!) {
    adminUpdateMessage(input: $input) {
      id
      text
      authorFormatted
    }
  }
`

export const UpdateMessage = (props: IUpdateMessage) => {
  const [characters, setCharacters] = useState(props.characters)
  const { addToast } = useToasts()
  const [updateMessageMutation, { loading }] = useMutation<
    AdminUpdateMessageMutation,
    AdminUpdateMessageMutationVariables
  >(UPDATE_MESSAGE)

  const saveMessage = useCallback(async () => {
    const { errors, data } = await updateMessageMutation({
      variables: {
        input: {
          id: props.id,
          characters
        }
      }
    })

    if (errors?.length || !data) {
      addToast('There was an error saving your message', {
        appearance: 'error',
        autoDismiss: true
      })

      return
    }

    addToast('Message saved', {
      appearance: 'success',
      autoDismiss: true
    })

    props.onSave({
      id: props.id,
      characters
    })

    props.onClose()
  }, [characters, props, updateMessageMutation, addToast])

  return loading ? (
    <LoadMask />
  ) : (
    <FullModal onClose={props.onClose}>
      <BoardEditor boardValue={characters} setBoardValue={setCharacters} sendButtonLabel='Save' onSend={saveMessage} />
    </FullModal>
  )
}
