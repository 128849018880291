import React from 'react'
import { Route, Switch } from 'react-router'
import { DevelopersList } from './pages/List'
import { DeveloperDetail } from './pages/Update'
import { CreateDeveloper } from './pages/Create'

export const DevelopersRouter = () => (
  <Switch>
    <Route exact path='/developers' component={DevelopersList} />
    <Route exact path='/developers/create' component={CreateDeveloper} />
    <Route exact path='/developers/:id' component={DeveloperDetail} />
  </Switch>
)
