import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateDeveloper, CreateDeveloperVariables } from './__generated__/CreateDeveloper'

const MUTATION = gql`
  mutation CreateDeveloper($title: String!) {
    createDeveloper(input: { title: $title }) {
      id
      title
    }
  }
`

export const useCreateDeveloper = () => useMutation<CreateDeveloper, CreateDeveloperVariables>(MUTATION)
