import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { SuperadminTableQuery } from './__generated__/SuperadminTableQuery'
import { Table, TableHead, TableRow, TableCell, CircularProgress, TableBody } from '@material-ui/core'
import { TruncatedId } from '../truncated-id'

type SuperadminTableProps = ISuperadminTableProps

interface ISuperadminTableProps {}

const QUERY = gql`
  query SuperadminTableQuery {
    superadmins {
      id
      account {
        id
        emailAddress
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const SuperadminTable: React.FC<SuperadminTableProps> = props => {
  const { data, loading } = useQuery<SuperadminTableQuery>(QUERY)

  if (loading || !data) return <CircularProgress />

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Superadmin ID</TableCell>
          <TableCell>Account ID</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>Email Address</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.superadmins.map(superadmin => (
          <TableRow>
            <TableCell>
              <TruncatedId value={superadmin.id} />
            </TableCell>
            <TableCell>
              <TruncatedId value={superadmin.account.id} />
            </TableCell>
            <TableCell>{superadmin.account.person.firstName ?? '(none)'}</TableCell>
            <TableCell>{superadmin.account.person.lastName ?? '(none)'}</TableCell>
            <TableCell>{superadmin.account.emailAddress}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
