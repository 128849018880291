import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Avatar,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { gql } from 'apollo-boost'
import {
  ListMarketplaceListingFeatureTypes,
  ListMarketplaceListingFeatureTypesVariables,
  ListMarketplaceListingFeatureTypes_marketplaceListingFeatureType_items
} from './__generated__/ListMarketplaceListingFeatureTypes'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { useParams } from 'react-router'
import { DroppableTableBody } from '../../ui/DropableTableBody'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { TruncatedId } from '../truncated-id'
import { DraggableTableRow } from '../../ui/DraggableTableRow'
import {
  UpdateMarketplaceListingFeatureType,
  UpdateMarketplaceListingFeatureTypeVariables
} from './__generated__/UpdateMarketplaceListingFeatureType'
import { useUpdateMarketplaceFeatureTypeV2 } from '../../api/useUpdateMarketplaceFeatureTypeV2'

const QUERY = gql`
  query ListMarketplaceListingFeatureTypes($id: String!) {
    marketplaceListingFeatureType(id: $id) {
      id
      title
      items {
        id
        sortOrder
        marketplaceListing {
          id
          title
          icon
        }
      }
    }
  }
`

const MUTATION = gql`
  mutation UpdateMarketplaceListingFeatureType($input: UpdateMarketplaceListingFeatureInput!) {
    updateMarketplaceListingFeatureType(input: $input) {
      id
      title
      items {
        id
        sortOrder
        marketplaceListing {
          id
          title
          icon
        }
      }
    }
  }
`

export const MarketplaceListingFeatureTypeList = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<
    ListMarketplaceListingFeatureTypes,
    ListMarketplaceListingFeatureTypesVariables
  >(QUERY, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })
  const [saveSortOrderMutation] = useMutation<
    UpdateMarketplaceListingFeatureType,
    UpdateMarketplaceListingFeatureTypeVariables
  >(MUTATION)
  const [updateV2] = useUpdateMarketplaceFeatureTypeV2()
  const [sortOrder, setSortOrder] = useState<string[]>([])

  useEffect(() => {
    setSortOrder((data?.marketplaceListingFeatureType.items || []).map(item => item.id))
  }, [data])

  const items = sortOrder.map(id =>
    (data?.marketplaceListingFeatureType.items || []).find(item => item.id === id)
  ) as ListMarketplaceListingFeatureTypes_marketplaceListingFeatureType_items[]

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newItems = Array.from(sortOrder)
      const [removed] = newItems.splice(startIndex, 1)
      newItems.splice(endIndex, 0, removed)

      setSortOrder(newItems)

      await saveSortOrderMutation({
        variables: {
          input: {
            id,
            marketplaceListingIds: newItems
          }
        }
      })

      await updateV2({
        variables: {
          input: {
            id,
            title: data?.marketplaceListingFeatureType.title || '',
            marketplaceListingIds: newItems.map(id => items.find(item => item.id === id)?.marketplaceListing.id || '')
          }
        }
      })
    },
    [sortOrder, saveSortOrderMutation, id, updateV2, data, items]
  )

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>There was an error loading this marketplace listing type</Alert>
  ) : (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Marketplace Listing Type / {data?.marketplaceListingFeatureType.title}</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      {!items.length ? (
        <Alert severity='warning'>There are no marketplace listings associated with this type</Alert>
      ) : (
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 50 }}></TableCell>
                  <TableCell style={{ width: 50 }}></TableCell>
                  <TableCell style={{ width: 170 }}>Listing ID</TableCell>
                  <TableCell>Listing Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody component={DroppableTableBody(onDragEnd)}>
                {items.map((item, index) => (
                  <TableRow key={item.id} component={DraggableTableRow(item.id, index)}>
                    <TableCell>
                      <DragIndicatorIcon />
                    </TableCell>
                    <TableCell>
                      {item.marketplaceListing.icon ? (
                        <Avatar src={item.marketplaceListing.icon} />
                      ) : (
                        <Avatar>{item.marketplaceListing.title?.split('')[0]}</Avatar>
                      )}
                    </TableCell>
                    <TableCell>
                      <TruncatedId
                        value={item.marketplaceListing.id}
                        full
                        path={`/marketplace-listings/${item.marketplaceListing.id}`}
                      />
                    </TableCell>
                    <TableCell>{item.marketplaceListing.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </>
  )
}
