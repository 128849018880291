import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Bar, ComposedChart } from 'recharts'

interface ITopChannels {
  data: {
    Installed: number
    key: string
    fill: string
  }[]
  onBarClick?: (e: any) => void
}

export const TopChannels = (props: ITopChannels) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key' hide />
        <YAxis />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Bar dataKey='Installed' onClick={props.onBarClick ? props.onBarClick : undefined} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
