import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { AddPlusSubscription, AddPlusSubscriptionVariables } from './__generated__/AddPlusSubscription'

const ADD_PLUS_SUBSCRIPTION_MUTATION = gql`
  mutation AddPlusSubscription($boardId: String!) {
    createProductApplication(input: { board: $boardId }) {
      productApplication {
        id
      }
    }
  }
`

export const useAddPlusSubscription = () =>
  useMutation<AddPlusSubscription, AddPlusSubscriptionVariables>(ADD_PLUS_SUBSCRIPTION_MUTATION)
