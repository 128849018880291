import { useEffect, useState } from 'react'

interface IMessageSet {
  id: string
  title: string
  description: string | null
  icon: string | null
}

export const useGetMessageSets = () => {
  const [data, setData] = useState<IMessageSet[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    fetch(`https://platform.vestaboard.com/v3.0/message-sets`, {})
      .then(res => res.json())
      .then(result => {
        setLoading(false)
        setData(result?.messageSets || [])
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return { data, loading }
}
