import React, { useState } from 'react'
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { useHistory, useLocation } from 'react-router'
import { ListUpcoming } from '../components/ListUpcoming'
import { Categories } from '../components/Categories'
import { PicksHistory } from '../components/PicksHistory'

const tabs = ['Upcoming', 'History', 'Categories']

export const Picks = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tabName = query.get('tab') || 'Upcoming'
  const tab = tabs.indexOf(tabName)
  const [tabState, setTabState] = useState(tab)

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>
          {tabName === 'History' ? 'Picks History' : tabName === 'Categories' ? 'Picks Categories' : 'Upcoming Picks'}
        </Typography>
        <Button
          onClick={() => history.push(tabName === 'Categories' ? '/picks/categories/create' : '/picks/create')}
          variant='contained'
          color='primary'>
          {tabName === 'Categories' ? 'Create New Pick Category' : 'Create New Pick'}
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      <Tabs
        value={tabState}
        onChange={(_event, newValue) => {
          setTabState(newValue)
          history.push(`/picks?tab=${tabs[newValue]}`)
        }}>
        {tabs.map(tab => (
          <Tab label={tab} id={tab} key={tab} />
        ))}
      </Tabs>
      <Spacer size='large' />
      {tabName === 'Upcoming' ? <ListUpcoming /> : null}
      {tabName === 'History' ? <PicksHistory /> : null}
      {tabName === 'Categories' ? <Categories /> : null}
    </Box>
  )
}
