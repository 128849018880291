import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost'
import { OSVersions, OSVersionsVariables } from './__generated__/OSVersions';

export const OS_VERSIONS_QUERY = gql`
  query OSVersions($page: Int!, $perPage: Int!) {
    osVersions(page: $page, perPage: $perPage) {
      id
      created
      commitSha
      hawkbitSoftwareModule
      production
      deploy
      metadata
      deployedBy {
        id
        firstName
        lastName
      }
    #   do not generate signed downloadLink for every os version in list
    #   downloadLink
    }
  }
`

export const useOsVersions = ({ page, perPage }: { page?: number; perPage?: number }) => {
  return useQuery<OSVersions, OSVersionsVariables>(OS_VERSIONS_QUERY, {
    variables: {
      page: page || 0,
      perPage: perPage || 1000
    }
  })
}
