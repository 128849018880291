import React from 'react'
import { useGetBoardsReport } from '../hooks/useGetBoardsReport'
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { Spacer } from '@vestaboard/installables'
import { MessagesSent } from '../components/MessagesSent'
import { formatReportLegend } from '../../../utils/formatReportLegend'
import { BoardsOverTimeChart } from '../components/BoardsOverTime'
import { FirmwareOverTime } from '../components/FirmwareOverTime'
import { ActiveOverTime } from '../components/ActiveOverTime'
import { sorter } from '../utils/sorter'
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useGetMessageSources } from '../hooks/useGetMessageSources'
import { CsvSimpleExport } from '../../../ui/CsvSimpleExport'
import moment from 'moment'

interface IBoardsOverTime {
  rangeType: string
  searchKey: string | null
}

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    padding: 24
  },
  title: {
    color: '#FFF',
    fontSize: 24,
    paddingBottom: 24,
    fontWeight: 500
  },
  text: {
    padding: 24,
    paddingTop: 7
  },
  chart: {
    height: 350,
    padding: 24,
    marginBottom: 80,
    width: 'calc(100% - 48px)'
  }
})

const CURRENT_YEAR = new Date().getFullYear()
const CURRENT_WEEK_OF_THE_YEAR = moment().week()
const CURRENT_MONTH = new Date().getMonth()

export const BoardsOverTime = (props: IBoardsOverTime) => {
  const { data, loading, error } = useGetBoardsReport(props.rangeType, props.searchKey)
  const messageSources = useGetMessageSources({
    rangeType: props.rangeType,
    searchKey: props.searchKey
  })
  const classes = useStyles()
  const year = (props?.searchKey || '').split('-')[0] || `${CURRENT_YEAR}`

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress />
      </Box>
    )
  }

  if (error || !data) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.title}>No data found</Typography>
        <Typography className={classes.text}>
          There is no data for this time range. Board report data has not been fully backfilled and we can't predict the
          future.
          <br />
          Consider consulting the Magic 8 Board installable for all such inquiries.
        </Typography>
      </Box>
    )
  }

  const filterEmptyData = (key: string, index: number) => {
    if (`${CURRENT_YEAR}` === year) {
      // Exclude current week if range is year_weekly
      if (props.rangeType === 'year_weekly') {
        if (CURRENT_WEEK_OF_THE_YEAR <= index) {
          return false
        }
      }

      // Exclude current month if range is year
      if (props.rangeType === 'year') {
        if (CURRENT_MONTH <= index) {
          return false
        }
      }
    }

    // Exclude any days that we don't have data for
    return (data?.records?.[key]?.pairedBoards || 0) > 0
  }

  const northStarMetric = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      '% Active': +(
        ((data?.records?.[key]?.activeBoards || 0) / (data?.records?.[key]?.pairedBoards || 0)) *
        100
      ).toFixed(2),
      activeBoards: data?.records?.[key]?.activeBoards || 0,
      pairedBoards: data?.records?.[key]?.pairedBoards || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const plusEnabledBoards = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => {
      const row = data?.records?.[key]

      return {
        '% Attachment': parseFloat(
          (row?.plusBoards ? ((row?.plusBoards || 0) / (row?.pairedBoards || 0)) * 100 : 0).toFixed(2)
        ),
        key: formatReportLegend(props.rangeType, key)
      }
    })

  const pairedOnlineAndPlusBoards = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      Paired: data?.records?.[key]?.pairedBoards || 0,
      Online: data?.records?.[key]?.onlineBoards || 0,
      Plus: data?.records?.[key]?.plusBoards || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const messagesSent = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      Messages: data?.records?.[key]?.messagesInPastDay || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const averageMessagesPerBoard = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      Messages: +((data?.records?.[key]?.messagesInPastDay || 0) / (data?.records?.[key]?.pairedBoards || 0)).toFixed(
        2
      ),
      messagesCount: data?.records?.[key]?.messagesInPastDay || 0,
      boardsCount: data?.records?.[key]?.pairedBoards || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const averageUsersPerBoard = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map((key, i) => ({
      Users: +((messageSources?.data?.[i]?.['Board Persons'] || 0) / (data?.records?.[key]?.pairedBoards || 0)).toFixed(
        3
      ),
      usersCount: messageSources?.data?.[i]?.['Board Persons'] || 0,
      boardsCount: data?.records?.[key]?.pairedBoards || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const messageSendingUsers = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map((key, i) => ({
      Users: +(messageSources?.data?.[i]?.['Message Sending Persons'] || 0).toFixed(2),
      totalUsersCount: messageSources?.data?.[i]?.['Board Persons'] || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const enabledChannels = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      'Enabled Channels': data?.records?.[key]?.enabledChannels || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const channelSubscriptions = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      Channels: data?.records?.[key]?.channels || 0,
      'Enabled Channels': data?.records?.[key]?.enabledChannels || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  const firmwareVersions = Object.keys(data?.records || {})
    .filter(filterEmptyData)
    .sort(sorter)
    .map(key => ({
      PVT: data?.records?.[key]?.firmwareVersion['PVT'] || 0,
      'Yocto 3': data?.records?.[key]?.firmwareVersion['YOCTO3'] || 0,
      'Yocto 1': data?.records?.[key]?.firmwareVersion['YOCTO1'] || 0,
      Unknown: data?.records?.[key]?.firmwareVersion['Unknown'] || 0,
      key: formatReportLegend(props.rangeType, key)
    }))

  return (
    <Box className={classes.root}>
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Northstar Metric
          <span role='img' aria-label='North Star'>
            ✨
          </span>
          <CsvSimpleExport data={northStarMetric} title={`northstar-${props.rangeType}-${props.searchKey}`} />
        </Typography>
        <ResponsiveContainer>
          <ComposedChart data={northStarMetric} syncId='timeSeries'>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' scale='point' />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
            <Area type='monotone' dataKey='% Active' fill='rgb(255, 184, 28)' stroke='rgb(255, 184, 28)' />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Plus Enabled Boards
          <CsvSimpleExport
            data={plusEnabledBoards}
            title={`plus-enabled-boards-${props.rangeType}-${props.searchKey}`}
          />
        </Typography>
        <ResponsiveContainer>
          <ComposedChart maxBarSize={20} data={plusEnabledBoards} syncId='timeSeries'>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
            <Area type='monotone' dataKey='% Attachment' fill='#27AE60' stroke='#27AE60' />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Active Boards
          <CsvSimpleExport data={northStarMetric} title={`active-boards-${props.rangeType}-${props.searchKey}`} />
        </Typography>
        <ActiveOverTime
          data={Object.keys(data?.records || {})
            .filter(filterEmptyData)
            .sort(sorter)
            .map(key => ({
              Active: data?.records?.[key]?.activeBoards || 0,
              key: formatReportLegend(props.rangeType, key)
            }))}
        />
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Paired / Online / Plus Boards
          <CsvSimpleExport
            data={pairedOnlineAndPlusBoards}
            title={`paired-online-plus-${props.rangeType}-${props.searchKey}`}
          />
        </Typography>
        <BoardsOverTimeChart data={pairedOnlineAndPlusBoards} />
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Messages Sent
          <CsvSimpleExport data={messagesSent} title={`messages-sent-${props.rangeType}-${props.searchKey}`} />
        </Typography>
        <MessagesSent data={messagesSent} />
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Average Daily Messages Per Board
          <CsvSimpleExport
            data={averageMessagesPerBoard}
            title={`average-messages-per-board-${props.rangeType}-${props.searchKey}`}
          />
        </Typography>
        <ResponsiveContainer>
          <ComposedChart maxBarSize={20} data={averageMessagesPerBoard} syncId='timeSeries'>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
            <Area type='monotone' dataKey='Messages' fill='rgb(218, 41, 28)' stroke='rgb(218, 41, 28)' />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Spacer size='extraLarge' />
      {averageUsersPerBoard.filter(item => item.usersCount > 0).length > 0 ? (
        <>
          <Box className={classes.chart}>
            <Typography className={classes.title}>
              Average Users Per Board
              <CsvSimpleExport
                data={averageUsersPerBoard}
                title={`average-users-per-board-${props.rangeType}-${props.searchKey}`}
              />
            </Typography>
            <ResponsiveContainer>
              <ComposedChart maxBarSize={20} data={averageUsersPerBoard} syncId='timeSeries'>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='key' />
                <YAxis domain={['dataMin', 'dataMax']} />
                <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
                <Area type='monotone' dataKey='Users' fill='rgba(0, 0, 0, 0.5)' stroke='#000000' />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
          <Spacer size='extraLarge' />
        </>
      ) : null}
      {messageSendingUsers.filter(item => item.totalUsersCount > 0).length > 0 ? (
        <>
          <Box className={classes.chart}>
            <Typography className={classes.title}>
              Users Who Have Sent a Message
              <CsvSimpleExport
                data={messageSendingUsers}
                title={`message-sending-users-${props.rangeType}-${props.searchKey}`}
              />
            </Typography>
            <ResponsiveContainer>
              <ComposedChart maxBarSize={20} data={messageSendingUsers} syncId='timeSeries'>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='key' />
                <YAxis domain={['dataMin', 'dataMax']} />
                <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
                <Area type='monotone' dataKey='Users' fill='rgba(0, 0, 0, 0.5)' stroke='#000000' />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
          <Spacer size='extraLarge' />
        </>
      ) : null}
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Channel Subscriptions
          <CsvSimpleExport
            data={channelSubscriptions}
            title={`channel-subscriptions-${props.rangeType}-${props.searchKey}`}
          />
        </Typography>
        <ResponsiveContainer>
          <ComposedChart data={channelSubscriptions} syncId='timeSeries'>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
            <Area type='monotone' dataKey='Channels' fill='rgb(0, 132, 213)' stroke='rgb(0, 132, 213)' />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Spacer size='extraLarge' />
      <Box className={classes.chart}>
        <Typography className={classes.title}>
          Enabled Channels
          <CsvSimpleExport
            data={enabledChannels}
            title={`channel-subscriptions-${props.rangeType}-${props.searchKey}`}
          />
        </Typography>
        <ResponsiveContainer>
          <ComposedChart data={enabledChannels} syncId='timeSeries'>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
            <Area type='monotone' dataKey='Enabled Channels' fill='rgb(112, 47, 138)' stroke='rgb(112, 47, 138)' />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      {props.rangeType === 'week' || props.rangeType === 'month' ? (
        <>
          <Spacer size='extraLarge' />
          <Box className={classes.chart}>
            <Typography className={classes.title}>
              Firmware Versions
              <CsvSimpleExport
                data={firmwareVersions}
                title={`firmware-versions-${props.rangeType}-${props.searchKey}`}
              />
            </Typography>
            <FirmwareOverTime data={firmwareVersions} />
          </Box>
        </>
      ) : null}
    </Box>
  )
}
