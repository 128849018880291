import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateScheduledNotification,
  UpdateScheduledNotificationVariables
} from './__generated__/UpdateScheduledNotification'

const MUTATION = gql`
  mutation UpdateScheduledNotification($input: UpdateScheduledNotificationInputV2!) {
    updateScheduledNotificationV2(input: $input) {
      ... on ScheduledNotificationV2 {
        id
        createdAt
        sendAt
        message
        title
        callToAction
        deepLink
        tag {
          id
          title
        }
        subscribers {
          id
          title
        }
      }
      ... on ScheduledNotificationErrorV2 {
        type
        error
      }
    }
  }
`

export const useUpdateScheduledNotification = () =>
  useMutation<UpdateScheduledNotification, UpdateScheduledNotificationVariables>(MUTATION)
