import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { MessageCreator } from './MessageCreator'
import { Alert } from '@material-ui/lab'
import { DragIndicator, RemoveCircle } from '@material-ui/icons'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { formatQAMessage } from '../utils/formatQAMessage'
import { IHeader } from '../types'
import { vbml } from '@vestaboard/vbml'

interface IMessageList {
  headerRow?: IHeader
  data: number[][][]
  title: string
  setData: (data: number[][][]) => void
}

export const MessageList = (props: IMessageList) => {
  const [isAdding, setIsAdding] = useState(false)
  const [editing, setEditing] = useState<null | {
    index: number
    value: IBoard
  }>(null)

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newData = Array.from(props.data)
      const [removed] = newData.splice(startIndex, 1)
      newData.splice(endIndex, 0, removed)
      props.setData(newData)
    },
    [props]
  )

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h6'>{props.title}</Typography>
        <Button
          variant='outlined'
          onClick={() => {
            setIsAdding(true)
          }}>
          Add {props.title}
        </Button>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      {props.data.length ? (
        <TableContainer component={Paper} elevation={10}>
          <Table>
            <TableBody component={DroppableTableBody(onDragEnd)}>
              {props.data.map((message, index) => (
                <TableRow key={index} component={DraggableTableRow(JSON.stringify(message), index)}>
                  <TableCell style={{ width: 80 }}>
                    <DragIndicator />
                  </TableCell>
                  <TableCell>
                    <ButtonBase
                      onClick={() => {
                        const messageCopy = vbml.copyCharacterCodes(message)
                        if (props.headerRow) {
                          messageCopy.shift()
                        }

                        setEditing({
                          index,
                          value: formatQAMessage(messageCopy) as IBoard
                        })
                      }}>
                      <Box width={200}>
                        <BoardPreview characters={message as IBoard} />
                      </Box>
                    </ButtonBase>
                  </TableCell>
                  <TableCell style={{ width: 70 }}>
                    <ButtonBase
                      onClick={() => {
                        const newData = Array.from(props.data)
                        newData.splice(index, 1)
                        props.setData(newData)
                      }}>
                      <RemoveCircle />
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity='info'>No {props.title} yet</Alert>
      )}
      <MessageCreator
        visible={isAdding}
        setVisible={setIsAdding}
        onSave={message => {
          props.setData([...props.data, props.headerRow ? formatQAMessage(message, props.headerRow) : message])
        }}
      />
      {editing ? (
        <MessageCreator
          visible={true}
          setVisible={() => setEditing(null)}
          defaultValue={editing?.value}
          onSave={message => {
            const newData = [...props.data]
            newData[editing!.index] = props.headerRow
              ? formatQAMessage(vbml.copyCharacterCodes(message), props.headerRow)
              : message

            props.setData(newData)
            setEditing(null)
          }}
        />
      ) : null}
    </>
  )
}
