import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core'
import React from 'react'
import { DashboardSwitcher } from './DashboardSwitcher'
import { Spacer } from '@vestaboard/installables'

interface IReportLayout {
  children: React.ReactNode
  title: string
  filters: React.ReactNode
}

const useStyles = makeStyles({
  container: {
    background: '#222 ',
    position: 'relative',
    top: -24,
    left: -24,
    width: 'calc(100% + 48px)',
    minHeight: 'calc(100vh + 48px)',
    height: '100%'
  },
  appBar: {
    top: 64,
    backgroundColor: '#222'
  },
  bottom: {
    position: 'absolute',
    bottom: -50,
    height: 50,
    width: '100%',
    right: 0,
    background: '#222 '
  },
  filter: {
    display: 'flex'
  }
})

export const ReportLayout = (props: IReportLayout) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <AppBar position='fixed' color='transparent' className={classes.appBar}>
        <Toolbar>
          <DashboardSwitcher title={props.title} />
          <Box flex={1} />
          <Box className={classes.filter}>{props.filters}</Box>
        </Toolbar>
      </AppBar>
      <Box className={classes.bottom} />
      <Spacer size='extraLarge' />
      <Spacer size='extraLarge' />
      {props.children}
    </Box>
  )
}
