import * as React from 'react'
import { DailyBoardsDashboard } from '../reports/DailyBoardsDashboard'
import { RangeType } from '../../../ui/RangeType'
import moment from 'moment'
import { BoardsOverTime } from '../reports/BoardsOverTime'
import { YearPicker } from '../../../ui/YearPicker'
import { WeekPicker } from '../../../ui/WeekPicker'
import { MonthPicker } from '../../../ui/MonthPicker'
import { ReportLayout } from '../../../ui/ReportLayout'
import { DisabledDateButton } from '../../../ui/DisabledDateButton'
import { ReportPreviousButton } from '../../../ui/ReportPreviousButton'
import { ReportNextButton } from '../../../ui/ReportNextButton'
import { getReportStateFromHash } from '../../../utils/getReportStateFromHash'

export const DashboardBoards = () => {
  const [date, setDate] = React.useState<string | null>(null)
  const [{ rangeType, searchKey }, setSearchState] = React.useState<{
    rangeType: string
    searchKey: string | null
  }>(
    getReportStateFromHash() || {
      rangeType: 'day',
      searchKey: null
    }
  )

  const setSearch = React.useCallback(({ rangeType, searchKey }) => {
    setSearchState({ rangeType, searchKey })
    window.location.hash = `#${rangeType}|${searchKey}`
  }, [])

  return (
    <ReportLayout
      title='Boards Report'
      filters={
        <>
          {rangeType === 'week' ? (
            <WeekPicker
              year={searchKey?.split('-')[0] || '2021'}
              value={searchKey?.split('-')[1] || '1'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 1 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          {rangeType === 'month' ? (
            <MonthPicker
              value={searchKey?.split('-')[1] || '1'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 1 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          {rangeType === 'day' ? <DisabledDateButton date={date} /> : null}
          {rangeType !== 'day' ? (
            <YearPicker
              value={searchKey?.split('-')[0] || '2021'}
              setValue={(value: string) => {
                setSearch({
                  rangeType,
                  searchKey:
                    searchKey
                      ?.split('-')
                      ?.map((v, i) => (i === 0 ? value : v))
                      .join('-') || null
                })
              }}
            />
          ) : null}
          <RangeType
            setSearch={setSearch}
            rangeType={rangeType}
            types={['day', 'week', 'month', 'year', 'year_weekly']}
          />
          <ReportPreviousButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.subtract(1, 'day').format('YYYY-MM-DD')
                })
              }
              if (rangeType === 'month') {
                const [year, month] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${month === '1' ? `${+year - 1}` : year}-${month === '1' ? '12' : +month - 1}`
                })
              }

              if (rangeType === 'week') {
                const [year, week] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${week === '1' ? `${+year - 1}` : year}-${week === '1' ? '52' : +week - 1}`
                })
              }
            }}
          />
          <ReportNextButton
            disabled={rangeType === 'all_time'}
            onClick={() => {
              if (rangeType === 'day') {
                const currentDay = moment(searchKey || date || undefined)
                setSearch({
                  rangeType,
                  searchKey: currentDay.add(1, 'day').format('YYYY-MM-DD')
                })
              }

              if (rangeType === 'month') {
                const [year, month] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${month === '12' ? `${+year + 1}` : year}-${month === '12' ? '1' : +month + 1}`
                })
              }

              if (rangeType === 'week') {
                const [year, week] = searchKey?.split('-') || []
                setSearch({
                  rangeType,
                  searchKey: `${week === '52' ? `${+year + 1}` : year}-${week === '52' ? '1' : +week + 1}`
                })
              }
            }}
          />
        </>
      }>
      {rangeType === 'day' ? (
        <DailyBoardsDashboard setDate={setDate} searchKey={searchKey || null} />
      ) : (
        <BoardsOverTime searchKey={searchKey || null} rangeType={rangeType} />
      )}
    </ReportLayout>
  )
}
