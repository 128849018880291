import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { MessageSetVersionSubmissionListQuery } from './__generated__/MessageSetVersionSubmissionListQuery'
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Table,
  TableCell,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core'
import { FormattedDateTime } from '../../formatted-date-time'
import { useHistory } from 'react-router'
import { TruncatedId } from '../../truncated-id'
import { Alert } from '@material-ui/lab'
import { Confirm, FlexHorizontal, Spacer } from '@vestaboard/installables'
import { SearchInput } from '../../../ui/SearchInput'
import { useAuth0 } from '../../../react-auth0-spa'

type MessageSetVersionSubmissionListProps = IMessageSetVersionSubmissionListProps

interface IMessageSetVersionSubmissionListProps {}

const QUERY = gql`
  query MessageSetVersionSubmissionListQuery {
    messageSetVersionSubmissions {
      id
      created
      icon {
        cdnUrl
      }
      title
      description
      messageSetVersion {
        messageSet {
          id
          public
        }
        id
      }
    }
  }
`

export const MessageSetVersionSubmissionList: React.FC<MessageSetVersionSubmissionListProps> = props => {
  const perPage = 30
  const { getTokenSilently } = useAuth0()
  const { data, loading, error } = useQuery<MessageSetVersionSubmissionListQuery>(QUERY)
  const [removedMessageSets, setRemovedMessageSets] = React.useState<string[]>([])

  const [confirmApprove, setConfirmApprove] = React.useState<string | null>(null)
  const [page, setPage] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [confirmDeny, setConfirmDeny] = React.useState<string | null>(null)

  const filteredMessageSets = (data?.messageSetVersionSubmissions || []).filter(
    messageSet =>
      !removedMessageSets.includes(messageSet.id) &&
      ((messageSet && messageSet.id.includes(search)) ||
        (messageSet.title || '').toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  )

  const startIndex = perPage * page
  const endIndex = page * perPage + perPage
  const visibleMessageSets = (filteredMessageSets || []).slice(startIndex, endIndex)
  const history = useHistory()

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message Set Submissions</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <FlexHorizontal spaceBetween>
        <Tabs
          value={1}
          onChange={(_event, newValue) => {
            if (newValue === 0) {
              history.push(`/message-sets`)
            }
          }}>
          <Tab label='Message Sets' id='message-sets' />
          <Tab label='Message Set Submissions' id='message-set-submissions' />
        </Tabs>
        <SearchInput onSearch={setSearch} />
      </FlexHorizontal>
      <Spacer size='large' />
      <Confirm
        title='Deny Message Set Submission?'
        message='Are you sure you want to deny this submission?'
        open={!!confirmDeny}
        handleClose={() => setConfirmDeny(null)}
        handleAccept={async () => {
          if (confirmDeny) {
            setRemovedMessageSets(removedMessageSets => [...removedMessageSets, confirmDeny])
            setConfirmDeny(null)

            // This is hacky, but doing it through Apollo is throwing a weird error I can't figure out the cause of
            await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
              method: 'post',
              headers: {
                'X-Vestaboard-Token': await getTokenSilently()
              },
              body: JSON.stringify({
                operationName: 'DenyMessageSetMutation',
                query: `mutation DenyMessageSetMutation($input: DeleteMessageSetVersionSubmissionInput!) {
                  adminDeleteMessageSetVersionSubmission(input: $input) {
                    id
                  }
                }`,
                variables: {
                  input: {
                    messageSetVersionSubmissionId: confirmDeny
                  }
                }
              })
            }).then(res => res.json())
          }
        }}
      />
      <Confirm
        title='Approve Message Set Submission?'
        message='Are you sure you want to approve this submission?'
        open={!!confirmApprove}
        handleClose={() => setConfirmApprove(null)}
        handleAccept={async () => {
          if (confirmApprove) {
            setRemovedMessageSets(removedMessageSets => [...removedMessageSets, confirmApprove])
            setConfirmApprove(null)

            // This is hacky, but doing it through Apollo is throwing a weird error I can't figure out the cause of
            await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
              method: 'post',
              headers: {
                'X-Vestaboard-Token': await getTokenSilently(),
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                operationName: 'ApproveMessageSetMutation',
                query: `mutation ApproveMessageSetMutation($submissionId: String!) {
                  approveMessageSetVersionSubmission(input: { submissionId: $submissionId, moderationResult: "Approved" }) {
                    success
                  }
                }`,
                variables: { submissionId: confirmApprove }
              })
            }).then(res => res.json())
          }
        }}
      />
      {error ? (
        <Alert severity='error'>Unable to load message set submissions</Alert>
      ) : loading || !data ? (
        <CircularProgress />
      ) : (
        <Card>
          <Table>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell style={{ width: 120 }}>Submitted</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {visibleMessageSets.map(submission => (
              <TableRow key={submission.id}>
                <TableCell>
                  {submission.icon ? (
                    <Avatar src={submission.icon.cdnUrl} />
                  ) : (
                    <Avatar>{submission.title?.split('')[0]}</Avatar>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() =>
                      history.push(
                        `/message-sets/${submission.messageSetVersion.messageSet.id}/${submission.messageSetVersion.id}?title=${submission.title}`
                      )
                    }>
                    <TruncatedId value={submission.messageSetVersion.messageSet.id} />
                  </Button>
                </TableCell>
                <TableCell>{submission.title}</TableCell>
                <TableCell>
                  <FormattedDateTime value={submission.created} format='MMM DD, YYYY' />
                </TableCell>
                <TableCell>{submission.description}</TableCell>
                <TableCell>
                  <FlexHorizontal>
                    <Button onClick={() => setConfirmApprove(submission.id)} variant='outlined'>
                      Approve
                    </Button>
                    <div style={{ width: 12 }} />
                    <Button onClick={() => setConfirmDeny(submission.id)} variant='outlined'>
                      Deny
                    </Button>
                  </FlexHorizontal>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {filteredMessageSets?.length ? (
            <TablePagination
              rowsPerPageOptions={[]}
              count={filteredMessageSets?.length}
              rowsPerPage={perPage}
              page={page}
              onPageChange={(_, page) => {
                setPage(page)
              }}
              onChangeRowsPerPage={() => {}}
            />
          ) : null}
        </Card>
      )}
    </Box>
  )
}
