import React from 'react'
import { Route, Switch } from 'react-router'
import { Picks } from './pages/ListPicks'
import { PickCreate } from './pages/CreatePick'
import { PickUpdate } from './pages/UpdatePick'
import { CreatePickCategory } from './pages/CreatePickCategory'
import { UpdatePickCategory } from './pages/UpdatePickCategory'

export const PicksRouterV1 = () => (
  <Switch>
    <Route exact path={`/picks`} component={Picks} />
    <Route exact path={`/picks/create`} component={PickCreate} />
    <Route exact path={`/picks/:id`} component={PickUpdate} />
    <Route exact path={`/picks/categories/create`} component={CreatePickCategory} />
    <Route exact path={`/picks/categories/:id`} component={UpdatePickCategory} />
  </Switch>
)
