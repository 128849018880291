import React from 'react'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { useCreateColorTemplate } from '../hooks/useCreateColorTemplate'
import { ColorTemplateForm } from '../components/ColorTemplateForm'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router'

export const CreateColorTemplate = () => {
  const [createColorTemplate, { loading: saving }] = useCreateColorTemplate()
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Color Template</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <ColorTemplateForm
        onSave={async input => {
          const response = await createColorTemplate({
            variables: {
              input: {
                title: input.title,
                messageIds: (input.messages || [])?.map(message => message.id)
              }
            }
          })

          if (response.data?.createColorTemplate?.__typename === 'ColorTemplateV2') {
            history.push(`/color-templates/${response.data.createColorTemplate.id}`)
          } else {
            addToast(response?.data?.createColorTemplate?.error || 'There was an error creating your color template', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}
      />
    </>
  )
}
