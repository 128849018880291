import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ModerateFeedItemV2, ModerateFeedItemV2Variables } from './__generated__/ModerateFeedItemV2'

const MUTATION = gql`
  mutation ModerateFeedItemV2($input: ModerateFeedItemInputV2!) {
    moderateFeedItem: moderateFeedItemV2(input: $input) {
      __typename
      ... on FeedItemV2 {
        id
        moderated
      }
      ... on FeedItemErrorV2 {
        type
        error
      }
    }
  }
`

export const useModerate = () => {
  return useMutation<ModerateFeedItemV2, ModerateFeedItemV2Variables>(MUTATION)
}
