import React from 'react'
import { CsvImportButton } from '../../../ui/CsvImportButton'
import { useAdminUpdateBoard } from '../../../api/useAdminUpdateBoard'
interface IResponse {
  boardId?: string
  hubspotDealId?: string
}

export const CsvImport = () => {
  const [updateBoard] = useAdminUpdateBoard()

  return (
    <CsvImportButton
      mappings={['boardId', 'hubspotDealId']}
      handleImport={async records => {
        await Promise.all(
          (records as IResponse[]).map(record => {
            if (!!record.boardId) {
              return updateBoard({
                variables: {
                  input: {
                    boardId: record.boardId,
                    hubspotDealId: record.hubspotDealId
                  }
                }
              })
            }

            return null
          })
        )
      }}
    />
  )
}
