import { CircularProgress, Typography } from '@material-ui/core'
import { Confirm, FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { useHistory, useParams } from 'react-router'

import { Alert } from '@material-ui/lab'
import { GetPickV2_pick_PickV2 } from '../hooks/__generated__/GetPickV2'
import { PickForm } from '../components/PickForm'
import React from 'react'
import { useDeletePick } from '../hooks/useDeletePick'
import { useGetPick } from '../hooks/useGetPick'
import { useUpdatePick } from '../hooks/useUpdatePick'

export const UpdatePick = () => {
  const params = useParams<{ id: string }>()
  const { data, loading, error } = useGetPick(params.id)
  const [updatePick, { loading: saving }] = useUpdatePick()
  const [deletePick, { loading: deleting }] = useDeletePick()
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      {saving || deleting ? <LoadMask /> : null}
      <Confirm
        title='Delete Color Template? This cannot be undone!'
        handleClose={() => {
          setConfirmDelete(false)
        }}
        open={confirmDelete}
        handleAccept={async () => {
          const response = await deletePick({
            variables: {
              input: {
                id: params.id
              }
            }
          })

          if (response.data?.deletePick?.__typename === 'PickV2') {
            history.push(`/picks`)
          } else {
            addToast(response?.data?.deletePick?.error || 'There was an error updating your pick', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}></Confirm>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Update Pick</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading this pick</Alert>
      ) : data?.pick.__typename === 'PickErrorV2' ? (
        <Alert severity='error'>{data.pick.error}</Alert>
      ) : (
        <PickForm
          pick={data?.pick as GetPickV2_pick_PickV2}
          onDelete={() => {
            setConfirmDelete(true)
          }}
          onSave={async pick => {
            const response = await updatePick({
              variables: {
                input: {
                  ...pick,
                  order: pick.order ? pick.order / 1000 : 0,
                  id: params.id
                }
              }
            })

            if (response.data?.updatePick?.__typename === 'PickV2') {
              history.push('/picks')
            } else {
              addToast(response?.data?.updatePick?.error || 'There was an error updating your pick', {
                appearance: 'error',
                autoDismiss: true
              })
            }
          }}
        />
      )}
    </>
  )
}
