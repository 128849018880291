import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetAcceptedReferral, GetAcceptedReferralVariables } from './__generated__/GetAcceptedReferral'

const QUERY = gql`
  query GetAcceptedReferral($id: String!) {
    acceptedReferral: acceptedReferralV2(id: $id) {
      ... on AcceptedReferralV2 {
        id
        notified
        createdAt
        purchaseId
        invalidated
        shopifyOrderId
        shopifyOrderName
        shopifyCustomerName
        shopifyCustomerEmail
        referrerPersonId
        referralCode
        referrerName
        referrerEmail
        orderDeliveredAt
        creditRedeemed
        creditType
      }
      ... on AcceptedReferralErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetAcceptedReferral = (id: string) =>
  useQuery<GetAcceptedReferral, GetAcceptedReferralVariables>(QUERY, {
    variables: {
      id
    }
  })
