import React from 'react'
import { Confirm, FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { ColorTemplateForm } from '../components/ColorTemplateForm'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import { useUpdateColorTemplate } from '../hooks/useUpdateColorTemplate'
import { useGetColorTemplate } from '../hooks/useGetColorTemplate'
import { Alert } from '@material-ui/lab'
import { useDeleteColorTemplate } from '../hooks/useDeleteColorTemplate'

export const UpdateColorTemplate = () => {
  const params = useParams<{ id: string }>()
  const { loading, data, error } = useGetColorTemplate(params.id)
  const [updateColorTemplate, { loading: saving }] = useUpdateColorTemplate()
  const [deleteColorTemplate, { loading: deleting }] = useDeleteColorTemplate()
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      {saving || deleting ? <LoadMask /> : null}
      <Confirm
        title='Delete Color Template? This cannot be undone!'
        handleClose={() => {
          setConfirmDelete(false)
        }}
        open={confirmDelete}
        handleAccept={async () => {
          const response = await deleteColorTemplate({
            variables: {
              input: {
                id: params.id
              }
            }
          })

          if (response.data?.deleteColorTemplate?.__typename === 'ColorTemplateV2') {
            history.push(`/color-templates`)
          } else {
            addToast(response?.data?.deleteColorTemplate?.error || 'There was an error updating your color template', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}></Confirm>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Update Color Template</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading this color template</Alert>
      ) : data?.colorTemplate?.__typename !== 'ColorTemplateV2' ? (
        <Alert severity='error'>{data?.colorTemplate.error || '!!!'}</Alert>
      ) : (
        <ColorTemplateForm
          colorTemplate={data.colorTemplate}
          onDelete={() => {
            setConfirmDelete(true)
          }}
          onSave={async input => {
            const response = await updateColorTemplate({
              variables: {
                input: {
                  id: params.id,
                  title: input.title,
                  messageIds: (input.messages || [])?.map(message => message.id)
                }
              }
            })

            if (response.data?.updateColorTemplate?.__typename === 'ColorTemplateV2') {
              history.push(`/color-templates`)
            } else {
              addToast(
                response?.data?.updateColorTemplate?.error || 'There was an error updating your color template',
                {
                  appearance: 'error',
                  autoDismiss: true
                }
              )
            }
          }}
        />
      )}
    </>
  )
}
