import { BoardPreview, FullModal, IBoard, Spacer } from '@vestaboard/installables'
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'

import { Alert } from '@material-ui/lab'
import { FormattedDateTime } from '../../formatted-date-time'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'

interface IMessages {
  id: string
  text: string
  characters: string
  created: number
}

interface IFormattedMessage {
  id: string
  text: string
  formatted: number[][]
  created: number
}

interface IResult {
  messageSet: {
    id: string
    messagesLength: number
    title: string
    created: number
    messages: IMessages[]
  }
}

interface IDynamicMessages {
  messageSetId: string
}

const useStyles = makeStyles({
  gridPadding: {
    padding: 20
  }
})

export const DynamicMessages = (props: IDynamicMessages) => {
  const [messages, setMessages] = useState<IFormattedMessage[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const classes = useStyles()
  const [previewMessage, setPreviewMessage] = useState<IBoard | null>(null)

  const fetchMessages = useCallback(async () => {
    await fetch(`${INSTALLABLES_MONOREPO_API}/api/message-set/${props.messageSetId}`)
      .then(res => res.json())
      .then((data: IResult) => {
        setMessages(
          data.messageSet.messages.map(message => ({
            ...message,
            formatted: JSON.parse(message.characters),
            created: message.created * 1000
          }))
        )
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError('There was an error loading your messages')
      })
  }, [props.messageSetId])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  return error ? (
    <Alert severity='error'>There was an error loading your messages</Alert>
  ) : loading ? (
    <CircularProgress />
  ) : (
    <>
      {previewMessage ? (
        <FullModal onClose={() => setPreviewMessage(null)}>
          <BoardPreview characters={previewMessage} />
        </FullModal>
      ) : null}
      <Typography variant='h5'>Messages</Typography>
      <Spacer size='large' />
      <Card>
        <Box className={classes.gridPadding}>
          {messages?.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map(message => (
                  <TableRow key={message.id}>
                    <TableCell>
                      <FormattedDateTime value={message.created} />
                    </TableCell>
                    <TableCell onClick={() => setPreviewMessage(message.formatted as IBoard)}>
                      <BoardPreview characters={message.formatted as IBoard} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert severity='warning'>There are no messages in this message set yet</Alert>
          )}
        </Box>
      </Card>
    </>
  )
}
