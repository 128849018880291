import {
  SaveSystemMessageTemplate,
  SaveSystemMessageTemplateVariables
} from './__generated__/SaveSystemMessageTemplate'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation SaveSystemMessageTemplate($input: SaveSystemMessageTemplateInputV2!) {
    saveSystemMessageTemplateV2(input: $input) {
      ... on SystemMessageTemplateErrorV2 {
        type
        error
      }
      ... on SystemMessageTemplateV2 {
        id
        title
        template
        deepLink
        callToAction
      }
    }
  }
`

export const useSaveSystemMessageTemplate = () =>
  useMutation<SaveSystemMessageTemplate, SaveSystemMessageTemplateVariables>(MUTATION)
