import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useListScheduledNotifications } from '../hooks/useListScheduledNotifications'
import { TruncatedId } from '../../truncated-id'
import { Check } from '@material-ui/icons'
import { FormattedDateTime } from '../../formatted-date-time'
import InfiniteScroll from 'react-infinite-scroller'
import { Alert } from '@material-ui/lab'
import { ListTab } from '../components/ListTab'

export const ListNotifications = () => {
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const input = {
    cursor: null,
    limit: 25
  }

  const { loading, error, data, fetchMore } = useListScheduledNotifications({
    input
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Notifications</Typography>
        <FlexHorizontal>
          <Button onClick={() => history.push('/notifications/create')} variant='contained' color='primary'>
            Send Notification
          </Button>
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <ListTab activeTab='scheduled' />
      {loading && !data?.listScheduledNotifications.scheduledNotifications ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the notifications</Alert>
      ) : !data?.listScheduledNotifications.scheduledNotifications.length ? (
        <Alert severity='info'>There are no notifications</Alert>
      ) : (
        <>
          {data ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={async () => {
                if (isLoadingMore) {
                  return
                }

                setIsLoadingMore(true)
                return await fetchMore({
                  updateQuery(previousQueryResult, options) {
                    const newSearch = options.fetchMoreResult?.listScheduledNotifications.scheduledNotifications || []
                    const previousSearch = previousQueryResult.listScheduledNotifications.scheduledNotifications || []

                    return {
                      listScheduledNotifications: {
                        ...previousQueryResult.listScheduledNotifications,
                        nextCursor: options.fetchMoreResult?.listScheduledNotifications.nextCursor || null,
                        scheduledNotifications: [...previousSearch, ...newSearch]
                      }
                    }
                  },
                  variables: {
                    input: {
                      ...input,
                      cursor: data.listScheduledNotifications.nextCursor || null
                    }
                  }
                }).then(res => {
                  setIsLoadingMore(false)
                  return res
                })
              }}
              hasMore={!!data?.listScheduledNotifications?.nextCursor}
              loader={
                <Box>
                  <Spacer size='large' />
                  <CircularProgress />
                </Box>
              }>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Sent</TableCell>
                      <TableCell>Send Time</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Tag</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.listScheduledNotifications.scheduledNotifications.map(scheduled => (
                      <TableRow key={scheduled.id}>
                        <TableCell>
                          <Button
                            onClick={() => history.push(`/notifications/${scheduled.id}`)}
                            variant='outlined'
                            color='primary'>
                            <TruncatedId value={scheduled.id} />
                          </Button>
                        </TableCell>
                        <TableCell>{!scheduled.sendAt || scheduled.sendAt < Date.now() ? <Check /> : null}</TableCell>
                        <TableCell>
                          <FormattedDateTime value={scheduled.sendAt || scheduled.createdAt} />
                        </TableCell>
                        <TableCell>{scheduled.title}</TableCell>
                        <TableCell>{scheduled.tag?.title}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : null}
        </>
      )}
    </Box>
  )
}
