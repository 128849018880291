import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { useGetMarketplaceListingsReport } from '../hooks/useGetMarketplaceListingsReport'
import { Alert } from '@material-ui/lab'
import { ChartContainer } from '../../../ui/ChartContainer'
import { TopChannels } from '../components/TopChannels'
import { categories } from '../categories'
import { ReportNumberBox } from '../../../ui/ReportNumberBox'
import { AllChannels } from '../components/AllChannels'
import { useGetBoardsReport } from '../hooks/useGetBoardsReport'
import { CsvSimpleExport } from '../../../ui/CsvSimpleExport'
import { Spacer } from '@vestaboard/installables'

interface IDailyInstallablesDashboard {
  searchKey?: string | null
  setDate: (date: string | null) => void
}

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    padding: 24
  },
  title: {
    color: '#FFF',
    padding: 24,
    paddingLeft: 48,
    marginTop: 24,
    fontSize: 24,
    fontWeight: 500
  }
})

export const DailyInstallablesDashboard = (props: IDailyInstallablesDashboard) => {
  const classes = useStyles()
  const [category, setCategory] = useState<string | null>(null)
  const { data, loading, error } = useGetMarketplaceListingsReport('day', props.searchKey)
  const boardsReport = useGetBoardsReport('day', props.searchKey)

  useEffect(() => {
    if (data?.date) {
      props.setDate(data.date)
    }
  }, [data, props])

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress />
      </Box>
    )
  }

  if (error || !data) {
    return (
      <Box className={classes.root}>
        <Alert severity='error'>Error loading data</Alert>
      </Box>
    )
  }

  const channelCategoriesObject = data.listings.reduce(
    (prev, current) => {
      const category = current.categories?.[0] || 'Other'
      const subscriptions = current.subscriptions

      if (current.title === 'Read & Write') {
        return prev
      }

      return {
        ...prev,
        [category]: prev[category] ? prev[category] + subscriptions : subscriptions
      }
    },
    {} as {
      [key: string]: number
    }
  )

  const channelCategories = Object.keys(channelCategoriesObject).map(key => ({
    key,
    Installed: channelCategoriesObject[key],
    fill: (categories as any)[key]
  }))

  const installed = data.listings.reduce((prev, current) => {
    return prev + current.subscriptions
  }, 0)

  const installedInPast30Days = data.listings.reduce((prev, current) => {
    return prev + current.subscriptionsInPast30Days
  }, 0)

  const trending = data.listings
    .filter(listing => listing.title !== 'Read & Write')
    .filter(listing => {
      if (!category) {
        return true
      }

      return listing.categories.includes(category)
    })
    .sort((a, b) => b.subscriptionsInPast30Days - a.subscriptionsInPast30Days)
    .filter((_, index) => index < 20)
    .map(listing => ({
      key: listing.title,
      Installed: listing.subscriptionsInPast30Days,
      fill: (categories as any)[listing.categories?.[0] as string] as string
    }))

  const channelRankings = data.listings
    .filter(listing => {
      if (!category) {
        return true
      }

      return listing.categories.includes(category)
    })
    .map(category => ({
      Title: category.title,
      Installed: category.subscriptions,
      'Installed in Past 30 Days': category.subscriptionsInPast30Days,
      Categories: category.categories.join(', ')
    }))

  return (
    <>
      <Box display='flex' justifyContent='flex-start' flexWrap='wrap' maxWidth='calc(100% - 48px)' padding={'24px'}>
        <ReportNumberBox title='Total Subscriptions' number={installed} />
        <ReportNumberBox title='New Subscriptions' number={installedInPast30Days} />
        {boardsReport?.data ? (
          <ReportNumberBox
            title='Enabled Channels Per Board'
            number={boardsReport.data.enabledChannels / boardsReport.data.pairedBoards}
            postfix='AVG'
          />
        ) : null}
        {boardsReport?.data ? (
          <ReportNumberBox
            title='Enabled Channels Per Plus Board'
            number={boardsReport.data.enabledChannels / boardsReport.data.plusBoards}
            postfix='AVG'
          />
        ) : null}
      </Box>
      <ChartContainer
        title='Subscriptions By Category'
        exportButton={
          <CsvSimpleExport
            title={`subscriptions-by-category-${data.date}`}
            data={channelCategories.map(category => ({
              Category: category.key,
              Installed: category.Installed
            }))}
          />
        }>
        <TopChannels
          data={channelCategories.sort((a, b) => b.Installed - a.Installed)}
          onBarClick={e => {
            if (category === e.key) {
              setCategory(null)
            } else {
              setCategory(e.key)
            }
          }}
        />
      </ChartContainer>
      <ChartContainer
        title='Trending'
        exportButton={<CsvSimpleExport title={`channel-rankings-${data.date}`} data={channelRankings} />}>
        <TopChannels data={trending} />
      </ChartContainer>
      <Spacer size='extraLarge' />
      <Typography className={classes.title}>
        Channel Rankings
        <CsvSimpleExport title={`channel-rankings-${data.date}`} data={channelRankings} />
      </Typography>
      <AllChannels
        data={data.listings
          .filter(listing => listing.title !== 'Read & Write')
          .filter(listing => {
            if (!category) {
              return true
            }

            return listing.categories.includes(category)
          })
          .sort((a, b) => b.subscriptions - a.subscriptions)
          .map(listing => ({
            title: listing.title,
            value: listing.subscriptions,
            fill: (categories as any)[listing.categories?.[0] as string] as string
          }))}
      />
    </>
  )
}
