import React from 'react'
import { Bar, BarChart, XAxis, YAxis } from 'recharts'

interface IAllChannels {
  data: {
    title: string
    fill: string
    value: number
  }[]
}

const renderCustomizedLabel = (input: any) => {
  const value = input?.value.toLocaleString()

  return (
    <text x={input.x + 42} y={input.y + 12} fill='white' textAnchor={'end'} dominantBaseline='central'>
      {value}
    </text>
  )
}

export const AllChannels = (props: IAllChannels) => {
  return (
    <BarChart width={1350} height={props.data.length * 30} layout='vertical' data={props.data}>
      <YAxis dataKey='title' type='category' width={250} />
      <XAxis type='number' hide />
      <Bar dataKey='value' fill='#323232' label={renderCustomizedLabel} />
    </BarChart>
  )
}
