import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  IndexBoardDeviceAssociationsMutation,
  IndexBoardDeviceAssociationsMutationVariables
} from '../../board/hooks/__generated__/IndexBoardDeviceAssociationsMutation'

const INDEX_MUTATION = gql`
  mutation IndexBoardDeviceAssociationsMutation($boardDeviceAssociationId: String!) {
    indexBoardDeviceAssociation(boardDeviceAssociationId: $boardDeviceAssociationId)
  }
`

export const useIndexBoardDeviceAssociations = () => {
  return useMutation<IndexBoardDeviceAssociationsMutation, IndexBoardDeviceAssociationsMutationVariables>(
    INDEX_MUTATION
  )
}
