import { gql } from 'apollo-boost'
import { ListMessageAppearancesV2, ListMessageAppearancesV2Variables } from './__generated__/ListMessageAppearancesV2'
import { useQuery } from '@apollo/react-hooks'

export const QUERY = gql`
  query ListMessageAppearancesV2($input: SearchMessageInputV2!) {
    listSearchMessages: listSearchMessagesV2(input: $input) {
      searchMessages {
        __typename
        ... on MessageAppearanceV2 {
          id
          attribution
          created
          sentAt
          personId
          subscriptionId
        }
        ... on FavoriteV2 {
          id
          attribution
          created
          favoritePersonId: personId
        }
        ... on FeedItemV2 {
          id
          attribution
          created
          personId
          subscriptionId
          moderated
          likeCount
        }
      }
      nextCursor
    }
  }
`

export const useListMessageAppearances = (variables: ListMessageAppearancesV2Variables) => {
  return useQuery<ListMessageAppearancesV2, ListMessageAppearancesV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
