import React from 'react'
import { gql } from 'apollo-boost'
import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GetTenantQuery, GetTenantQueryVariables } from './__generated__/GetTenantQuery'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../truncated-id'
import { FormattedDateTime } from '../formatted-date-time'

const QUERY = gql`
  query GetTenantQuery($id: String!) {
    tenant(id: $id) {
      id
      installations {
        id
      }
      created
      boards {
        id
        title
      }
      name
      members {
        id
        role
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const useStyles = makeStyles({
  columnTitle: {
    width: '200px',
    fontWeight: 'bold',
    background: '#eee'
  },
  membersCard: {
    width: 650
  }
})

export const Tenant = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<GetTenantQuery, GetTenantQueryVariables>(QUERY, { variables: { id } })
  const classes = useStyles()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Tenant not found.</Alert>
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Tenant</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Table>
          <TableRow>
            <TableCell className={classes.columnTitle}>ID</TableCell>
            <TableCell>
              <TruncatedId value={data?.tenant.id || ''} full />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Title</TableCell>
            <TableCell>{data?.tenant.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Created</TableCell>
            <TableCell>
              <FormattedDateTime value={data?.tenant.created ? +data?.tenant.created : undefined} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Boards</TableCell>
            <TableCell>
              {data?.tenant.boards.map(board => (
                <TruncatedId key={board.id} value={board.title || ''} path={`/boards/${board.id}`} full />
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Installations</TableCell>
            <TableCell>
              {data?.tenant.installations.map(installation => (
                <TruncatedId
                  key={installation.id}
                  value={installation.id || ''}
                  path={`/installation/${installation.id}`}
                  full
                />
              ))}
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <Spacer size='extraLarge' />
      <Typography variant='h5'>Members</Typography>
      <Spacer size='large' />
      <Card className={classes.membersCard}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.tenant.members.map(member => (
              <TableRow key={member.id}>
                <TableCell>
                  <TruncatedId full value={member.person.id || ''} path={`/person/${member.person.id}`} />
                </TableCell>
                <TableCell>
                  {member.person.firstName} {member.person.lastName}
                </TableCell>
                <TableCell>{member.role}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}
