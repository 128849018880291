import { useCallback } from 'react'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'
import { IQa } from '../types'

interface IUseCreateQa {
  token: string
}

interface ICreateQaResponse {
  message: string
  questions_answers_set_id: number
  success: number
}

export const useCreateQA = (props: IUseCreateQa): ((input: IQa) => Promise<ICreateQaResponse | null>) => {
  const createQa = useCallback(
    async (input: IQa): Promise<ICreateQaResponse | null> => {
      try {
        const result = await fetch(`${INSTALLABLES_MONOREPO_API}/api/question-answer/set`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.token}`
          },
          body: JSON.stringify(input)
        }).then(res => res.json())
        return result as ICreateQaResponse
      } catch (err) {
        return null
      }
    },
    [props]
  )

  return createQa
}
