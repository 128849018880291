import React from 'react'
import { IHeader, IQuestionAnswer } from '../types'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { MessageList } from './MessageList'
import {
  Box,
  Chip,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { Checkbox } from '../../../ui/Checkbox'
import { CorrectInput } from './CorrectInput'

interface IQa {
  headerRow?: IHeader
  data: IQuestionAnswer
  setData: (data: IQuestionAnswer) => void
}

export const Qa = (props: IQa) => {
  return (
    <>
      <Spacer size='extraLarge' />
      <MessageList
        title='Question'
        data={props.data.question}
        headerRow={props.headerRow}
        setData={question => {
          props.setData({
            ...props.data,
            question
          })
        }}
      />
      <Spacer size='extraLarge' />
      <MessageList
        title='Answer'
        data={props.data.answer}
        headerRow={props.headerRow}
        setData={answer => {
          props.setData({
            ...props.data,
            answer
          })
        }}
      />
      <Spacer size='extraLarge' />
      <Typography variant='h6'>Input</Typography>
      <Spacer size='large' />
      <TableContainer component={Paper} elevation={10}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <Checkbox
                  label='Required?'
                  checked={props.data.input.is_required}
                  handleCheck={is_required => {
                    props.setData({
                      ...props.data,
                      input: {
                        ...props.data.input,
                        is_required
                      }
                    })
                  }}
                />
              </TableCell>
            </TableRow>
            {props.data.input.is_required && (
              <>
                <TableRow>
                  <TableCell>Max Tries</TableCell>
                  <TableCell>
                    <TextField
                      variant='outlined'
                      value={props.data.input.max_tries}
                      type='number'
                      onChange={e => {
                        props.setData({
                          ...props.data,
                          input: {
                            ...props.data.input,
                            max_tries: +e.target.value
                          }
                        })
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Match Type</TableCell>
                  <TableCell>
                    <Select
                      value={props.data.input.match_input_type || 'none'}
                      variant='outlined'
                      onChange={e => {
                        props.setData({
                          ...props.data,
                          input: {
                            ...props.data.input,
                            match_input_type: e.target.value === 'none' ? false : (e.target.value as any)
                          }
                        })
                      }}>
                      <MenuItem value='none'>None</MenuItem>
                      <MenuItem value='contains'>Contains</MenuItem>
                      <MenuItem value='exact'>Exact</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Minimum Number Of Correct Answers</TableCell>
                  <TableCell>
                    <FlexHorizontal>
                      <Select
                        variant='outlined'
                        value={props.data.input.match_amount === 'all' ? 'all' : 'number'}
                        onChange={e => {
                          props.setData({
                            ...props.data,
                            input: {
                              ...props.data.input,
                              match_amount: e.target.value === 'all' ? 'all' : 0
                            }
                          })
                        }}>
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='number'>A Certain Number</MenuItem>
                      </Select>
                      <Box width={14} />
                      {props.data.input.match_amount !== 'all' ? (
                        <TextField
                          variant='outlined'
                          type='number'
                          value={props.data.input.match_amount}
                          onChange={e => {
                            props.setData({
                              ...props.data,
                              input: {
                                ...props.data.input,
                                match_amount: +e.target.value
                              }
                            })
                          }}
                        />
                      ) : null}
                    </FlexHorizontal>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Correct Input (Comma Separated)</TableCell>
                  <TableCell>
                    {props.data.input.correct_input.map((answer, index) => (
                      <Chip
                        key={index}
                        label={answer}
                        onDelete={() => {
                          const correct_input = props.data.input.correct_input.filter(
                            (_, answerIndex) => answerIndex !== index
                          )
                          props.setData({
                            ...props.data,
                            input: {
                              ...props.data.input,
                              correct_input
                            }
                          })
                        }}
                      />
                    ))}
                    <Spacer size='large' />
                    <CorrectInput
                      key={`correct-input-${props.data.guid}`}
                      onBlur={correctInput => {
                        props.setData({
                          ...props.data,
                          input: {
                            ...props.data.input,
                            correct_input: [...props.data.input.correct_input, ...correctInput]
                          }
                        })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
    </>
  )
}
