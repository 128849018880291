export enum PlusRenewalFailureSortBy {
  created = 'created',
  id = 'id',
  errorMessage = 'errorMessage',
  errorCode = 'errorCode',
  productApplicationId = 'productApplicationId'
}

export enum PlusRenewalFailureSortOrder {
  asc = 'asc',
  desc = 'desc'
}
