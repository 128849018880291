import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  TableCell,
  TextField,
  Button,
  Box
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useCallback } from 'react'
import { IHeader, IQa } from '../types'
import { useHistory } from 'react-router'
import { IntroOutro } from '../components/IntroOutro'
import { Qas } from '../components/Qas'
import { QaTitle } from '../components/QaTitle'
import { formatQAMessage } from '../utils/formatQAMessage'
import { Align, Justify } from '@vestaboard/vbml'

interface IQuestionAnswerForm {
  data?: IQa
  onSubmit(data: IQa): void
  onDelete?: () => void
}

export const QuestionAnswerForm = (props: IQuestionAnswerForm) => {
  const history = useHistory()
  const [data, setData] = React.useState<IQa>(
    props.data || {
      title: '',
      intro: [],
      outro: [],
      questions_answers: [],
      secs_delay_between_question_answer: 0,
      num_question_answers_per_game: 0,
      display_corner_tile_color: null,
      display_title: null,
      is_display_title_enabled: 'N',
      additional_options: {
        align_message_content: null,
        justify_message_content: null
      }
    }
  )

  const handleSave = useCallback(() => {
    props.onSubmit(data)
  }, [data, props])

  const getHeaderRow = useCallback((data: IQa) => {
    return {
      isEnabled: data.is_display_title_enabled === 'Y',
      color: data.display_corner_tile_color || '0',
      text: data.display_title,
      align: (data?.additional_options?.align_message_content as Align) || Align.justified,
      justify: (data?.additional_options?.justify_message_content as Justify) || Justify.justified
    } as IHeader
  }, [])

  const renderHeader = useCallback(
    (data: IQa) => {
      const header = getHeaderRow(data)

      if (!header) {
        return data
      }

      const qas = data.questions_answers.map(qa => {
        const questions = qa.question.map(question => {
          const messageCopy = [...question]
          messageCopy.shift()
          return formatQAMessage(messageCopy, header)
        })

        const answers = qa.answer.map(answer => {
          const messageCopy = [...answer]
          messageCopy.shift()
          return formatQAMessage(messageCopy, header)
        })

        return {
          ...qa,
          question: questions,
          answer: answers
        }
      })

      return {
        ...data,
        questions_answers: qas
      }
    },
    [getHeaderRow]
  )

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        handleSave()
      }}>
      <Spacer size='extraLarge' />
      <TableContainer
        component={Paper}
        style={{
          maxWidth: 800
        }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Title</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  fullWidth
                  value={data.title}
                  onChange={e => {
                    setData({ ...data, title: e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Seconds Delay Between Q&A</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='number'
                  value={data.secs_delay_between_question_answer}
                  onChange={e => {
                    setData({ ...data, secs_delay_between_question_answer: +e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Number of Rounds Per Game</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='number'
                  value={data.num_question_answers_per_game}
                  onChange={e => {
                    setData({ ...data, num_question_answers_per_game: +e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FlexHorizontal>
                  <Button
                    variant='contained'
                    color='default'
                    size='large'
                    onClick={() => history.push('/questions-and-answers')}>
                    Cancel
                  </Button>
                  <Box width={16} />
                  <Button variant='contained' type='submit' color='primary' size='large'>
                    Save
                  </Button>
                  <Box width={16} />
                  {!!props.onDelete ? (
                    <Button variant='contained' color='secondary' size='large' onClick={props.onDelete}>
                      Delete
                    </Button>
                  ) : null}
                </FlexHorizontal>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='extraLarge' />
      <IntroOutro
        title='Intro'
        messages={data.intro || []}
        setMessages={intro => {
          setData({
            ...data,
            intro
          })
        }}
      />
      <Spacer size='extraLarge' />
      <IntroOutro
        title='Outro'
        messages={data.outro || []}
        setMessages={outro => {
          setData({
            ...data,
            outro
          })
        }}
      />
      <Spacer size='extraLarge' />
      <QaTitle
        align={(data?.additional_options?.align_message_content as Align) || null}
        justify={(data?.additional_options?.justify_message_content as Justify) || null}
        enabled={data.is_display_title_enabled === 'Y'}
        color={data.display_corner_tile_color || null}
        title={data.display_title || null}
        setAlign={(align: Align | null) => {
          setData(
            renderHeader({
              ...data,
              additional_options: {
                ...data.additional_options,
                align_message_content: align
              }
            })
          )
        }}
        setJustify={(justify: Justify | null) => {
          setData(
            renderHeader({
              ...data,
              additional_options: {
                ...data.additional_options,
                justify_message_content: justify
              }
            })
          )
        }}
        setEnabled={(enabled: boolean) => {
          // If the header is becoming enabled, add an empty row to the top of every question or answer message
          if (enabled) {
            const header = getHeaderRow({
              ...data,
              is_display_title_enabled: 'Y'
            })
            const qas = data.questions_answers.map(qa => {
              const questions = qa.question.map(message => formatQAMessage(message, header))
              const answers = qa.answer.map(message => formatQAMessage(message, header))

              return {
                ...qa,
                question: questions,
                answer: answers
              }
            })

            setData({
              ...data,
              is_display_title_enabled: 'Y',
              questions_answers: qas
            })
          } else {
            const header = getHeaderRow({
              ...data,
              is_display_title_enabled: 'N'
            })

            const qas = data.questions_answers.map(qa => {
              const questions = qa.question.map(question => {
                const messageCopy = [...question]
                messageCopy.shift()
                return formatQAMessage(messageCopy, header)
              })

              const answers = qa.answer.map(answer => {
                const messageCopy = [...answer]
                messageCopy.shift()
                return formatQAMessage(messageCopy, header)
              })

              return {
                ...qa,
                question: questions,
                answer: answers
              }
            })

            setData({
              ...data,
              is_display_title_enabled: 'N',
              questions_answers: qas
            })
          }
        }}
        setColor={(color: string | null) => {
          setData(
            renderHeader({
              ...data,
              display_corner_tile_color: color
            })
          )
        }}
        setTitle={(title: string | null) => {
          setData(
            renderHeader({
              ...data,
              display_title: title
            })
          )
        }}
      />
      <Spacer size='extraLarge' />
      <Qas
        headerRow={getHeaderRow(data) || undefined}
        qas={data.questions_answers}
        setQas={qas => {
          setData({
            ...data,
            questions_answers: qas
          })
        }}
      />
      <Spacer size='extraLarge' />
    </form>
  )
}
