import moment from 'moment'

export const formatReportLegend = (rangeType: string, key: string) => {
  if (rangeType === 'year') {
    return moment()
      .month(+key - 1)
      .format('MMM')
  }
  if (rangeType === 'year_weekly') {
    return moment()
      .week(+key)
      .format('MMM Do')
  }
  if (rangeType === 'month') {
    return moment(key).format('M/D')
  }

  if (rangeType === 'week') {
    return moment(key).format('ddd M/D')
  }
  return key
}
