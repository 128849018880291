export const categories = {
  Business: '#3498DB ',
  Education: '#E74C3C',
  Entertainment: '#2ECC71',
  Lifestyle: '#9B59B6',
  Music: '#F1C40F',
  'News & Weather': '#E67E22',
  Productivity: '#1ABC9C',
  Sports: '#34495E',
  Other: '#16A085',
  Seasonal: '#D35400',
  'TV & Film': '#27AE60',
  Wellness: '#8E44AD',
  Literature: '#2C3E50'
}
