import React from 'react'
import { useParams } from 'react-router'
import { useGetNotification } from '../hooks/useGetNotification'
import {
  Typography,
  Link as MuiLink,
  CircularProgress,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'

export const NotificationMessage = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetNotification(id)

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the notification</Alert>
      ) : data?.notification.__typename === 'NotificationErrorV2' ? (
        <Alert severity='error'>{data.notification.error || 'There was an error'}</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell>
                <Typography>ID</Typography>
              </TableCell>
              <TableCell>
                <TruncatedId value={data?.notification.id || ''} full />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Subscription Person</Typography>
              </TableCell>
              <TableCell>
                <TruncatedId
                  value={data?.notification.personId || ''}
                  path={`/notifications/subscriber/${data?.notification.personId}`}
                  full
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Scheduled Notification</Typography>
              </TableCell>
              <TableCell>
                {data?.notification?.scheduledNotification?.id ? (
                  <TruncatedId
                    value={data?.notification?.scheduledNotification?.id || ''}
                    path={`/notifications/${data?.notification?.scheduledNotification?.id}`}
                    full
                  />
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Title</Typography>
              </TableCell>
              <TableCell>{data?.notification.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Message</Typography>
              </TableCell>
              <TableCell>{data?.notification.message}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Deep Link</Typography>
              </TableCell>
              <TableCell>{data?.notification.deepLink}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Call to Action</Typography>
              </TableCell>
              <TableCell>{data?.notification.callToAction}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Tag</Typography>
              </TableCell>
              <TableCell>
                {data?.notification?.tag ? (
                  <Link component={MuiLink} to={`/notifications/tag/${data?.notification?.tag?.id}`}>
                    {data?.notification.tag?.title}
                  </Link>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Sent At</Typography>
              </TableCell>
              <TableCell>
                <FormattedDateTime value={data?.notification.createdAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Scheduled At</Typography>
              </TableCell>
              <TableCell>
                {data?.notification.scheduledNotification ? (
                  <FormattedDateTime value={data?.notification.scheduledNotification?.createdAt} />
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Read At</Typography>
              </TableCell>
              <TableCell>
                {data?.notification.readAt ? <FormattedDateTime value={data?.notification.readAt} /> : 'Never'}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
