import { Typography } from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import React from 'react'
import { useHistory } from 'react-router'
import { IPickCategoryData, PickCategoryForm } from '../components/PickCategoryForm'
import { useCreatePickCategory } from '../hooks/useCreatePickCategory'

export const CreatePickCategory = () => {
  const history = useHistory()
  const [createPickCategoryMutation, { loading: saving }] = useCreatePickCategory()
  const { addToast } = useToasts()

  const onSave = async (pickCategory: IPickCategoryData) => {
    const { errors, data } = await createPickCategoryMutation({
      variables: {
        input: pickCategory
      }
    })

    if (errors?.length) {
      return addToast('There was an error creating your pick', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your pick has been created', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/picks/categories/${data?.createPickCategory.created.id}`)
  }

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Pick Category</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <PickCategoryForm onSave={onSave} />
    </>
  )
}
