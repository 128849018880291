import { Box, Card, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { FlexHorizontal, Input, Spacer, Button, Theme } from '@vestaboard/installables'
import { useHistory } from 'react-router'

export interface IColorTemplateCategoryData {
  title: string
}

interface IColorTemplateCategoryForm {
  colorTemplateCategory?: IColorTemplateCategoryData
  onSave: (colorTemplateCategory: IColorTemplateCategoryData) => void
  onDelete?: () => void
}

const useStyles = makeStyles({
  cardInner: {
    backgroundColor: '#181919',
    padding: 50
  }
})

export const ColorTemplateCategoryForm = (props: IColorTemplateCategoryForm) => {
  const classes = useStyles()
  const [title, setTitle] = useState(props?.colorTemplateCategory?.title || '')
  const history = useHistory()
  const [titleError, setTitleError] = useState<string | null>(null)

  const handleSave = () => {
    if (!title) {
      setTitleError('Title is required')
      return
    }

    props.onSave({
      title
    })
  }

  return (
    <Theme>
      <Card>
        <Box className={classes.cardInner}>
          <Input value={title} onValueChange={setTitle} label='Category Title' error={titleError || undefined} />
          <Spacer size='extraLarge' />
          <FlexHorizontal>
            <Button
              width={120}
              buttonType='outline'
              onClick={() => {
                history.push('/color-templates?tab=Categories')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button width={120} buttonType='white' onClick={handleSave}>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button width={120} buttonType='danger' onClick={props.onDelete}>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
    </Theme>
  )
}
