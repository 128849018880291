import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListMarketplaceListings,
  ListMarketplaceListingsVariables,
  ListMarketplaceListings_listMarketplaceListings_marketplaceListings
} from './__generated__/ListMarketplaceListings'
import { MarketplaceListingSortBy, MarketplaceListingSortOrder } from '../types'

const QUERY = gql`
  query ListMarketplaceListings($input: MarketplaceListingInput!) {
    listMarketplaceListings(input: $input) {
      marketplaceListings {
        id
        title
        created
        developerId
        developerName
        description
        shortDescription
        categoryIds
        deleted
        releaseDate
        installableId
        installableTitle
        installableCreated
        messageSetId
        messageSetTitle
        installationsCount
        subscriptionsCount
        enabledSubscriptionsCount
        subscriptionsInPast30DaysCount
        featureTypes
        listing {
          id
          icon
        }
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`

interface IUseListMarketplaceListings {
  page?: number
  perPage?: number
  sortBy?: MarketplaceListingSortBy
  sortOrder?: MarketplaceListingSortOrder
}

export const useListMarketplaceListings = (input: IUseListMarketplaceListings) => {
  return useQuery<ListMarketplaceListings, ListMarketplaceListingsVariables>(QUERY, {
    variables: {
      input
    },
    fetchPolicy: 'network-only'
  })
}

useListMarketplaceListings.query = QUERY

export type MarketplaceListing = ListMarketplaceListings_listMarketplaceListings_marketplaceListings
