import * as React from 'react'
import { gql } from 'apollo-boost'
import { Card, Table, TableBody, CircularProgress, TableRow, TableCell, TableHead } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { TenantListQuery, TenantListQuery_tenants } from './__generated__/TenantListQuery'
import { TruncatedId } from '../truncated-id'
import { FormattedDateTime } from '../formatted-date-time'

type TenantListProps = ITenantListProps

interface ITenantListProps {}

const QUERY = gql`
  query TenantListQuery {
    tenants(all: true) {
      id
      created
      name
      __typename
      ... on PersonTenant {
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const TenantList: React.FC<TenantListProps> = props => {
  const { data, loading } = useQuery<TenantListQuery>(QUERY)

  if (loading) return <CircularProgress />

  const personLink = (tenant: TenantListQuery_tenants) => {
    switch (tenant.__typename) {
      case 'PersonTenant':
        return <TruncatedId value={tenant.person.id} path={`/person/${tenant.person.id}`} />
      default:
        return '(none)'
    }
  }

  const rows = data?.tenants.map(tenant => (
    <TableRow>
      <TableCell>
        <TruncatedId value={tenant.id} path={`/tenants/${tenant.id}`}></TruncatedId>
      </TableCell>
      <TableCell>
        <FormattedDateTime value={parseInt(tenant.created)} />
      </TableCell>
      <TableCell>{personLink(tenant)}</TableCell>
    </TableRow>
  ))

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tenant ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Person</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </Card>
  )
}
