import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useParams } from 'react-router'
import { SubscriptionQuery, SubscriptionQueryVariables } from './__generated__/SubscriptionQuery'
import { Card, CircularProgress, Table, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Check, CheckBoxOutlineBlank } from '@material-ui/icons'
import { FormattedDateTime } from '../formatted-date-time'
import { TruncatedId } from '../truncated-id'

const QUERY = gql`
  query SubscriptionQuery($id: String!) {
    subscription(id: $id) {
      id
      title
      icon
      description
      muted
      rank
      created
      subscriptionSet {
        id
        boards {
          id
          title
        }
      }
      installation {
        id
        installable {
          id
          title
        }
      }
      configurationUrl
      configurationToken
      runOfShow {
        description
      }
    }
  }
`

const useStyles = makeStyles({
  columnTitle: {
    width: '200px',
    fontWeight: 'bold',
    background: '#eee'
  }
})

export const Subscription = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<SubscriptionQuery, SubscriptionQueryVariables>(QUERY, { variables: { id } })
  const classes = useStyles()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Subscription not found.</Alert>
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Subscription</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Table>
          <TableRow>
            <TableCell className={classes.columnTitle}>ID</TableCell>
            <TableCell>{data?.subscription.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Title</TableCell>
            <TableCell>{data?.subscription.title || 'No Subscription Title'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Description</TableCell>
            <TableCell>{data?.subscription.description || 'No Subscription Description'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Created</TableCell>
            <TableCell>
              <FormattedDateTime value={data?.subscription.created ? +data?.subscription.created : undefined} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Muted?</TableCell>
            <TableCell>{data?.subscription.muted ? <Check /> : <CheckBoxOutlineBlank />}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Run of Show Description</TableCell>
            <TableCell>{data?.subscription.runOfShow.description || 'No Subscription Description'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Installable</TableCell>
            <TableCell>
              {data?.subscription.installation?.installable.id ? (
                <TruncatedId
                  value={data?.subscription.installation?.installable.id || ''}
                  path={`/installables/${data?.subscription.installation?.installable.id}`}
                  full
                />
              ) : (
                'No Installable'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Installation</TableCell>
            <TableCell>
              {data?.subscription.installation ? (
                <TruncatedId
                  key={data?.subscription.installation.id}
                  value={data?.subscription.installation.id || ''}
                  path={`/installation/${data?.subscription.installation.id}`}
                  full
                />
              ) : (
                'No Installation'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Boards</TableCell>
            <TableCell>
              {(data?.subscription.subscriptionSet.boards || []).map(board => (
                <TruncatedId key={board.id} value={board.title || ''} path={`/boards/${board.id}`} />
              ))}
            </TableCell>
          </TableRow>
        </Table>
      </Card>
    </>
  )
}
