import { BoardPreview, FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { CreateMessageModal, MessageModal } from '../../message-v2'
import React, { useCallback, useState } from 'react'

import { Alert } from '@material-ui/lab'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { RemoveCircle } from '@material-ui/icons'
import { useHistory } from 'react-router'

export interface IColorTemplateData {
  title: string
  messages?: {
    id: string
    text: string | null
    characters: Array<number[]> | null
  }[]
}

interface IColorTemplateForm {
  colorTemplate?: IColorTemplateData
  onSave: (colorTemplateCategory: IColorTemplateData) => void
  onDelete?: () => void
}

const useStyles = makeStyles({
  cardInner: {
    padding: 24
  }
})

export const ColorTemplateForm = (props: IColorTemplateForm) => {
  const classes = useStyles()
  const [messageId, setMessageId] = useState<string | null>(null)
  const [title, setTitle] = useState(props?.colorTemplate?.title || '')
  const [messages, setMessages] = useState(props?.colorTemplate?.messages || [])
  const history = useHistory()
  const [titleError, setTitleError] = useState<string | null>(null)
  const [isAddingMessage, setIsAddingMessage] = useState(false)

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      setMessages(messages => {
        const newMessages = Array.from(messages)
        const [removed] = newMessages.splice(startIndex, 1)
        newMessages.splice(endIndex, 0, removed)
        return newMessages
      })
    },
    [setMessages]
  )

  const handleSave = () => {
    if (!title) {
      setTitleError('Title is required')
      return
    }

    props.onSave({
      title,
      messages
    })
  }

  return (
    <>
      <MessageModal messageId={messageId} onClose={() => setMessageId(null)} />
      {isAddingMessage ? (
        <CreateMessageModal
          onClose={() => {
            setIsAddingMessage(false)
          }}
          onSaved={message => {
            setMessages([message, ...messages])
          }}
        />
      ) : null}
      <Card>
        <Box className={classes.cardInner}>
          <TextField
            value={title}
            onChange={e => setTitle(e.target.value)}
            variant='outlined'
            label='Title'
            fullWidth
            error={!!titleError}
          />
          <Spacer size='large' />
          <FlexHorizontal>
            <Button
              onClick={() => {
                history.push('/color-templates')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button onClick={handleSave} variant='contained' color='primary' size='large'>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button onClick={props.onDelete} variant='contained' color='secondary' size='large'>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween={true}>
        <Typography variant='h5'>Messages</Typography>
        <Button variant='contained' onClick={() => setIsAddingMessage(true)}>
          Add Message
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      {!messages.length ? (
        <Alert severity='info'>No messages yet. Add a message to get started.</Alert>
      ) : (
        <Card>
          <TableContainer>
            <Table>
              <TableBody component={DroppableTableBody(onDragEnd)}>
                {messages.map((message, index) => (
                  <TableRow component={DraggableTableRow(message.id, index)} key={message.id}>
                    <TableCell style={{ width: 40 }}>
                      <DragIndicatorIcon />
                    </TableCell>
                    <TableCell>
                      {message.text ? (
                        <Card raised>
                          <CardContent>
                            <Typography variant='body2'>{message.text}</Typography>
                          </CardContent>
                        </Card>
                      ) : null}
                    </TableCell>
                    <TableCell style={{ width: 250 }}>
                      <Box
                        width={250}
                        onClick={() => {
                          setMessageId(message.id)
                        }}>
                        <BoardPreview characters={message.characters as IBoard} />
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: 30 }}>
                      <ButtonBase
                        onClick={() => {
                          setMessages(messages => messages.filter(currentMessage => currentMessage.id !== message.id))
                        }}>
                        <RemoveCircle color='action' />
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </>
  )
}
