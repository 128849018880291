import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  RenewProductionApplication,
  RenewProductionApplicationVariables
} from './__generated__/RenewProductionApplication'

const MUTATION = gql`
  mutation RenewProductionApplication($input: RenewProductApplicationInput!) {
    renewProductApplication(input: $input) {
      id
    }
  }
`

export const useRenewProductApplication = () => {
  return useMutation<RenewProductionApplication, RenewProductionApplicationVariables>(MUTATION)
}
