import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { AccountDetailQuery, AccountDetailQueryVariables } from './__generated__/AccountDetailQuery'
import { useHistory, useParams } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

type AccountDetailPageProps = IAccountDetailPageProps

interface IAccountDetailPageProps {}

const QUERY = gql`
  query AccountDetailQuery($emailAddress: String!) {
    account(emailAddress: $emailAddress) {
      id
      created
      person {
        id
        firstName
        lastName
      }
    }
  }
`

export const AccountDetailPage: React.FC<AccountDetailPageProps> = props => {
  const { emailAddress } = useParams<{ emailAddress: string }>()
  const history = useHistory()

  const { data: accountDetailData, loading } = useQuery<AccountDetailQuery, AccountDetailQueryVariables>(QUERY, {
    variables: {
      emailAddress: emailAddress.toLowerCase()
    }
  })

  React.useEffect(() => {
    if (accountDetailData?.account?.person.id) {
      history.push(`/person/${accountDetailData.account?.person.id}`)
    }
  }, [accountDetailData, history])

  return !loading && !accountDetailData?.account ? (
    <Alert severity='error'>Account not found.</Alert>
  ) : (
    <CircularProgress />
  )
}
