import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeleteInstallable, DeleteInstallableVariables } from './__generated__/DeleteInstallable'

const DELETE_INSTALLABLE_MUTATION = gql`
  mutation DeleteInstallable($id: String!) {
    deleteInstallable(input: { id: $id }) {
      deleted
    }
  }
`

export const useDeleteInstallable = () =>
  useMutation<DeleteInstallable, DeleteInstallableVariables>(DELETE_INSTALLABLE_MUTATION)
