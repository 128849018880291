import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiTimeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import moment from 'moment'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  userEvent: {
    flexDirection: 'row-reverse'
  },
  userEventTime: {
    textAlign: 'left'
  }
}))

interface ITimelineEvent {
  date: number
  event: string
  type: string
  error: boolean
}

interface ITimelineEventWrapper {
  display: string
  events: Array<ITimelineEvent>
}

interface ITimelineProps {
  data: Array<ITimelineEvent>
  onEventSelect: (event: ITimelineEvent) => void
}

export const Timeline = (props: ITimelineProps) => {
  const classes = useStyles()
  const timelineObject = props.data.reduce((prev, event) => {
    const date = moment(event.date * 1000)
    const timestamp = date.format('YYYY-MM-DD hh:mm A')
    return {
      ...prev,
      [timestamp]: {
        display: date.format('hh:mm A'),
        events: prev[timestamp] ? [...prev[timestamp].events, event] : [event]
      }
    }
  }, {} as any)

  const timeline = Object.keys(timelineObject).map(key => timelineObject[key]) as Array<ITimelineEventWrapper>

  return (
    <MuiTimeline align='left'>
      {timeline.map((item, index) => (
        <React.Fragment key={index}>
          {item.events.map((event, eventIndex) => (
            <TimelineItem key={index} className={event.type === 'userEvent' ? classes.userEvent : ''}>
              <TimelineOppositeContent>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={event.type === 'userEvent' ? classes.userEventTime : ''}>
                  {item.display}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={!event.error ? 'grey' : event.type === 'deviceEvent' ? 'primary' : 'secondary'}>
                  {event.error && <ErrorIcon />}
                </TimelineDot>
                {!(timeline.length === index + 1 && item.events.length) && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent key={eventIndex}>
                <Paper elevation={3} className={classes.paper}>
                  <Button
                    onClick={() => {
                      props.onEventSelect(event)
                    }}>
                    {event.event}
                  </Button>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </React.Fragment>
      ))}
    </MuiTimeline>
  )
}
