import React, { useState } from 'react'
import { useParams } from 'react-router'
import { Tab, Tabs, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { EditInstallable } from '../components/EditInstallable'
import { ListInstallableSubscriptions } from '../components/ListInstallableSubscriptions'

type InstallablesDetailProps = IInstallablesDetailProps

interface IInstallablesDetailProps {}

export const InstallablesDetail: React.FC<InstallablesDetailProps> = props => {
  const [tab, setTab] = useState<'settings' | 'subscriptions'>('settings')
  const { id } = useParams<{ id: string }>()

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Edit Installable</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Tabs value={tab} onChange={(_, key) => setTab(key)}>
        <Tab value='settings' label='Settings' />
        <Tab value='subscriptions' label='Subscriptions' />
      </Tabs>
      {tab === 'settings' ? <EditInstallable id={id} /> : <ListInstallableSubscriptions installableId={id} />}
    </>
  )
}
