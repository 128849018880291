import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, ComposedChart, Area } from 'recharts'

interface IMessagesByHour {
  data: {
    count: number
    hour: string
  }[]
}

const useTooltipStyles = makeStyles({
  root: {
    backgroundColor: '#000',
    padding: 14
  }
})

export const CustomTooltip = ({ active, payload }: any) => {
  const classes = useTooltipStyles()

  if (active && payload && payload.length) {
    return (
      <div className={classes.root}>
        <Typography>{`${payload[0]?.payload?.hour} - ${payload[0].value.toLocaleString()}`} messages</Typography>
      </div>
    )
  }

  return null
}

export const MessagesByHour = (props: IMessagesByHour) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data} syncId='hourSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='hour' />
        <YAxis />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} content={CustomTooltip} />
        <Area type='monotone' dataKey='count' fill='rgb(255, 117, 0)' stroke='rgb(255, 117, 0)' />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
