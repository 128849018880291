import moment from 'moment'

type ParseFriendlyDateType = {
  month: number
  year: number
  day: number
}

export const parseFriendlyDate = (dateToFormat: string, format = 'YYYY-MM-DD'): ParseFriendlyDateType => {
  try {
    const date: string = moment(parseInt(dateToFormat)).format(format)
    const dateParts = date.split('-')
    return {
      year: parseInt(dateParts[0]),
      month: parseInt(dateParts[1]),
      day: parseInt(dateParts[2])
    }
  } catch (e) {
    console.error(e, 'Error in parseFriendlyDate')
    throw e
  }
}

export const renderParsedDate = (props: ParseFriendlyDateType): string => {
  const { year, month, day } = props
  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
}
