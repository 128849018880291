import { CircularProgress, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, IBoard, Spacer, LoadMask, Confirm, useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'
import { IPickData, PickForm } from '../components/PickForm'
import { useHistory, useParams } from 'react-router-dom'
import { useUpdatePick } from '../hooks/useUpdatePick'
import { useGetPick } from '../hooks/useGetPick'
import { useDeletePick } from '../hooks/useDeletePick'
import { useMigratePick } from '../hooks/useMigratePick'
import { useDeletePick as useDeletePickV2 } from '../../picks-v2/hooks/useDeletePick'
interface RouteParams {
  id: string
}

export const PickUpdate = () => {
  const { addToast } = useToasts()
  const history = useHistory()
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const params = useParams<RouteParams>()
  const [deletePickMutation, { loading: deleting }] = useDeletePick()
  const [updatePickMutation, { loading: saving }] = useUpdatePick()
  const { data, loading, error } = useGetPick(params.id)
  const [migratePick] = useMigratePick()
  const [deletePickV2] = useDeletePickV2()

  const pick = data?.pick

  const updatePick = async (pick: IPickData) => {
    const { errors } = await updatePickMutation({
      variables: {
        input: {
          id: params.id,
          ...pick
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error updating your pick', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your pick has been updated', {
      appearance: 'success',
      autoDismiss: true
    })

    try {
      await migratePick({
        variables: {
          pickId: params.id
        }
      })
    } catch (err) {}
  }

  const deletePick = async () => {
    setConfirmingDelete(false)

    const { errors } = await deletePickMutation({
      variables: {
        input: {
          id: params.id
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error deleting your pick', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    try {
      deletePickV2({
        variables: {
          input: {
            id: params.id
          }
        }
      })
    } catch (err) {}

    addToast('Your pick has been deleted', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push('/picks')
  }

  return (
    <>
      <Confirm
        title='Delete Pick?'
        message='Are you sure you want to delete this pick? Chose wisely!'
        open={confirmingDelete}
        handleClose={() => setConfirmingDelete(false)}
        handleAccept={deletePick}
      />
      {saving || deleting ? <LoadMask /> : null}
      {error ? (
        <Alert severity='error'>Pick not found.</Alert>
      ) : loading || !data ? (
        <CircularProgress />
      ) : (
        <>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>Update Pick</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <PickForm
            onSave={updatePick}
            onDelete={() => {
              setConfirmingDelete(true)
            }}
            pick={{
              characters: pick?.message.formatted as IBoard,
              categoryIds: (pick?.categories || []).map(category => category.id),
              recommendAfter: pick?.recommendAfter,
              recommendBefore: pick?.recommendBefore
            }}
          />
        </>
      )}
    </>
  )
}
