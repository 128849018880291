import { gql } from 'apollo-boost'
import {
  ListDeveloperMarketplaceListings,
  ListDeveloperMarketplaceListingsVariables
} from './__generated__/ListDeveloperMarketplaceListings'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListDeveloperMarketplaceListings($input: MarketplaceListingInput!) {
    listMarketplaceListings(input: $input) {
      marketplaceListings {
        id
        title
        created
        installableId
        installableTitle
        listing {
          icon
        }
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`

export const useGetDeveloperMarketplaceListings = (variables: ListDeveloperMarketplaceListingsVariables) =>
  useQuery<ListDeveloperMarketplaceListings, ListDeveloperMarketplaceListingsVariables>(QUERY, {
    variables
  })
