import { ButtonBase, CircularProgress } from '@material-ui/core'
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'

import React from 'react'
import { useListProductPricings } from '../hooks/useListProductPricings'

interface ISelectPricingProps {
  value: string
  onValueChange: (id: string) => void
}

export const SelectPricing = (props: ISelectPricingProps) => {
  const { data, loading } = useListProductPricings()

  return loading || !data ? (
    <CircularProgress />
  ) : (
    <div
      style={{
        color: '#FFF'
      }}>
      {data.productPricings
        .filter(pricing => pricing.title?.includes('Year'))
        .sort((a, b) =>
          a.title === b.title ? (a.quantity || 0) - (b.quantity || 0) : (a.title || '')?.localeCompare(b.title || '')
        )
        .map((pricing, i) => (
          <ButtonBase
            onClick={() => props.onValueChange(pricing.id)}
            key={pricing.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: 10,
              backgroundColor: pricing.id === props.value ? '#222' : 'transparent',
              borderTop: i ? 'solid 1px #CCC' : undefined,
              width: '100%',
              justifyContent: 'flex-start'
            }}>
            {pricing.id === props.value ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />} &nbsp;
            {pricing.title} | {pricing.quantity} Board
          </ButtonBase>
        ))}
    </div>
  )
}
