import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface IAlertDialog {
  open: boolean
  title: string
  description: string
  onCancel: () => void
  onConfirm: () => void
}

export const AlertDialog = (props: IAlertDialog) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color='default'>
          No
        </Button>
        <Button onClick={props.onConfirm} color='primary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
