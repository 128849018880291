import React, { useEffect } from 'react'
import { useAuth0 } from '../../../react-auth0-spa'
import { REPORTS_API } from '../../../constants'
import { formatReportLegend } from '../../../utils/formatReportLegend'
import { sorter } from '../utils/sorter'

export interface IMessagesPerBoardType {
  rangeType: string
  searchKey: string | null
}

export interface IGetMessagesByBoardTypeData {
  name: string
  Plus: number
  'Non-Plus': number
}

export const useGetMessagesByBoardType = (props: IMessagesPerBoardType) => {
  const { getTokenSilently } = useAuth0()
  const [data, setData] = React.useState<IGetMessagesByBoardTypeData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          props.rangeType !== 'all_time'
            ? `${REPORTS_API}/message-type/${props.rangeType}/${props.searchKey}`
            : `${REPORTS_API}/message-type`,
          {
            headers: { 'X-Vestaboard-Token': await getTokenSilently() }
          }
        )
        const json = await response.json()
        const root =
          json?.messagesByTypeByDay ||
          json?.messagesByTypeByMonth ||
          json?.messagesByTypeByYear ||
          json?.messagesByTypeByWeek

        const filled = root

        setData(
          Object.keys(filled)
            .sort(sorter)
            .map(key => ({
              'Non-Plus': filled[key].noPlus,
              Plus: filled[key].plus,
              name: formatReportLegend(props.rangeType, key)
            }))
        )
      } catch (e) {
        setData([])
      }
    }
    fetchData()
  }, [props.rangeType, props.searchKey, getTokenSilently])
  return { data }
}
