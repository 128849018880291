import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { IGetMessagesByBoardTypeData } from '../hooks/useGetMessagesByBoardType'

interface IMessagesPerBoardType {
  data: IGetMessagesByBoardTypeData[]
}

export const MessagesPerBoardType = ({ data }: IMessagesPerBoardType) => {
  return (
    <ResponsiveContainer>
      <BarChart data={data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Legend />
        <Bar dataKey='Plus' stackId='a' fill='rgb(0, 132, 213)' />
        <Bar dataKey='Non-Plus' stackId='a' fill='rgb(112, 47, 138)' />
      </BarChart>
    </ResponsiveContainer>
  )
}
