import * as React from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ReferralReportQuery } from './__generated__/ReferralReportQuery'
import { Box, Card, CircularProgress, Table, TableCell, TableRow, Typography } from '@material-ui/core'
import { FormattedDateTime } from '../formatted-date-time'
import { Alert, ToggleButton } from '@material-ui/lab'
import { UpdateReferralMutation, UpdateReferralMutationVariables } from './__generated__/UpdateReferralMutation'
import { ErrorOutline } from '@material-ui/icons'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
type ReferralReportProps = IReferralReportProps

interface IReferralReportProps {}

const MUTATION = gql`
  mutation UpdateReferralMutation($id: String!, $invalid: Boolean!) {
    updateReferral(input: { id: $id, invalid: $invalid }) {
      success
    }
  }
`

const QUERY = gql`
  query ReferralReportQuery {
    referralReport {
      rows {
        personId
        firstName
        lastName
        shopifyCustomerName
        quantity
        transactionDate
        notified
        id
        invalid
      }
    }
  }
`

const useUpdateReferralMutation = () => useMutation<UpdateReferralMutation, UpdateReferralMutationVariables>(MUTATION)

const useReferralReportQuery = () => useQuery<ReferralReportQuery>(QUERY)

export const ReferralReport: React.FC<ReferralReportProps> = props => {
  const referralReportQuery = useReferralReportQuery()
  const [, setSaving] = React.useState(false)

  const [updateReferral] = useUpdateReferralMutation()

  if (referralReportQuery.error) {
    return <Alert severity='error'>There was an error loading the referrals</Alert>
  }

  if (referralReportQuery.loading || !referralReportQuery.data) return <CircularProgress />

  const onChangeValidity = async (id: string, invalid: boolean) => {
    setSaving(true)
    await updateReferral({
      variables: {
        id,
        invalid
      }
    })
    await referralReportQuery.refetch()
    setSaving(false)
  }

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Referrals</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <Table>
          <TableRow>
            <TableCell>Referrer Person ID</TableCell>
            <TableCell>Referrer</TableCell>
            <TableCell>Shopify Customer Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Transaction date</TableCell>
            <TableCell>Flag as invalid</TableCell>
          </TableRow>
          {referralReportQuery.data.referralReport.rows.map(row => (
            <TableRow>
              <TableCell>{row.personId}</TableCell>
              <TableCell>
                {row.firstName} {row.lastName}
              </TableCell>
              <TableCell>{row.shopifyCustomerName}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>
                <FormattedDateTime value={row.transactionDate} />
              </TableCell>
              <TableCell>
                <ToggleButton
                  value='check'
                  selected={!row.invalid}
                  onChange={() => onChangeValidity(row.id ?? '', !row.invalid)}>
                  <ErrorOutline />
                </ToggleButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Card>
    </Box>
  )
}
