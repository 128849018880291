import { UpdateProductApplication, UpdateProductApplicationVariables } from './__generated__/UpdateProductApplication'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdateProductApplication($input: UpdateProductApplicationInput!) {
    updateProductApplication(input: $input) {
      id
      pricing {
        id
        title
        tagline
        autoRenew
        banner
        chargeFrequency
        created
        title
        priceAfterFirstInCents
        priceInCents
        quantity
        shopifySellingPlanId
        shopifyVariantId
        product {
          id
          title
        }
      }
    }
  }
`

export const useUpdateProductApplication = () => {
  return useMutation<UpdateProductApplication, UpdateProductApplicationVariables>(MUTATION)
}
