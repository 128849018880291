import * as React from 'react'

type ChannelDetailProps = IChannelDetailProps

interface IChannelDetailProps {}

// const QUERY = gql``;

export const ChannelDetail: React.FC<ChannelDetailProps> = props => {
  return <span />
}
