import React, { useState } from 'react'
import { useCreateDeveloper } from '../hooks'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import { useHistory } from 'react-router'

export const CreateDeveloper = () => {
  const [create, { loading: saving }] = useCreateDeveloper()
  const [title, setTitle] = useState('')
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      <Typography variant='h4'>Create Developer</Typography>
      <Spacer size='extraLarge' />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Title</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value)
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer size='large' />
      <FlexHorizontal>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            history.push('/developers')
          }}>
          Cancel
        </Button>
        <Box width={14} />
        <Button
          disabled={saving}
          size='large'
          variant='contained'
          color='primary'
          onClick={async () => {
            const result = await create({
              variables: {
                title
              }
            })

            const id = result.data?.createDeveloper.id

            if (!id) {
              return addToast('Failed to create developer', {
                appearance: 'error'
              })
            }

            addToast('Developer created', {
              appearance: 'success'
            })

            history.push(`/developers/${id}`)
          }}>
          Create
        </Button>
      </FlexHorizontal>
    </>
  )
}
