import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useAdminUpdateBoard } from '../../../api/useAdminUpdateBoard'

interface IHubspotDealId {
  boardId: string
  hubspotDealId: string | null
}

export const HubspotDealId = (props: IHubspotDealId) => {
  const [hubspotDealId, setHubspotDealId] = useState<string | null>(props.hubspotDealId || '')
  const [updateBoard] = useAdminUpdateBoard()

  return (
    <TextField
      placeholder='Hubspot Deal ID'
      value={hubspotDealId}
      onChange={e => setHubspotDealId(e.target.value)}
      variant='outlined'
      onBlur={async () => {
        await updateBoard({
          variables: {
            input: {
              boardId: props.boardId,
              hubspotDealId
            }
          }
        })
      }}
    />
  )
}
