import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeleteMessageSetV2, DeleteMessageSetV2Variables } from './__generated__/DeleteMessageSetV2'

const MUTATION = gql`
  mutation DeleteMessageSetV2($input: DeleteMessageSetInputV2!) {
    deleteMessageSet: deleteMessageSetV2(input: $input) {
      __typename
      ... on MessageSetV2 {
        id
        title
        public
        dynamic
        rssUrl
        messages {
          id
          text
          characters
        }
      }
      ... on MessageSetErrorV2 {
        type
        error
      }
    }
  }
`
export const useDeleteMessageSet = () => useMutation<DeleteMessageSetV2, DeleteMessageSetV2Variables>(MUTATION)
