import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListNotificationSubscribers,
  ListNotificationSubscribersVariables
} from './__generated__/ListNotificationSubscribers'

const QUERY = gql`
  query ListNotificationSubscribers($input: ListNotificationSubscribersInputV2!) {
    listNotificationSubscribers: listNotificationSubscribersV2(input: $input) {
      nextCursor
      notificationSubscribers {
        id
        personId
        title
        unreadCount
        tags {
          id
          title
        }
      }
    }
  }
`

export const useListNotificationSubscribers = (variables: ListNotificationSubscribersVariables, skip?: boolean) =>
  useQuery<ListNotificationSubscribers, ListNotificationSubscribersVariables>(QUERY, {
    variables,
    skip
  })

export const useListNotificationSubscribersLazy = (variables: ListNotificationSubscribersVariables) =>
  useLazyQuery<ListNotificationSubscribers, ListNotificationSubscribersVariables>(QUERY, {
    variables
  })
