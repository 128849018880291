import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import * as React from 'react'
import { useParams } from 'react-router'
import { MarketplaceListingDetail } from '../components/EditMarketplaceListing'
import { ListSubscriptions } from '../components/ListSubscriptions'

type MarketplaceListingDetailProps = IMarketplaceListingDetailProps

interface IMarketplaceListingDetailProps {}

export const UpdateMarketplaceListings: React.FC<MarketplaceListingDetailProps> = props => {
  const { id } = useParams<{ id: string }>()
  const [tab, setTab] = React.useState<'settings' | 'subscriptions'>('settings')

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Edit Marketplace Listing</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Tabs value={tab} onChange={(_, key) => setTab(key)}>
        <Tab value='settings' label='Settings' />
        <Tab value='subscriptions' label='Subscriptions' />
      </Tabs>
      {tab === 'settings' ? <MarketplaceListingDetail id={id} /> : <ListSubscriptions id={id} />}
    </Box>
  )
}
