import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { DeviceLiteQuery, DeviceLiteQueryVariables } from './__generated__/DeviceLiteQuery'
import { Box, Table, TableCell, TableRow, TextField } from '@material-ui/core'
import moment from 'moment'
import { FormattedDateTime } from '../formatted-date-time'

const DEVICE_QUERY = gql`
  query DeviceLiteQuery($deviceId: String!) {
    device(id: $deviceId) {
      id
      yoctoStatus
      latestHeartbeat {
        id
        created
        ipAddress
        location
        timeago
        maskStatus
        networks {
          up
          ssid
          ipAddress
          online
          type
        }
        containers {
          id
          image
          since
          version
        }
      }
    }
  }
`

export const useDeviceQuery = (id: string) =>
  useQuery<DeviceLiteQuery, DeviceLiteQueryVariables>(DEVICE_QUERY, {
    variables: {
      deviceId: id
    }
  })

export const DeviceRow: React.FC<{ id: string }> = props => {
  const { data } = useDeviceQuery(props.id)

  return (
    <TableRow>
      <TableCell>{data?.device.id}</TableCell>
      <TableCell>
        {
          <>
            {data?.device.latestHeartbeat?.created ? (
              <>
                {moment(data?.device.latestHeartbeat?.created).fromNow()} (
                <FormattedDateTime value={data?.device.latestHeartbeat?.created} />)
              </>
            ) : (
              'Never'
            )}
          </>
        }
      </TableCell>
      <TableCell>
        {data?.device.latestHeartbeat?.containers?.find(c => c.image.indexOf('upgrader-util') > -1)?.version}
      </TableCell>
    </TableRow>
  )
}

export const DeviceBatchView: React.FC = props => {
  const [ids, setIds] = React.useState('')

  const splitIds = ids.split('\n')

  return (
    <Box>
      <TextField multiline value={ids} onChange={v => setIds(v.target.value)} />
      <Table>
        {splitIds.map(id => (
          <DeviceRow id={id}></DeviceRow>
        ))}
      </Table>
    </Box>
  )
}
