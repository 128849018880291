import { LinearProgress, Tab, Tabs, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Confirm, FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { IPickCategoryData, PickCategoryForm } from '../components/PickCategoryForm'
import { PickCategoryHistory } from '../components/PickCategoryHistory'
import { PickCategoryUpcoming } from '../components/PickCategoryUpcoming'
import { useGetPickCategory } from '../hooks/useGetPickCategory'
import { useDeletePickCategory } from '../hooks/useDeletePickCategory'
import { useUpdatePickCategory } from '../hooks/useUpdatePickCategory'

interface RouteParams {
  id: string
}

const tabs = ['Upcoming', 'History']

export const UpdatePickCategory = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tabName = query.get('tab') || 'Upcoming'
  const tab = tabs.indexOf(tabName)
  const { addToast } = useToasts()
  const params = useParams<RouteParams>()
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [deletePickCategoryMutation, { loading: deleting }] = useDeletePickCategory()
  const [updatePickCategoryMutation, { loading: updating }] = useUpdatePickCategory()
  const { data, loading, error } = useGetPickCategory(params.id)
  const onSave = async (pick: IPickCategoryData) => {
    const { errors } = await updatePickCategoryMutation({
      variables: {
        input: {
          id: params.id,
          ...pick
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error updating your pick category', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your pick category has been updated', {
      appearance: 'success',
      autoDismiss: true
    })
  }

  const deletePickCategory = async () => {
    setConfirmingDelete(false)

    const { errors } = await deletePickCategoryMutation({
      variables: {
        input: {
          id: params.id
        }
      }
    })

    if (errors?.length) {
      return addToast('There was an error deleting your pick category', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your pick category has been deleted', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push('/picks?tab=Categories')
  }

  return (
    <>
      <Confirm
        title='Delete Pick Category?'
        message='Are you sure you want to delete this pick category? Chose wisely!'
        open={confirmingDelete}
        handleClose={() => setConfirmingDelete(false)}
        handleAccept={deletePickCategory}
      />
      {updating || deleting ? <LoadMask /> : null}
      {error ? (
        <Alert severity='error'>Pick category not found.</Alert>
      ) : loading || !data ? (
        <LinearProgress />
      ) : (
        <>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>Update Pick Category</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <PickCategoryForm
            key={params.id}
            onSave={onSave}
            onDelete={() => {
              setConfirmingDelete(true)
            }}
            pickCategory={{
              title: data?.pickCategory.title || ''
            }}
          />

          <Spacer size='large' />
          <Tabs
            value={tab}
            onChange={(_event, newValue) => {
              history.push(`/picks/categories/${params.id}?tab=${tabs[newValue]}`)
            }}>
            {tabs.map(tab => (
              <Tab label={tab} id={tab} key={tab} />
            ))}
          </Tabs>
          <Spacer size='large' />
          {tabName === 'History' ? <PickCategoryHistory id={params.id} /> : <PickCategoryUpcoming id={params.id} />}
        </>
      )}
    </>
  )
}
