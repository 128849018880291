import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdateNotificationSegment,
  UpdateNotificationSegmentVariables
} from './__generated__/UpdateNotificationSegment'

const MUTATION = gql`
  mutation UpdateNotificationSegment($input: UpdateNotificationSegmentInputV2!) {
    updateNotificationSegment: updateNotificationSegmentV2(input: $input) {
      ... on NotificationSegmentV2 {
        id
        title
        subscribers {
          id
          title
        }
      }
    }
  }
`

export const useUpdateNotificationSegment = () =>
  useMutation<UpdateNotificationSegment, UpdateNotificationSegmentVariables>(MUTATION)
