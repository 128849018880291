import React, { createContext } from 'react'

export const FlagsContext = createContext<{
  messagesV2: boolean
  toggleV2Messages: () => void
}>({
  messagesV2: false,
  toggleV2Messages() {}
})

interface IFlagsProvider {
  children: React.ReactNode
}

export const FlagsProvider = (props: IFlagsProvider) => {
  const [messagesV2, setMessagesV2] = React.useState<boolean>(!!localStorage.getItem('messagesV2'))

  return (
    <FlagsContext.Provider
      value={{
        messagesV2,
        toggleV2Messages: () => {
          if (messagesV2) {
            localStorage.removeItem('messagesV2')
          } else {
            localStorage.setItem('messagesV2', 'true')
          }
          setMessagesV2(!messagesV2)
        }
      }}>
      {props.children}
    </FlagsContext.Provider>
  )
}

export const useFlags = () => React.useContext(FlagsContext)
