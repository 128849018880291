import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  MigrateMarketplaceListing,
  MigrateMarketplaceListingVariables
} from './__generated__/MigrateMarketplaceListing'

const MUTATION = gql`
  mutation MigrateMarketplaceListing($id: String!) {
    migrateMarketplaceListing(id: $id) {
      ... on MarketplaceMigrationResultV2 {
        success
        description
      }
    }
  }
`

export const useMigrateMarketplaceListing = () =>
  useMutation<MigrateMarketplaceListing, MigrateMarketplaceListingVariables>(MUTATION)
