import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  SetColorTemplateSortOrders,
  SetColorTemplateSortOrdersVariables
} from './__generated__/SetColorTemplateSortOrders'

const SET_COLOR_TEMPLATE_SORT_ORDERS = gql`
  mutation SetColorTemplateSortOrders($input: SetColorTemplateSortOrdersInput!) {
    setColorTemplateSortOrders(input: $input) {
      id
    }
  }
`

export const useSetColorTemplateSortOrders = () => {
  return useMutation<SetColorTemplateSortOrders, SetColorTemplateSortOrdersVariables>(SET_COLOR_TEMPLATE_SORT_ORDERS)
}
