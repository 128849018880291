import { gql } from 'apollo-boost'
import { ListMessageFlagsV2, ListMessageFlagsV2Variables } from './__generated__/ListMessageFlagsV2'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListMessageFlagsV2($id: String!, $input: ListFlagsInputV2!) {
    message: messageV2(id: $id) {
      ... on MessageV2 {
        id
        listFlaggedMessages(input: $input) {
          nextCursor
          flaggedMessages {
            personId
            messageId
            reason
          }
        }
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`

export const useListMessageFlags = (variables: ListMessageFlagsV2Variables) => {
  return useQuery<ListMessageFlagsV2, ListMessageFlagsV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
