import * as React from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  YoctoRolloutQuery,
  YoctoRolloutQuery_devicesByGmtOffset_yoctoStatuses
} from './__generated__/YoctoRolloutQuery'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  TextField
} from '@material-ui/core'
import { CellParams, ColDef, XGrid, XGridProps } from '@material-ui/x-grid'
import { YoctoRolloutMutation, YoctoRolloutMutationVariables } from './__generated__/YoctoRolloutMutation'
import {
  YoctoStatusDrilldownQuery,
  YoctoStatusDrilldownQueryVariables
} from './__generated__/YoctoStatusDrilldownQuery'
import moment from 'moment'
import { useHistory } from 'react-router'
import { TruncatedId } from '../truncated-id'

type YoctoRolloutProps = IYoctoRolloutProps

interface IYoctoRolloutProps {}

const MUTATION = gql`
  mutation YoctoRolloutMutation($offset: Int!, $confirmation: String!, $limit: Int!) {
    updateDeviceYoctoStatusByTimezone(input: { offset: $offset, confirmation: $confirmation, limit: $limit }) {
      id
    }
  }
`

const useRolloutMutation = () => useMutation<YoctoRolloutMutation, YoctoRolloutMutationVariables>(MUTATION)

const QUERY = gql`
  query YoctoRolloutQuery {
    devicesByGmtOffset {
      offset
      devicesCount
      yoctoStatuses {
        yoctoStatus
        count
      }
    }
  }
`

const useYoctoRolloutQuery = () => useQuery<YoctoRolloutQuery>(QUERY)

export const YoctoRollout: React.FC<YoctoRolloutProps> = props => {
  const { data, loading, refetch } = useYoctoRolloutQuery()
  const [refetching, setRefetching] = React.useState(false)
  const [drilldownOffset, setDrilldownOffset] = React.useState<number | null>(null)
  const [drilldownStatus, setDrilldownStatus] = React.useState<string | null>(null)

  const [hasBuffer, setHasBuffer] = React.useState([] as number[])
  const [triggerOffsetConfirm, setTriggerOffsetConfirm] = React.useState(null as number | null)
  const [confirmText, setConfirmText] = React.useState('')
  const [limit, setLimit] = React.useState(0)

  const [rolloutMutation] = useRolloutMutation()

  const beginRefetchLoop = React.useCallback(() => {
    setTimeout(async () => {
      setRefetching(true)
      await refetch()
      setRefetching(false)
      beginRefetchLoop()
    }, 5000)
  }, [refetch])

  React.useEffect(() => {
    setTimeout(() => {
      beginRefetchLoop()
    }, 5000)
  }, [beginRefetchLoop])

  if (loading || !data) return <CircularProgress />

  const trigger = async () => {
    if (triggerOffsetConfirm) {
      setHasBuffer(existing => [...existing, triggerOffsetConfirm])
      await rolloutMutation({
        variables: {
          confirmation: confirmText,
          offset: triggerOffsetConfirm,
          limit
        }
      })
      setConfirmText('')
      setLimit(0)
      setTriggerOffsetConfirm(null)
    }
  }

  const closeConfirmModal = () => {
    setTriggerOffsetConfirm(null)
    setConfirmText('')
    setLimit(0)
  }

  const buttonCellProps = (status: string) => ({
    renderCell: (params: CellParams) =>
      params.value ? (
        <Button
          onClick={() => {
            setDrilldownOffset(params.getValue('offset') as number)
            setDrilldownStatus(status)
          }}
          variant='outlined'>
          {params.value}
        </Button>
      ) : (
        <span />
      )
  })

  const columns: ColDef[] = [
    {
      field: 'offset',
      headerName: 'GMT Offset',
      flex: 1,
      renderCell: (params: CellParams) => (
        <span>{params.value ? `GMT${plusOrMinus((params.value! as number) / 60 / 60)}` : 'None'}</span>
      )
    },
    {
      field: 'devicesCount',
      flex: 1,
      headerName: 'Total count'
    },
    { field: 'status_none', headerName: 'Pending' },
    { field: 'status_not_eligible', headerName: 'Excluded', ...buttonCellProps('Not Eligible') },
    { field: 'status_eligible', headerName: 'Eligible', ...buttonCellProps('Eligible') },
    { field: 'status_installation_began', headerName: 'Began', ...buttonCellProps('Installation Began') },
    { field: 'status_installation_completed', headerName: 'Completed', ...buttonCellProps('Installation Completed') },
    {
      field: 'status_rebooting',
      headerName: 'Rebooting',
      ...buttonCellProps('Rebooting')
    },
    { field: 'status_installed', headerName: 'Installed', ...buttonCellProps('Installed') },
    {
      field: 'viz',
      headerName: '',
      flex: 2,
      renderHeader: () => <span />,
      renderCell: (params: CellParams) => {
        const total = (params.getValue('devicesCount') ?? 0) as number
        const installed = (params.getValue('status_installed') ?? 0) as number
        const eligible = (params.getValue('status_eligible') ?? 0) as number
        const began = (params.getValue('status_installation_began') ?? 0) as number
        const completed = (params.getValue('status_installation_completed') ?? 0) as number
        const rebooting = (params.getValue('status_rebooting') ?? 0) as number
        const offset = (params.getValue('offset') ?? null) as number | null
        const offsetHasBuffer = offset != null && hasBuffer.indexOf(offset) > -1

        const doneProgress = total ? (installed / total) * 100 : 0
        const otherProgress = offsetHasBuffer
          ? total
            ? ((installed + eligible + began + completed + rebooting) / total) * 100
            : 0
          : 0

        return (
          <LinearProgress
            style={{ width: '100%' }}
            variant={offsetHasBuffer ? 'buffer' : 'determinate'}
            value={doneProgress}
            valueBuffer={doneProgress + otherProgress}
          />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => (
        <Button
          style={{ width: 200 }}
          variant='contained'
          color='primary'
          onClick={() => {
            setTriggerOffsetConfirm((params.getValue('offset') ?? null) as number | null)
          }}>
          Rollout
        </Button>
      )
    }
  ]

  const findStatus = (statuses: YoctoRolloutQuery_devicesByGmtOffset_yoctoStatuses[]) => (status: string) =>
    statuses.find(s => s.yoctoStatus === status)?.count

  const rows = data.devicesByGmtOffset.map(gmtOffset => ({
    id: gmtOffset.offset ?? 'none',
    ...gmtOffset,
    status_not_eligible: findStatus(gmtOffset.yoctoStatuses)('Not Eligible'),
    status_none: findStatus(gmtOffset.yoctoStatuses)('Unknown'),
    status_eligible: findStatus(gmtOffset.yoctoStatuses)('Eligible'),
    status_rebooting: findStatus(gmtOffset.yoctoStatuses)('Rebooting'),
    status_installed: findStatus(gmtOffset.yoctoStatuses)('Installed'),
    status_installation_began: findStatus(gmtOffset.yoctoStatuses)('Installation Began'),
    status_installation_completed: findStatus(gmtOffset.yoctoStatuses)('Installation Completed')
  }))

  const gridProps: XGridProps = {
    columns,
    rows
  }

  const closeDrilldownModal = () => {
    setDrilldownStatus(null)
    setDrilldownOffset(null)
  }

  return (
    <Box style={{ height: 1000 }}>
      <Dialog maxWidth={'md'} fullWidth open={drilldownOffset != null && drilldownStatus != null}>
        <DialogTitle>Matching devices</DialogTitle>
        <DialogContent>
          <Box style={{ height: 1000, width: '100%' }}>
            {drilldownOffset && drilldownStatus && (
              <YoctoStatusDrilldown offset={drilldownOffset} status={drilldownStatus} onClose={closeDrilldownModal} />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => closeDrilldownModal()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {refetching ? <CircularProgress /> : null}
      {triggerOffsetConfirm ? (
        <Dialog open={true}>
          <DialogTitle disableTypography>
            <h2>Confirm rollout to Vestaboards</h2>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box>To confirm this rollout, enter a number of Vestaboards in this group and type "rollout" below.</Box>
            <Box>
              <TextField
                variant='outlined'
                placeholder='Limit'
                value={limit}
                onChange={e => setLimit(parseInt(e.target.value, 10))}></TextField>
            </Box>
            <Box>
              <TextField
                variant='outlined'
                placeholder='rollout'
                value={confirmText}
                onChange={e => setConfirmText(e.target.value)}
                fullWidth></TextField>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant='contained' onClick={() => closeConfirmModal()} color='default'>
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={confirmText !== 'rollout' || !(limit > 0)}
              onClick={() => trigger()}
              color='primary'
              autoFocus>
              Confirm Rollout
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <XGrid {...gridProps}></XGrid>
    </Box>
  )
}

const plusOrMinus = (n: number) => (n < 0 ? '' : '+') + n

interface YoctoStatusDrilldownProps {
  offset: number
  status: string
  onClose: () => void
}

const YOCTO_STATUS_DRILLDOWN_QUERY = gql`
  query YoctoStatusDrilldownQuery($offset: Int!, $status: String!, $page: Int!, $perPage: Int!) {
    devicesByGmtOffsetAndYoctoStatus(gmtOffset: $offset, yoctoStatus: $status, page: $page, perPage: $perPage) {
      id
      latestHeartbeat {
        created
      }
      boards {
        id
        title
        tenant {
          members {
            role
            person {
              account {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`

const useYoctoStatusDrilldownQuery = (offset: number, status: string, page: number, perPage: number) =>
  useQuery<YoctoStatusDrilldownQuery, YoctoStatusDrilldownQueryVariables>(YOCTO_STATUS_DRILLDOWN_QUERY, {
    variables: { offset, status, page, perPage },
    fetchPolicy: 'no-cache'
  })

const YoctoStatusDrilldown: React.FC<YoctoStatusDrilldownProps> = props => {
  const history = useHistory()
  const { data, loading } = useYoctoStatusDrilldownQuery(props.offset, props.status, 0, 10000)

  if (!data || loading) return <CircularProgress />

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Device ID',
      renderCell: params => (
        <Button variant='outlined' onClick={() => history.push(`/devices/${params.value}`)}>
          <TruncatedId value={params.value as string} />
        </Button>
      )
    },
    {
      field: 'boardId',
      headerName: 'Board ID',
      renderCell: params => (
        <Button variant='outlined' onClick={() => history.push(`/boards/${params.value}`)}>
          <TruncatedId value={params.value as string} />
        </Button>
      )
    },
    { field: 'boardTitle', flex: 2, headerName: 'Board Title' },
    {
      field: 'lastOnline',
      flex: 1,
      headerName: 'Last Contact',
      renderCell: (params: CellParams) => <span>{moment(params.value as number).fromNow()}</span>
    }
  ]

  const rows = data.devicesByGmtOffsetAndYoctoStatus.map(device => ({
    id: device.id,
    boardId: device.boards[0] && device.boards[0].id,
    boardTitle: device.boards[0] && device.boards[0].title,
    lastOnline: device.latestHeartbeat?.created
  }))

  return <XGrid disableColumnResize rows={rows} columns={columns} autoHeight />
}
