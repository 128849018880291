import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  RemoveMarketplaceListingFeature,
  RemoveMarketplaceListingFeatureVariables
} from './__generated__/RemoveMarketplaceListingFeature'

const MUTATION = gql`
  mutation RemoveMarketplaceListingFeature($input: RemoveMarketplaceListingFeatureInput!) {
    removeMarketplaceListingFeature(input: $input) {
      id
      marketplaceListing {
        id
        featureTypes {
          id
          marketplaceListingFeatureType {
            id
          }
        }
      }
    }
  }
`

export const useRemoveMarketplaceListingFeature = () =>
  useMutation<RemoveMarketplaceListingFeature, RemoveMarketplaceListingFeatureVariables>(MUTATION)
