import * as React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { ChannelsReportQuery } from './__generated__/ChannelsReportQuery'
import { Box, Card, CircularProgress, Table, TableCell, TableRow, Typography } from '@material-ui/core'
import { TruncatedId } from '../truncated-id'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

type ChannelsReportProps = IChannelsReportProps

interface IChannelsReportProps {}

const QUERY = gql`
  query ChannelsReportQuery {
    listingsReport {
      rows {
        installable {
          id
          title
        }
        listing {
          id
          title
        }
        channels
        enabledChannels
      }
    }
  }
`

const useChannelsReport = () => useQuery<ChannelsReportQuery>(QUERY)

export const ChannelsReport: React.FC<ChannelsReportProps> = props => {
  const { data, loading, error } = useChannelsReport()

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Channels</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {error ? (
        <Alert severity='error'>There was an error loading the channels report</Alert>
      ) : loading || !data ? (
        <CircularProgress />
      ) : (
        <Card>
          <Table>
            <TableRow>
              <TableCell>
                <strong>Listing ID</strong>
              </TableCell>
              <TableCell>
                <strong>Listing Title</strong>
              </TableCell>
              <TableCell>
                <strong>Installable</strong>
              </TableCell>
              <TableCell># Channels</TableCell>
              <TableCell># Enabled Channels</TableCell>
            </TableRow>
            {data.listingsReport.rows.map(row => (
              <TableRow>
                <TableCell>
                  <TruncatedId value={row.listing.id}></TruncatedId>
                </TableCell>
                <TableCell>{row.listing.title}</TableCell>
                <TableCell>{row.installable.title}</TableCell>
                <TableCell>{row.channels}</TableCell>
                <TableCell>{row.enabledChannels}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Card>
      )}
    </Box>
  )
}
