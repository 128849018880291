import { TableBody, TableBodyProps } from '@material-ui/core'
import React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'

interface IDroppableTableBody extends TableBodyProps {}

export const DroppableTableBody = (onDragEnd: (result: DropResult, provided: ResponderProvided) => void) => (
  props: IDroppableTableBody
) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'} direction='vertical'>
        {provided => {
          return (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
