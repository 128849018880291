import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useListMessageSetSubmissions } from '../hooks/useListMessageSetSubmissions'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import InfiniteScroll from 'react-infinite-scroller'

export const ListSubmittedMessageSets = () => {
  const history = useHistory()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { data, loading, error, fetchMore } = useListMessageSetSubmissions({
    input: {
      cursor: null,
      limit: 50
    }
  })

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Message Set Submissions</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading && !data?.listSubmittedMessageSets ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the submitted message sets</Alert>
      ) : !data?.listSubmittedMessageSets.submittedMessageSets.length ? (
        <Alert severity='info'>There are no submitted message sets</Alert>
      ) : (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={async () => {
              if (isLoadingMore) {
                return
              }

              setIsLoadingMore(true)
              return await fetchMore({
                updateQuery(previousQueryResult, options) {
                  const newMessageSets = options.fetchMoreResult?.listSubmittedMessageSets.submittedMessageSets || []
                  const previousMessageSets = previousQueryResult.listSubmittedMessageSets.submittedMessageSets || []

                  return {
                    listSubmittedMessageSets: {
                      ...previousQueryResult.listSubmittedMessageSets,
                      nextCursor: options.fetchMoreResult?.listSubmittedMessageSets.nextCursor || null,
                      submittedMessageSets: [...previousMessageSets, ...newMessageSets]
                    }
                  }
                },
                variables: {
                  input: {
                    cursor: data.listSubmittedMessageSets.nextCursor || null,
                    limit: 50
                  }
                }
              }).then(res => {
                setIsLoadingMore(false)
                return res
              })
            }}
            hasMore={!!data?.listSubmittedMessageSets?.nextCursor}
            loader={
              <Box>
                <Spacer size='large' />
                <CircularProgress />
              </Box>
            }>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: 100
                      }}>
                      ID
                    </TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell># of Messages</TableCell>
                    <TableCell>Person Name</TableCell>
                    <TableCell>Person ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.listSubmittedMessageSets.submittedMessageSets.map(messageSet => (
                    <TableRow key={messageSet.id}>
                      <TableCell>
                        <Button
                          onClick={() => {
                            history.push(`/submitted-message-sets/${messageSet.id}`)
                          }}
                          variant='outlined'
                          size='small'
                          color='primary'>
                          <TruncatedId value={messageSet.id} />
                        </Button>
                      </TableCell>
                      <TableCell>{messageSet.title}</TableCell>
                      <TableCell>{messageSet.description}</TableCell>
                      <TableCell>{messageSet.messageIds.length}</TableCell>
                      <TableCell>{messageSet.personName}</TableCell>
                      <TableCell>
                        <TruncatedId value={messageSet.personId} path={`/person/${messageSet.personId}`} full />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </>
      )}
    </Box>
  )
}
