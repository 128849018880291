import React from 'react'
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { useHistory, useLocation } from 'react-router'
import { ColorTemplates } from '../components/ColorTemplates'
import { Categories } from '../components/Categories'

const tabs = ['Color Templates', 'Categories']

export const ColorTemplatesList = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tabName = query.get('tab') || 'Color Templates'
  const tab = tabs.indexOf(tabName)

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>
          {tabName === 'Categories' ? 'Color Template Categories' : 'Color Templates'}
        </Typography>
        <Button
          onClick={() =>
            history.push(tabName === 'Categories' ? '/color-templates/categories/create' : '/color-templates/create')
          }
          variant='contained'
          color='primary'>
          {tabName === 'Categories' ? 'Create New Category' : 'Create New Color Template'}
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      <Tabs
        value={tab}
        onChange={(_event, newValue) => {
          history.push(`/color-templates?tab=${tabs[newValue]}`)
        }}>
        {tabs.map(tab => (
          <Tab label={tab} id={tab} key={tab} />
        ))}
      </Tabs>
      <Spacer size='large' />
      {tabName === 'Categories' ? <Categories /> : <ColorTemplates />}
    </Box>
  )
}
