import { Input, TableCell } from '@material-ui/core'

import React from 'react'
import { useState } from 'react'

interface IEditableCell {
  value: string
  onChange: (value: string) => void
}

export const EditableCell = (props: IEditableCell) => {
  const [value, setValue] = useState(props.value)

  return (
    <TableCell>
      <Input
        fullWidth
        style={{ fontSize: 12 }}
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={() => {
          if (value !== props.value) {
            props.onChange(value)
          }
        }}
      />
    </TableCell>
  )
}
