import { useEffect, useState } from 'react'
import { useAuth0 } from '../../../react-auth0-spa'
import { REPORTS_API } from '../../../constants'

interface IData {
  boardPersons: number
  date: string
  manual: number
  marketplaceListings: number
  messageSendingPersons: number
  privateInstallables: number
  total: number
}

export const useGetDayMessageSources = (searchKey: string | null) => {
  const { getTokenSilently } = useAuth0()
  const [data, setData] = useState<IData | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${REPORTS_API}/message-sent/day/${searchKey}`, {
          headers: { 'X-Vestaboard-Token': await getTokenSilently() }
        })
        const json = await response.json()

        setData(json)
      } catch (e) {
        setData(null)
      }
    }

    if (!!searchKey) {
      fetchData()
    }
  }, [searchKey, getTokenSilently])

  return { data }
}
