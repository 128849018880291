import React from 'react'
import { AppBar, Box, Button, IconButton, Toolbar, Typography, makeStyles } from '@material-ui/core'
import { MenuIcon } from '@material-ui/x-grid'
import clsx from 'clsx'
import { SITE_TITLE, drawerWidth } from '../constants'
import { OmniSearch } from './OmniSearch'

const appBarHeight = 64

const useStyles = makeStyles(theme => ({
  appBar: {
    height: appBarHeight,
    background: '#333',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  title: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  rightToolbar: {
    display: 'flex'
  },
  rightToolbarSpacer: {
    width: 12
  }
}))

interface ITopNavProps {
  setDrawerOpen: (open: boolean) => void
  drawerOpen: boolean
  logout: () => void
}

export const TopNav = ({ setDrawerOpen, drawerOpen, logout }: ITopNavProps) => {
  const classes = useStyles()

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen
      })}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={() => setDrawerOpen(true)}
          edge='start'
          className={clsx(classes.menuButton, drawerOpen && classes.hide)}>
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' className={classes.title}>
          {SITE_TITLE}
        </Typography>
        <Box className={classes.rightToolbar}>
          <OmniSearch />
          <Box className={classes.rightToolbarSpacer} />
          <Button variant='contained' onClick={() => logout()}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
