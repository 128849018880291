import * as React from 'react'
import { SuperadminTable } from '../superadmin-table'
import { Card } from '@material-ui/core'

type SuperadminListPageProps = ISuperadminListPageProps

interface ISuperadminListPageProps {}

export const SuperadminListPage: React.FC<SuperadminListPageProps> = props => {
  return (
    <Card>
      <SuperadminTable />
    </Card>
  )
}
