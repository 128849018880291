import React from 'react'
import { Tooltip, CartesianGrid, ResponsiveContainer, XAxis, YAxis, ComposedChart, Area } from 'recharts'

interface IMessagesSent {
  data: {
    Messages: number
    key: string
  }[]
}

export const MessagesSent = (props: IMessagesSent) => {
  return (
    <ResponsiveContainer>
      <ComposedChart data={props.data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key' />
        <YAxis domain={['dataMin', 'dataMax']} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Area type='monotone' dataKey='Messages' fill='rgb(255, 117, 0)' stroke='rgb(255, 117, 0)' />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
