import { BoardEditor, createBoard, IBoard, FullModal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'

interface IMessageCreator {
  visible: boolean
  setVisible: (visible: boolean) => void
  onSave: (message: IBoard) => void
  defaultValue?: IBoard | null
}

export const MessageCreator = (props: IMessageCreator) => {
  const [value, setValue] = useState(props.defaultValue || createBoard())

  return props.visible ? (
    <FullModal onClose={() => props.setVisible(false)}>
      <>
        <BoardEditor
          boardValue={value}
          setBoardValue={setValue}
          sendButtonLabel='Save'
          onSend={() => {
            props.setVisible(false)
            props.onSave(value)
          }}
        />
        <Spacer size='extraLarge' />
      </>
    </FullModal>
  ) : null
}
