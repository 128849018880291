import React from 'react'
import { CircularProgress, MenuItem, Select } from '@material-ui/core'
import { useGetQAs } from '../hooks/useGetQAs'
import { useGetViewer } from '../../../api/useGetViewer'

interface ISelectQa {
  value: string | null
  setValue: (value: string | null) => void
}

interface ISelectQaComponent extends ISelectQa {
  token: string
}

export const SelectQaComponent = (props: ISelectQaComponent) => {
  const { data } = useGetQAs({
    token: props.token
  })

  return (
    <Select
      fullWidth
      variant='outlined'
      value={props.value}
      onChange={e => {
        props.setValue(e.target.value ? `${e.target.value}` : null)
      }}>
      <MenuItem></MenuItem>
      {(data || []).map(qa => (
        <MenuItem key={qa.id} value={qa.id}>
          {qa.title}
        </MenuItem>
      ))}
    </Select>
  )
}

export const SelectQa = (props: ISelectQa) => {
  const { data } = useGetViewer()

  return !data?.viewer?.adminInstallableMonorepoToken ? (
    <CircularProgress />
  ) : (
    <SelectQaComponent
      value={props.value}
      setValue={props.setValue}
      token={data.viewer?.adminInstallableMonorepoToken}
    />
  )
}
