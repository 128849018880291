import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useCallback, useState } from 'react'
import { useSendMessage } from '../message-v1'
import { CsvImportButton } from '../../ui/CsvImportButton'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import { useBoardDeviceAssociations } from '../board/hooks/useBoardDeviceAssociations'
import { Error } from '@material-ui/icons'

interface ICsvMessage {
  message: string
}

export const SerialExceptionChecker = () => {
  const [board, setBoard] = useState<string>('')
  const [sendMessage] = useSendMessage()
  const [messages, setMessages] = useState<string[]>([])
  const [sentState, setSentState] = useState<string[]>([])
  const { data } = useBoardDeviceAssociations({
    input: {
      internalBoard: true,
      perPage: 10000
    }
  })

  const sendMessagesRecursive = useCallback(
    async (messages: string[], index: number) => {
      setSentState(sentState => {
        sentState[index] = 'current'
        return sentState
      })

      try {
        await sendMessage({
          variables: {
            input: {
              boardId: board,
              text: messages[index]
            }
          }
        })

        setSentState(sentState => {
          sentState[index] = 'success'
          return sentState
        })
      } catch (e) {
        setSentState(sentState => {
          sentState[index] = 'error'
          return sentState
        })
      }

      setTimeout(() => {
        sendMessagesRecursive(messages, index + 1)
      }, 30000)
    },
    [board, sendMessage]
  )

  return (
    <>
      <Typography variant='h4'>Serial Exception Checker</Typography>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <CsvImportButton
          noHeader
          mappings={['message']}
          handleImport={messages => {
            setMessages(((messages as any) as ICsvMessage[]).map(({ message }) => message))
          }}
        />
        <Box width={14} />
        <Select
          variant='outlined'
          value={board}
          onChange={e => {
            setBoard(e.target.value as string)
          }}>
          <MenuItem value=''>Select a board</MenuItem>
          {data?.listBoardDeviceAssociations.boardDeviceAssociations
            .filter(board => !board.boardDeleted && !!board.boardTitle)
            .sort((a, b) => a.boardTitle.localeCompare(b.boardTitle))
            .map(board => (
              <MenuItem key={board.boardId} value={board.boardId}>
                {board.boardTitle}
              </MenuItem>
            ))}
        </Select>
        <Box width={14} />
        <Button
          disabled={!board || !messages.length}
          variant='contained'
          color='primary'
          onClick={() => {
            sendMessagesRecursive(messages, 0)
          }}>
          Send Messages
        </Button>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {messages.map((message, index) => (
              <TableRow key={index}>
                <TableCell>
                  {!sentState[index] ? (
                    <CheckBoxOutlineBlankIcon />
                  ) : sentState[index] === 'current' ? (
                    <HourglassFullIcon color='primary' />
                  ) : sentState[index] === 'error' ? (
                    <Error color='error' />
                  ) : (
                    <CheckBoxIcon />
                  )}
                </TableCell>
                <TableCell>
                  <Typography>{message}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
