import { gql } from 'apollo-boost'
import {
  ListUpcomingCategoryPicks,
  ListUpcomingCategoryPicksVariables
} from './__generated__/ListUpcomingCategoryPicks'
import { useQuery } from '@apollo/react-hooks'

const LIST_UPCOMING_CATEGORY_PICKS = gql`
  query ListUpcomingCategoryPicks($id: String!, $page: Int, $perPage: Int) {
    pickCategory(id: $id) {
      id
      upcomingPicks(page: $page, perPage: $perPage) {
        id
        recommendBefore
        recommendAfter
        categories {
          id
          title
        }
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useListUpcomingCategoryPicks = (variables: ListUpcomingCategoryPicksVariables) => {
  return useQuery<ListUpcomingCategoryPicks, ListUpcomingCategoryPicksVariables>(LIST_UPCOMING_CATEGORY_PICKS, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
