export enum ProductApplicationSortBy {
  activationCode = 'activationCode',
  boardId = 'boardId',
  created = 'created',
  effectiveOnOrAfter = 'effectiveOnOrAfter',
  email = 'email',
  expiresOnOrAfter = 'expiresOnOrAfter',
  id = 'id',
  optOutDate = 'optOutDate',
  optOutOfRenewal = 'optOutOfRenewal',
  optOutReason = 'optOutReason',
  personId = 'personId',
  pricingType = 'pricingType',
  shopifyOrderId = 'shopifyOrderId',
  shopifyOrderName = 'shopifyOrderName'
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}
