import { useCallback } from 'react'
import { INSTALLABLES_MONOREPO_API } from '../../../constants'

interface IDeleteQa {
  id: string
}

interface IUseDeleteQa {
  token: string
}

export const useDeleteQA = (props: IUseDeleteQa) => {
  const deleteQa = useCallback(
    async (input: IDeleteQa) => {
      const result = await fetch(`${INSTALLABLES_MONOREPO_API}/api/question-answer/set/${input.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.token}`
        }
      }).then(res => res.json())

      return result
    },
    [props]
  )

  return deleteQa
}
