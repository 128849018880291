import { useQuery } from '@apollo/react-hooks'
import { BoardDetailQuery, BoardDetailQueryVariables } from './__generated__/BoardDetailQuery'
import { gql } from 'apollo-boost'

export const BOARD_DETAIL_QUERY = gql`
  query BoardDetailQuery($boardId: String!) {
    board(id: $boardId) {
      id
      boardStyle
      title
      friendlyIdentifier
      deleted
      quietHoursBegin
      quietHoursEnd
      internalBoard
      hubspotDealId
      pinnedMessage {
        message {
          id
        }
        pinnedUntil
      }
      timezone {
        id
        timezoneCity
      }
      productApplications {
        id
        created
        started
        ends
        pricing {
          product {
            isPlus
          }
        }
      }
      tenant {
        id
        name
        ... on PersonTenant {
          members {
            id
            created
            role
            isCurrentMember
            invitationStatus
            person {
              id
              firstName
              lastName
              account {
                id
                emailAddress
              }
            }
          }
        }
      }
      devices {
        id
        type
        timezone
        model {
          id
          title
        }
        configurationNetwork {
          id
          ssid
        }
      }
      subscriptionSet {
        id
        subscriptions {
          id
          title
          muted
          created
          messageSet {
            id
          }
          configurationUrl
          marketplaceListing {
            id
            title
          }
          installation {
            id
            marketplaceListing {
              title
            }
            installable {
              id
              title
            }
          }
        }
      }
      messageAppearances(limit: 1) {
        id
        appeared
      }
    }
  }
`

export const useBoardDetailQuery = (boardId: string) =>
  useQuery<BoardDetailQuery, BoardDetailQueryVariables>(BOARD_DETAIL_QUERY, {
    variables: { boardId }
  })

useBoardDetailQuery.Query = BOARD_DETAIL_QUERY
