import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGetAcceptedReferral } from '../hooks/useGetAcceptedReferral'
import {
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import moment from 'moment'
import { TruncatedId } from '../../truncated-id'
import { useUpdateAcceptedReferral } from '../hooks/useUpdateAcceptedRefferal'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

export const GetReferral = () => {
  const params = useParams<{ id: string }>()
  const { loading, error, data } = useGetAcceptedReferral(params.id)
  const [updateAcceptedReferral] = useUpdateAcceptedReferral()
  const history = useHistory()

  const updateNotifiedAt = useCallback(
    async notified => {
      if (data?.acceptedReferral.__typename === 'AcceptedReferralV2') {
        await updateAcceptedReferral({
          variables: {
            input: {
              id: data.acceptedReferral.id,
              creditRedeemed: data.acceptedReferral.creditRedeemed,
              notified
            }
          },
          optimisticResponse: {
            updateAcceptedReferral: {
              ...data.acceptedReferral,
              notified
            }
          }
        })
      }
    },
    [data, updateAcceptedReferral]
  )

  const updateDeliveredAt = useCallback(
    async orderDeliveredAt => {
      if (data?.acceptedReferral.__typename === 'AcceptedReferralV2') {
        await updateAcceptedReferral({
          variables: {
            input: {
              id: data.acceptedReferral.id,
              creditRedeemed: data.acceptedReferral.creditRedeemed,
              orderDeliveredAt
            }
          },
          optimisticResponse: {
            updateAcceptedReferral: {
              ...data.acceptedReferral,
              orderDeliveredAt
            }
          }
        })
      }
    },
    [data, updateAcceptedReferral]
  )

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>There was an error loading this accepted referral</Alert>
  ) : data?.acceptedReferral.__typename === 'AcceptedReferralV2' ? (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Order</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>
                  <TruncatedId value={data?.acceptedReferral?.id} full />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Referral Date</TableCell>
                <TableCell>
                  <TextField
                    type='date'
                    variant='outlined'
                    value={moment(data?.acceptedReferral?.createdAt).format('YYYY-MM-DD')}
                    fullWidth
                    disabled
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shopify Order ID</TableCell>
                <TableCell>
                  <a
                    style={{
                      color: '#3f51b5',
                      fontSize: '1rem',
                      textDecoration: 'none'
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://vestaboard-plus.myshopify.com/admin/orders/${data.acceptedReferral?.shopifyOrderId}`}>
                    {data.acceptedReferral?.shopifyOrderId || ''}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shopify Order Name</TableCell>
                <TableCell>{data.acceptedReferral.shopifyOrderName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shopify Customer Name</TableCell>
                <TableCell>{data.acceptedReferral.shopifyCustomerName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shopify Customer Email</TableCell>
                <TableCell>{data.acceptedReferral.shopifyCustomerEmail}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Referrer</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Referrer ID</TableCell>
                <TableCell>
                  <TruncatedId
                    value={data?.acceptedReferral?.referrerPersonId}
                    full
                    path={`/person/${data.acceptedReferral.referrerPersonId}`}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Referrer Name</TableCell>
                <TableCell>{data.acceptedReferral.referrerName || ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Referrer Email</TableCell>
                <TableCell>{data.acceptedReferral.referrerEmail || ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Management</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Delivered At</TableCell>
                <TableCell>
                  <FlexHorizontal>
                    <TextField
                      type='date'
                      variant='outlined'
                      onChange={e => {
                        updateDeliveredAt(moment(e.target.value).toDate().getTime())
                      }}
                      value={
                        data?.acceptedReferral?.orderDeliveredAt
                          ? moment(data?.acceptedReferral?.orderDeliveredAt).format('YYYY-MM-DD')
                          : ''
                      }
                      fullWidth
                    />
                    <Button
                      onClick={() => {
                        updateDeliveredAt(null)
                      }}>
                      Clear
                    </Button>
                  </FlexHorizontal>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Notified At</TableCell>
                <TableCell>
                  <FlexHorizontal>
                    <TextField
                      type='date'
                      variant='outlined'
                      onChange={e => {
                        updateNotifiedAt(moment(e.target.value).toDate().getTime())
                      }}
                      value={
                        data?.acceptedReferral?.notified
                          ? moment(data?.acceptedReferral?.notified).format('YYYY-MM-DD')
                          : ''
                      }
                      fullWidth
                    />
                    <Button
                      onClick={() => {
                        updateNotifiedAt(null)
                      }}>
                      Clear
                    </Button>
                  </FlexHorizontal>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Invalid</TableCell>
                <TableCell>
                  <ButtonBase
                    onClick={() => {
                      if (data.acceptedReferral.__typename === 'AcceptedReferralV2') {
                        const invalidated = !data.acceptedReferral.invalidated ? new Date().getTime() : null
                        updateAcceptedReferral({
                          variables: {
                            input: {
                              id: data.acceptedReferral.id,
                              creditRedeemed: data.acceptedReferral.creditRedeemed,
                              invalidated
                            }
                          },
                          optimisticResponse: {
                            updateAcceptedReferral: {
                              ...data.acceptedReferral,
                              invalidated
                            }
                          }
                        })
                      }
                    }}>
                    {data.acceptedReferral.invalidated ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </ButtonBase>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Credit Redeemed</TableCell>
                <TableCell>
                  <ButtonBase
                    onClick={() => {
                      if (data.acceptedReferral.__typename === 'AcceptedReferralV2') {
                        const creditRedeemed = !data.acceptedReferral.creditRedeemed

                        updateAcceptedReferral({
                          variables: {
                            input: {
                              id: data.acceptedReferral.id,
                              creditRedeemed,
                              ...(!creditRedeemed
                                ? {
                                    creditType: null
                                  }
                                : {})
                            }
                          },
                          optimisticResponse: {
                            updateAcceptedReferral: {
                              ...data.acceptedReferral,
                              creditRedeemed
                            }
                          }
                        })
                      }
                    }}>
                    {data.acceptedReferral.creditRedeemed ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </ButtonBase>
                </TableCell>
              </TableRow>
              {data.acceptedReferral.creditRedeemed ? (
                <TableRow>
                  <TableCell>Credit Type</TableCell>
                  <TableCell>
                    <Select
                      value={data.acceptedReferral.creditType || ''}
                      variant='outlined'
                      fullWidth
                      onChange={e => {
                        if (data.acceptedReferral.__typename === 'AcceptedReferralV2') {
                          const creditType = (e.target.value as string) || null

                          updateAcceptedReferral({
                            variables: {
                              input: {
                                id: data.acceptedReferral.id,
                                creditRedeemed: true,
                                creditType
                              }
                            },
                            optimisticResponse: {
                              updateAcceptedReferral: {
                                ...data.acceptedReferral,
                                creditRedeemed: true,
                                creditType
                              }
                            }
                          })
                        }
                      }}>
                      <MenuItem></MenuItem>
                      <MenuItem value='eGift Card'>eGift Card</MenuItem>
                      <MenuItem value='Vestaboard+'>Vestaboard+</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Spacer size='extraLarge' />
      <Button
        onClick={() => {
          history.push('/referrals')
        }}>
        Cancel
      </Button>
      <Spacer size='extraLarge' />
    </>
  ) : (
    <Alert severity='error'>There was an error loading this accepted referral</Alert>
  )
}
