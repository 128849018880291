import {
  Box,
  ButtonBase,
  Card,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import React from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { SearchInput } from './SearchInput'
import { SearchValueType } from './AdvancedSearch'
import moment from 'moment'

const useStyles = makeStyles({
  link: {
    color: '#3f51b5',
    fontSize: '1rem',
    textDecoration: 'none'
  },
  tableHeader: {
    fontWeight: 'bold'
  },
  tableBody: {
    overflow: 'auto',
    maxHeight: `calc(100vh - 230px)`
  }
})

interface IColumn {
  title: string
  key?: string
  width?: number
  render: (row: any) => any
}

interface IPagination {
  page: number
  total: number
  perPage: number
}

interface ISortOrder {
  by: any
  order: any
}

interface ISuperGrid {
  title: string
  columns: IColumn[]
  rows: any[]
  sortBy: string
  sortOrder: string
  pagination?: IPagination
  setPage: (page: number) => void
  setSort: (order: ISortOrder) => void
  setSearchValue: (terms: string) => void
  searchValue: SearchValueType
  loading: boolean
  error: boolean
  centerButtons?: React.ReactNode | null
  renderSearch?: React.ReactNode
  searchType?: string
  disableWarnings?: boolean
}

const getSearchValueText = (searchValue: SearchValueType) =>
  searchValue === null
    ? ''
    : typeof searchValue === 'string'
    ? searchValue
    : typeof searchValue === 'boolean'
    ? searchValue
      ? 'Yes'
      : 'No'
    : searchValue.max > 2147483647
    ? `Between ${moment(searchValue.min).format('MM/DD/YYYY')} and ${
        searchValue.max === 2524629600000 ? 'the distant future' : moment(searchValue.max).format('MM/DD/YYYY')
      }`
    : `Between ${searchValue.min} and ${searchValue.max === 2147483647 ? 'Infinity' : searchValue.max}`

export const SuperGrid = (props: ISuperGrid) => {
  const classes = useStyles()

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>{props.title}</Typography>
        {props.centerButtons}
        {props.renderSearch || (
          <SearchInput
            onSearch={value => {
              props.setSearchValue(value)
              props.setPage(0)
            }}
          />
        )}
      </FlexHorizontal>
      <Spacer size='large' />
      {props.searchValue ? (
        props.rows.length ? (
          <>
            <Alert severity='info'>
              Searching for {props.searchType || 'terms'} "{getSearchValueText(props.searchValue)}"
            </Alert>
            <Spacer size='medium' />
          </>
        ) : !props.disableWarnings ? (
          <>
            <Alert severity='warning'>
              There are no results for {props.searchType || 'terms'} "{getSearchValueText(props.searchValue)}"
            </Alert>
            <Spacer size='medium' />
          </>
        ) : null
      ) : null}
      {props.error ? (
        <Alert severity='error'>There was an error loading the {props.title}</Alert>
      ) : props.loading ? (
        <CircularProgress />
      ) : props.rows.length ? (
        <Card
          style={{
            maxWidth: 'calc(100vw - 290px)',
            overflowX: 'auto'
          }}>
          <TableContainer className={classes.tableBody}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {props.columns.map((column, index) => (
                    <TableCell key={column.key || index}>
                      <ButtonBase
                        className={classes.tableHeader}
                        onClick={() => {
                          if (!column.key) {
                            return
                          }
                          props.setSort({
                            by: column.key,
                            order: props.sortOrder === 'asc' ? 'desc' : 'asc'
                          })
                        }}>
                        {props.sortBy === column.key ? (
                          props.sortOrder === 'asc' ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )
                        ) : null}{' '}
                        {column.title}
                      </ButtonBase>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row, index) => (
                  <TableRow key={row.id || index}>
                    {props.columns.map(column => (
                      <TableCell key={`${column.key || index}-${row.id}`}>
                        <Box
                          style={{
                            width: column.width ? `${column.width}px` : undefined,
                            overflow: 'hidden'
                          }}>
                          {column.render(row)}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {props.rows.length ? (
            <TablePagination
              rowsPerPageOptions={[]}
              count={props.pagination?.total || 0}
              rowsPerPage={props.pagination?.perPage || 0}
              page={props.pagination?.page || 0}
              onPageChange={(_, page) => props.setPage(page)}
              onChangeRowsPerPage={() => {}}
            />
          ) : null}
        </Card>
      ) : null}
    </>
  )
}
