import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import { FormattedDateTime } from '../../formatted-date-time'
import InfiniteScroll from 'react-infinite-scroller'
import { SearchInput } from '../../../ui/SearchInput'
import { TruncatedId } from '../../truncated-id'
import { useListDigitalDevices } from '../hooks/useListDigitalDevices'

const LIMIT = 6

export const ListDigitalDevices = () => {
  const [search, setSearch] = useState('')
  const { data, loading, error, fetchMore } = useListDigitalDevices({ cursor: null, limit: LIMIT, search })
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Digital Devices</Typography>
        <SearchInput
          onSearch={search => {
            setSearch(search)
          }}
        />
      </FlexHorizontal>
      <Spacer size='large' />
      {loading && !data?.listDigitalDevices ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the digital devices</Alert>
      ) : !data?.listDigitalDevices.digitalDevices.length ? (
        <Alert severity='info'>There are no digital devices</Alert>
      ) : (
        <Card>
          <InfiniteScroll
            pageStart={0}
            hasMore={!!data?.listDigitalDevices.nextCursor && !isLoadingMore}
            loadMore={async () => {
              if (isLoadingMore) {
                return
              }

              setIsLoadingMore(true)
              return await fetchMore({
                updateQuery(previousQueryResult, options) {
                  const newDigitalDevices = options.fetchMoreResult?.listDigitalDevices?.digitalDevices || []
                  const previousDigitalDevices = previousQueryResult?.listDigitalDevices?.digitalDevices || []

                  return {
                    listDigitalDevices: {
                      ...previousQueryResult.listDigitalDevices,
                      nextCursor: options.fetchMoreResult?.listDigitalDevices.nextCursor || null,
                      digitalDevices: [...previousDigitalDevices, ...newDigitalDevices]
                    }
                  }
                },
                variables: {
                  limit: LIMIT,
                  cursor: data?.listDigitalDevices.nextCursor || null
                }
              }).then(res => {
                setIsLoadingMore(false)
                return res
              })
            }}
            loader={
              <Box>
                <Spacer size='large' />
                <CircularProgress />
              </Box>
            }>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Board ID</TableCell>
                    <TableCell>Board Title</TableCell>
                    <TableCell>Device Style</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data?.listDigitalDevices?.digitalDevices || []).map(digitalDevice => (
                    <TableRow key={digitalDevice.id}>
                      <TableCell>
                        <TruncatedId value={digitalDevice.id} path={`/digital-devices/${digitalDevice.id}`} full />
                      </TableCell>
                      <TableCell>
                        <TruncatedId value={digitalDevice.board.id} path={`/boards/${digitalDevice.board.id}`} full />
                      </TableCell>
                      <TableCell>{digitalDevice.board.title}</TableCell>
                      <TableCell>{digitalDevice.digitalDeviceStyle.title}</TableCell>
                      <TableCell>
                        <FormattedDateTime value={digitalDevice.board.createdAt} format='YYYY-MM-DD hh:mm a' />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Card>
      )}
    </>
  )
}
