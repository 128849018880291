import {
  ListSystemMessageTemplates,
  ListSystemMessageTemplatesVariables
} from './__generated__/ListSystemMessageTemplates'

import { ListSystemMessageTemplatesInputV2 } from '../../../../__generated__/globalTypes'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListSystemMessageTemplates($input: ListSystemMessageTemplatesInputV2!) {
    listSystemMessageTemplates: listSystemMessageTemplatesV2(input: $input) {
      systemMessageTemplates {
        id
        title
        template
        deepLink
        callToAction
      }
      nextCursor
    }
  }
`

export const useListSystemMessageTemplates = (input: ListSystemMessageTemplatesInputV2) => {
  return useQuery<ListSystemMessageTemplates, ListSystemMessageTemplatesVariables>(QUERY, { variables: { input } })
}
