import { FullModal } from '@vestaboard/installables'
import { GetMessage } from '../pages/GetMessage'
import React from 'react'

interface IMessageModal {
  onClose: () => void
  messageId: string | null
}

export const MessageModal = (props: IMessageModal) => {
  return !!props.messageId ? (
    <FullModal onClose={props.onClose}>
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'white',
          padding: '24px'
        }}>
        {props.messageId ? <GetMessage messageId={props.messageId} /> : <div />}
      </div>
    </FullModal>
  ) : null
}
