import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  MarketplaceListingSubscriptions,
  MarketplaceListingSubscriptionsVariables
} from './__generated__/MarketplaceListingSubscriptions'

const QUERY = gql`
  query MarketplaceListingSubscriptions($id: String!, $page: Int, $perPage: Int) {
    marketplaceListing(id: $id) {
      id
      title
      adminInstallations(page: $page, perPage: $perPage) {
        id
        created
        subscriptions {
          id
          title
          muted
          configurationUrl
          subscriptionSet {
            id
            boards {
              id
              title
            }
          }
        }
      }
    }
  }
`

interface IUseMarketplaceListingSubscriptions {
  page?: number
  perPage?: number
}

export const useMarketplaceListingSubscriptions = (id: string, config?: IUseMarketplaceListingSubscriptions) => {
  return useQuery<MarketplaceListingSubscriptions, MarketplaceListingSubscriptionsVariables>(QUERY, {
    variables: {
      id,
      page: config?.page,
      perPage: config?.perPage
    }
  })
}
