import { useMutation } from '@apollo/react-hooks'
import { DeletePick, DeletePickVariables } from './__generated__/DeletePick'
import { gql } from 'apollo-boost'

const DELETE_PICK = gql`
  mutation DeletePick($input: DeletePickInput!) {
    deletePick(input: $input) {
      deleted {
        id
      }
    }
  }
`

export const useDeletePick = () => {
  return useMutation<DeletePick, DeletePickVariables>(DELETE_PICK)
}
