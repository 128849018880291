import { gql } from 'apollo-boost'
import { ListCategoryPicks, ListCategoryPicksVariables } from './__generated__/ListCategoryPicks'
import { useQuery } from '@apollo/react-hooks'

const LIST_CATEGORY_PICKS = gql`
  query ListCategoryPicks($id: String!, $page: Int, $perPage: Int) {
    pickCategory(id: $id) {
      id
      picks(page: $page, perPage: $perPage) {
        id
        recommendBefore
        recommendAfter
        categories {
          id
          title
        }
        message {
          id
          formatted
        }
      }
    }
  }
`

export const useListCategoryPicks = (variables: ListCategoryPicksVariables) => {
  return useQuery<ListCategoryPicks, ListCategoryPicksVariables>(LIST_CATEGORY_PICKS, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
