import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { IUseGetMessageSources } from '../hooks/useGetMessageSources'

interface IMessageSources {
  data: IUseGetMessageSources[]
}

export const MessageSources = ({ data }: IMessageSources) => {
  return (
    <ResponsiveContainer>
      <BarChart data={data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.3)' }} />
        <Legend />
        <Bar dataKey='Manual Compose' fill='rgb(218, 41, 28)' />
        <Bar dataKey='Marketplace Listing' fill='rgb(255, 117, 0)' />
        <Bar dataKey='Private Installable' fill='rgb(255, 184, 28)' />
      </BarChart>
    </ResponsiveContainer>
  )
}
