import React from 'react'
import { Route, Switch } from 'react-router'
import { DashboardBoards } from './pages/DashboardBoards'
import { DashboardInstallables } from './pages/DashboardInstallables'
import { DashboardMessages } from './pages/DashboardMessages'
import { DashboardBlended } from './pages/DashboardBlended'

export const DashboardRouter = () => (
  <Switch>
    <Route exact path={`/dashboard`} component={DashboardBoards} />
    <Route exact path='/dashboard/boards' component={DashboardBoards} />
    <Route exact path='/dashboard/messages' component={DashboardMessages} />
    <Route exact path='/dashboard/installables' component={DashboardInstallables} />
    <Route exact path='/dashboard/blended' component={DashboardBlended} />
  </Switch>
)
