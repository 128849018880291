import * as React from 'react'
import { ReportActivations } from '../report-activations'

type ReportActivationsContainerProps = IReportActivationsContainerProps

interface IReportActivationsContainerProps {}

export const ReportActivationsContainer: React.FC<ReportActivationsContainerProps> = props => {
  return <ReportActivations deleted={false} ignored={false} revoked={false} online={true} />
}
