import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import { Spacer } from '@vestaboard/installables'
import * as React from 'react'
import { useHistory } from 'react-router'
import { useInstallableCategories } from '../hooks/useInstallableCategories'
import { useCreateInstallable } from '../hooks/useCreateInstallable'

type NewInstallableProps = INewInstallableProps

interface INewInstallableProps {}

export const NewInstallable: React.FC<NewInstallableProps> = props => {
  const { data, loading } = useInstallableCategories()
  const [mutate] = useCreateInstallable()
  const history = useHistory()

  const [id, setId] = React.useState('')
  const [categoryId, setCategoryId] = React.useState('')
  const [isSingleton] = React.useState(true)
  const [canRename] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [icon, setIcon] = React.useState('')
  const [webhookUrl, setWebhookUrl] = React.useState<string | null>(null)
  const [subscriptionConfigurationUrl, setSubscriptionConfigurationUrl] = React.useState('')
  const [developer] = React.useState('a2beec22-ee1a-4ab8-a41b-1b8930616a68')
  const [cadenceConfigurationUrl, setCadenceConfigurationUrl] = React.useState('')
  const [cadenceDisplayTemplate, setCadenceDisplayTemplate] = React.useState('')
  const [cadenceToken, setCadenceToken] = React.useState('')

  if (!data || loading) return <CircularProgress />

  const submit = async () => {
    if (id && categoryId && description && title && icon && subscriptionConfigurationUrl && webhookUrl && developer) {
      await mutate({
        variables: {
          input: {
            id,
            installableCategory: categoryId,
            title,
            description,
            icon,
            webhookUrl,
            subscriptionConfigurationUrl,
            developer,
            isSingleton,
            canRename,
            cadenceConfigurationUrl: cadenceConfigurationUrl || null,
            cadenceDisplayTemplate: cadenceDisplayTemplate || null,
            cadenceToken: cadenceToken || null
          }
        }
      })

      history.push('/installables')
    }
  }

  return (
    <Box>
      <Table>
        <TableRow>
          <TableCell style={{ width: 200 }}>Installable ID</TableCell>
          <TableCell>
            <TextField variant='outlined' fullWidth value={id} onChange={e => setId(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Category</TableCell>
          <TableCell>
            <Select
              variant='outlined'
              fullWidth
              value={categoryId}
              onChange={e => setCategoryId(e.target.value as string)}>
              {data.installableCategories.map(category => (
                <MenuItem value={category.id}>{category.title}</MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>
            <TextField variant='outlined' fullWidth value={title} onChange={e => setTitle(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={description}
              onChange={e => setDescription(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Icon</TableCell>
          <TableCell>
            <TextField variant='outlined' fullWidth value={icon} onChange={e => setIcon(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Webhook URL</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={webhookUrl}
              onChange={e => setWebhookUrl(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Subscription Configuration URL</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={subscriptionConfigurationUrl}
              onChange={e => setSubscriptionConfigurationUrl(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cadence Configuration URL</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={cadenceConfigurationUrl}
              onChange={e => setCadenceConfigurationUrl(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cadence Token</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={cadenceToken}
              onChange={e => setCadenceToken(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cadence Display Template</TableCell>
          <TableCell>
            <TextField
              variant='outlined'
              fullWidth
              value={cadenceDisplayTemplate}
              onChange={e => setCadenceDisplayTemplate(e.target.value)}></TextField>
          </TableCell>
        </TableRow>
      </Table>
      <Spacer size='extraLarge' />
      <Button size='large' variant='contained' color='primary' onClick={() => submit()}>
        Submit
      </Button>
    </Box>
  )
}
