import { TagMessage, TagMessageVariables } from './__generated__/TagMessage'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation TagMessage($id: String!, $tags: [String!]!) {
    tagMessage: tagMessageV2(id: $id, tags: $tags) {
      ... on MessageV2 {
        id
        tags
      }
      ... on MessageErrorV2 {
        type
        error
      }
    }
  }
`

export const useTagMessage = () => {
  return useMutation<TagMessage, TagMessageVariables>(MUTATION)
}
