import * as React from 'react'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Card,
  Button,
  Box
} from '@material-ui/core'
import { parseFriendlyDate, renderParsedDate } from '../../../utils'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { TruncatedId } from '../../truncated-id'
import { useListDevelopers } from '../hooks'
import { SearchInput } from '../../../ui/SearchInput'
import { useHistory } from 'react-router'

type DeveloperListProps = IDeveloperListProps

interface IDeveloperListProps {}

const PAGE_SIZE = 100

export const DevelopersList: React.FC<DeveloperListProps> = _props => {
  const { data, loading } = useListDevelopers()
  const [page, setPage] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const history = useHistory()

  if (!data || loading) return <CircularProgress />

  const listStart = page === 0 ? 0 : PAGE_SIZE * page
  const listEnd = page === 0 ? PAGE_SIZE : listStart + PAGE_SIZE

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Developers</Typography>
        <FlexHorizontal>
          <SearchInput
            onSearch={search => {
              setPage(0)
              setSearch(search)
            }}
          />
          <Box width={14} />
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push('/developers/create')
            }}>
            Create Developer
          </Button>
        </FlexHorizontal>
      </FlexHorizontal>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.developers
                ?.filter(developer => {
                  if (!search) {
                    return true
                  }
                  return developer.title.toLowerCase().includes(search.toLowerCase())
                })
                .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
                .slice(listStart, listEnd)
                .map(developer => (
                  <TableRow key={developer.id}>
                    <TableCell>
                      <TruncatedId value={developer.id} path={`/developers/${developer.id}`} />
                    </TableCell>
                    <TableCell>{developer.title}</TableCell>
                    <TableCell>{renderParsedDate(parseFriendlyDate(developer.created))}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[PAGE_SIZE]}
          component='div'
          count={data.developers?.length}
          rowsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={(_, pageNumber) => setPage(pageNumber)}
        />
      </Card>
    </>
  )
}
