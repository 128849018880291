import React from 'react'
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts'
import { IUseGetMessagesPerUser } from '../hooks/useGetMessagesPerUser'

interface IMessagesPerUser {
  data: IUseGetMessagesPerUser[]
}

export const MaxMessagesPerUser = ({ data }: IMessagesPerUser) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} syncId='timeSeries'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Area type='monotone' dataKey='Max' stackId='1' stroke='rgb(112, 47, 138)' fill='rgb(112, 47, 138)' />
      </AreaChart>
    </ResponsiveContainer>
  )
}
