import React from 'react'

import { Button, makeStyles } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles({
  date: {
    color: '#FFF',
    marginRight: 7,
    '&:disabled': {
      color: '#FFF'
    }
  }
})

interface IDisabledDateButton {
  date?: string | null
}

export const DisabledDateButton = ({ date }: IDisabledDateButton) => {
  const classes = useStyles()

  if (!date) {
    return null
  }

  return (
    <Button disabled className={classes.date}>
      {moment(date).format('MMM DD, YYYY')}
    </Button>
  )
}
