import React from 'react'
import { Route, Switch } from 'react-router'

import { QuestionsAndAnswersList } from './pages/List'
import { CreateQuestionAnswer } from './pages/Create'
import { UpdateQuestionAnswer } from './pages/Update'
import { useGetViewer } from '../../api/useGetViewer'
import { CircularProgress } from '@material-ui/core'

interface IQuestionAnswerRouter {
  token: string
}

const QuestionAnswerRouter = (props: IQuestionAnswerRouter) => (
  <Switch>
    <Route exact path='/questions-and-answers' render={() => <QuestionsAndAnswersList token={props.token} />} />
    <Route exact path='/questions-and-answers/create' render={() => <CreateQuestionAnswer token={props.token} />} />
    <Route exact path='/questions-and-answers/:id' render={() => <UpdateQuestionAnswer token={props.token} />} />
  </Switch>
)

export const QuestionsAndAnswers = () => {
  const { data } = useGetViewer()

  return !data?.viewer?.adminInstallableMonorepoToken ? (
    <CircularProgress />
  ) : (
    <QuestionAnswerRouter token={data.viewer?.adminInstallableMonorepoToken} />
  )
}
