import { ListPicksV2, ListPicksV2Variables } from './__generated__/ListPicksV2'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListPicksV2($input: SearchMessageInputV2!) {
    listSearchMessages: listSearchMessagesV2(input: $input) {
      searchMessages {
        __typename
        ... on PickV2 {
          id
          date
          sortOrder
          public
          message {
            id
            text
            characters
            tags
          }
          categories {
            id
            title
          }
        }
      }
      nextCursor
    }
  }
`

export const useListPicks = (variables: ListPicksV2Variables) => {
  return useQuery<ListPicksV2, ListPicksV2Variables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  })
}
