export enum MessageSetSortBy {
  dynamic = 'dynamic',
  messagesCount = 'messagesCount',
  public = 'public',
  title = 'title'
}

export enum MessageSetSortDirection {
  asc = 'asc',
  desc = 'desc'
}
