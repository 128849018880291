import React from 'react'
import { Route, Switch } from 'react-router'
import { GetFeedItem } from './pages/GetFeedItem'
import { ListFeedItems } from './pages/ListFeedItems'

export const FeedItemRouterV2 = () => (
  <Switch>
    <Route exact path={`/feed-items/:id`} component={GetFeedItem} />
    <Route exact path={`/feed-items`} component={ListFeedItems} />
  </Switch>
)
