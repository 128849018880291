export enum PurchaseSortBy {
  created = 'created',
  email = 'email',
  id = 'id',
  personId = 'personId',
  shopifyOrderId = 'shopifyOrderId',
  shopifyOrderName = 'shopifyOrderName',
  productApplicationId = 'productApplicationId'
}

export enum PurchaseSortOrder {
  asc = 'asc',
  desc = 'desc'
}
