import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetViewer } from './__generated__/GetViewer'

const QUERY = gql`
  query GetViewer {
    viewer {
      id
      adminInstallableMonorepoToken
      account {
        id
        emailAddress
        person {
          id
        }
      }
    }
  }
`

export const useGetViewer = () => useQuery<GetViewer>(QUERY)
