import * as React from 'react'
import moment from 'moment'

type FormattedDateTimeProps = IFormattedDateTimeProps

interface IFormattedDateTimeProps {
  value?: number;
  format?: string;
  hideTime?: boolean;
  children?: (formatted: string) => React.ReactElement
}

export const FormattedDateTime: React.FC<FormattedDateTimeProps> = (props) => {
  const defaultFormat =  props.hideTime ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm a'

  const renderer = props.children ?? ((str: string) => <>{str}</>)

  return props.value ? renderer(moment(props.value).format(props.format ?? defaultFormat)) : null
}
