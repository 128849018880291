import React, { useCallback, useState } from 'react'
import {
  Button,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, IBoard, Spacer } from '@vestaboard/installables'
import { IHeader, IQuestionAnswer } from '../types'
import { v4 as uuid } from 'uuid'
import { Alert } from '@material-ui/lab'
import { DragIndicator, RemoveCircle } from '@material-ui/icons'
import { DroppableTableBody } from '../../../ui/DropableTableBody'
import { DraggableTableRow } from '../../../ui/DraggableTableRow'
import { Qa } from './Qa'
import { characterCodesToText } from '../../../utils/characterCodesToText'
import { CsvImport } from './CsvImport'
import { formatQAMessage } from '../utils/formatQAMessage'

interface IQas {
  headerRow?: IHeader
  qas: IQuestionAnswer[]
  setQas: (qas: IQuestionAnswer[]) => void
}

export const Qas = (props: IQas) => {
  const [showQa, setShowQa] = useState<string | null>(null)

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newData = Array.from(props.qas)
      const [removed] = newData.splice(startIndex, 1)
      newData.splice(endIndex, 0, removed)
      props.setQas(newData)
    },
    [props]
  )

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h5'>Questions and Answers</Typography>
        <CsvImport
          handleImport={newRecords => {
            const qas = newRecords.map(
              qa =>
                ({
                  guid: uuid(),
                  question: [formatQAMessage(qa.question, props.headerRow)],
                  answer: [formatQAMessage(qa.answer, props.headerRow)],
                  input: {
                    is_required: false,
                    max_tries: 0,
                    match_input_type: false,
                    match_amount: 'all',
                    correct_input: []
                  }
                } as IQuestionAnswer)
            )

            props.setQas([...props.qas, ...qas])
          }}
        />
        <Button
          variant='outlined'
          onClick={() =>
            props.setQas([
              ...props.qas,
              {
                guid: uuid(),
                question: [],
                answer: [],
                input: {
                  is_required: false,
                  max_tries: 0,
                  match_input_type: false,
                  match_amount: 'all',
                  correct_input: []
                }
              } as IQuestionAnswer
            ])
          }>
          Add QA
        </Button>
      </FlexHorizontal>
      <Spacer size='large' />
      {!props.qas.length ? (
        <Alert severity='info'>Add a question and answer to get started</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableBody component={DroppableTableBody(onDragEnd)}>
              {(props.qas || []).map((qa, index) => (
                <TableRow key={qa.guid} component={DraggableTableRow(qa.guid, index)}>
                  <TableCell style={{ width: 80, verticalAlign: 'top' }}>
                    <DragIndicator />
                  </TableCell>
                  <TableCell>
                    <ButtonBase
                      onClick={() => {
                        if (showQa === qa.guid) {
                          setShowQa(null)
                        } else {
                          setShowQa(qa.guid)
                        }
                      }}>
                      <Typography>
                        {qa.question?.[0] ? characterCodesToText(qa.question[0] as IBoard) : `QUESTION ${index + 1}`}
                      </Typography>
                    </ButtonBase>
                    {showQa === qa.guid ? (
                      <Qa
                        headerRow={props.headerRow}
                        data={qa}
                        setData={data => {
                          const newQas = Array.from(props.qas)
                          newQas[index] = data
                          props.setQas(newQas)
                        }}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell style={{ width: 70, verticalAlign: 'top' }}>
                    <ButtonBase
                      onClick={() => {
                        const newQas = Array.from(props.qas)
                        newQas.splice(index, 1)
                        props.setQas(newQas)
                      }}>
                      <RemoveCircle color='action' />
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
