import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  DeletePickCategoryMutation,
  DeletePickCategoryMutationVariables
} from './__generated__/DeletePickCategoryMutation'

const DELETE_PICK_CATEGORY = gql`
  mutation DeletePickCategoryMutation($input: DeletePickCategoryInput!) {
    deletePickCategory(input: $input) {
      deleted {
        id
        title
      }
    }
  }
`

export const useDeletePickCategory = () => {
  return useMutation<DeletePickCategoryMutation, DeletePickCategoryMutationVariables>(DELETE_PICK_CATEGORY)
}
