import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GetMessageAppearanceV2, GetMessageAppearanceV2Variables } from './__generated__/GetMessageAppearanceV2'

const QUERY = gql`
  query GetMessageAppearanceV2($id: String!) {
    messageAppearance: messageAppearanceV2(id: $id) {
      ... on MessageAppearanceV2 {
        id
        attribution
        sentAt
        created
        subscriptionId
        personId
        boardId
        message {
          id
          characters
          text
        }
      }
      ... on MessageAppearanceErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetMessageAppearance = (id: string) => {
  return useQuery<GetMessageAppearanceV2, GetMessageAppearanceV2Variables>(QUERY, {
    variables: { id }
  })
}
