import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress, Grid, Table, TableRow, TableCell, TableBody, Card, TableHead } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { DeviceBoardsQuery, DeviceBoardsQueryVariables } from './__generated__/DeviceBoardsQuery'
import { TruncatedId } from '../truncated-id'
import { Link } from '../link'

interface IDeviceBoards {
  deviceId: string
}

const DEVICE_BOARDS_QUERY = gql`
  query DeviceBoardsQuery($deviceId: String!) {
    device(id: $deviceId) {
      id
      boards {
        id
        title
        tenant {
          ... on PersonTenant {
            members {
              id
              role
              person {
                id
                firstName
                lastName
                tenants {
                  id
                }
                account {
                  id
                  emailAddress
                }
              }
              invitationStatus
              isCurrentMember
            }
          }
          id
          name
        }
      }
    }
  }
`

export const DeviceBoards = (props: IDeviceBoards) => {
  const { data, loading, error } = useQuery<DeviceBoardsQuery, DeviceBoardsQueryVariables>(DEVICE_BOARDS_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      deviceId: props.deviceId
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>There was an error loading the boards for this device.</Alert>
  }

  if (!data?.device?.boards?.length) {
    return <Alert severity='info'>There are no boards associated with this device.</Alert>
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Board ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Tenant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.device?.boards.map(board => (
                <TableRow key={board.id}>
                  <TableCell>
                    <TruncatedId path={`/boards/${board.id}`} value={board.id} />
                  </TableCell>
                  <TableCell>{board.title}</TableCell>
                  <TableCell>
                    <Link to={`/tenants/${board.tenant.id}`}>{board.tenant.name}</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  )
}
