import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { RemoveNotificationTag, RemoveNotificationTagVariables } from './__generated__/RemoveNotificationTag'

const MUTATION = gql`
  mutation RemoveNotificationTag($input: RemoveNotificationTagInputV2!) {
    removeNotificationTag: removeNotificationTagV2(input: $input) {
      ... on NotificationSubscriberV2 {
        id
        tags {
          id
          title
        }
      }
      ... on NotificationSubscriberErrorV2 {
        type
        error
      }
    }
  }
`

export const useRemoveNotificationTag = () =>
  useMutation<RemoveNotificationTag, RemoveNotificationTagVariables>(MUTATION)
