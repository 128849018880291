import React from 'react'
import { ButtonBase, TableCell, makeStyles } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

interface ISortableTableCell {
  width?: number
  active?: boolean
  direction: SortDirection
  children: string
  onClick: (direction: SortDirection) => void
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'flex-start'
  }
})

export const SortableTableCell = (props: ISortableTableCell) => {
  const classes = useStyles()
  return (
    <TableCell style={{ width: props.width }}>
      <ButtonBase
        className={classes.root}
        style={{
          width: props.width
        }}
        onClick={() => {
          props.onClick(props.direction === 'asc' ? SortDirection.desc : SortDirection.asc)
        }}>
        <span
          style={{
            whiteSpace: 'nowrap'
          }}>
          {props.children}
        </span>
        {props.active ? props.direction === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
      </ButtonBase>
    </TableCell>
  )
}
