import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link as MuiLink
} from '@material-ui/core'
import { useGetQAs } from '../hooks/useGetQAs'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { FormattedDateTime } from '../../formatted-date-time'

interface IQuestionAnswersList {
  token: string
}

export const QuestionsAndAnswersList = (props: IQuestionAnswersList) => {
  const { data, loading, error } = useGetQAs(props)
  const history = useHistory()

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Questions and Answers</Typography>
        <Button onClick={() => history.push('/questions-and-answers/create')} variant='contained' color='primary'>
          Create New Q&A
        </Button>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      {loading ? (
        <CircularProgress />
      ) : error || !data ? (
        <Alert severity='error'>There was an error loading the questions and answers list.</Alert>
      ) : !!data?.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell style={{ width: 200 }}>Created On</TableCell>
                <TableCell style={{ width: 200 }}>Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    <MuiLink component={Link} to={`/questions-and-answers/${item.id}`}>
                      {item.title}
                    </MuiLink>
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={new Date(item.created_at).getTime()} />
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime value={new Date(item.updated_at).getTime()} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity='warning'>There are no question and answer listings yet.</Alert>
      )}
    </Box>
  )
}
