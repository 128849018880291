import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { FormattedDateTime } from '../../formatted-date-time'
import { TruncatedId } from '../../truncated-id'
import { Spacer } from '@vestaboard/installables'
import { useSearchSSID } from '../hooks/useSearchSSID'

interface ISearchDeviceId {
  ssid: string
}

export const SearchSSIDComponent = (props: ISearchDeviceId) => {
  const { data, loading } = useSearchSSID(props.ssid)

  if (loading) {
    return <CircularProgress />
  }

  if (!data?.adminConfigurationNetwork?.device?.id) {
    return <Alert severity='warning'>There are no configuration networks that match the SSID "{props.ssid}"</Alert>
  }

  return (
    <>
      <Alert severity='info'>On the bright side, we found an unpaired device with the SSID "{props.ssid}"</Alert>
      <Spacer size='large' />
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Device Created</TableCell>
                <TableCell>Pairing Code</TableCell>
                <TableCell>Firmware Version</TableCell>
                <TableCell>Latest Hearteat</TableCell>
                <TableCell>SSID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TruncatedId
                    value={data.adminConfigurationNetwork.device.id}
                    path={`/devices/${data.adminConfigurationNetwork.device.id}`}
                  />
                </TableCell>
                <TableCell>
                  <FormattedDateTime format='MM/DD/YYYY' value={+data.adminConfigurationNetwork.device.created} />
                </TableCell>
                <TableCell>{data.adminConfigurationNetwork.device.pairingCode || ''}</TableCell>
                <TableCell>{data.adminConfigurationNetwork.device.firmwareVersion || 'Unknown'}</TableCell>
                <TableCell>
                  {data.adminConfigurationNetwork.device.latestHeartbeat?.created ? (
                    <FormattedDateTime
                      format='MM/DD/YYYY'
                      value={+data.adminConfigurationNetwork.device.latestHeartbeat?.created}
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>{data.adminConfigurationNetwork.ssid || ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}
