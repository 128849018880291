import { gql } from 'apollo-boost'
import { GetMessageQuery, GetMessageQueryVariables } from './__generated__/GetMessageQuery'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetMessageQuery($id: String!) {
    message(id: $id) {
      id
      text
      formatted
      authorFormatted
      created
      subscription {
        id
        title
      }
      source {
        id
        title
      }
    }
  }
`

export const useGetMessage = (variables: GetMessageQueryVariables) =>
  useQuery<GetMessageQuery, GetMessageQueryVariables>(QUERY, { variables })
