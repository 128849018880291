import React, { useState } from 'react'
import { SearchMessageType } from '../types'
import { useListMessageAppearances } from '../hooks/useListMessageAppearances'
import {
  Box,
  Card,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { ListMessageAppearancesV2_listSearchMessages_searchMessages_FeedItemV2 } from '../hooks/__generated__/ListMessageAppearancesV2'
import { Link as RouterLink } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { Spacer } from '@vestaboard/installables'
import { Check } from '@material-ui/icons'

interface IListMessageAppearancesProps {
  messageId: string
}

const LIMIT = 50

export const ListFeedAppearances = (props: IListMessageAppearancesProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { data, loading, error, fetchMore } = useListMessageAppearances({
    input: {
      types: [SearchMessageType.FeedItem],
      allUsers: true,
      messageId: props.messageId,
      cursor: null,
      limit: LIMIT,
      showHidden: true
    }
  })

  const appearances = (data?.listSearchMessages.searchMessages.filter(
    searchMessage => searchMessage.__typename === 'FeedItemV2'
  ) || []) as ListMessageAppearancesV2_listSearchMessages_searchMessages_FeedItemV2[]

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Alert severity='error'>There was an error loading the message feed appearances</Alert>
  ) : !appearances?.length ? (
    <Alert severity='info'>There are no feed appearances for this message</Alert>
  ) : (
    <Card>
      <InfiniteScroll
        pageStart={0}
        loadMore={async () => {
          if (isLoadingMore) {
            return
          }

          setIsLoadingMore(true)

          return await fetchMore({
            updateQuery(previousQueryResult, options) {
              const newMessageSets = options.fetchMoreResult?.listSearchMessages?.searchMessages || []
              const previousMessageSets = previousQueryResult?.listSearchMessages?.searchMessages || []

              return {
                listSearchMessages: {
                  ...previousQueryResult.listSearchMessages,
                  nextCursor: options.fetchMoreResult?.listSearchMessages.nextCursor || null,
                  searchMessages: [...previousMessageSets, ...newMessageSets]
                }
              }
            },
            variables: {
              input: {
                types: [SearchMessageType.FeedItem],
                allUsers: true,
                messageId: props.messageId,
                cursor: data?.listSearchMessages.nextCursor || null,
                limit: LIMIT,
                showHidden: true
              }
            }
          }).then(res => {
            setIsLoadingMore(false)
            return res
          })
        }}
        hasMore={!!data?.listSearchMessages?.nextCursor}
        loader={
          <Box>
            <Spacer size='large' />
            <CircularProgress />
          </Box>
        }>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Moderated</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Attribution</TableCell>
                <TableCell>Likes Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appearances.map(feedItem => (
                <TableRow key={feedItem.id}>
                  <TableCell>
                    <TruncatedId value={feedItem.id} full path={`/feed-items/${feedItem.id}`} />
                  </TableCell>
                  <TableCell>{feedItem.moderated ? <Check /> : null}</TableCell>
                  <TableCell>
                    <FormattedDateTime value={new Date(feedItem.created).getTime()} />
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={
                        feedItem.personId ? `/person/${feedItem.personId}` : `/subscription/${feedItem.subscriptionId}`
                      }>
                      {feedItem.attribution}
                    </Link>
                  </TableCell>
                  <TableCell>{feedItem.likeCount.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Card>
  )
}
