import React, { useCallback, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'

interface ICorrectInput {
  onBlur: (value: string[]) => void
}

export const CorrectInput = (props: ICorrectInput) => {
  const [value, setValue] = useState('')
  const ref = useRef<any>()

  const handleChange = useCallback(() => {
    props.onBlur(value.split(',').filter((answer: string) => answer !== ''))
    setValue('')
  }, [value, props])

  return (
    <div ref={ref}>
      <TextField
        fullWidth
        multiline
        value={value}
        variant='outlined'
        minRows={3}
        onChange={e => {
          setValue(e.target.value)
        }}
        onBlur={handleChange}
      />
    </div>
  )
}
