import * as React from 'react'
import { Avatar, Box, Button, CircularProgress } from '@material-ui/core'
import { TruncatedId } from '../../truncated-id'
import { useHistory } from 'react-router'
import { MarketplaceListingSortBy, MarketplaceListingSortOrder } from '../types'
import { SearchValueType } from '../../../ui/AdvancedSearch'
import { SuperGrid } from '../../../ui/SuperGrid'
import { FormattedDateTime } from '../../formatted-date-time'
import { SearchInput } from '../../../ui/SearchInput'
import { Confirm } from '@vestaboard/installables'
import { CsvExport } from '../../../ui/CsvExport'
import moment from 'moment'
import { useDeleteMarketplaceListing } from '../hooks/useDeleteMarketplaceListing'
import { MarketplaceListing, useListMarketplaceListings } from '../hooks/useListMarketplaceListings'

type MarketplaceListingsListProps = IMarketplaceListingsListProps

interface IMarketplaceListingsListProps {}

interface DeletableMarketplaceListing extends MarketplaceListing {
  setIsDeleting: () => void
}

const columns = [
  {
    title: '',
    width: 50,
    render: (listing: MarketplaceListing) =>
      listing.listing.icon ? <Avatar src={listing.listing.icon} /> : <Avatar>{listing.title?.split('')[0]}</Avatar>
  },
  {
    title: 'Listing ID',
    key: MarketplaceListingSortBy.id,
    width: 150,
    render: (listing: MarketplaceListing) => (
      <TruncatedId value={listing.id} path={`/marketplace-listings/${listing.id}`} />
    )
  },
  {
    title: 'Listing Title',
    key: MarketplaceListingSortBy.title,
    width: 150,
    render: (listing: MarketplaceListing) => listing.title
  },
  {
    title: 'Installable ID',
    key: MarketplaceListingSortBy.installableId,
    width: 150,
    render: (listing: MarketplaceListing) =>
      listing.installableId ? (
        <TruncatedId value={listing.installableId} path={`/installables/${listing.installableId}`} />
      ) : null
  },
  {
    title: 'Installable Title',
    key: MarketplaceListingSortBy.installableTitle,
    width: 150,
    render: (listing: MarketplaceListing) => listing.installableTitle
  },
  {
    title: 'Message Set ID',
    key: MarketplaceListingSortBy.messageSetId,
    width: 150,
    render: (listing: MarketplaceListing) =>
      listing.messageSetId ? (
        <TruncatedId value={listing.messageSetId} path={`/message-sets/${listing.messageSetId}`} />
      ) : null
  },
  {
    title: 'Message Set Title',
    key: MarketplaceListingSortBy.messageSetTitle,
    width: 150,
    render: (listing: MarketplaceListing) => listing.messageSetTitle
  },
  {
    title: 'Release Date',
    width: 120,
    key: MarketplaceListingSortBy.releaseDate,
    render: (listing: MarketplaceListing) =>
      listing.releaseDate ? <FormattedDateTime format='MM/DD/YYYY' value={listing.releaseDate} /> : ''
  },
  {
    title: 'Developer ID',
    key: MarketplaceListingSortBy.developerId,
    width: 150,
    render: (listing: MarketplaceListing) =>
      listing.developerId ? (
        <TruncatedId value={listing.developerId} path={`/developers/${listing.developerId}`} />
      ) : null
  },
  {
    title: 'Developer Name',
    key: MarketplaceListingSortBy.developerName,
    width: 150,
    render: (listing: MarketplaceListing) => listing.developerName
  },
  {
    title: 'Categories',
    key: MarketplaceListingSortBy.categories,
    width: 150,
    render: (listing: MarketplaceListing) => listing.categoryIds.join(', ')
  },
  {
    title: 'Feature Types',
    key: MarketplaceListingSortBy.featureTypes,
    width: 150,
    render: (listing: MarketplaceListing) => listing.featureTypes.join(', ')
  },
  {
    title: 'Subscriptions',
    key: MarketplaceListingSortBy.subscriptionsCount,
    width: 120,
    render: (listing: MarketplaceListing) => (listing.subscriptionsCount || 0).toLocaleString()
  },
  {
    title: 'Subscriptions in Past 30 Days',
    key: MarketplaceListingSortBy.subscriptionsInPast30DaysCount,
    width: 200,
    render: (listing: MarketplaceListing) => (listing.subscriptionsInPast30DaysCount || 0).toLocaleString()
  },
  {
    title: '',
    width: 100,
    render: (listing: DeletableMarketplaceListing) => (
      <Button variant='contained' color='secondary' onClick={listing.setIsDeleting}>
        Delete
      </Button>
    )
  }
]

const useHashParams = () => {
  const hash = window.location.hash
    .replace('?', '')
    .replace('#', '')
    .split('&')
    .reduce((prev, current) => {
      const [key, value] = current.split('=')
      return {
        ...prev,
        [key]: decodeURI(value)
      }
    }, {}) as any

  if (
    typeof hash.searchValue === 'string' &&
    (hash.searchValue.includes('min') ||
      hash.searchValue.includes('false') ||
      hash.searchValue.includes('true') ||
      hash.searchValue.includes('null'))
  ) {
    hash.searchValue = JSON.parse(hash.searchValue)
  }

  return hash
}

export const MarketplaceListingsList: React.FC<MarketplaceListingsListProps> = props => {
  const hashParams = useHashParams()
  const [perPage] = React.useState(hashParams.perPage ? +hashParams.perPage : 50)
  const [sortBy, setSortBy] = React.useState<MarketplaceListingSortBy>(hashParams.sortBy || MarketplaceListingSortBy.title)
  const [sortOrder, setSortOrder] = React.useState<MarketplaceListingSortOrder>(MarketplaceListingSortOrder.asc)
  const [searchValue, setSearchValue] = React.useState<SearchValueType>(hashParams.searchValue || null)
  const [page, setPage] = React.useState(hashParams.page ? +hashParams.page : 0)
  const [searchType] = React.useState(hashParams?.searchType || 'terms')
  const [searchModel] = React.useState(hashParams?.searchModel || 'marketplaceListings')
  const input = {
    ...(searchValue || searchValue === false
      ? {
          [searchType]: typeof searchValue === 'string' ? (searchValue || '').trim() : searchValue
        }
      : {}),
    ...(sortBy ? { sortBy } : {}),
    sortOrder
  }
  const history = useHistory()
  const { data, loading, refetch, error } = useListMarketplaceListings({
    ...input,
    perPage,
    page
  })
  const [isDeleting, setIsDeleting] = React.useState<string | null>(null)

  const [deleteMarketplaceListing] = useDeleteMarketplaceListing()

  React.useEffect(() => {
    const searchValueText = typeof searchValue === 'string' ? searchValue : JSON.stringify(searchValue)
    window.location.hash = `?sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&perPage=${perPage}&searchType=${searchType}&searchValue=${
      searchValueText || ''
    }&searchModel=${searchModel}`
  }, [sortBy, sortOrder, page, searchType, searchValue, perPage, searchModel])

  const markeplaceListings = data?.listMarketplaceListings.marketplaceListings || []
  const pagination = data?.listMarketplaceListings?.pagination

  if (!data || loading) return <CircularProgress />

  const _deleteMarketplaceListing = async (id: string) => {
    await deleteMarketplaceListing({
      variables: {
        id
      }
    })
    setTimeout(async () => {
      await refetch()
    }, 1000)
  }

  return (
    <>
      {isDeleting ? (
        <Confirm
          title='Are you sure you want to delete this Marketplace Listing?'
          open={true}
          handleClose={() => {
            setIsDeleting(null)
          }}
          handleAccept={async () => {
            await _deleteMarketplaceListing(isDeleting)
            setIsDeleting(null)
          }}></Confirm>
      ) : null}


      <SuperGrid
        title='Marketplace Listings'
        searchType={searchType}
        setSort={({ by, order }) => {
          setSortBy(by)
          setSortOrder(order)
        }}
        centerButtons={
          <>
          <CsvExport
            paginationKey='input'
            query={useListMarketplaceListings.query}
            variables={{
              input
            }}
            getPagination={({ data }) => ({
              ...data.listMarketplaceListings.pagination,
              page: data.listMarketplaceListings.pagination.page + 1
            })}
            formatData={({ data }) => {
              return data?.listMarketplaceListings.marketplaceListings.map((listing: MarketplaceListing) => ({
                ...listing,
                created: listing.created ? moment(listing.created).format('YYYY-MM-DD') : null,
                releaseDate: listing.releaseDate ? moment(listing.releaseDate).format('YYYY-MM-DD') : null,
                installableCreated: listing.installableCreated
                  ? moment(listing.installableCreated).format('YYYY-MM-DD')
                  : null,
                categoryIds: (listing.categoryIds || []).join(', '),
                featureTypes: (listing.featureTypes || []).join(', '),
                deleted: listing.deleted ? 'Yes' : 'No',
                listing: null
              }))
            }}
          />
                <Button
        onClick={() => {
          // @ts-ignore
          setSortBy('')
        }}>
        Sort by best search match
      </Button>
          </>
        }
        renderSearch={
          <Box
            style={{
              display: 'flex'
            }}>
            <SearchInput
              value={(searchValue as string) || ''}
              onSearch={value => {
                setPage(0)
                // @ts-ignore
                setSortBy('')
                setSearchValue(value)
              }}
            />
            <Box
              style={{
                width: 12
              }}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                history.push(`/new-marketplace-listing`)
              }}>
              Create Listing
            </Button>
          </Box>
        }
        columns={columns}
        rows={markeplaceListings.map(row => ({
          ...row,
          setIsDeleting: () => setIsDeleting(row.id)
        }))}
        sortBy={sortBy}
        sortOrder={sortOrder}
        pagination={pagination}
        setPage={setPage}
        error={!!error}
        loading={loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        disableWarnings={searchModel !== 'marketplaceListings'}
      />
    </>
  )
}
