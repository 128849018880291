import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  UpdatePickCategoryMutation,
  UpdatePickCategoryMutationVariables
} from './__generated__/UpdatePickCategoryMutation'

const UPDATE_PICK_CATEGORY = gql`
  mutation UpdatePickCategoryMutation($input: UpdatePickCategoryInput!) {
    updatePickCategory(input: $input) {
      updated {
        id
        title
      }
    }
  }
`

export const useUpdatePickCategory = () => {
  return useMutation<UpdatePickCategoryMutation, UpdatePickCategoryMutationVariables>(UPDATE_PICK_CATEGORY)
}
