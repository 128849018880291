import { Typography } from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import React from 'react'
import { useHistory } from 'react-router'
import { IPickData, PickForm } from '../components/PickForm'
import { useCreatePick } from '../hooks/useCreatePick'
import { useMigratePick } from '../hooks/useMigratePick'

export const PickCreate = () => {
  const history = useHistory()
  const [createPickMutation, { loading: saving }] = useCreatePick()
  const { addToast } = useToasts()
  const [migratePick] = useMigratePick()

  const savePick = async (pick: IPickData) => {
    const { errors, data } = await createPickMutation({
      variables: {
        input: pick
      }
    })

    if (errors?.length) {
      return addToast('There was an error creating your pick', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your pick has been created', {
      appearance: 'success',
      autoDismiss: true
    })

    try {
      await migratePick({
        variables: {
          pickId: data?.createPick.created.id
        }
      })
    } catch (err) {}

    history.push(`/picks/${data?.createPick.created.id}`)
  }

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Pick</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <PickForm onSave={savePick} />
    </>
  )
}
