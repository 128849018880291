import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ApplyProductMutation, ApplyProductMutationVariables } from './__generated__/ApplyProductMutation'

const MUTATION = gql`
  mutation ApplyProductMutation($board: String!, $code: String!) {
    applyRedemptionCode(input: { board: $board, code: $code }) {
      productApplication {
        id
        board {
          id
        }
      }
    }
  }
`

export const useApplyProduct = () => useMutation<ApplyProductMutation, ApplyProductMutationVariables>(MUTATION)
