import React, { useCallback, useState } from 'react'
import { Box, Card, CircularProgress, Typography } from '@material-ui/core'
import {
  BoardEditor,
  createBoard,
  IBoard,
  Spacer,
  Theme,
  CheckBox,
  Button,
  FlexHorizontal,
  useToasts
} from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router'
import { useListColorTemplateCategories } from '../hooks/useListColorTemplateCategories'

export interface IColorTemplateData {
  categoryId: string
  characters: IBoard
}

interface IColorTemplateForm {
  onSave: (colorTemplate: IColorTemplateData) => void
  onDelete?: () => void
  colorTemplate?: IColorTemplateData
}

const useStyles = makeStyles({
  cardInner: {
    backgroundColor: '#181919',
    padding: 50
  }
})

export const ColorTemplateForm = (props: IColorTemplateForm) => {
  const { addToast } = useToasts()
  const [characters, setCharacters] = useState(props.colorTemplate?.characters || createBoard())
  const [category, setCategory] = useState<string | null>(props.colorTemplate?.categoryId || null)
  const { data, loading, error } = useListColorTemplateCategories()
  const classes = useStyles()
  const history = useHistory()

  const { onSave } = props

  const saveColorTemplate = useCallback(() => {
    if (!category) {
      addToast('Please select a category!', {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }
    onSave({
      characters,
      categoryId: category
    })
  }, [characters, category, onSave, addToast])

  return error ? (
    <Alert severity='error'>Unable to load the color template categories</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Theme>
      <Card>
        <Box className={classes.cardInner}>
          <BoardEditor boardValue={characters} setBoardValue={setCharacters} />
          <Spacer size='extraLarge' />
          <Spacer size='large' />
          <Typography variant='h5' color='secondary'>
            Category
          </Typography>
          <Spacer size='medium' />
          {data.colorTemplateCategories.map(colorTemplateCategory => (
            <CheckBox
              key={colorTemplateCategory.id}
              label={colorTemplateCategory.title}
              checked={category === colorTemplateCategory.id}
              onValueChange={() => {
                setCategory(colorTemplateCategory.id)
              }}
            />
          ))}
          <Spacer size='extraLarge' />
          <FlexHorizontal>
            <Button
              width={120}
              buttonType='outline'
              onClick={() => {
                history.push('/color-templates')
              }}>
              Cancel
            </Button>
            <Box width={24} />
            <Button width={120} buttonType='white' onClick={saveColorTemplate}>
              Save
            </Button>
            <Box width={24} />
            {props.onDelete ? (
              <Button width={120} buttonType='danger' onClick={props.onDelete}>
                Delete
              </Button>
            ) : null}
          </FlexHorizontal>
        </Box>
      </Card>
    </Theme>
  )
}
