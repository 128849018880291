import { Button, Divider, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'

export const yoctoStatuses = [
  'Installed',
  'Rebooting',
  'Eligible',
  'Installation Began',
  'Installation Completed',
  'Unknown'
]

export const DeviceListFilter = () => {
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button onClick={handleClick}>Filter By</Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            history.push('/devices')
            handleClose()
          }}>
          All Devices
        </MenuItem>
        <Divider />
        <MenuItem disabled>Yocto Status</MenuItem>
        {yoctoStatuses.map(status => (
          <MenuItem
            key={status}
            onClick={() => {
              history.push(`/devices/yocto-status/${status}`)
              handleClose()
            }}>
            &nbsp; &nbsp; {status}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
