import React from 'react'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import { useHistory, useParams } from 'react-router'
import { CircularProgress, Typography, Link as MuiLink } from '@material-ui/core'
import { ScheduledNotificationForm } from '../components/ScheduledNotificationForm'
import { useListScheduledNotifications } from '../hooks/useListScheduledNotifications'
import { useUpdateScheduledNotification } from '../hooks/useUpdateScheduledNotification'
import { useGetScheduledNotification } from '../hooks/useGetScheduledNotification'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const UpdateScheduledNotification = () => {
  const params = useParams<{ id: string }>()
  const [update] = useUpdateScheduledNotification()
  const { data, loading, error } = useGetScheduledNotification(params.id)

  const { addToast } = useToasts()
  const history = useHistory()

  const isSent =
    data?.scheduledNotification.__typename === 'ScheduledNotificationV2' &&
    (!data.scheduledNotification.sendAt || data.scheduledNotification.sendAt < Date.now())
  const title =
    isSent && data?.scheduledNotification.__typename === 'ScheduledNotificationV2'
      ? `Notification (Sent ${moment(data?.scheduledNotification.sendAt || data.scheduledNotification.createdAt).format(
          'MMMM Do YYYY, h:mm a'
        )})`
      : 'Notification'

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>{title}</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {error ? (
        <Alert severity='error'>There was an error loading the notification</Alert>
      ) : loading ? (
        <CircularProgress />
      ) : data?.scheduledNotification.__typename === 'ScheduledNotificationV2' ? (
        <ScheduledNotificationForm
          disabled={isSent}
          data={{
            segmentId: data.scheduledNotification.segment?.id || '',
            subscriberType: data.scheduledNotification.segment?.id
              ? 'segment'
              : data.scheduledNotification.tag
              ? 'tag'
              : 'persons',
            schedule: data.scheduledNotification.sendAt ? 'later' : 'now',
            tagId: data.scheduledNotification.tag?.id || '',
            subscribers: (data.scheduledNotification.subscribers || []).map(subscriber => ({
              id: subscriber.id,
              title: subscriber.title
            })),
            sendAt: data.scheduledNotification.sendAt
              ? moment(data.scheduledNotification.sendAt).format('YYYY-MM-DD HH:mm:ss')
              : '',
            message: data.scheduledNotification.message,
            title: data.scheduledNotification.title,
            deepLink: data.scheduledNotification.deepLink || '',
            callToAction: data.scheduledNotification.callToAction || ''
          }}
          onSubmit={async input => {
            const result = await update({
              variables: {
                input: {
                  ...input,
                  id: params.id
                }
              },
              refetchQueries: [
                {
                  query: useListScheduledNotifications.QUERY,
                  variables: {
                    input: {
                      cursor: null,
                      limit: 25
                    }
                  }
                }
              ]
            })

            if (result.errors) {
              addToast('There was an error saving the notification', {
                appearance: 'error'
              })
              return
            }

            if (result.data?.updateScheduledNotificationV2.__typename === 'ScheduledNotificationErrorV2') {
              addToast(
                result.data.updateScheduledNotificationV2.error || 'There was an error saving the notification',
                {
                  appearance: 'error'
                }
              )
              return
            } else {
              history.push('/notifications')
            }
          }}
        />
      ) : (
        <Alert severity='error'>There was an error loading the notification</Alert>
      )}
    </>
  )
}
