import React from 'react'
import { ButtonBase, Tooltip, makeStyles } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

interface IReportPreviousButton {
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles({
  arrow: {
    color: '#FFF'
  }
})

export const ReportPreviousButton = (props: IReportPreviousButton) => {
  const classes = useStyles()

  return (
    <Tooltip title='Previous'>
      <ButtonBase disabled={props.disabled} onClick={props.onClick}>
        <ChevronLeft className={classes.arrow} />
      </ButtonBase>
    </Tooltip>
  )
}
