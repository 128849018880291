import { BoardEditor, FullModal, IBoard, createBoard, useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'

import { useCreateMessage } from '../hooks/useCreateMessage'

interface ICreateMessageModalProps {
  onClose: () => void
  onSaved: (message: {
    id: string
    text: string | null
    tags: string[]
    characters: Array<number[]> | null
    createdAt: number
  }) => void
}

export const CreateMessageModal = (props: ICreateMessageModalProps) => {
  const [createMessage] = useCreateMessage()
  const [value, setValue] = useState<IBoard>(createBoard())
  const { addToast } = useToasts()

  return (
    <FullModal onClose={props.onClose}>
      <BoardEditor
        boardValue={value}
        setBoardValue={setValue}
        sendButtonLabel='Save'
        onSend={async () => {
          const response = await createMessage({
            variables: {
              input: {
                characters: value
              }
            }
          })

          if (response.data?.createMessage.__typename === 'MessageV2') {
            props.onSaved({
              id: response.data.createMessage.id,
              text: response.data.createMessage.text,
              characters: response.data.createMessage.characters,
              createdAt: response.data.createMessage.createdAt,
              tags: response.data.createMessage.tags || []
            })
            props.onClose()
          } else {
            addToast(response.data?.createMessage.error || 'There was an error creating your message', {
              appearance: 'error'
            })
          }
        }}
      />
    </FullModal>
  )
}
