import { gql } from 'apollo-boost'
import { DeletePickV2, DeletePickV2Variables } from './__generated__/DeletePickV2'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation DeletePickV2($input: DeletePickInputV2!) {
    deletePick: deletePickV2(input: $input) {
      __typename
      ... on PickV2 {
        id
        date
        created
        categories {
          id
          title
        }
        sortOrder
        attribution
        message {
          id
          text
          characters
        }
      }
      ... on PickErrorV2 {
        type
        error
      }
    }
  }
`

export const useDeletePick = () => {
  return useMutation<DeletePickV2, DeletePickV2Variables>(MUTATION)
}
