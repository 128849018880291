import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { CreateTag, CreateTagVariables } from './__generated__/CreateTag'

const MUTATION = gql`
  mutation CreateTag($input: CreateNotificationTagInputV2!) {
    createNotificationTag: createNotificationTagV2(input: $input) {
      ... on NotificationTagV2 {
        id
        title
      }
      ... on NotificationTagErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreateTag = () => useMutation<CreateTag, CreateTagVariables>(MUTATION)
