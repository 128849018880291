import React from 'react'
import { SegmentForm } from '../components/SegmentForm'
import { Typography, Link as MuiLink } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import { useCreateNotificationSegment } from '../hooks/useCreateNotificationSegment'
import { useListNotificationSegments } from '../hooks/useListNotificationSegments'

export const CreateSegment = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [create] = useCreateNotificationSegment()

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Segment</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <SegmentForm
        onSubmit={async input => {
          const result = await create({
            variables: {
              input
            },
            refetchQueries: [
              {
                query: useListNotificationSegments.QUERY,
                variables: {
                  input: {
                    cursor: null,
                    limit: 25
                  }
                }
              }
            ]
          })

          if (result.errors) {
            addToast('There was an error creating the segment', {
              appearance: 'error'
            })
            return
          }

          if (result.data?.createNotificationSegment.__typename === 'NotificationSegmentErrorV2') {
            addToast('There was an error creating the notification', {
              appearance: 'error'
            })
            return
          } else {
            history.push('/notifications/segments')
          }
        }}
      />
    </>
  )
}
