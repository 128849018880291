import React, { useCallback, useState } from 'react'
import { Typography } from '@material-ui/core'
import { QuestionAnswerForm } from './Form'
import { useCreateQA } from '../hooks/useCreateQa'
import { IQa } from '../types'
import { LoadMask, useToasts } from '@vestaboard/installables'
import { useHistory } from 'react-router'

interface ICreateQuestionAnswer {
  token: string
}

export const CreateQuestionAnswer = (props: ICreateQuestionAnswer) => {
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const create = useCreateQA(props)
  const { addToast } = useToasts()

  const handleCreate = useCallback(
    async (data: IQa) => {
      setSaving(true)
      const result = await create(data)
      setSaving(false)

      if (!!result?.questions_answers_set_id) {
        addToast('Questions and Answer created successfully', {
          autoDismiss: true,
          appearance: 'success'
        })
        history.push(`/questions-and-answers/${result?.questions_answers_set_id}`)
      } else {
        addToast('There was an error saving your Question and Answer', {
          autoDismiss: true,
          appearance: 'error'
        })
      }
    },
    [addToast, create, history]
  )

  return (
    <>
      <Typography variant='h4'>Create Questions and Answers</Typography>
      <QuestionAnswerForm onSubmit={handleCreate} />
      {saving ? <LoadMask /> : null}
    </>
  )
}
