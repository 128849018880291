import React from 'react'
import { Route, Switch } from 'react-router'
import { ListColorTemplates } from './pages/ListColorTemplates'
import { CreateColorTemplate } from './pages/CreateColorTemplate'
import { UpdateColorTemplate } from './pages/UpdateColorTemplate'

export const ColorTemplateRouterV2 = () => (
  <Switch>
    <Route exact path='/color-templates' component={ListColorTemplates} />
    <Route exact path='/color-templates/create' component={CreateColorTemplate} />
    <Route exact path='/color-templates/:id' component={UpdateColorTemplate} />
  </Switch>
)
