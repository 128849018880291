import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { InvalidateActivationCode, InvalidateActivationCodeVariables } from './__generated__/InvalidateActivationCode'

const MUTATION = gql`
  mutation InvalidateActivationCode($id: String!) {
    invalidateActivationCode(input: { activationCode: $id }) {
      id
      code
      isInvalidated
      isUsed
    }
  }
`

export const useInvalidateActivationCode = () => {
  return useMutation<InvalidateActivationCode, InvalidateActivationCodeVariables>(MUTATION)
}
