export enum SearchMessageSortBy {
  attribution = 'attribution',
  date = 'date',
  likes = 'likes',
  messageText = 'messageText',
  moderated = 'moderated'
}

export enum SearchMessageSortDirection {
  asc = 'asc',
  desc = 'desc'
}
