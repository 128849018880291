import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { csvFormat } from 'd3-dsv'
import { csvDownload } from '../utils'
import { CloudDownload } from '@material-ui/icons'

interface ICsvExport {
  data: any[]
  title: string
}

const useStyles = makeStyles({
  root: {
    color: '#CCC',
    padding: 8,
    paddingLeft: 14,
    paddingRight: 14,
    border: 'solid 1px #333',
    marginLeft: 24,
    borderRadius: 6
  }
})

export const CsvSimpleExport = (props: ICsvExport) => {
  const classes = useStyles()
  return (
    <Button
      endIcon={<CloudDownload />}
      className={classes.root}
      onClick={() => {
        const csv = csvFormat(props.data || [], Object.keys(props.data?.[0] || {}))
        csvDownload(csv, props.title)
      }}>
      Export
    </Button>
  )
}
