import { Typography } from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import React from 'react'
import { useHistory } from 'react-router'
import { IColorTemplateCategoryData, ColorTemplateCategoryForm } from '../components/ColorTemplateCategoryForm'
import { useCreateColorTemplateCategory } from '../hooks/useCreateColorTemplateCategory'
import { useMigratedColorTemplates } from '../../../api/useMigrateColorTemplates'

export const ColorTemplateCategoryCreate = () => {
  const history = useHistory()
  const [createColorTemplateCategoryMutation, { loading: saving }] = useCreateColorTemplateCategory()
  const { addToast } = useToasts()
  const [migrate] = useMigratedColorTemplates()

  const onSave = async (colorTemplateCategory: IColorTemplateCategoryData) => {
    const { errors, data } = await createColorTemplateCategoryMutation({
      variables: {
        input: colorTemplateCategory
      }
    })

    if (errors?.length) {
      return addToast('There was an error creating your color template category', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    addToast('Your color template category has been created', {
      appearance: 'success',
      autoDismiss: true
    })

    history.push(`/color-templates/categories/${data?.createColorTemplateCategory.created.id}`)

    await migrate()
  }

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create ColorTemplate Category</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <ColorTemplateCategoryForm onSave={onSave} />
    </>
  )
}
