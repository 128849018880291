import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListMarketplaceListingsV2,
  ListMarketplaceListingsV2Variables
} from './__generated__/ListMarketplaceListingsV2'

const QUERY = gql`
  query ListMarketplaceListingsV2($input: ListMarketplaceListingSearchInputV2!) {
    listMarketplaceListingsV2(input: $input) {
      marketplaceListings {
        id
        title
      }
      nextCursor
    }
  }
`

export const useListMarketplaceListingsV2 = (variables: ListMarketplaceListingsV2Variables) => {
  return useQuery<ListMarketplaceListingsV2, ListMarketplaceListingsV2Variables>(QUERY, {
    variables,
    skip: !variables.input.terms
  })
}
