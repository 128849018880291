import {
  Card,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'

import { Alert } from '@material-ui/lab'
import { Check } from '@material-ui/icons'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { TruncatedId } from '../../truncated-id'
import { useGetDigitalDevice } from '../hooks'
import { useParams } from 'react-router'

export const GetDigitalDevice = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetDigitalDevice({
    id
  })

  return (
    <>
      {error ? (
        <Alert severity='error'>Digital Device not found.</Alert>
      ) : loading ? (
        <CircularProgress />
      ) : data?.board?.__typename === 'BoardErrorV2' ? (
        <Alert severity='error'>{data.board.error}</Alert>
      ) : (
        <>
          <Typography variant='button' display='block' gutterBottom>
            <Link component={RouterLink} to='/digital-devices'>
              Digital Devices
            </Link>
          </Typography>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>{id}</Typography>
          </FlexHorizontal>
          <Spacer size='extraLarge' />
          <Card style={{ maxWidth: 600 }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                    <TableCell>{data?.board?.digitalDevice?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell>{data?.board?.digitalDevice?.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Style</TableCell>
                    <TableCell>{data?.board?.digitalDevice?.digitalDeviceStyle?.title}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Screen Saver Enabled?</TableCell>
                    <TableCell>{data?.board?.digitalDevice?.screenSaverEnabled ? <Check /> : null}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Audio Enabled?</TableCell>
                    <TableCell>{data?.board?.digitalDevice?.audioEnabled ? <Check /> : null}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Board</Typography>
          <Spacer size='small' />
          <Card style={{ maxWidth: 600 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TruncatedId value={data?.board.id || ''} full path={`/boards/${data?.board.id}`} />
                    </TableCell>
                    <TableCell>{data?.board.title}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      )}
    </>
  )
}
