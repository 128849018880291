import { useQuery } from '@apollo/react-hooks'
import { Card, CircularProgress, Table, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { gql } from 'apollo-boost'
import React from 'react'
import { useParams } from 'react-router'
import { TruncatedId } from '../truncated-id'
import { GetInstallationQuery, GetInstallationQueryVariables } from './__generated__/GetInstallationQuery'

const QUERY = gql`
  query GetInstallationQuery($id: String!) {
    installation(id: $id) {
      id
      subscriptions {
        id
      }
      installable {
        id
        title
      }
      marketplaceListing {
        id
        title
      }
      tenant {
        id
        name
      }
      principal {
        id
      }
    }
  }
`

const useStyles = makeStyles({
  columnTitle: {
    width: '200px',
    fontWeight: 'bold',
    background: '#eee'
  }
})

export const Installation = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<GetInstallationQuery, GetInstallationQueryVariables>(QUERY, {
    variables: { id }
  })
  const classes = useStyles()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Installation not found.</Alert>
  }

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Installation</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Table>
          <TableRow>
            <TableCell className={classes.columnTitle}>ID</TableCell>
            <TableCell>
              <TruncatedId value={data?.installation.id || ''} full />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Installable</TableCell>
            <TableCell>
              <TruncatedId
                value={data?.installation.installable.title || ''}
                path={`/installables/${data?.installation.installable.id || ''}`}
                full
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Marketplace Listing</TableCell>
            <TableCell>
              <TruncatedId
                value={data?.installation?.marketplaceListing?.title || ''}
                path={`/marketplace-listings/${data?.installation?.marketplaceListing?.id || ''}`}
                full
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Subscriptions</TableCell>
            <TableCell>
              {(data?.installation.subscriptions || []).map(subscription => (
                <TruncatedId value={subscription.id} path={`/subscription/${subscription.id}`} full />
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Tenant</TableCell>
            <TableCell>
              <TruncatedId
                value={
                  data?.installation?.tenant?.name === 'Unnamed Tenant'
                    ? data?.installation?.tenant?.id
                    : data?.installation?.tenant?.name || ''
                }
                path={`/tenant/${data?.installation?.tenant?.id || ''}`}
                full
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnTitle}>Principal</TableCell>
            <TableCell>
              <TruncatedId
                value={data?.installation?.principal?.id || ''}
                path={`/principal/${data?.installation?.principal?.id || ''}`}
                full
              />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
    </>
  )
}
