import { useMutation } from '@apollo/react-hooks'
import { DeleteColorTemplate, DeleteColorTemplateVariables } from './__generated__/DeleteColorTemplate'
import { gql } from 'apollo-boost'

const DELETE_COLOR_TEMPLATE = gql`
  mutation DeleteColorTemplate($input: DeleteColorTemplateInput!) {
    deleteColorTemplate(input: $input) {
      deleted {
        id
      }
    }
  }
`

export const useDeleteColorTemplate = () => {
  return useMutation<DeleteColorTemplate, DeleteColorTemplateVariables>(DELETE_COLOR_TEMPLATE)
}
