import { ListProductPricings } from './__generated__/ListProductPricings'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const QUERY = gql`
  query ListProductPricings {
    productPricings {
      id
      title
      quantity
    }
  }
`

export const useListProductPricings = () => {
  return useQuery<ListProductPricings>(QUERY)
}
