import { ListMarketplaceFeatureTypes } from './__generated__/ListMarketplaceFeatureTypes'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListMarketplaceFeatureTypes {
    listMarketplaceFeatureTypes: listMarketplaceFeatureTypesV2 {
      marketplaceFeatureTypes {
        id
        title
      }
    }
  }
`

export const useListPlusFeatureTypes = () => useQuery<ListMarketplaceFeatureTypes>(QUERY)
