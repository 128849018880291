import React from 'react'
import {
  ButtonBase,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { BoardPreview, IBoard } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { useHistory, useLocation } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { useListPicks } from '../hooks/useListPicks'

const useStyles = makeStyles({
  chips: {
    '& > *': {
      margin: 3
    }
  }
})

export const PicksHistory = () => {
  const perPage = 25
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page') || '0')

  const { data, loading, error } = useListPicks({
    page,
    perPage
  })

  const picksLength = data?.picksLength || 0

  return error ? (
    <Alert severity='error'>There was an error loading the picks</Alert>
  ) : loading || !data ? (
    <CircularProgress />
  ) : (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Pick ID</TableCell>
            <TableCell>Display On</TableCell>
            <TableCell>End Display</TableCell>
            <TableCell>Categories</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.picks.map(pick => (
            <TableRow key={pick.id}>
              <TableCell>
                <TruncatedId value={pick.id} path={`/picks/${pick.id}`} />
              </TableCell>
              <TableCell>
                <FormattedDateTime format='MM/DD/YYYY' value={pick.recommendAfter} />
              </TableCell>
              <TableCell>
                <FormattedDateTime format='MM/DD/YYYY' value={pick.recommendBefore} />
              </TableCell>
              <TableCell className={classes.chips}>
                {pick.categories.map(category => (
                  <ButtonBase
                    key={category.id}
                    onClick={() => {
                      history.push(`/picks/categories/${category.id}`)
                    }}>
                    <Chip label={category.title} variant='outlined' />
                  </ButtonBase>
                ))}
              </TableCell>
              <TableCell>
                <Link to={`/picks/${pick.id}`}>
                  {pick.message.formatted ? <BoardPreview characters={pick.message.formatted as IBoard} /> : null}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {picksLength ? (
        <TablePagination
          rowsPerPageOptions={[]}
          count={picksLength}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => history.push(`/picks?tab=History&page=${page}`)}
          onChangeRowsPerPage={() => {}}
        />
      ) : null}
    </Card>
  )
}
