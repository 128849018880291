import React from 'react'
import { useParams } from 'react-router'
import { useGetTagSubscribers } from '../hooks/useGetTagSubscribers'
import {
  Typography,
  Link as MuiLink,
  CircularProgress,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import { TruncatedId } from '../../truncated-id'

export const Tag = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetTagSubscribers({
    id
  })

  return (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <MuiLink component={Link} to='/notifications'>
          Notifications
        </MuiLink>
      </Typography>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Tag</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>There was an error loading the tag</Alert>
      ) : data?.notificationTag.__typename === 'NotificationTagErrorV2' ? (
        <Alert severity='error'>{data.notificationTag.error || 'There was an error'}</Alert>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>ID</Typography>
                  </TableCell>
                  <TableCell>
                    <TruncatedId value={data?.notificationTag.id || ''} full />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>Title</Typography>
                  </TableCell>
                  <TableCell>{data?.notificationTag.title}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Subscribers</Typography>
          <Spacer size='medium' />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.notificationTag.subscribers.map(subscriber => (
                  <TableRow key={subscriber.id}>
                    <TableCell>
                      <TruncatedId value={subscriber.id} full path={`/notifications/subscriber/${subscriber.id}`} />
                    </TableCell>
                    <TableCell>{subscriber.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
