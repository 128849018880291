import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  ListScheduledNotifications,
  ListScheduledNotificationsVariables
} from './__generated__/ListScheduledNotifications'

const QUERY = gql`
  query ListScheduledNotifications($input: ListScheduledNotificationInputV2!) {
    listScheduledNotifications: listScheduledNotificationsV2(input: $input) {
      nextCursor
      scheduledNotifications {
        id
        createdAt
        sendAt
        message
        title
        callToAction
        deepLink
        tag {
          id
          title
        }
      }
    }
  }
`

export const useListScheduledNotifications = (variables: ListScheduledNotificationsVariables) => {
  return useQuery<ListScheduledNotifications, ListScheduledNotificationsVariables>(QUERY, {
    variables
  })
}

useListScheduledNotifications.QUERY = QUERY
