import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

interface IReportNumberBox {
  title: string
  number?: number
  postfix?: string
}

const useStyles = makeStyles({
  title: {
    color: '#FFF',
    fontSize: 16,
    paddingLeft: 24,
    fontWeight: 500,
    paddingBottom: 0
  },
  bigNumber: {
    color: '#FFF',
    fontSize: 42,
    padding: 24,
    paddingTop: 0,
    fontWeight: 500
  },
  numberBox: {
    marginRight: 50,
    minWidth: 250
  },
  postfix: {
    fontSize: 16
  }
})

export const ReportNumberBox = (props: IReportNumberBox) => {
  const classes = useStyles()

  return (
    <Box className={classes.numberBox}>
      <Typography className={classes.title}>{props.title}</Typography>
      <Typography className={classes.bigNumber}>
        {(+(props.number || 0).toFixed(2)).toLocaleString()}
        {props.postfix ? <span className={classes.postfix}>{props.postfix}</span> : null}
      </Typography>
    </Box>
  )
}
