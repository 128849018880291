import { MenuItem, Select } from '@material-ui/core'

import React from 'react'
import { useListColorTemplates } from '../../color-template-v2/hooks/useListColorTemplates'

interface ITemplateSelector {
  value: string
  onChange: (value: string) => void
}

export const TemplateSelector = (props: ITemplateSelector) => {
  const { data } = useListColorTemplates({
    input: {}
  })

  return (
    <Select fullWidth variant='outlined' value={props.value} onChange={e => props.onChange(e.target.value as string)}>
      <MenuItem>Template Category</MenuItem>
      {(data?.listColorTemplates?.colorTemplates || []).map(template => (
        <MenuItem key={template.id} value={template.id}>
          {template.title}
        </MenuItem>
      ))}
    </Select>
  )
}
