import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Alert } from '@material-ui/lab'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { ApolloClient, gql } from 'apollo-boost'
import { ApolloConsumer } from '@apollo/react-hooks'
import {
  ReservePairingCodes as ReservePairingCodesMutation,
  ReservePairingCodes_reservePairingCode_pairingCode
} from './__generated__/ReservePairingCodes'
import { saveJSONFile } from '../../utils/file'

const MUTATION = gql`
  mutation ReservePairingCodes($model: String!) {
    reservePairingCode {
      pairingCode {
        id
        pairingCode
        token(type: "flagship", modelUuid: $model)
      }
    }
  }
`

const useStyles = makeStyles({
  box: {
    padding: 24
  }
})

interface IReservePairingCodesFn {
  apollo: ApolloClient<object>
}

const MODELS = {
  'Flagship EVT': '926b1fc5-dea4-42f0-bec4-cf3c434b1846',
  'Flagship DVT': 'c8b4ce4e-be94-4880-8287-c74f9804aef9',
  'Flagship PVT': '59f500bd-73d5-4433-a4e0-21ec4231e8e6',
  'Flagship White EVT': '1352a0e0-a5f8-4602-a250-21dc578df961',
  'Flagship White DVT': '0df43c47-e600-48e0-a2d9-099506d2fe8c',
  'Flagship White PVT': '362bcd53-9945-4c08-a823-f8f518fdd5a2'
}

export const ReservePairingCodesFn = ({ apollo }: IReservePairingCodesFn) => {
  const [showHelp, setShowHelp] = useState(false)
  const [model, setModel] = useState('')
  const [number, setNumber] = useState(0)
  const [numberRemaining, setNumberRemaining] = useState(0)
  const [isGenerating, setIsGenerating] = useState(false)
  const [generated, setGenerated] = useState('')
  const classes = useStyles()

  const downloadFile = useCallback((data: ReservePairingCodes_reservePairingCode_pairingCode[]) => {
    const outputData = data.map(({ pairingCode, token }) => ({
      pairingCode,
      token
    }))

    saveJSONFile('pairing-codes.json', outputData)
    setIsGenerating(false)
    setGenerated(JSON.stringify(outputData, null, 2))
  }, [])

  const reservePairingCodes = useCallback(
    async (numberRemaining: number, data: ReservePairingCodes_reservePairingCode_pairingCode[]) => {
      setNumberRemaining(numberRemaining)

      // Finished
      if (numberRemaining === 0) {
        return downloadFile(data)
      }

      try {
        const result = await apollo.mutate<ReservePairingCodesMutation>({
          mutation: MUTATION,
          variables: {
            model
          }
        })

        // Recurse
        await reservePairingCodes(numberRemaining - 1, [
          ...data,
          result?.data?.reservePairingCode?.pairingCode as ReservePairingCodes_reservePairingCode_pairingCode
        ])
      } catch (e) {
        console.warn('Retrying...')
        await reservePairingCodes(numberRemaining, data)
      }
    },
    [apollo, downloadFile, model]
  )

  return (
    <>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Reserve Pairing Codes</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card>
        <Box className={classes.box}>
          {isGenerating ? (
            <>
              <LinearProgress variant='determinate' value={((number - numberRemaining) / number) * 100} />
              <Spacer size='large' />
              <Typography>
                Generated {number - numberRemaining} of {number} pairing codes...
              </Typography>
            </>
          ) : (
            <>
              <FlexHorizontal>
                <Typography variant='h5'>Model &nbsp;</Typography>
                <Select
                  value={model}
                  label='Model'
                  onChange={e => {
                    setModel(e.target.value as string)
                  }}>
                  {Object.keys(MODELS).map(model => (
                    // @ts-ignore
                    <MenuItem value={MODELS[model]}>{model}</MenuItem>
                  ))}
                </Select>
              </FlexHorizontal>
              <Spacer size='medium' />
              <FlexHorizontal>
                <Typography variant='h5'>Number of Pairing Codes &nbsp;</Typography>
                <ButtonBase onClick={() => setShowHelp(showHelp => !showHelp)}>
                  <HelpIcon />
                </ButtonBase>
              </FlexHorizontal>
              <Spacer size='medium' />

              <FlexHorizontal>
                <TextField
                  variant='outlined'
                  type='number'
                  value={number}
                  onChange={e => {
                    if (+e.target.value < 0) {
                      setNumber(0)
                      return
                    }
                    setNumber(+e.target.value)
                  }}
                />
                <Box width={7} />
                <Button
                  variant='contained'
                  color='primary'
                  onClick={async () => {
                    setIsGenerating(true)
                    await reservePairingCodes(number, [])
                  }}>
                  Generate
                </Button>
              </FlexHorizontal>
              <Spacer size='extraLarge' />
              {showHelp ? (
                <Alert severity='info'>
                  Generating a list of pairing codes will give you a one time file to download that may be imported into
                  the factory server. These pairing codes will be guaranteed to be unique and will be reserved for your
                  use.
                </Alert>
              ) : null}
              {generated ? (
                <>
                  <Spacer size='extraLarge' />
                  <Typography>Output</Typography>
                  <Spacer size='medium' />
                  <TextField
                    fullWidth
                    disabled
                    maxRows={100}
                    value={generated}
                    variant='outlined'
                    minRows={10}
                    multiline
                  />
                </>
              ) : null}
            </>
          )}
        </Box>
      </Card>
    </>
  )
}

export const ReservePairingCodes = () => (
  <ApolloConsumer>{apollo => <ReservePairingCodesFn apollo={apollo} />}</ApolloConsumer>
)
