import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, TextField, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Spacer } from '@vestaboard/installables'
import { useApplyProduct } from '../hooks/useApplyProduct'

interface IApplyActivationCode {
  boardId: string
}

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  space: {
    width: 12
  }
})

export const ApplyActivationCode = (props: IApplyActivationCode) => {
  const classes = useStyles()
  const [code, setCode] = useState('')
  const [applyRedemptionCode, { data, loading, error }] = useApplyProduct()

  useEffect(() => {
    if (data) {
      window.location.reload()
    }
  }, [data])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <Box className={classes.container}>
        <TextField size='small' variant='outlined' value={code} onChange={e => setCode(e.target.value)} />
        <Box className={classes.space} />
        <Button
          variant='outlined'
          onClick={async () => {
            try {
              await applyRedemptionCode({ variables: { board: props.boardId, code } })
            } catch (e) {}
          }}>
          Apply
        </Button>
      </Box>
      {error ? (
        <>
          <Spacer size='large' />
          <Alert severity='error'>Error Applying Activation Code</Alert>
        </>
      ) : null}
    </>
  )
}
