import * as React from 'react'
import { gql } from 'apollo-boost'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Card,
  CircularProgress,
  Link as MuiLink,
  makeStyles,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Chip,
  ButtonBase
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { GetPurchase, GetPurchaseVariables } from './__generated__/GetPurchase'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { Link } from 'react-router-dom'
import { TruncatedId } from '../truncated-id'
import { FormattedDateTime } from '../formatted-date-time'
import {
  OptOutOfUnactivatedRenewal,
  OptOutOfUnactivatedRenewalVariables
} from './__generated__/OptOutOfUnactivatedRenewal'
import { useInvalidateActivationCode } from '../plus/hooks/useInvalidateActivationCode'
import { useUnInvalidateActivationCode } from '../plus/hooks/useUnInvalidateActivationCode'

const DATE_FORMAT = 'MM/DD/YYYY h:mm a'

const OPT_OUT_OF_UNACTIVATED_RENEWAL = gql`
  mutation OptOutOfUnactivatedRenewal($input: OptOutOfUnactivatedRenewalInput!) {
    optOutOfUnactivatedRenewal(input: $input) {
      id
      optOutOfRenewal
    }
  }
`

const PURCHASE_QUERY = gql`
  query GetPurchase($id: String!) {
    purchase(id: $id) {
      id
      optOutOfRenewal
      account {
        id
        person {
          id
          firstName
          lastName
        }
      }
      emailAddress
      created
      purchaseSession {
        id
        shopifyOrderId
        shopifyOrderName
        shopifyUserId
        paymentMethod {
          company
          number
        }
      }
      lineItems {
        id
        productApplication {
          id
        }
        activationCode {
          id
          code
          isInvalidated
          isUsed
        }
      }
    }
  }
`

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    background: '#EEE',
    width: 200,
    color: '#333'
  },
  link: {
    color: '#3f51b5',
    fontSize: '1rem',
    textDecoration: 'none'
  }
})

export const OrderPage = () => {
  const params = useParams<{ id: string }>()
  const [optOutOfRenewalMutation, { error: optOutError }] = useMutation<
    OptOutOfUnactivatedRenewal,
    OptOutOfUnactivatedRenewalVariables
  >(OPT_OUT_OF_UNACTIVATED_RENEWAL)
  const [invalidateActivationCode] = useInvalidateActivationCode()
  const [unInvalidateActivationCode] = useUnInvalidateActivationCode()

  const { data, loading, error } = useQuery<GetPurchase, GetPurchaseVariables>(PURCHASE_QUERY, {
    variables: {
      id: params.id
    }
  })
  const classes = useStyles()

  const purchase = data?.purchase

  const toggleRenewalOptIn = React.useCallback(async () => {
    if (!!purchase?.optOutOfRenewal) {
      return
    }

    try {
      await optOutOfRenewalMutation({
        variables: {
          input: {
            purchaseId: purchase?.id || '',
            reason: 'Deactivated from SuperAdmin'
          }
        }
      })
    } catch (err) {}
  }, [purchase, optOutOfRenewalMutation])

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>Plus order not found</Alert>
      ) : (
        <>
          {' '}
          <Typography variant='button' display='block' gutterBottom>
            <Link to='/orders' component={MuiLink}>
              Orders
            </Link>
          </Typography>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>{params.id}</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <Typography variant='h5'>Order Information</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell className={classes.title}>Order ID</TableCell>
                  <TableCell>
                    <TruncatedId value={purchase?.id || ''} full />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Subscription ID(s)</TableCell>
                  <TableCell>
                    {(purchase?.lineItems || []).map((lineItem, i) => (
                      <React.Fragment key={lineItem.id}>
                        {!!i ? <Spacer size='small' /> : null}
                        <TruncatedId
                          value={lineItem?.productApplication?.id || ''}
                          path={`/plus/${lineItem?.productApplication?.id}`}
                          full
                        />
                      </React.Fragment>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Activation Code(s)</TableCell>
                  <TableCell>
                    {(purchase?.lineItems || []).map(lineItem => (
                      <Chip
                        color={lineItem.activationCode?.isInvalidated ? 'secondary' : 'primary'}
                        label={
                          <>
                            {lineItem.activationCode?.code}
                            {' | '}
                            <ButtonBase
                              onClick={async () => {
                                if (lineItem.activationCode?.isInvalidated) {
                                  await unInvalidateActivationCode({
                                    variables: {
                                      id: lineItem.activationCode?.id || ''
                                    }
                                  })
                                } else {
                                  await invalidateActivationCode({
                                    variables: {
                                      id: lineItem.activationCode?.id || ''
                                    }
                                  })
                                }
                              }}>
                              {lineItem.activationCode?.isInvalidated ? 'Uninvalidate' : 'Invalidate'}
                            </ButtonBase>
                          </>
                        }
                        key={lineItem.activationCode?.id}
                      />
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Date Created</TableCell>
                  <TableCell>
                    <FormattedDateTime format={DATE_FORMAT} value={purchase?.created} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Opted Out of Renewal</TableCell>
                  <TableCell>
                    <Switch
                      checked={purchase?.optOutOfRenewal}
                      onClick={toggleRenewalOptIn}
                      disabled={!!purchase?.optOutOfRenewal}
                    />
                    {optOutError ? (
                      <Alert severity='error'>There was an error opting out this order from renewals</Alert>
                    ) : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Shopify Order ID</TableCell>
                  <TableCell>
                    <a
                      className={classes.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://vestaboard-plus.myshopify.com/admin/orders/${
                        purchase?.purchaseSession?.shopifyOrderId || ''
                      }`}>
                      {purchase?.purchaseSession?.shopifyOrderId || ''}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Shopify Order Name</TableCell>
                  <TableCell>{purchase?.purchaseSession?.shopifyOrderName || ''}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Card>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Purchaser Information</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell className={classes.title}>Account ID</TableCell>
                  <TableCell>
                    <TruncatedId value={purchase?.account?.id || ''} full />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Email Address</TableCell>
                  <TableCell>{purchase?.emailAddress || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Person ID</TableCell>
                  <TableCell>
                    <TruncatedId
                      value={purchase?.account?.person?.id || ''}
                      path={`/person/${purchase?.account?.person?.id}`}
                      full
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>First Name</TableCell>
                  <TableCell>{purchase?.account?.person?.firstName || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Last Name</TableCell>
                  <TableCell>{purchase?.account?.person?.lastName || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Shopify Customer</TableCell>
                  <TableCell>
                    <a
                      className={classes.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://vestaboard-plus.myshopify.com/admin/customers/${
                        purchase?.purchaseSession?.shopifyUserId || ''
                      }`}>
                      {purchase?.purchaseSession?.shopifyUserId || ''}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Payment Method</TableCell>
                  <TableCell>{purchase?.purchaseSession?.paymentMethod?.company || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Payment Card</TableCell>
                  <TableCell>{purchase?.purchaseSession?.paymentMethod?.number || ''}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Card>
        </>
      )}
    </>
  )
}
