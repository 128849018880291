import { useEffect, useState } from 'react'
import { useAuth0 } from '../../../react-auth0-spa'
import { REPORTS_API } from '../../../constants'
import { formatReportLegend } from '../../../utils/formatReportLegend'
import { sorter } from '../utils/sorter'

export interface IMessageSources {
  rangeType: string
  searchKey: string | null
}

export interface IUseGetMessageSources {
  name: string
  'Manual Compose': number
  'Marketplace Listing': number
  'Private Installable': number
  'Board Persons': number
  'Message Sending Persons': number
}

export const useGetMessageSources = (props: IMessageSources) => {
  const { getTokenSilently } = useAuth0()
  const [data, setData] = useState<IUseGetMessageSources[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          props.rangeType !== 'all_time'
            ? `${REPORTS_API}/message-sent/${props.rangeType}/${props.searchKey}`
            : `${REPORTS_API}/message-sent`,
          {
            headers: { 'X-Vestaboard-Token': await getTokenSilently() }
          }
        )
        const json = await response.json()
        const data =
          json?.messagesSentByDay ||
          json?.messagesSentByMonth ||
          json?.messagesSentByYear ||
          json?.messagesSentByWeek ||
          json

        const result = Object.keys(data)
          .sort(sorter)
          .map(key => ({
            'Board Persons': data[key].boardPersons || 0,
            'Manual Compose': data[key].manual,
            'Private Installable': data[key].privateInstallables,
            'Marketplace Listing': data[key].marketplaceListings,
            'Message Sending Persons': data[key].messageSendingPersons || 0,
            name: formatReportLegend(props.rangeType, key)
          }))

        setData(result)
      } catch (e) {
        setData([])
      }
    }

    if (!!props.searchKey) {
      fetchData()
    }
  }, [props.rangeType, props.searchKey, getTokenSilently])

  return { data }
}
