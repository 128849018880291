import { useMutation } from '@apollo/react-hooks'
import {
  DeleteMarketplaceListingMutation,
  DeleteMarketplaceListingMutationVariables
} from './__generated__/DeleteMarketplaceListingMutation'
import { gql } from 'apollo-boost'

const DELETE_MARKETPLACE_LISTING = gql`
  mutation DeleteMarketplaceListingMutation($id: String!) {
    deleteMarketplaceListing(input: { marketplaceListingId: $id }) {
      id
      deleted
    }
  }
`

export const useDeleteMarketplaceListing = () =>
  useMutation<DeleteMarketplaceListingMutation, DeleteMarketplaceListingMutationVariables>(DELETE_MARKETPLACE_LISTING)
