import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { FormattedDateTime } from '../../formatted-date-time'
import { TruncatedId } from '../../truncated-id'
import { useSearchByDeviceId } from '../hooks/useSearchDeviceById'

interface ISearchDeviceId {
  id: string
}

export const SearchDeviceId = (props: ISearchDeviceId) => {
  const { data, loading, error } = useSearchByDeviceId(props.id)
  return (
    <>
      {loading ? <CircularProgress /> : null}
      {error ? <Alert severity='warning'>There are no unpaired devices that match the ID "{props.id}"</Alert> : null}
      {data ? (
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Device Created</TableCell>
                  <TableCell>Pairing Code</TableCell>
                  <TableCell>Firmware Version</TableCell>
                  <TableCell>Latest Hearteat</TableCell>
                  <TableCell>SSID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TruncatedId value={data.device.id} path={`/devices/${data.device.id}`} />
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime format='MM/DD/YYYY' value={+data.device.created} />
                  </TableCell>
                  <TableCell>{data.device.pairingCode || ''}</TableCell>
                  <TableCell>{data.device.firmwareVersion || 'Unknown'}</TableCell>
                  <TableCell>
                    {data.device.latestHeartbeat?.created ? (
                      <FormattedDateTime format='MM/DD/YYYY' value={+data.device.latestHeartbeat?.created} />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>{data.device.configurationNetwork?.ssid || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      ) : null}
    </>
  )
}
