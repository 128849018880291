import { CreateColorTemplateV2, CreateColorTemplateV2Variables } from './__generated__/CreateColorTemplateV2'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation CreateColorTemplateV2($input: CreateColorTemplateInputV2!) {
    createColorTemplate: createColorTemplateV2(input: $input) {
      __typename
      ... on ColorTemplateV2 {
        id
        title
        messages {
          id
          text
          characters
        }
      }
      ... on ColorTemplateErrorV2 {
        type
        error
      }
    }
  }
`

export const useCreateColorTemplate = () => {
  return useMutation<CreateColorTemplateV2, CreateColorTemplateV2Variables>(MUTATION)
}
