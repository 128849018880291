import React from 'react'

import { useFormik } from 'formik'
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core'
import { Spacer } from '@vestaboard/installables'
import * as Yup from 'yup'

const useStyles = makeStyles({
  formWrapper: {
    padding: 24
  },
  buttonSpace: {
    width: 12
  }
})

export interface IMessageSetFormData {
  title: string
  public: boolean
  dynamic: boolean
  dynamicConfiguration: string | null
}

interface IMessageSetForm {
  messageSet?: IMessageSetFormData
  onSave: (messageSet: IMessageSetFormData) => void
  onCancel: () => void
  onDelete?: () => void
}

enum DynamicMessageSetType {
  RSS = 'RssMessageSetDynamicConfiguration'
}

const MessageSetFormSchema = Yup.object().shape({
  title: Yup.string().required('Required')
})

export const MessageSetForm = (props: IMessageSetForm) => {
  const formik = useFormik({
    validationSchema: MessageSetFormSchema,
    initialValues: {
      title: props.messageSet?.title || '',
      public: props.messageSet?.public || false,
      dynamic: props.messageSet?.dynamic || false,
      dynamicConfiguration: props.messageSet?.dynamicConfiguration
        ? JSON.parse(props.messageSet?.dynamicConfiguration)
        : null
    },
    onSubmit: values => {
      props.onSave({
        title: values.title,
        public: values.public,
        dynamic: values.dynamic,
        dynamicConfiguration: values.dynamicConfiguration ? JSON.stringify(values.dynamicConfiguration) : null
      })
    }
  })

  const classes = useStyles()

  return (
    <Card>
      <Box className={classes.formWrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                id='title'
                name='title'
                label='Title'
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Spacer size='large' />
            <Grid item xs={12}>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.public}
                      onChange={() => formik.setFieldValue('public', !formik.values.public)}
                      name='public'
                      color='primary'
                    />
                  }
                  label='Public'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!!props.messageSet}
                      checked={formik.values.dynamic}
                      onChange={() => {
                        const dynamic = !formik.values.dynamic
                        formik.setFieldValue('dynamic', dynamic)
                        formik.setFieldValue(
                          'dynamicConfiguration',
                          dynamic
                            ? {
                                type: DynamicMessageSetType.RSS,
                                rssUrl: ''
                              }
                            : null
                        )
                      }}
                      name='dynamic'
                      color='primary'
                    />
                  }
                  label='Dynamic'
                />
              </Box>
            </Grid>
            <Spacer size='large' />
            {formik.values.dynamic ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='dynamicConfigurationRssUrl'
                  name='dynamicConfigurationRssUrl'
                  label='RSS URL'
                  value={formik.values.dynamicConfiguration?.rssUrl || ''}
                  onChange={e => {
                    const rssUrl = e.target.value
                    formik.setFieldValue('dynamicConfiguration', {
                      type: DynamicMessageSetType.RSS,
                      rssUrl
                    })
                  }}
                  error={formik.touched.dynamicConfiguration && Boolean(formik.errors.dynamicConfiguration)}
                  helperText={formik.touched.dynamicConfiguration && formik.errors.dynamicConfiguration}
                />
              </Grid>
            ) : null}
            <Grid item>
              <Button fullWidth variant='contained' onClick={props.onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button fullWidth variant='contained' color='primary' type='submit'>
                Save
              </Button>
            </Grid>
            {props.onDelete ? (
              <Grid item>
                <Button color='secondary' fullWidth variant='contained' onClick={props.onDelete}>
                  Delete
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </Box>
    </Card>
  )
}
