import * as React from 'react'
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import { useCreateMarketplaceListing } from '../hooks/useCreateMarketplaceListing'
import { useNewMarketplaceListingQuery } from '../hooks/useNewMarketplaceListingQuery'
import { useMigrateMarketplaceListing } from '../hooks/useMigrateMarketplaceListing'

type NewMarketplaceListingContainerProps = INewMarketplaceListingContainerProps

interface INewMarketplaceListingContainerProps {}

export const NewMarketplaceListingContainer: React.FC<NewMarketplaceListingContainerProps> = props => {
  const { data } = useNewMarketplaceListingQuery()
  const history = useHistory()
  const [mutate] = useCreateMarketplaceListing()
  const [handleMigration] = useMigrateMarketplaceListing()

  const messageSetOptions = data?.messageSets
    .sort((l1, l2) => (l1.title ?? '').localeCompare(l2.title ?? ''))
    .map(messageSet => (
      <MenuItem value={messageSet.id}>
        {messageSet.title} ({messageSet.id})
      </MenuItem>
    ))

  const [selectedMessageSet, setSelectedMessageSet] = React.useState(null as string | null)
  const [selectedInstallable, setSelectedInstallable] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')

  const [, setSubmitting] = React.useState(false)

  const submit = async () => {
    if (!selectedInstallable || !title || !description) {
      return
    }

    setSubmitting(true)
    const result = await mutate({
      variables: {
        messageSet: selectedMessageSet,
        installable: selectedInstallable,
        title,
        description,
        releaseDate: new Date().getTime()
      }
    })
    const id = result.data?.createMarketplaceListing?.id

    // Migrate the listing for the new marketplace service
    if (id) {
      await handleMigration({ variables: { id } })
    }

    setSubmitting(false)
    history.push('/marketplace-listings')
  }

  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Marketplace Listing</Typography>
      </FlexHorizontal>
      <Spacer size='extraLarge' />
      <Card style={{ width: 500 }}>
        <Table>
          <TableRow>
            <TableCell>Message set</TableCell>
            <TableCell>
              <Select
                variant='outlined'
                fullWidth
                value={selectedMessageSet}
                onChange={e => setSelectedMessageSet(e.target.value as string)}>
                {messageSetOptions}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Installable</TableCell>
            <TableCell>
              <Select
                fullWidth
                variant='outlined'
                value={selectedInstallable}
                onChange={e => setSelectedInstallable(e.target.value as string)}>
                {data?.publicInstallables
                  .sort((l1, l2) => (l1.title ?? '').localeCompare(l2.title ?? ''))
                  .map(i => (
                    <MenuItem value={i.id}>{i.title}</MenuItem>
                  ))}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>
              <TextField variant='outlined' value={title} onChange={e => setTitle(e.target.value)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <TextField
                variant='outlined'
                multiline
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            history.push('/marketplace-listings')
          }}>
          Cancel
        </Button>
        <Box width={24} />
        <Button onClick={() => submit()} variant='contained' size='large' color='primary'>
          Create
        </Button>
      </FlexHorizontal>
    </Box>
  )
}
