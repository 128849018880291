import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import { LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { gql } from 'apollo-boost'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { MessageSetForm, IMessageSetFormData } from '../message-set-form'
import { CreateMessageSetMutation, CreateMessageSetMutationVariables } from './__generated__/CreateMessageSetMutation'

const CREATE_MESSAGE_SET_MUTATION = gql`
  mutation CreateMessageSetMutation($input: CreateMessageSetInput!) {
    createMessageSet(input: $input) {
      messageSet {
        id
      }
    }
  }
`

export const CreateMessageSet = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [createMessageSetMutation, { loading: creatingMessageSet }] = useMutation<
    CreateMessageSetMutation,
    CreateMessageSetMutationVariables
  >(CREATE_MESSAGE_SET_MUTATION)
  const onSave = useCallback(
    async (data: IMessageSetFormData) => {
      const dynamicConfiguration =
        data.dynamic && data.dynamicConfiguration ? JSON.parse(data.dynamicConfiguration) : null
      const result = await createMessageSetMutation({
        variables: {
          input: {
            public: data.public,
            title: data.title,
            dynamic: data.dynamic,
            dynamicConfiguration: dynamicConfiguration
              ? {
                  rss: {
                    url: dynamicConfiguration?.rssUrl as string
                  }
                }
              : null
          }
        }
      })

      if (result?.errors?.length) {
        addToast('There was an error creating your message set', {
          appearance: 'error',
          autoDismiss: true
        })
        return
      } else {
        addToast('Message set created', {
          appearance: 'success',
          autoDismiss: true
        })

        history.push(`/message-sets/${result.data?.createMessageSet.messageSet.id}`)
      }
    },
    [createMessageSetMutation, addToast, history]
  )

  return (
    <>
      {creatingMessageSet ? <LoadMask /> : null}
      <Typography variant='h4'>Create Message Set</Typography>
      <Spacer size='extraLarge' />
      <MessageSetForm onSave={onSave} onCancel={() => history.push('/message-sets')} />
    </>
  )
}
