import React from 'react'
import { Typography } from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer, useToasts } from '@vestaboard/installables'
import { useHistory } from 'react-router'
import { PickForm } from '../components/PickForm'
import { useCreatePick } from '../hooks/useCreatePick'

export const CreatePick = () => {
  const [createPick, { loading: saving }] = useCreatePick()
  const { addToast } = useToasts()
  const history = useHistory()

  return (
    <>
      {saving ? <LoadMask /> : null}
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Create Pick</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      <PickForm
        onSave={async pick => {
          const response = await createPick({
            variables: {
              input: {
                ...pick
              }
            }
          })

          if (response.data?.createPick?.__typename === 'PickV2') {
            history.push(`/picks/${response.data.createPick.id}}`)
          } else {
            addToast(response?.data?.createPick?.error || 'There was an error updating your pick', {
              appearance: 'error',
              autoDismiss: true
            })
          }
        }}
      />
    </>
  )
}
