import { Route, Switch } from 'react-router'

import { CreateScheduledNotification } from './pages/CreateScheduled'
import { CreateSegment } from './pages/CreateSegment'
import { GetSubscriber } from './pages/GetSubscriber'
import { ListNotifications } from './pages/List'
import { ListSegments } from './pages/ListSegments'
import { ListSubscribers } from './pages/ListSubscribers'
import { ListTags } from './pages/ListTags'
import { NotificationMessage } from './pages/NotificationMessage'
import React from 'react'
import { Tag } from './pages/Tag'
import { Templates } from './pages/Templates'
import { UpdateScheduledNotification } from './pages/UpdateScheduled'
import { UpdateSegment } from './pages/UpdateSegment'

export const NotificationsRouter = () => (
  <Switch>
    <Route exact path={`/notifications/templates`} component={Templates} />
    <Route exact path={`/notifications/subscribers`} component={ListSubscribers} />
    <Route exact path={`/notifications/segments`} component={ListSegments} />
    <Route exact path={`/notifications/segments/create`} component={CreateSegment} />
    <Route exact path={`/notifications/segments/:id`} component={UpdateSegment} />
    <Route exact path={`/notifications/tags`} component={ListTags} />
    <Route exact path={`/notifications/tag/:id`} component={Tag} />
    <Route exact path={`/notifications/message/:id`} component={NotificationMessage} />
    <Route exact path={`/notifications/subscriber/:id`} component={GetSubscriber} />
    <Route exact path={`/notifications/create`} component={CreateScheduledNotification} />
    <Route exact path={`/notifications/:id`} component={UpdateScheduledNotification} />
    <Route exact path={`/notifications`} component={ListNotifications} />
  </Switch>
)
