import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DeleteColorTemplateV2, DeleteColorTemplateV2Variables } from './__generated__/DeleteColorTemplateV2'

const MUTATION = gql`
  mutation DeleteColorTemplateV2($input: DeleteColorTemplateInputV2!) {
    deleteColorTemplate: deleteColorTemplateV2(input: $input) {
      __typename
      ... on ColorTemplateV2 {
        id
        title
        messages {
          id
          text
          characters
        }
      }
      ... on ColorTemplateErrorV2 {
        type
        error
      }
    }
  }
`

export const useDeleteColorTemplate = () => {
  return useMutation<DeleteColorTemplateV2, DeleteColorTemplateV2Variables>(MUTATION)
}
